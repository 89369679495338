<div class="row wrapper border-bottom white-bg page-heading float-e-margins mTB20">
  <div class="col-lg-9">
    <h2><strong>Consultazione Vendite - Dettaglio biglietto</strong></h2>
    <ol class="breadcrumb"><li></li></ol>
  </div>
</div>
<div class="col-lg-4"></div>

<div *ngIf="qrCodeWindow" class="col-lg-4">
  <div class="ibox float-e-margins">
      <div class="ibox-title">
          <a (click)="closeQrCodeTicketDetails()"> <span class="label label-danger pull-right">Chiudi</span></a>
          <h5>QRCode Ticket</h5>
      </div>
      <div class="ibox-content">
        <div class = "col-md-3"></div>
        <qr-code [value]="stringQrCode" [size]="150"></qr-code>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-xs-4">
              <small class="stats-label">Prezzo</small>
              <h4>2.30</h4>
          </div>
          <div class="col-xs-4">
              <small class="stats-label">Ristampato </small>
              <h4>No</h4>
          </div>
          <div class="col-xs-4">
              <small class="stats-label">Numero</small>
              <h4>432.021</h4>
          </div>
        </div>
      </div>           
    </div>
</div>



<div *ngIf="!qrCodeWindow" class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-content">
     
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>Codice Ticket</th>
                <th>Tariffa</th>
                <th>Tipo</th>
                <th>Stato</th>
                <th>Data Scadenza</th>
                <th style="width:100px;"></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let order of orders" style="cursor:pointer">
                <td>{{order.codeOrder}} - {{order.descOrder}}</td>
                <td>{{order.businessName}}</td>
                <td>{{order.descCommission}}</td>
                <td>{{order.orderStatus === 'OPEN' ? 'Aperto' : 'Chiuso'}}</td>
                <td>{{order.orderStatus === 'OPEN' ? 'Aperto' : 'Chiuso'}}</td>
                <td style="width: 50px" class="col-md-4 text-center">
  
                  <button type="button" (click)="viewQrCode('afghjkljkjkjkjkjkja')" class="btn btn-view btn-outline" ><i class="fa fa-qrcode"></i></button>
  
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
            [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

