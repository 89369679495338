import { Component, OnInit,ViewChild, ElementRef, ViewContainerRef, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from '../../../services/utils.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import swal2, { SweetAlertResult } from 'sweetalert2'
declare var jQuery:any;
@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
styleUrls: ['../../../app.component.css']
})
export class  CompaniesListComponent implements OnInit {



 
  
  //FORM
  @ViewChild('formCompany') companyForm: NgForm;
  @ViewChild('formSharingCompany') companySharingForm: NgForm;
  
  
  //OGGETTO LISTA ELEMENTI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  dataListShare: any[] = new Array();
  resales: any[] = new Array();

  errorMessage: string = "";
  urlDeletePath;
  urlSharePath;
  visibleFilter;
  defaultValue;
  UpOrDown;
  viewAll;
  typeUser;
  roleUser;
  widthController;
  visibleModal;
  idCompanyShare;
  idCompanyReciving;
  pathShare; 
  isSol1;
  modalRef: BsModalRef;


  constructor(private modalService: BsModalService,
    public toastr: ToastrService, 
    vcr: ViewContainerRef, private utils: UtilsService, private logger: LoggingService, private router: Router, public listService: ListService){
    this.listService.resetList();
    this.listService.restURL = "/company/companies";
    this.listService.configurationServiceCall("/companies/list",false,true,1,5);
 //  this.toastr.setRootViewContainerRef(vcr);

  }

  ngOnInit() {
   this.loadElements();
   this.UpOrDown = true;
   this.pathShare = "/route/routeshare"
   this.typeUser = this.utils.getTypeUser();
   this.roleUser = this.utils.getRoleUser();
   this.visibleModal = false;
   this.isSol1 = this.utils.isSol1(this.typeUser)

   if( !this.isSol1){
    this.router.navigate(['/companies/details']);
   }
 
    this.viewAll = this.utils.companiesButtonsManager(this.typeUser)
    if(this.viewAll == true){
      this.widthController = "150"
    }else{
      this.widthController = "50"
    }

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  showModal(idCompanyShare, template: TemplateRef<any>){

    this.openModal(template)
    this. dataListShare = []
    this.idCompanyShare = idCompanyShare; 
    console.log("ID AZIENDA CONDIVISIONE", idCompanyShare)
    console.log("ID AZIENDA CONDIVISIONE", this.idCompanyShare)
    console.log("ID AZIENDA CONDIVISIONE", this.resales)
    for(let c of this.resales){
      if(c.idCompany != idCompanyShare){
        this.dataListShare.push(c)
      }
    }
    this.visibleModal = true;
  }



  shareCompany(){
    this.idCompanyReciving  = this.companySharingForm.value.shareWithCompany;
    console.log("Azienda ricevente", this.idCompanyReciving);

   this.serviceShare()

    this.visibleModal = false;

   // this.visibleModal = false;
  }


  getNotify(){
    console.log("entra")
  this.toastr.success('', 'Modifica completata!');



  }

  getError(){
    this.toastr.error('', 'Qualcosa è andato storto!');
  }


serviceShare(){

 
var model ={
  idCompany :  this.idCompanyShare,
  shareWithCompany : this.idCompanyReciving

}




  this.listService.newObject(model, this.pathShare).subscribe(
    (response) => {

        if (response.outcome.success === true) {
          this.getNotify()

          this.modalRef.hide()
        } else {
           this.getError()
        }
    },    
    (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
    }
);
}




  closeModal(){
    this.modalRef.hide()
  }
 
  
  changeStatus(){
    if(this.UpOrDown == true){
      this.UpOrDown = false;

      console.log("VIEE", this.UpOrDown)
    }else{
      this.UpOrDown = true;
    }
  
  }

  getStatusDescription(statusNumber){
    var buttonColor; 
    if(statusNumber == 1){
   return   buttonColor =  "Attivo"
    }
    if(statusNumber == 0){
      return   buttonColor =  "Non Attivo"
       }
  }
  getButtonCircle(statusNumber){
    var buttonColor; 
    if(statusNumber == 1){
   return   buttonColor =  "btn btn-primary btn-circle"
    }
    if(statusNumber == 0){
      return   buttonColor =  "btn btn-danger btn-circle"
       }
  }


  pageChange(page){
    this.listService.page = page; 
    this.loadElements()
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.companyForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){
    this.companyForm.value.businessName = ""
    this.companyForm.value.city = ""
    this.companyForm.value.status = ""
    this.listService.visibleFilter = false;
    this.loadElements();
     this.companyForm.reset();


    //this.loadElements();
  }

  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.resales = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement){
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.listService.backToList = true;
    this.router.navigate(['/companies/edit']);
  }

  checkStatus(status){
    return this.utils.checkStatus(status);
  }

  newCompany(){
    this.listService.backToList = true;
    this.router.navigate(['/companies/new']);
  }


  shareData(data){

    var mySelf = this;
    

    swal2.fire({
      title: 'Condivisione linee',
      text: "Sei sicuro di voler le tue linee con l'azienda "+data.legalBusinessName+" ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, Condividi',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.share(data.idCompany);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })


  }




  share( idCompany){

    this.urlSharePath =  "/share/"+ idCompany;
    
        swal2.fire({title: 'Condivisione in corso',didOpen: function () {swal2.showLoading()}});
        
        this.logger.log("ID SHARE:",idCompany,200);
        
        this.listService.delete(this.urlSharePath).subscribe(
          (response) => {
         
            if (response.outcome.success === true) {
                   
              this.logger.log("Response value",response.value,200);
              swal2.fire("Hai condiviso le Linee", "","success").then((ev: SweetAlertResult) => {
                if (ev.value) {
                  this.loadElements();
                }
              });
            } else {   
              this.logger.log("Response",response,200);
              swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
            }
          },    
          (error) => {
            this.logger.log("Error",error,200);
            swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
          }
        );
        
      }

  


  deleteCompany(id,companyName) {

    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione azienda',
      text: "Sei sicuro di voler eliminare l'azienda "+companyName+" ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(id);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idCompany) {
    this.urlDeletePath =  "/company/"+ idCompany;

    swal2.fire({title: 'Eliminazione in corso...', didOpen: function () {swal2.showLoading()}});
    
    this.logger.log("ID DELETE:",idCompany,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
     
        if (response.outcome.success === true) {
               
          this.logger.log("Response value",response.value,200);
          swal2.fire("L'azienda di trasporto è stata eliminata con successo", "","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.logger.log("Response",response,200);
          swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

}

