
<!-- Modal -->
<div class="modal fade show" id="modalDescription" tabindex="-1" role="dialog" aria-labelledby="modalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" id="modalLabel">Inserisci una motivazione</h4>
                </div>
                <div class="modal-body">
                    <textarea class="form-control" style="max-width: 100%; margin:0 auto;" (change)="doTextareaValueChange($event)"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="deleteText()">Annulla </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="confirmOperation()">Conferma</button>
                </div>
            </div>
        </div>
    </div>