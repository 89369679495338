import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-statistics-operators',
  templateUrl: './statistics-operators.component.html',
  styleUrls: ['../../../app.component.css']
})
export class StatisticsOperatorsComponent implements OnInit {
  @ViewChild('formStatsSold', { static: true }) statsSoldForm: NgForm;

  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();

  dataFilter: any[] = new Array();
  dataOperator: any[] = new Array();
  dataChannel: any[] = new Array();
  dataResale: any[] = new Array();
  transactionData: any[] = new Array();
  resale;
  dataCompany: any[] = new Array();
  bsConfig: Partial<BsDatepickerConfig>;
  bsConfigV: Partial<BsDatepickerConfig>;
  filterUserData: any[] = new Array();
  daterangepickerModel: Date[];

  // PATH CHIAMATE
  urlPaymentPath = '/stats/cash';
  urlDeletePath = '';
  urlChannelPath = '/ticket/channel/channels';
  urlResalePath = '/resale/resales';
  urlOperatorPath = '/user/users?deleted=false'; // cerco utenti NON eliminati
  urlTransactionPath = '/stats/sold/analysis/ticket?idTransaction=';
  urlCompanyPath = '/company/companies';
  urlCsvSoldPath = '/stats/sold/csv';
  urlCsvPaymentPath = '/stats/cash/cash/csv';
  urlPdfPaymentPath = '/stats/cash/cash/pdf';

  totalAmount;
  totalSupplementAmount;
  isSoluzione1;
  typeUser;
  datepickerModel: Date[];
  datepickerModelV: Date[];
  colorTheme = 'theme-orange';
  errorMessage = '';
  resumePayment;
  totalToPay;
  displayedTotalToPay = 0;
  totalPaid;
  isPayment = 'PAYMENT';

  // DATEPICKER FILTRI
  public dateSellTo = {
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  public dateSellFrom = {
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  modalRef: BsModalRef;

  public labelHeaderExport = {
    idUser : 'ID utente',
    user : 'Utente',
    transactionDate : 'Data transazione',
    paymentType : 'Tipo pagamento',
    payment : 'pagato',
    totAmount : 'Importo totale',
    numTransaction : 'Numero transazioni',
    userPayments : 'userPayments',
    totPayment : 'Totale versato',
    amountToPay : 'Totale da versare',
    maxValuePay : 'maxValuePay',
    isCollapsed : 'isCollapsed'
  }

  public columnToRemove = [
    'isCollapsed',
    'userPayments'
  ]

  constructor(
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService
  ) {

    this.listService.resetList();
    this.listService.configurationServiceCall(
      '/statistics/cash',
      false,
      true,
      1,
      1000
    );

    this.listService.restURL = '/stats/cash/cash';
  }

  ngOnInit() {
    this.getChannels();
    this.getOperators();
    this.getResales();
    this.getCompanies();

    console.log('Valore iniziale', this.statsSoldForm.value.idUser);
    console.log('Valore iniziale', this.statsSoldForm.value.operation);

    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);

    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: 'DD-MM-YYYY' },
      { rangeInputFormat: 'DD-MM-YYYY' }
    );

    this.bsConfigV = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: 'DD-MM-YYYY' },
      { rangeInputFormat: 'DD-MM-YYYY' }
    );
  }

  isPaymentCheck(event: Event, string: string) {
    this.isPayment = string;
    this.dataList = [];
    if (string === 'PAYMENT') {
      // this.isPayment = true;
      this.listService.restURL  = '/stats/cash/cash'
    } else if (string === 'CHECK')  {
      //  this.isPayment = false;
      this.listService.restURL  = '/stats/cash/check'
    } else if (string === 'EXTERNAL')  {
      //  this.isPayment = false;
      this.listService.restURL  = '/stats/cash/cash'
    }

    console.log('Versamento  e Controllo? ' + this.isPayment);
  }

  openModal(template: TemplateRef<any>, data) {
    this.resumePayment = data;
    console.log(this.resumePayment);
    this.modalRef = this.modalService.show(template);
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  calculateAmount(toPay, paid) {
    const result = toPay - paid;
    return result;
  }

  getFilter() {
    if (this.isPayment === 'EXTERNAL') {
      this.dataFilter['external'] = true;
    } else {
      this.dataFilter['external'] = false;
    }

    if (this.statsSoldForm.value.idUser === 'all') {
      this.dataFilter['idUser'] =  'all'
    } else {
      this.dataFilter['idUser'] = this.statsSoldForm.value.idUser
    }

    if (this.isPayment === 'PAYMENT' || this.isPayment === 'EXTERNAL') {
      let stringFirstDay = 'not';

      if (this.datepickerModel !== null) {
        stringFirstDay = this.utils.stringTotalFRomDate(this.datepickerModel);
      }

      /*this.dataFilter['bsDatepicker'] = 'all';
      if (stringFirstDay !== null && stringFirstDay !== undefined) {
        this.dataFilter['dateFrom'] = stringFirstDay;
        this.dataFilter['dateTo'] = stringFirstDay;
      }


      this.dataFilter['bsDatepicker'] = 'all';
      if (stringFirstDay === 'not') {
        this.dataFilter['dateFrom'] = 'all';
        this.dataFilter['dateTo'] = 'all';
      } else {
        this.dataFilter['dateFrom'] = stringFirstDay;
        this.dataFilter['dateTo'] = stringFirstDay;
      }*/

      this.dataFilter['bsDaterangepicker'] = 'all';
      const dateFrom = this.utils.stringTotalFRomDate(this.daterangepickerModel[0]);
      const dateTo = this.utils.stringTotalFRomDate(this.daterangepickerModel[1]);
      this.dataFilter['dateFrom'] = dateFrom + ' ' + '00:00:00';
      this.dataFilter['dateTo'] = dateTo + ' ' + '23:59:59';
      this.dataFilter['balanceDate'] = null;
    } else {

      if (this.statsSoldForm.value.idUser === 'all') {
        this.dataFilter['idUser'] =  'all'
      } else {
        this.dataFilter['idUser'] = this.statsSoldForm.value.idUser
      }


      this.dataFilter['bsDaterangepicker'] = 'all';
      const dateFrom = this.utils.stringTotalFRomDate(this.daterangepickerModel[0]);
      const dateTo = this.utils.stringTotalFRomDate(this.daterangepickerModel[1]);
      this.dataFilter['dateFrom'] = dateFrom;
      this.dataFilter['dateTo'] = dateTo;
      if (this.isPayment === 'CHECK' ) {
        this.dataFilter['balanceDate'] = this.utils.formatDate(this.statsSoldForm.value.bsDatepickerV);
      }
    }
  }
  // FILTRI
  filterSearch() {
    this.getFilter();
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  // SERVIZI SELECT
  getChannels() {
    this.listService.getListSelect(this.urlChannelPath).subscribe(
      response => {
        this.dataChannel = response.results;
        this.logger.log('Lista canali', this.dataChannel, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  close() {
    this.modalRef.hide();
  }

  getOperators() {
    this.listService.getListSelect(this.urlOperatorPath).subscribe(
      response => {
        this.dataOperator = response.results;
        if (localStorage.getItem('typeUser') === 'RESALE') {
          this.filterUser('SELLER');
        } else {
          this.filterUser('OPERATOR');
        }
        this.logger.log('Lista operatori: ', this.dataOperator, 300);
      },
      error => {}
    );
  }

  getPaymentType(string) {
    let value;

    if (string === 'CASH') {
      value = 'Contante';
    } else {
      value = 'POS';
    }
    return value;
  }

  getResales() {
    this.listService.getListSelect(this.urlResalePath).subscribe(
      response => {
        this.dataResale = response.results;
        this.logger.log('Lista rivendite: ', this.dataResale, 300);
      },
      error => {}
    );
  }

  filterUser(roleUser) { // filtro per operatori di company o di rivendita
    for (let u of this.dataOperator) {
      if (u.roleUser === roleUser) {
        this.filterUserData.push(u);
      }
    }
  }

  collapser(data) {
    if (data['isCollapsed'] === true) {
      data['isCollapsed'] = false;
    } else {
      data['isCollapsed'] = true;
    }
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.dataList = response.results;

        this.listService.totalRows = response.total;
        this.totalToPay = 0.0;
        this.totalPaid = 0.0;
        for (let d of this.dataList) {
          d.maxValuePay = d.amountToPay;
          d['isCollapsed'] = true;
          this.totalToPay = this.totalToPay + d.amountToPay;
        }

        this.logger.log('Response:', response, 300);
        this.totalAmount = response.totalAmount;
        this.totalSupplementAmount = response.totalSupplementAmount;
        this.totalPaid = this.totalAmount - this.totalToPay;
        this.errorMessage = '';
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['id'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/statistics/ticket']);
  }

  getDetailsTicket(idTransaction) {
    this.listService
      .getListSelect(this.urlTransactionPath + idTransaction)
      .subscribe(
        response => {
          this.transactionData = response.results;

          for (let t of this.transactionData) {
            if (t.channel === 'Rivendita') {
              this.resale = t.resale;
            } else {
              this.resale = t.channel;
            }
          }

          console.log('TRANSAZIONI', this.transactionData);
        },
        error => {}
      );
  }

  checkName(string) {
    let value;
    if (string === 'Wallet recharge') {
      value = 'Ricarica';
    } else {
      value = 'Vendita Biglietto';
    }
    return value;
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe(
      response => {
        this.dataCompany = response.results;
        this.logger.log('Lista canali', this.dataChannel, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }


  getCompanyName(idCompany) {
    let string;
    for (let c of this.dataCompany) {
      if (c.idCompany === idCompany) {
        return string = c.legalBusinessName;
      }
    }

  }

  // Calcola i totali versati per compagnia
  getSumOfUserPayments(userPayments) {
    const total = userPayments.reduce((accum, item) => accum + item.totPayment, 0);
    return total;
  }

  // calcola i totali venduti per compagnia
  getSumOfTotAmount(userPayments) {
    const total = userPayments.reduce((accum, item) => accum + item.totAmount, 0);
    return total;
  }

  // calcola i totali supplementi venduti per compagnia
  getSumOfTotSupplementAmount(userPayments) {
    const total = userPayments.reduce((accum, item) => accum + item.totSupplementAmount, 0);
    return total;
  }

  // calcola i totali ancora da versare per compagnia
  getSumOfAmountToPay(userPayments) {
    const total = userPayments.reduce((accum, item) => accum + (+item.amountToPay), 0);
    return total;
  }

  payment(dataElement, type, passedIdCompany) {
    /**
     * type = 0 uso data del picket per il versamento
     * type = 1 serve per indicare la data di oggi del versamento idCompany è la compagnia per la quale effettuiamo il versamento
     */
    const myself = this;
    const data = dataElement;
    // console.log(' dataElement.transactionDate ' +  dataElement.transactionDate + 'DATEPICKET ' + this.datepickerModel);
    let dataPassed = {
      idUser: dataElement.idUser,
      paymentDate: dataElement.transactionDate
        ? this.utils.formatDate(dataElement.transactionDate)
        : this.utils.formatDate(this.statsSoldForm.value.bsDatepicker),
      balanceDate: type === 1
        ? this.utils.today()
        : this.utils.formatDate(this.statsSoldForm.value.bsDatepickerV),
      amount: data.amountToPay,
      paymentType: data.paymentType,
      idCompany: dataElement.idCompany ? dataElement.idCompany : passedIdCompany,
    };
    this.listService.newObject(dataPassed, this.urlPaymentPath).subscribe(
      response => {
        if (response.outcome.success === true) {
          this.dataList = response.results;
          this.listService.totalRows = response.total;
          this.logger.log('Response:', response, 300);
          swal2.fire('Incasso Versato', 'Il versamento è stato eseguito', 'success');
          this.loadElements();
          this.isPayment = 'PAYMENT';
          this.statsSoldForm.value.operation = 'PAYMENT';
        } else {
          swal2.fire(
            'Attenzione!',
            'Non è stato possibile eseguire il versamento. Controlla che in nel giorno indicato sia stato effettivamente effettuato un incasso',
            'warning'
          );
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        swal2.fire(
          'Attenzione!',
          'Non è stato possibile eseguire il versamento',
          'warning'
        );
        //    this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  deletePayment(id) {
    const mySelf = this;

    swal2.fire({
      title: 'Eliminazione Versamento',
      text: 'Sei sicuro di eliminare il versamento?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      function(ev: SweetAlertResult) {
        if (ev.value) {
          mySelf.deleteElement(id);
        }
      },
      function(dismiss) {
        if (dismiss === 'cancel') {
          swal2.close();
        }
      }
    );
  }

  deleteElement(id) {
    this.urlDeletePath = '/stats/cash/' + id;

    swal2.fire({
      title: 'Eliminazione in corso...',
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.logger.log('ID DELETE:', id, 200);

    this.listService.delete(this.urlDeletePath).subscribe(
      response => {
        if (response.outcome.success === true) {
          this.logger.log('Response value', response.value, 200);
          swal2.fire('Il versamento è stato cancellato', '', 'success').then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
              this.close();
            }
          });
        } else {
          this.logger.log('Response', response, 200);
          swal2.fire(
            'Errore',
            'Spiacente, si è verificato un errore tecnico.Riprova più tardi',
            'error'
          );
          this.close();
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.Riprova più tardi',
          'error'
        );
      }
    );
  }

  getObject(data) {
    console.log('oggetto Da inviare al server', data);
  }

  changeValue(target, data) {
      data['amountToPay'] = target;
  }

  manageExport(): void {
    switch (this.isPayment) {
      case 'PAYMENT':
        this.exportPayment();
      break;
      case 'CHECK':
        this.exportPaymentCheck();
      break;
    }
  }

  // ---------- PRIVATE METHODS ---------- //

  private exportPayment(): void {
    if (!this.dataFilter) {
      this.getFilter();
    }

    this.listService.exportListPdf(this.dataFilter, this.urlPdfPaymentPath).subscribe(
      (response) => {
        this.utils.pdfDownloadManagement(response);
      }, (error) => {
        this.logger.log('Error', error, 200);
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  /** Method that manages the export function for checks tab section */
  private exportPaymentCheck(): void {
    const name_user = this.dataList[0].user;
    console.log( 'exportPaymentCheck');
    const _keys = Object.keys( this.dataList[0] )
    console.log( '_key' , _keys )

    //  Costruisco un nuovo array con le chiavi tradotte
    let data4Export = this.dataList.map( obj => {
      let newObj = {}
      _keys.forEach(k => {
        newObj[this.labelHeaderExport[k]] = obj[k]
      });
      console.log('newObj' , newObj)
      return newObj;
    });

    //  Rimuovo eventuali campi che non desidero visualizzare nell'excel
    data4Export = data4Export.map( obj => {
      this.columnToRemove.forEach( col => {
        delete obj[col]
        console.log( 'obj' , obj )
        console.log( 'colToRemove' , col )
      });
      return obj;
    })
    console.log( data4Export )
    //  console.log( 'data4Export' , data4Export )
    //  this.utils.exportAsExcelFile( this.dataList , 'sample');
    this.utils.exportAsExcelFile( data4Export , name_user );
  }

}
