<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">



  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Movimenti</strong>
      </h2>
      <div class="ibox-tools col-xs-1 pull-right">

      </div>
    </div>


    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">

          <div class="row col-lg-3 b-r ">


            <div class="row">
              <div class="col-lg-12">

                <div class="{{checkColorCreditAvaiable()}}">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fa fa-eur fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> Credito Residuo </span>
                      <h2 class="font-bold">{{avaiableCredit + trust | number : '1.2-2'}}</h2>
                    </div>
               
                  
                  </div>
                  <div class= "text-right">
                  <span *ngIf="trust != 0" > di cui fido: {{trust}} €</span>
                </div>
                </div>

              </div>

            </div>
          </div>
         
          <div style="margin-top: 8px; padding-left: 0px" class="row col-lg-9 col-sm-12 pull-right">
            <form #formWallet="ngForm">

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <label>Data da:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateFrom"
                    id="dateFrom"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    (bsValueChange)="onDateChanged($event)"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <label>Data a:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateTo"
                    id="dateTo"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>Tipo</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="transactionType" class="form-control" ngModel name="transactionType" required transactionType #transactionType="ngModel"
                      required>
                      <option [ngValue]="'Wallet Recharge'"> Ricarica</option>
                      <option [ngValue]="'Tickets Sale'"> Vendita Ticket</option>

                    </select>
                  </div>
                </div>
              </div>
              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>Operatore</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCommission" class="form-control" ngModel name="idCommission" required idCommission #idCommission="ngModel">
                      <option [ngValue]=0> </option>
                      <option *ngFor="let c of commissions" [ngValue]="c.idUser">{{c.name}} {{c.surname}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                  <div class="form-group">
                    <label>Metodo di pagamento</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="paymentType" class="form-control" ngModel name="paymentType" required paymentType #paymentType="ngModel" required>

                        <!-- <option [ngValue]="'POS'"> Pos</option> -->
                        <!-- <option [ngValue]="'CASH'"> Cash</option> -->

                        <option [ngValue]=0> </option>
                        <option *ngFor="let p of paymentTypeList" [ngValue]="p.code">{{p.description}}</option>

                      </select>
                    </div>
                  </div>
                </div>

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>


      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th></th>
              <th>Data</th>
              <th>Tipo</th>
              <th>
                <p style="margin-bottom: 0px">Operatore</p>
              </th>

              <th  > Metodo di pagamento</th>
              <th  class="text-right" > Aggio</th>

              <th  class="text-right" > Rifatturazione</th>
              <th class="text-right">Importo</th>


            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let t of dataList" style="cursor:pointer">
              <td style="width: 60px" class="col-md-4 text-center">
                <button title="{{checkTitle(t.transactionType)}}" type="button" class="{{ checkColor(t.transactionType)}}">
                  <i class="{{checkIcon(t.transactionType)}}"></i>
                </button>
              </td>
              <td>{{t.transactionDate | date:'dd/MM/yyyy HH:mm:ss' }} </td>
              <td>{{checkName(t.transactionType)}}</td>
              <td>Operatore</td>
              <td>{{getPaymentType(t.paymentType)}}</td>
              <td class="text-right" *ngIf="t.agio">{{t.agio}}%</td>
              <td *ngIf="!t.agio">---</td>
  
  
              <td class="text-right" *ngIf="t.agio">{{calculateAggio(t.amount, t.agio)  | number : '1.2-2'}} €</td>
              <td *ngIf="!t.agio">---</td>
              <td class="text-right"  [ngStyle]="{'color': t.amount >= 0? '#1ab394' : '#ec4758'}"> <strong>{{t.amount | number : '1.2-2'}} €</strong></td>
  
            </tr>
          </tbody>
          <tr>
            <td colspan="7" style="background-color: rgba(255, 255, 255, 0.9);">

            </td>
            <td class="text-right" > <strong>Totale Uscite</strong> <br>  <strong style="color: #ec4758 ">{{cost | number : '1.2-2'}} €</strong> </td>
          </tr>

          <tr>
            <td  colspan="7">

            </td>
            <td class="text-right" >  <strong>Totale Incassi</strong> <br> <strong style="color: #1ab394 ">{{revenue | number : '1.2-2'}} €</strong> </td>
          </tr>

        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>

    </div>
  </div>

</div>