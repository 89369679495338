import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { QrCodeModule } from 'ng-qrcode';
import { RecoverPdfComponent } from './recover-pdf.component';



@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    QrCodeModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ RecoverPdfComponent]
})
export class RecoverPdfModule {}