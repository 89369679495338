<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div *ngIf="!qrCodeWindow" class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-md-6">
          <strong>Analisi Obliterazioni</strong>
        </h2>
      </div>
      <div class="ibox-content">

        <form #formStatsTicket="ngForm">
          <div *ngIf="isSol1" class="form-group col-lg-2 col-xs-6">
            <label>Azienda di trasporto</label>
            <div class='input-group col-xs-12'>
              <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel"
                required>
                <option [ngValue]="'all'"> Tutti </option>
                <option *ngFor="let c of dataCompany" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
              </select>
            </div>
          </div>

          <div class="form-group col-lg-2 col-xs-6">
            <label>Id Ticket</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <!--<input type="text" id="idTicket" class="form-control" ngModel name="idTicket" required idTicket #idTicket="ngModel">-->
              <input type="text" id="idTicket" class="form-control" ngModel name="idTicket" required idTicket #idTicket="ngModel" [(ngModel)]="dataFilter['idTicket']">
            </div>
          </div>

          <div class="form-group col-lg-2 col-xs-6">
            <label>Id Card</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <input type="text" id="cardId" class="form-control" ngModel name="cardId" required cardId #cardId="ngModel">
            </div>
          </div>

          <div class='col-xs-12 col-sm-6 col-md-12 col-lg-3'>
            <label>Obliterato tra - e:</label>
            <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue" ngModel bsDaterangepicker
              #bsDaterangepicker placement="right" [bsConfig]="bsConfig" placement="bottom" autocomplete="off">
          </div>

          <div class='col-xs-12 col-sm-6 col-md-12 col-lg-3'>
            <label for="idObliterator">Obliteratrice (Veicolo) </label>
            <select type="text" id="idObliterator" class="form-control" ngModel name="idObliterator"
                required idObliterator #idObliterator="ngModel" >
                <option *ngFor="let obliterator of dataObliterator, let f = index" [value]="obliterator.idObliterator">{{obliterator.name}} ({{getNameVehicle(obliterator.idVehicle)}}) </option>
            </select>
          </div>

          <div class="clearfix"></div>

          <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Linea</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select (change)="onChange($event.target.value)" type="text" id="routeId" class="form-control" ngModel name="routeId" routeId
                #routeId="ngModel" required [(ngModel)]="modTicketRoute">
                <option [ngValue]="'all'"> Tutte </option>
                <option *ngFor="let o of lineList, let x = index" [value]='x'>{{o.routeId}}, {{o.routeLongName}}</option>
            </select>
            </div>
          </div>

          <div class="form-group col-lg-2 col-xs-4">
            <label for="direction">Direzione</label>
            <select (change)="getDirection($event.target.value)" type="text" id="direction" class="form-control" ngModel name="direction" direction
              #direction="ngModel" required [(ngModel)]="modTicketDir">
                <option [ngValue]="'all'"> Tutte </option>
                <option *ngFor="let d of modDirectionList, let f = index" [value]="f">{{d.tripHeadsign}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2 col-xs-4">
            <div class="form-group">
                <label>Data di viaggio</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="tripDate"
                    id="tripDate"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig"
                    (bsValueChange)="onDateChanged($event)" />
                </div>
            </div>
          </div>

          <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Corsa</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="idTrip" (change)="getTrip($event.target.value)" class="form-control" ngModel name="idTrip" required idTrip #idTrip="ngModel" [(ngModel)] = "modTicketTrip">
                <option [ngValue]="null"> Tutte </option>
                <option  *ngFor="let data of modTripList,let i = index" [value]="i">{{data.departureTime}} - {{data.arrivalTime}} ({{data.tripId}})</option>
            </select>
            </div>
          </div>

          <div class='col-xs-12 col-sm-6 col-sm-6 col-md-3 col-lg-3'>
            <div class="form-group">
              <label></label>
              <div class='input-group col-md-12'>
                <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                  <i class="fa fa-rotate-left"></i>
                </button>
                <button type="button" (click)="getCsv()" style="margin-left: 10px" class="btn btn-info btn-outline">
                  Esporta</button> 
              </div>
            </div>
          </div>

        </form>

        <div class="clearfix"></div>
        <p  class="bg-info p-sm"><b>N.B.</b> Per cercare ticket legati ad una corsa selezionare Linea, Direzione e data di viaggio</p>
        <p  class="">Ticket totali: <b>{{totalRecords}}</b></p> 
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
          

        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>ID Ticket</th>
                <th>Tipologia</th>
                <th>Validità</th>
                <th>Obliterato il</th>
                <th>Obliterato da</th>
                <th>Obliteratrice</th>
                <th>Corsa Obliterazione</th>
                <th>QRCode</th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList" style="cursor:pointer">
              
                <td>
                  {{data.idTicket}}
                  <span *ngIf="!!data.voucherCode">
                    <br />
                    <small>Voucher: <b>{{data.voucherCode}}</b></small>
                  </span>
                </td>
                <td>{{checkNameKind(data.categoryType)}}
                  <ng-container *ngIf="data.cardId != null"><br><small>({{data.cardId}})</small></ng-container></td>
                <td>{{data.validFrom | date:'dd-MM-yyyy HH:mm:ss' }} / {{data.validTo | date:'dd-MM-yyyy HH:mm:ss' }}</td>
                <td>{{data.obliterationTs | date:'dd-MM-yyyy HH:mm:ss' }}</td>
                <td>{{data.firstname}} {{data.surname}}</td>
                <td>{{getNameObliterator(data.idObliterator)}}</td>
                <td><small>{{data.origin}} - {{data.destination}} <b>({{data.idTripObliteration}})</b></small></td>
                <td style="width: 60px">
                  <button (click)="openModal(data.codeEncrypted, ticketTemplate)" title="{{utils.checkTitleTickets(data.status)}}"
                    style="width: 40px" type="button" class="btn btn-outline btn-success">
                    <i class="fas fa-qrcode"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="paginationVisible" class="pull-right" [collectionSize]="listService.totalRows" [pageSize]="listService.rows"
            [maxSize]="10" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ticketTemplate>

  <div tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">QrCode:</h4>
      </div>
      <br>

      <div class="modal-body">
        <ng-container >
            <div class="row text-center"> <qr-code [value]="qrcodeString" [size]="150"></qr-code></div>
        </ng-container>
          <!-- <h2 style="margin-left: 25px">{{kindMenu}} <span *ngIf="cardId != null"> num. {{cardId}}</span></h2>
          <div class="row" style="margin-left: 15px">
            <div class="col-xs-6">
              <h3><i class="fas fa-calendar-alt" ></i> {{usableOn | date:'dd-MM-yyyy HH:mm'}}</h3>
              <h3><i class="fas fa-map-marker-alt" style="color:green"></i> {{origin}}</h3>
            </div>

            <div class="col-xs-6">
                <h3 *ngIf="idRoute"><i class="fas fa-route"></i> {{idRoute}}</h3>
                <h3><i class="fas fa-map-marker-alt" style="color:red"></i> {{destination}}</h3>
            </div>
          </div>
        </div>

        <h2 style="margin-left: 25px">Venduto da: {{sellerName}}</h2>

        <div class="row" style="margin-left: 15px">

          <div class="col-xs-6">
            <h3>Data di vendita: {{dateSell | date:'dd-MM-yyyy HH:mm'}}</h3>
            <h3>Pagamento: {{paymentType}}</h3>
            <h3>N. Transazione:  {{idTransaction}}</h3>
          </div>

          <div class="col-xs-6">
            <h3><i class="fas fa-user"></i> {{sellerOperator}}</h3>
            <h3><i class="fas fa-user"></i> {{name}}</h3>
            <h3><i class="fas fa-user-tag"></i> {{ticketCategoryName}}</h3>
            <h3><i class="fas fa-euro-sign"></i> {{price}}</h3>
          </div>
-->
        </div> 
      <div class="modal-footer"></div>
    </div>
  </div>



</ng-template>