<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} listino ticket</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #formTicketList="ngForm">
          <div class="ibox-content">

            <div hidden *ngIf="kindManage == 0" class="form-group">
              <label for="idTicketPricelist">IdPriceList</label>
              <input type="text" id="idTicketPricelist" name="idTicketPricelist" class="form-control" ngModel [ngModel]="ticketListData?.idTicketPricelist"
                required ng-disabled idTicketPricelist #idTicketPricelist="ngModel">
            </div>

            <div class="col-md-12">
              <div class="form-group col-md-5">
                <label for="username">Nome</label>
                <input type="text" id="name" name="name" class="form-control" ngModel [ngModel]="ticketListData?.name" required ng-disabled
                  #name="ngModel">
              </div>


              <div *ngIf="isSol1" class="form-group col-md-5">
                <label for="idCompany">Company</label>
                <select [disabled]="kindManage == 0 && !isSol1" type="text" id="idCompany" name="idCompany" class="form-control" ngModel [ngModel]="ticketListData?.idCompany"
                  required ng-disabled idCompany #idCompany="ngModel">
                  <option *ngFor="let t of companyList" [ngValue]="t.idCompany">{{t.legalBusinessName}}</option>
                </select>
              </div>

           
            </div>



            <div class="col-md-12">
              <div class="form-group col-md-6">
                <label for="idTicketType">Tipologia</label>
                <select 
                [disabled]="kindManage == 0"
                (change)="onChange($event.target.value)" type="text" id="idTicketType" class="form-control"
                  ngModel name="idTicketType" required idTicketType #idTicketType="ngModel" [ngModel]="ticketListData?.idTicketType"
                  required>
                  <option *ngFor="let t of ticketTypesList" [ngValue]="t.idTicketType">{{t.name}}</option>
                </select>
              </div>

              <div class="form-group col-md-6">
                <label for="idTicketFare">Tariffa</label>
                <span *ngIf="stringNameLine" class="label label-warning-light pull-right">{{stringNameLine}}</span>
                <select  
                [disabled]="kindManage == 0" (change)="changeFare($event.target.value)" type="text" id="idTicketFare" class="form-control"
                  ngModel name="idTicketFare" required idTicketFare #idTicketFare="ngModel" [ngModel]="ticketListData?.idTicketFare"
                  required>
                  <option *ngFor="let t of ticketFaresList" [ngValue]="t.idTicketFare">{{t.name}}</option>
                </select>
              </div>
            </div>


            <div *ngIf="ArVisible && !isExtra" class="col-md-12">
              <div class="col-md-6">
                <h2>Andata </h2>
                <div style="padding-left: 0px" [hidden]="!validityConstraintLine" class="col-md-12">
                  <div class="radio">
                    <input type="radio" name="validityRadioBoxA" [ngModel]="ticketListData?.validityRadioBox" id="validityLineCheckA" validityLineCheck
                      value="1">
                    <label for="validityLineCheckA">
                      <b>Validità vincolata alla corsa di una linea</b>
                    </label>
                  </div>
                  <div class="input-group col-md-8" *ngIf="formTicketList.value.validityRadioBoxA == '1'">
                    <span class="input-group-addon" id="basic-addon2">Tolleranza</span>
                    <select type="text" id="tollerance" class="form-control" ngModel name="tollerance" required tollerance #tollerance="ngModel"
                      [ngModel]="ticketListData?.tolerance" required>
                      <option [ngValue]="30">0.30 </option>
                      <option [ngValue]="60">1 </option>
                      <option [ngValue]="90">1.30 </option>
                      <option [ngValue]="120">2 </option>
                      <option [ngValue]="120">2.30</option>
                    </select>
                    <span class="input-group-addon" id="basic-addon2">Ore</span>
                  </div>
                </div>

                <div style="padding-left: 0px" class="col-md-12 ">
                  <div class="radio">
                    <input type="radio" name="validityRadioBoxA" (ngModel)="ticketListData?.validityRadioBox" id="validityOpenCheckA" validityOpenCheck
                      value="2">
                    <label for="validityOpenCheckA">
                      <b>Validità aperta</b>
                    </label>
                  </div>
                  <div class="input-group col-md-6" *ngIf="formTicketList.value.validityRadioBoxA == '2'">
                    <select type="number" id="validityOpen" name="validityOpen" class="form-control" [ngModel]="ticketListData?.validityOpen"
                      ngModel required min="1" validityOpen #validityOpen="ngModel">
                      <option [ngValue]="1440">1</option>
                      <option [ngValue]="2880">2</option>
                      <option [ngValue]="4320">3</option>
                      <option [ngValue]="5760">4</option>
                      <option [ngValue]="7200">5</option>
                      <option [ngValue]="8640">6</option>
                      <option [ngValue]="10080">7</option>
                    </select>
                    <span class="input-group-addon" id="basic-addon2">Gg</span>
                  </div>
                </div>
              </div>



              <div class="col-md-6">
                <h2>Ritorno </h2>
                <div style="padding-left: 0px" [hidden]="!validityConstraintLine" class="col-md-12">
                  <div class="radio">
                    <input type="radio" name="validityRadioBox" [ngModel]="ticketListData?.validityRadioBox" id="validityLineCheck" validityLineCheck
                      value="1">
                    <label for="validityLineCheck">
                      <b>Validità vincolata alla corsa di una linea</b>
                    </label>
                  </div>
                  <div class="input-group col-md-8" *ngIf="formTicketList.value.validityRadioBox == '1'">
                    <span class="input-group-addon" id="basic-addon2">Tolleranza</span>
                    <select type="text" id="tolleranceR" class="form-control" ngModel name="tolleranceR" required tolleranceR #tolleranceR="ngModel"
                      [ngModel]="ticketListData?.toleranceR" required>
                      <option [ngValue]="30">0.30 </option>
                      <option [ngValue]="60">1 </option>
                      <option [ngValue]="90">1.30 </option>
                      <option [ngValue]="120">2 </option>
                      <option [ngValue]="120">2.30</option>
                    </select>
                    <span class="input-group-addon" id="basic-addon2">Ore</span>
                  </div>
                </div>

                <div style="padding-left: 0px" class="col-md-12 ">
                  <div class="radio">
                    <input type="radio" name="validityRadioBox" (ngModel)="ticketListData?.validityRadioBox" id="validityOpenCheck" validityOpenCheck
                      value="2">
                    <label for="validityOpenCheck">
                      <b>Validità aperta</b>
                    </label>
                  </div>
                  <div class="input-group col-md-6" *ngIf="formTicketList.value.validityRadioBox == '2'">
                    <select type="number" id="validityOpenR" name="validityOpenR" class="form-control" [ngModel]="ticketListData?.validityOpenR"
                      ngModel required min="1" validityOpenR #validityOpenR="ngModel">
                      <option [ngValue]="1440">1</option>
                      <option [ngValue]="2880">2</option>
                      <option [ngValue]="4320">3</option>
                      <option [ngValue]="5760">4</option>
                      <option [ngValue]="7200">5</option>
                      <option [ngValue]="8640">6</option>
                      <option [ngValue]="10080">7</option>
                    </select>
                    <span class="input-group-addon" id="basic-addon2">Gg</span>
                  </div>
                </div>
              </div>


            </div>







            <div *ngIf="!ArVisible && !isExtra" class="col-md-6">
              <div [hidden]="!validityConstraintLine" class="form-group col-md-12 ">
                <div class="radio">
                  <input type="radio" name="validityRadioBox" [ngModel]="ticketListData?.validityRadioBox" id="validityLineCheck" validityLineCheck
                    value="1">
                  <label for="validityLineCheck">
                    <b>Validità vincolata alla corsa di una linea</b>
                  </label>
                </div>


                <div class="input-group col-md-8" *ngIf="formTicketList.value.validityRadioBox == '1'">
                  <span class="input-group-addon" id="basic-addon2">Tolleranza</span>
                  <select type="text" id="tollerance" class="form-control" ngModel name="tollerance" required tollerance #tollerance="ngModel"
                    [ngModel]="ticketListData?.tolerance" required>
                    <option [ngValue]="30">0.30 </option>
                    <option [ngValue]="60">1 </option>
                    <option [ngValue]="90">1.30 </option>
                    <option [ngValue]="120">2 </option>
                    <option [ngValue]="120">2.30</option>
                  </select>
                  <span class="input-group-addon" id="basic-addon2">Ore</span>

                </div>
              </div>

              <div class="form-group col-md-12 ">
                <div class="radio">
                  <input type="radio" name="validityRadioBox" (ngModel)="ticketListData?.validityRadioBox" id="validityOpenCheck" validityOpenCheck
                    value="2">
                  <label for="validityOpenCheck">
                    <b>Validità aperta</b>
                  </label>
                </div>
                <div class="input-group col-md-6" *ngIf="formTicketList.value.validityRadioBox == '2'">
                  <select type="number" id="validityOpen" name="validityOpen" class="form-control" [ngModel]="ticketListData?.validityOpen"
                    ngModel required min="1" validityOpen #validityOpen="ngModel">
                    <option [ngValue]="1440">1</option>
                    <option [ngValue]="2880">2</option>
                    <option [ngValue]="4320">3</option>
                    <option [ngValue]="5760">4</option>
                    <option [ngValue]="7200">5</option>
                    <option [ngValue]="8640">6</option>
                    <option [ngValue]="10080">7</option>
                  </select>
                  <span class="input-group-addon" id="basic-addon2">Gg</span>
                </div>
              </div>


            </div>

            <div style="margin-top: 15px" class="form-group col-md-6">

              <div class="col-sm-12">
                <label> Abilitazioni canali di rivendita</label>
                <div class="checkbox">
                  <input type="checkbox" name="checkResale" [ngModel]="ticketListData?.checkResale" id="checkResale" checkResale #checKResale>
                  <label>Rivendita</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" name="checkMobile" [ngModel]="ticketListData?.checkMobile" id="checkMobile" checkMobile #checkMobile>
                  <label>Operatore azienda via app</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" name="checkWeb" [ngModel]="ticketListData?.checkWeb" id="checkWeb" checkWeb #checkWeb>
                  <label>Operatore azienda via portale</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" name="checkAPI" [ngModel]="ticketListData?.checkAPI" id="checkAPI" checkAPI #checkAPI>
                  <label>API</label>
                </div>
              </div>
            </div>



            <div *ngIf="!ArVisible" style="margin-top: 15px" class="col-md-12">
              <div class="form-group col-md-4">

                <div class="input-group col-md-12">


                </div>
              </div>
            </div>



            <div class="col-md-6">


              <div style="margin-top: 15px" *ngIf="ArVisible" class="row"> </div>

              <div style="padding-left: 0px" class="col-md-12">
                <div class="form-group col-md-12 col-sm-12">
                  <label for="soldFrom">Vendibile dal</label>
                  <input type="text"
                    class="form-control"
                    name="dateStart"
                    id="dateStart"
                    [ngModel]="ticketListData?.dateStart"
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    (bsValueChange)="onDateChanged($event)"
                    [bsConfig]="bsConfig" />
                  </div>

                <div class="form-group col-md-12 col-sm-12">
                  <label for="dateEnd">Vendibile fino al</label>
                  <input type="text"
                    class="form-control"
                    name="dateEnd"
                    id="dateEnd"
                    [ngModel]="ticketListData?.dateEnd"
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class="form-group col-md-12">
                  <label for="dateEnd">Prezzo </label>
                <div class="input-group col-md-6">

                  
                  <input type="text" id="price" name="price" placeholder="0.00" class="form-control" ngModel [ngModel]="ticketListData?.price"
                    required price #price="ngModel">
                  <span class="input-group-addon">€</span>

                </div>
              </div>

            </div>



            <!--      <div style="margin-top: 15px" class="col-md-6">
              <div class="form-group col-md-12">

                <div class="input-group col-md-12">


                  <input type="text" id="price" name="price" placeholder="Prezzo" class="form-control" ngModel [ngModel]="ticketListData?.price"
                    required price #price="ngModel">
                  <span class="input-group-addon">€</span>

                </div>
              </div>
            </div> -->



            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!formTicketList.valid">Salva </button>


          </div>
        </form>
      </div>
    </div>
  </div>
</div>