
            <div class="row">
                <!--<div class="col-sm-12 wrapper border-bottom white-bg page-heading mTB20">
                    <div class="row">
                        <div class="col-sm-6">
                            <h2><strong>Benvenuto {{name}} {{surname}}</strong></h2>
                            <ol class="breadcrumb">
                                <li>
                                    {{dateToday}}
                                </li>
                            </ol>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" (click)="changePwd()" class="btn btn-redBU" style="float: right;margin:15px;"><i class="fa fa-lock"></i> Modifica Password</button>
                            <button type="button" (click)="editAnagrafica()" class="btn btn-redBU" style="float: right;margin:15px;" *ngIf="isASupplier"><i class="fa fa-pencil"></i> Modifica anagrafica</button>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <section id="optionTab"  *ngIf="isASupplier">
                    <div *ngFor="let d  of documentsInvalidToShow" class="col-lg-4">
                        <a (click)="goToReviewDocument()">
                         <div *ngFor="let doc of d.documents">
                            <div *ngIf="doc.status === 'EXPIRED'"  class="widget red-bg no-padding">
                                <div class="p-m">
                                    <i style="float: right" class="fa fa-clock-o fa-3x"></i>
                                    <h3 class="m-xs no-margins">{{d.descDocumentType}}</h3>
                                    <br>
                                    <div>
                                        <h4 class="font-bold no-margins">Documento scaduto: {{doc.fileName}}</h4>
                                        <small *ngIf="doc.dtValidityEnd !== '' && doc.dtValidityEnd !== null ">Valido fino al {{doc.dtValidityEnd | date:'dd/MM/yyyy'}}</small>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="doc.status === 'MISSING'"   class="widget lazur-bg no-padding">
                                <div class="p-m">
                                    <i style="float: right" class="fa fa-ban fa-3x"></i>
                                    <h3 class="m-xs no-margins">{{d.descDocumentType}}</h3>
                                    <br>
                                    <div>
                                        <h4 class="font-bold no-margins"> Documento mancante!</h4>
                                        <small *ngIf="doc.dtValidityEnd !== '' && doc.dtValidityEnd !== null ">Valido fino al {{doc.dtValidityEnd | date:'dd/MM/yyyy'}}</small>
                                    </div>
                                </div>
                            </div>

                             <div *ngIf="doc.status === 'REJECTED'"   class="widget red-bg no-padding">
                                <div class="p-m">
                                    <i style="float: right" class="fa fa-thumbs-o-down fa-3x"></i>
                                    <h3 class="m-xs no-margins">{{d.descDocumentType}}</h3>
                                    <br>
                                    <div>
                                         <h4 class="font-bold no-margins">Documento rifiutato: {{doc.fileName}}</h4>
                                        <small *ngIf="doc.dtValidityEnd !== '' && doc.dtValidityEnd !== null ">Valido fino al {{doc.dtValidityEnd | date:'dd/MM/yyyy'}}</small>
                                    </div>
                                </div>
                            </div>
                             <div *ngIf="doc.status === 'NEW'"   class="widget blue-bg no-padding">
                                <div class="p-m">
                                    <i style="float: right" class="fa fa-paper-plane fa-3x"></i>
                                    <h3 class="m-xs no-margins">{{d.descDocumentType}}</h3>
                                    <br>
                                    <div>
                                         <h4 class="font-bold no-margins">Documento caricato in attesa di validazione: {{doc.fileName}}</h4>
                                        <small *ngIf="doc.dtValidityEnd !== '' && doc.dtValidityEnd !== null ">Valido fino al {{doc.dtValidityEnd | date:'dd/MM/yyyy'}}</small>
                                    </div>
                                </div>
                            </div>


                        
                         </div>

                    
                        </a>
                    </div>
                </section>
                <div class="clearfix"></div>
                <section *ngIf="isAnAdmin">
                    <div class="col-md-12">
                        <div class="ibox float-e-margins">
                            <div class="ibox-title">
                                    <h5>Configurazione</h5>
                                <div class="ibox-tools"></div>
                            </div>
                            <div class="ibox-content">
                                <div class="row">
                                    <form (ngSubmit)="onSubmitMailAdmin()" #formConfigMailAdmin="ngForm">
                                        <div class='col-xs-12 col-sm-12 col-md-6'>
                                            <div class="form-group">
                                                <label class="col-xs-12 control-label">Mail Amministrazione:</label>
                                                <div class="col-xs-6">
                                                    <input type="text" id="mailAdmin" name="mailAdmin" class="form-control" ngModel [ngModel]="mailAdminText" required mailAdmin required email #mailAdmin="ngModel" placeholder="Inserisci mail">
                                                </div>
                                                <button type="submit" [disabled]="!formConfigMailAdmin.valid" class="btn btn-default"><i class="fa fa-envelope"></i> Conferma Mail</button>
                                            </div>
                                        </div>
                                    </form>
                                    <form (ngSubmit)="onSubmitValidity()" #formConfigValidity="ngForm">
                                        <div class='col-xs-12 col-sm-12 col-md-6'>
                                            <div class="form-group">
                                                <label class="col-xs-12 control-label">Durata verifica:</label>
                                                <div class="col-xs-6 input-group m-b" style="float:left;padding:0px 15px;">
                                                    <input type="text" id="ggValidita" name="ggValidita" class="form-control" ngModel [ngModel]="ggValiditaText" required ggValidita #ggValidita="ngModel" placeholder="Inserisci giorni">
                                                    <span class="input-group-addon">gg</span>
                                                </div>
                                                <button type="submit" [disabled]="!formConfigValidity.valid" class="btn btn-default"><i class="fa fa-clock-o"></i> Conferma Validità</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>-->
                
            </div>

