<div class="row" style="padding-top: 20px"></div>

<div class="col-md-12">
  <div  class="ibox float-e-margins ">
    <div class="ibox-title">
        <h2 class="col-xs-11"><strong>Listino Abbonamenti</strong></h2>
      
        <div class="ibox-tools col-xs-1 pull-right">
            <button type="button" (click)="newTicketList()" class="btn btn-view btn-outline"> <i class="fa fa-plus"></i></button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <form #formSubscriptionList="ngForm">

               

                <div  *ngIf="isSoluzione1" class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                    <div class="form-group">
                      <label>Azienda di trasporto</label>
                      <div class='input-group col-xs-12'>
                        <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany  #idCompany ="ngModel">
                          <option [ngValue] = "'all'" > Tutte </option>
                          <option *ngFor="let t of companiesList" [ngValue]="t.idCompany">{{t.legalBusinessName}} </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class='col-xs-12 col-sm-6  col-md-3 col-lg-2'>
                      <div class="form-group">
                        <label>Nome</label>
                        <div class='input-group col-xs-12'>
                        <input
                          type="text"
                          id="ticketPricelistName"
                          name="ticketPricelistName"
                          class="form-control"
                          ngModel
                          required
                          ticketPricelistName
                          #ticketPricelistName= "ngModel">
                        </div>
                      </div>
                    </div>


                  <div class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                      <div class="form-group">
                        <label>Tariffa</label>
                        <div class='input-group col-xs-12'>
                          <select type="text" id="idTicketFare" class="form-control" ngModel name="idTicketFare" required idTicketFare #idTicketFare="ngModel">
                            <option [ngValue] = "'all'" > Tutte </option>
                            <option *ngFor="let f of fareList" [ngValue]="f.idTicketFare">{{f.name}} </option>
                          </select>
                        </div>
                      </div>
                    </div>

                <div class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label>Tipologia</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="idTicketType" class="form-control" ngModel name="idTicketType" required idTicketType  #idTicketType ="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option *ngFor="let t of typologyList" [ngValue]="t.idTicketType">{{t.name}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group  col-xs-12 col-sm-6 col-md-3 col-lg-2">
                    <label>Vendibili al:</label>
                    <div class='input-group col-lg-12  col-xs-12'>
                      <input type="text"
                        class="form-control"
                        name="validOn"
                        id="validOn"
                        ngModel
                        required
                        bsDatepicker
                        #bsDatepicker
                        placement="bottom"
                        autocomplete="off"
                        [bsConfig]="bsConfig" />
                    </div>
                  </div>   

                <div class=' col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                    <div class="form-group">
                      <label>Stato</label>
                      <div class='input-group col-xs-12'>
                      <select
                        type="text"
                        id="status"
                        name="status"
                        class="form-control"
                        ngModel
                  
                        status
                        #status= "ngModel">
                        <option [ngValue] = "'all'" > Tutti </option>
                        <option [ngValue] = "'1'" > Attivo</option>
                        <option [ngValue] = "'2'" > Non Attivo</option>
                        </select>
                      </div>
                    </div>
                  </div>
            
                
            
           
                <div class='col-xs-12 col-sm-6  col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                        <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                        <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                    </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example" >
          <thead>
            <tr>     <th *ngIf="isSoluzione1">Azienda</th>
              <th>Nome</th>
            
           
              <th>Prezzo</th>
              <th>Periodo validità</th>    
              <th>Canale</th>
             
              <th *ngIf="!isMobileOperator"  ></th>
              
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList"  style="cursor:pointer">
              <td *ngIf="isSoluzione1" style="width: 200px;">{{getCompanyName(data.idCompany)}}</td>
              <td>{{data.name}}</td>
              <td>{{data.price | number : '1.2-2'}}€</td>
              <td>{{data.dateStart | date:'dd-MM-yyyy'}} / {{data.dateEnd | date:'dd-MM-yyyy'}}</td>
              <td>{{findString(data.ticketChannels)}}</td>
              <td *ngIf="!isMobileOperator" style="width: 160px" class="col-md-4 text-center">
                <div style="float:left">
                  <button type="button" class="btn btn-success btn-outline  " (click)="selectRow(data)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button style="margin-left: 5px" type="button" class="btn btn-success btn-outline " (click)=" duplicate(data)">
                    <i class="fa fa-file"></i>
                  </button>
                </div>
  
                <div style="float: right">
                  <button type="button" *ngIf="!data.hasTicket" style="margin-left: 10px;" class="btn btn-danger btn-outline "
                    (click)=" deleteTicketList(data.idTicketPricelist,data.name)">
  
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination 
          *ngIf="listService.totalRows > listService.rows"
          class="pull-right" 
          [collectionSize]="listService.totalRows"
          [maxSize]="10"
          [pageSize]="listService.rows" 
          [(page)]="listService.page"
          (pageChange)="pageChange($event)" >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

