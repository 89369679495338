<div class="row" style="padding-top: 20px;">
</div>
<div class="col-md-12">
    <div class="ibox float-e-margins ">
        <div class="ibox-title">
            <h2 class="col-xs-10">
                <strong>Canali di vendita</strong>
            </h2>
            <div *ngIf="isSoluzione1" class="ibox-tools col-md-2 pull-right">
                <button type="button" (click)="newSalesChannel()" class="btn btn-view btn-outline">
                  <i class="fa fa-plus"></i>
                </button>
            </div>
        </div>

        <div class="ibox-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <form #formChannels="ngForm">
                            <div *ngIf="isSoluzione1" class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                                <div class="form-group">
                                    <label>Azienda di trasporto</label>
                                    <div class='input-group col-xs-12'>
                                        <select type="text" id="idCompany" class="form-control"
                                            [(ngModel)]="dataFilter['idCompany']" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                                            <option [ngValue]="'all'"> Tutte </option>
                                            <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}} </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                                <div class="form-group">
                                    <label>Canale</label>
                                    <div class='input-group col-xs-12'>
                                    <select type="text" id="idTicketChannel" class="form-control"
                                        [(ngModel)]="dataFilter['idTicketChannel']" ngModel name="idTicketChannel" idTicketChannel #idTicketChannel="ngModel" required>
                                        <option [ngValue]="'all'"> Tutti </option>
                                        <option *ngFor="let c of dataChannel" [ngValue]="c.idChannel">{{c.name}}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class='col-xs-12 col-sm-4 col-md-3'>
                                <div class="form-group">
                                    <label class="hide-xs"></label>
                                    <div class='input-group col-md-12'>
                                        <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                                        <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                                            <i class="fa fa-rotate-left"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
                <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
            </p>
            <div *ngIf="errorMessage === ''" class="table-responsive">
                <table class="table table-striped table-bordered table-hover dataTables-example">
                    <thead>
                        <tr>
                            <th *ngIf="isSoluzione1">Azienda di trasporto</th>
                            <th>Canale di vendita</th>
                            <th>Supplemento associato</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="errorMessage === ''">
                        <tr *ngFor="let data of dataList" style="cursor:pointer">
                            <td style="width: 200px;" *ngIf="isSoluzione1">{{getCompanyName(data.idCompany)}}</td>
                            <td>{{utils.getTicketChannel(dataChannel, data.idTicketChannel)}}</td>
                            <td>{{!!data.supplementAmount ? data.supplementAmount + '€' : 'Nessuno'}}</td>
                            <td style="width: 100px" class="col-md-4 text-center">
                                <button *ngIf="isSoluzione1" style="float: left" type="button" class="btn btn-success btn-outline"
                                    title="Modifica canale di vendita" (click)="selectRow(data.idTicketChannel)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button *ngIf="typeUser === 'AZIENDA DI TRASPORTO'" style="float: left" type="button" class="btn btn-success btn-outline"
                                    title="Modifica supplemento associato" (click)="editPromotionAmount(data, channelPromotionTemplate)">
                                    <i class="fa fa-euro-sign"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
                    [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>

<!-- TRIP PREDICTION TEMPLATE -->
<ng-template #channelPromotionTemplate>
    <div>
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="ibox-title"><h2>Modifica supplemento associato</h2></div>
                    <div class="ibox-content mt-2" id="ibox-content">
                        <label for="supplement">Valore supplemento (€)</label>
                        <input
                            type="number"
                            id="supplement"
                            name="supplement"
                            class="form-control"
                            min="0"
                            [(ngModel)]="selectedChannel.supplementAmount" />
                    </div>
                    <div class="ibox-footer" style="text-align: right; margin-top: 1rem; border: none; padding-bottom: 0;">
                        <button class="btn btn-default" type="button" (click)="closeModal()">Chiudi</button>
                        <button class="btn btn-primary" type="button" style="margin-left: 1rem;" (click)="savePromotion()">Salva</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>