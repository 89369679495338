import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-zones-list',
  templateUrl: './zones-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ZonesListComponent implements OnInit {

  // FORM
  @ViewChild('formZone') zoneForm: NgForm;
  // OGGETTO LISTA ELEMENTI
  dataList: any[] = new Array();
 // dataList = [{  name: 'BUS01',  brand: 'Mercedes',  plate: 'FF 000 EB',  status: 1}];
  dataFilter: any[] = new Array();
  // PATH CHIAMATE
  urlDeletePath = '';
  errorMessage = '';
  visibleFilter;
  defaultValue;
  stringLenght;
  visibleContentFilter;
  stringLenghtTables;
  companiesList: any[] = new Array();
  urlCompany = '/company/companies';
  typeUser;
  isSoluzione1;
  UpOrDown;
  classString;

  constructor(
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    public utils: UtilsService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/zones/list', false, true, 1, 20);
    this.listService.restURL = '/zone/zones';

  }

  async ngOnInit() {
    this.UpOrDown = true;
    this.visibleContentFilter = false;
    this.stringLenght = 'col-md-3'
    this.stringLenghtTables = 'col-md-12';
    this.classString = 'theme-config-box'
    this.companiesList = await this.utils.getCompanyList();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.loadElements();
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }


  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
    } else {
      this.UpOrDown = true;
    }
  }

  visibleFilters(value) {

    if (value  === 'open' ) {
      this.visibleContentFilter = true;
      this.stringLenghtTables = 'col-md-9';
    } else {
      this.visibleContentFilter = false;
      this.stringLenghtTables = 'col-md-12';
      this.filterReset();
    }
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.zoneForm.value;
    this.loadElements();
    this.listService.visibleFilter = true;
  }

  filterReset() {
    this.zoneForm.value.name = ''
    this.zoneForm.value.plate = ''
    this.zoneForm.value.idCompany = ''
    this.zoneForm.value.obliterator = ''
    this.listService.visibleFilter = false;
    this.dataFilter = [];
    this.zoneForm.reset();
    this.loadElements();
  }

  getCompanyName(idCompany) {
    let stringCompanyName: string;
    for (let company of this.companiesList) {
      if (company.idCompany === idCompany) {
        stringCompanyName = company.legalBusinessName
        return stringCompanyName;
      }
    }
  }

  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === '1') {
   return   buttonColor =  'Attivo'
    }
    if (statusNumber === '2') {
      return   buttonColor =  'Non Attivo'
       }
  }



  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['id'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/zones/edit']);
  }

  newBlock() {
    this.listService.backToList = true;
    this.router.navigate(['/zones/new']);
  }

  closeFilter() {
    if ( this.classString === 'theme-config-box show') {
      this.classString = 'theme-config-box '
    } else {
    this.classString = 'theme-config-box show'
   }
  }

}
