import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListService } from '../../services/list.service';
import { LoggingService } from '../../services/logging.service';
import { UtilsService } from '../../services/utils.service';

declare var jQuery: any;

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.component.html'
})

export class NavigationComponent implements OnInit, AfterViewInit {

    name = 'name';
    surname = 'surname';
    roleUser;
    typeUser;
    isAdministrator = true;
    isManager = true;
    isMobile = true;
    isSeller = true;
    isOperator = false;

    isCompany = true;
    isResales = true;
    isSoluzione1 = true;
    userManager = false;
    lineManager = false;
    vehicleManager = false;
    ticketManager = false;
    resaleManager = false;
    companiesManager = false;
    newSellManager = false;
    viewAll = false;
    resaleOnly = false;
    isAdministratorResale = false;
    isMobileOperator = false;
    isDesk = false;
    showSubscriptions = false;

    isCompanyAdminstrator = false;
    isResaleAdministrator = false;
    pathChekPdfAvaiable = '/ticket/pdfenable'
    isPdfEnabled;

    idCompany = localStorage.getItem('idCompany');

    constructor(private router: Router, public listService: ListService, public utils: UtilsService, private logger: LoggingService) { }

    ngAfterViewInit() {
        jQuery('#side-menu').metisMenu();
    }

    ngOnInit() {
        if (localStorage.getItem('token') === null) { this.router.navigate(['\logout\expired']); };

        this.name = this.utils.getUserName();
        this.surname = this.utils.getSurname();
        this.typeUser = this.utils.getTypeUser();
        this.roleUser = this.utils.getRoleUser();
        this.isAdministrator = this.utils.isAdmin(this.roleUser);

        this.isAdministratorResale = this.utils.isAdmin(this.roleUser)
        this.isMobile = this.utils.isMob(this.roleUser);
        this.isSeller = this.utils.isSel(this.roleUser);
        this.isManager = this.utils.isMan(this.roleUser);
        this.isResales = this.utils.isRes(this.typeUser);
        this.isSoluzione1 = this.utils.isSol1(this.typeUser);
        this.isCompany = this.utils.isComp(this.typeUser);
        this.userManager = this.utils.viewUserManager(this.typeUser, this.roleUser);
        this.lineManager = this.utils.lineManager(this.typeUser);
        this.vehicleManager = this.utils.vehicleManager(this.typeUser);
        this.ticketManager = this.utils.ticketManager(this.typeUser);
        this.resaleManager = this.utils.resaleManager(this.typeUser);
        this.companiesManager = this.utils.companiesManager(this.typeUser);
        this.newSellManager = this.utils.newSellManager(this.typeUser);
        this.viewAll = this.utils.viewAll(this.typeUser);
        this.resaleOnly = this.utils.isResaleManager(this.typeUser)
        this.isMobileOperator = this.utils.isOperatorMobile(this.roleUser)
        this.isOperator = this.utils.isOperator(this.roleUser)
        this.isCompanyAdminstrator = this.utils.viewAdministratorCompany(this.typeUser, this.roleUser)
        this.isDesk = this.utils.isDesk();
        this.isResaleAdministrator = this.utils.isResaleAdministrator(this.typeUser, this.roleUser)
        this.showSubscriptions = this.utils.manageSubscriptionMenu();

        this.checkEnable()
    }

    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
    }

    activeRouteSame(routename: string): boolean {
        return this.router.url === routename;
    }


    checkEnable() {
        this.listService.getListSelect(this.pathChekPdfAvaiable).subscribe(
          response => {
           this.isPdfEnabled =  response.outcome.success;
           this.logger.log('RISPOSTA PDF', this.isPdfEnabled, 200);
          }
        );
    }

    sectionClick() {
        document.querySelectorAll('.mm-active').forEach(el => el.classList.remove('mm-active'));
        this.listService.resetList();
    }

}
