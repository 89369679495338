<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h2>{{kindTitle}} parametro</h2>
                </div>
                <form (ngSubmit)="onSubmit()" [formGroup]="configurationForm">
                    <div class="ibox-content">
                        <div *ngIf="isSoluzione1" class="row" style="padding: 0 15px">
                            <div class="form-group form-group col-xs-12 col-sm-6">
                                <label for="idCompany">Azienda di trasporto</label>
                                <select
                                    type="text"
                                    id="idCompany"
                                    class="form-control"
                                    formControlName="idCompany">
                                    <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Configuration values -->
                        <div class="form-group form-group col-xs-12 col-sm-6">
                            <label for="idCompany">Chiave</label>
                            <select
                                type="text"
                                id="idCompany"
                                class="form-control"
                                formControlName="name">
                                <option *ngFor="let k of keysList" [ngValue]="k.name">{{k.name}}</option>
                            </select>
                        </div>
                        <div class="form-group form-group col-xs-12 col-sm-6">
                            <label for="value">Valore</label>
                            <input
                                type="text"
                                id="value"
                                class="form-control"
                                formControlName="value">
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
                        <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!configurationForm.valid">Salva</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>