import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { LoadingService } from '../../../services/loading.service';
defineLocale('it', itLocale);

@Component({
  selector: 'app-statistics-voucher',
  templateUrl: './statistics-voucher.component.html',
  styleUrls: ['../../../app.component.css']
})
export class StatisticsVoucherComponent implements OnInit {
  @ViewChild('formStatsVoucher') statsVoucherForm: NgForm;
  @ViewChild('formModifyVoucher') modifyTicketForm: NgForm;

  @ViewChild('formInvalidVouvher') invalidTicketForm: NgForm;
  dataFilter: any[] = new Array();
  dataList: any[] = new Array();
  showList: any[] = new Array();
  qParams: any = null;
  searchChannel: any = null;
  searchCodeVoucher: any = null;
  searchType: string = null;
  searchQuery: any = null;
  errorMessage = '';
  lotList: any[];
  channelList: any[] = new Array();

  modStringDataLine;
  stringFirstDay;
  stringLastDay;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  bsConfig: Partial<BsDatepickerConfig>;
  colorTheme = 'theme-orange';

  urlChannelsPaths = '/voucherEmitChannel/channels'
  isSol1: any;
  typeUser: any;

  optionSelected = null;
  modalRef: BsModalRef;
  viewSubOption  = false;
  dataTickets: any;

  qrcodeString;

  public modTicketDate = {
    //  other options...
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  constructor(
    private logger: LoggingService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public listService: ListService ,
    public utils: UtilsService,
    private modalService: BsModalService,
    private loadingService: LoadingService
    ) {
      this.listService.resetList();
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.maxDate.setDate(lastDay.getDate());
      this.bsRangeValue = [firstDay, this.maxDate];
      console.log(this.bsRangeValue)
      this.listService.restURL = '/stats/sold/ticketvoucher';
      this.listService.configurationServiceCall( '/statistics/vanalysis', false, true, 1, 15 );

      this.bsConfig = Object.assign({}, { containerClass: this.colorTheme }, {dateInputFormat: 'DD-MM-YYYY'}, {rangeInputFormat: 'DD-MM-YYYY'});

      this.stringFirstDay = this.utils.formatDate(firstDay)
      this.stringLastDay = this.utils.formatDate(lastDay)
      this.dataFilter['dateFrom'] = this.stringFirstDay
      this.dataFilter['dateTo'] =   this.stringLastDay
      this.dataFilter['status'] = 'VALIDATED'

      if ( JSON.parse(sessionStorage.getItem('dataFilter')) ) {
        const receivedParams = JSON.parse(sessionStorage.getItem('dataFilter'));
        sessionStorage.removeItem('dataFilter');
        this.dataFilter['dateFrom'] = '';
        this.dataFilter['dateTo'] = '';
        this.bsRangeValue = null;
        Object.keys(receivedParams).map( key => {
          this.dataFilter[key] = receivedParams[key];
        })
      }

    }

  ngOnInit() {
    this.typeUser = this.utils.getTypeUser();
    this.isSol1 = this.utils.isSol1(this.typeUser);
    this.getAllChannel();
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements( bckTrace = null ) {
    this.loadingService.presentLoader();
    this.logger.log( 'dataFilter' , this.dataFilter , 200 )
    if ( bckTrace !== null ) {
      this.logger.log( 'bckTrace - loadElements' , bckTrace , 200 )
    }
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.logger.log( 'response stat voucher' , response , 200 )
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    ).add(() => {
      this.loadingService.hideLoader();
    });
  }

  getPageFromArray( page , array = null ) {
    this.logger.log( 'visible filter' , this.listService.visibleFilter , 200 )
    page--
    if ( array === null ) {
      array = this.dataList
    }

    if ( this.dataList.length === this.showList.length ) {
      // this.listService.totalRows = this.dataList.length
      this.logger.log( 'check grandezze ' , 'grandezze UGUALI' + this.dataList.length , 200 )
    } else if ( this.listService.totalRows !== this.showList.length && this.listService.visibleFilter ) {
      // this.listService.totalRows = this.showList.length
      this.logger.log( 'check grnadezze ' , 'grandezze DIVERSE' + this.showList.length , 200 )
    }
    this.logger.log( 'array.length ' , array.length , 200 )
    this.logger.log( 'showList.length ' , this.showList.length , 200 )
    this.logger.log( 'dataList.length ' , this.dataList.length , 200 )
    return array.slice(page * 15, (page + 1) * 15)
  }



  selectUnique( arrayList ) {
    let arrayUnique = new Array()
    for ( let element of arrayList ) {
      if ( arrayUnique.find( x => x === element.tsCreated ) === undefined ) {
        arrayUnique.push( element.tsCreated )
      }
    }
    return arrayUnique
  }

  getAllChannel() {
    this.listService.getListSelect(this.urlChannelsPaths).subscribe((response) => {
      this.channelList = response.results;
      this.logger.log('Lista Channels', this.channelList, 200);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  // FILTRI
  filterSearch() {
    this.getFilter();

    this.listService.visibleFilter = true;
    this.loadElements();
  }

  getFilter() {
    this.searchChannel = this.statsVoucherForm.value.emitChannel
    this.searchCodeVoucher = this.statsVoucherForm.value.code
    this.dataFilter = this.statsVoucherForm.value;
    this.dataFilter['bsDaterangepicker'] = 'all'
    this.stringFirstDay = this.bsRangeValue && this.bsRangeValue[0] ? this.utils.stringTotalFRomDate(this.bsRangeValue[0]) : null;
    this.stringLastDay = this.bsRangeValue && this.bsRangeValue[1] ? this.utils.stringTotalFRomDate(this.bsRangeValue[1]) : null;
    this.dataFilter['dateFrom'] = this.stringFirstDay ? this.stringFirstDay : '';
    this.dataFilter['dateTo'] = this.stringLastDay ? this.stringLastDay : '';
    if (this.modStringDataLine !== null && this.modStringDataLine !== '--') {
      this.dataFilter['tripDate'] =  this.modStringDataLine;
    }
  }

  filterReset() {
    this.statsVoucherForm.value.idCompany = '';
    this.statsVoucherForm.value.emitChannel = '';
    this.statsVoucherForm.value.idTicket = '';
    this.statsVoucherForm.value.codeTicket = '';
    this.statsVoucherForm.value.codeVoucher = '';

    this.listService.visibleFilter = false;
    this.loadElements();
  }

  viewDetailsTicket(data, template: TemplateRef<any>) {
    console.log('data voucher' , data)
    this.getDetailsVoucher(data , template );
  }

  openModal(template: TemplateRef<any>) {
    this.optionSelected = null;
    this.viewSubOption = false;
    this.modalRef = this.modalService.show(template);
  }

  close() {
    this.modalRef.hide()
  }



  getDetailsVoucher(data , template ) {
    // CASO VISUALIZZO TICKET DI UNA TRANSAZIONE
    if (data.transactionId !== null) {
      this.listService.getListSelect('/ticket/tickets/bytransaction/' + data.transactionId).subscribe((response) => {

        this.dataTickets = response.results
        this.logger.log('Tickets', this.dataTickets, 200);
        this.openModal(template)
      },
        (error) => {
          this.logger.log('Error', error, 200);
        }
      );
    }

    // CASO VISUALIZZO TICKET DI UN VOUCHER - FACCIO VEDERE INFO SUL PRIMO
    if (data.ticketIds.length > 0) {
      this.listService.getListSelect('/ticket/info/' + data.ticketIds[0]).subscribe((response) => {
        this.dataTickets = [];
        this.dataTickets.push(response);
        this.logger.log('Tickets', this.dataTickets, 200);
        this.openModal(template)
      },
        (error) => {
          this.logger.log('Error', error, 200);
        }
      );
    }

  }

  goToVoucherDetail( event ) {
    const data = { codeVoucher: event };
    sessionStorage.setItem( 'dataFilter', JSON.stringify(data) );
    this.router.navigate(['/voucher/list/']);
  }

  goToStatisticsObliterations( event ) {
    const data = { idTicket: String(event) };
    sessionStorage.setItem( 'dataFilter', JSON.stringify(data) );
    this.router.navigate(['/statistics/obliterations/', { fromVouchers: true }]);
  }

  sortArrayByDate( array ) {
    array.sort((a, b) => {
      let d1 = new Date(a.tsCreated)
      let d2 = new Date(b.tsCreated)
      return d2.getTime() - d1.getTime()
    });
  }

  pageChange(page) {
    this.listService.page = page;
    this.logger.log( 'page' , page , 200 )
    this.loadElements( 'fn pageChange');
    //  this.showList = this.getPageFromArray( page , this.dataList)
  }

  openQrCodeModal(qrCode, template: TemplateRef<any>) {
    this.qrcodeString = qrCode;
    this.modalRef = this.modalService.show(template);
  }

}
