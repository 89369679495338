import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from '../../../services/utils.service';
import swal2, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-lines-list',
  templateUrl: './lines-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class LinesListComponent implements OnInit {

  //FORM
  @ViewChild('formLines') linesForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();
  urlCompany = "/company/companies";
  typeUser;
  isSoluzione1;

  //PATH CHIAMATE
  urlDeletePath = "/route/routes/company";
  errorMessage: string = "";

  constructor(private logger: LoggingService, private router: Router,public utils: UtilsService, public listService: ListService){
    this.listService.resetList();
    this.listService.configurationServiceCall("/lines/list",false,true,1,15);
    this.listService.restURL = "/route/routes";
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  async ngOnInit() {
    this.loadElements();
    this.companiesList = await this.utils.getCompanyList();
  }

  pageChange(page){
    this.listService.page = page; 
    this.loadElements();
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.linesForm.value;
    this.loadElements();
    this.listService.visibleFilter = true;
  }

  filterReset(){
    this.linesForm.value.routeId = ""
    this.linesForm.value.routeLongName = ""
    this.linesForm.value.idCompany = ""


    this.listService.visibleFilter = false;
    this.loadElements();
     this.linesForm.reset();
    
  } 

  getBusinessName(id){
    
    var stringName;
    for(let c of this.companiesList){
      if(c.idCompany == id){
     return   stringName = c.legalBusinessName
      }
    }
  }

  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement){
    this.listService.selectedID = dataElement["idRoute"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/lines/edit']);
  }

  newLine() {
    this.listService.backToList = true;
    this.router.navigate(['/lines/new']);
  }

  manageDeleteLine(dataElement) {
    let mySelf = this;

    swal2.fire({
      title: 'Eliminazione linea',
      text: `Sei sicuro di volere eliminare la linea ${dataElement?.routeId}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteLine(dataElement);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteLine(dataElement) {
    swal2.fire({
      title: "Eliminazione in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    const queryObj = {
      body: {
        idCompany: dataElement?.idCompany,
        routeId: dataElement?.routeId
      }
    };

    this.listService.deleteWithQuery(this.urlDeletePath, queryObj).subscribe(
      response => {
        if (response.outcome.success === true) {
          this.logger.log("Response value", response.value, 200);
          swal2.fire("La linea è stata eliminata", "", "success").then(() => {
            this.loadElements();
          });
        } else {
          this.errorMessage = response.outcome.code + response.outcome.description;
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico. Riprova più tardi",
          "error"
        );
      }
    );
  }

}
