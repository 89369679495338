import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
class NgxPasswordStrengthBarComponent {
  static measureStrength(pass) {
    let score = 0;
    // award every unique letter until 5 repetitions
    const letters = {};
    for (const element of pass) {
      letters[element] = (letters[element] || 0) + 1;
      score += 5.0 / letters[element];
    }
    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
    };
    let variationCount = 0;
    for (let check in variations) {
      variationCount += variations[check] ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return Math.trunc(score);
  }
  constructor() {
    this.onStrengthChanged = new EventEmitter();
    this.defaultColors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
    this.defaultThresholds = [90, 70, 40, 20];
    this.defaultBaseColor = '#DDD';
    this.colors = this.defaultColors;
    this.thresholds = this.defaultThresholds;
  }
  checkBarColors() {
    // Accept custom colors if input is valid, otherwise the default colors will be used
    if (this.barColors && this.barColors.length === 5) {
      this.colors = this.barColors.slice();
    } else {
      this.colors = this.defaultColors;
    }
    this.strengths = this.strengthLabels && this.strengthLabels.length === 5 ? this.strengthLabels.slice() : null;
    this.setStrengthLabel(0);
    if (!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(this.baseColor)) {
      this.baseColor = this.defaultBaseColor;
    }
  }
  checkThresholds() {
    if (this.customThresholds && this.customThresholds.length === 4) {
      this.thresholds = this.customThresholds.slice();
    } else {
      this.thresholds = this.defaultThresholds;
    }
  }
  getColor(score) {
    let idx = 0;
    if (score > this.thresholds[0]) {
      idx = 4;
    } else if (score > this.thresholds[1]) {
      idx = 3;
    } else if (score >= this.thresholds[2]) {
      idx = 2;
    } else if (score >= this.thresholds[3]) {
      idx = 1;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }
  getStrengthIndexAndColor(password) {
    return this.getColor(NgxPasswordStrengthBarComponent.measureStrength(password));
  }
  ngOnChanges(changes) {
    if (!changes['passwordToCheck']) {
      return;
    }
    const password = changes['passwordToCheck'].currentValue;
    this.checkBarColors();
    this.checkThresholds();
    this.setBarColors(5, this.baseColor);
    let strength = 0;
    if (password) {
      const c = this.getStrengthIndexAndColor(password);
      strength = c.idx - 1;
      this.setStrengthLabel(strength);
      this.setBarColors(c.idx, c.col);
    }
    this.onStrengthChanged.emit(strength);
  }
  setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }
  setStrengthLabel(index) {
    if (this.strengths) {
      this.strengthLabel = this.strengths[index];
    }
  }
  static {
    this.ɵfac = function NgxPasswordStrengthBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPasswordStrengthBarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPasswordStrengthBarComponent,
      selectors: [["ngx-password-strength-bar"]],
      inputs: {
        passwordToCheck: "passwordToCheck",
        barLabel: "barLabel",
        barColors: "barColors",
        baseColor: "baseColor",
        strengthLabels: "strengthLabels",
        customThresholds: "customThresholds"
      },
      outputs: {
        onStrengthChanged: "onStrengthChanged"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 12,
      vars: 13,
      consts: [["strength", ""], ["id", "strength"], ["id", "strengthBar", 1, "strengthBar"], ["id", "bar0", 1, "point"], [1, "point"], [1, "pre", 3, "hidden"]],
      template: function NgxPasswordStrengthBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1, 0)(2, "small");
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "ul", 2);
          i0.ɵɵelement(5, "li", 3)(6, "li", 4)(7, "li", 4)(8, "li", 4)(9, "li", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(10, "small", 5);
          i0.ɵɵtext(11);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate(ctx.barLabel);
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("background-color", ctx.bar0);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("background-color", ctx.bar1);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("background-color", ctx.bar2);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("background-color", ctx.bar3);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("background-color", ctx.bar4);
          i0.ɵɵadvance();
          i0.ɵɵproperty("hidden", !ctx.strengths);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.strengthLabel, "");
        }
      },
      styles: [".strengthBar[_ngcontent-%COMP%]{display:inline;list-style:none;margin:0 0 0 15px;padding:0;vertical-align:2px}.strengthBar[_ngcontent-%COMP%]   .point[_ngcontent-%COMP%]{background:#DDD;border-radius:2px;display:inline-block;height:5px;margin-right:1px;width:20px}.strengthBar[_ngcontent-%COMP%]   .point[_ngcontent-%COMP%]:last-child{margin:0}.pre[_ngcontent-%COMP%]{white-space:pre}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPasswordStrengthBarComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-password-strength-bar',
      template: `
    <div id="strength" #strength>
      <small>{{barLabel}}</small>
      <ul id="strengthBar" class="strengthBar">
        <li id="bar0" class="point" [style.background-color]="bar0"></li>
        <li class="point" [style.background-color]="bar1"></li>
        <li class="point" [style.background-color]="bar2"></li>
        <li class="point" [style.background-color]="bar3"></li>
        <li class="point" [style.background-color]="bar4"></li>
      </ul>
      <small [hidden]="!strengths" class="pre">  {{strengthLabel}}</small>
    </div>
  `,
      styles: [".strengthBar{display:inline;list-style:none;margin:0 0 0 15px;padding:0;vertical-align:2px}.strengthBar .point{background:#DDD;border-radius:2px;display:inline-block;height:5px;margin-right:1px;width:20px}.strengthBar .point:last-child{margin:0}.pre{white-space:pre}\n"]
    }]
  }], function () {
    return [];
  }, {
    passwordToCheck: [{
      type: Input
    }],
    barLabel: [{
      type: Input
    }],
    barColors: [{
      type: Input
    }],
    baseColor: [{
      type: Input
    }],
    strengthLabels: [{
      type: Input
    }],
    customThresholds: [{
      type: Input
    }],
    onStrengthChanged: [{
      type: Output
    }]
  });
})();
class NgxPasswordStrengthBarModule {
  static {
    this.ɵfac = function NgxPasswordStrengthBarModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPasswordStrengthBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPasswordStrengthBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPasswordStrengthBarModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxPasswordStrengthBarComponent],
      imports: [],
      exports: [NgxPasswordStrengthBarComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-password-strength-bar
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPasswordStrengthBarComponent, NgxPasswordStrengthBarModule };
