<div class="customer-info-manager" ngModelGroup="customerInfoGroup">
  <div class="col-xs-12">
    <h1 class="text-center mt-2" style="font-weight: 800">Inserisci i dettagli del cliente</h1>
    <hr style="clear:both;" />
  </div>

  <div class="col-sm-12">
    <div class="col-sm-6 form-group customer-info-form">
      <div class="customer-info-input">
        <label><i class="fas fa-envelope fa-2x"></i> EMAIL</label>
        <input type="email" class="form-control" id="customerEmail" name="customerEmail" ngModel customerEmail #customerEmail="ngModel"
          [disabled]="!!findTripForm?.controls.customerInfoGroup?.controls.customerValidation?.value">
      </div>
      <button class="btn btn-info" *ngIf="!customerValidations || userInfoMode === 2; else emailReset"
        [disabled]="!findTripForm?.controls.customerInfoGroup?.controls.customerEmail?.value || findTripForm?.controls.customerInfoGroup?.controls.customerValidation?.value"
        (click)="checkUserInfo(customerEmail?.value, 1)"><i class="fas fa-search"></i>
      </button>
      <ng-template #emailReset>
        <button class="btn btn-info" (click)="clearUserInfo()"><i class="fas fa-redo"></i></button>
      </ng-template>
    </div>
    <!-- Input displayed when customer validations were not retrieved by mail -->
    <ng-container *ngIf="!customerValidations || userInfoMode === 2; else validationSelect">
      <div class="col-sm-6 form-group customer-info-form">
        <div class="customer-info-input">
          <label><i class="fas fa-id-card fa-2x"></i> TESSERA CLIENTE</label>
          <input type="text" class="form-control" id="customerValidation" name="customerValidation" ngModel customerValidation #customerValidation="ngModel"
            [disabled]="!!customerEmail?.value">
          <span *ngIf="customerValidations" style="position: absolute;">Tessera attiva: {{(customerValidations[0].metadata ? customerValidations[0].metadata.firstName + ' ' + customerValidations[0].metadata.lastName : customerValidations[0].customer.name + ' ' + customerValidations[0].customer.surname) + ' - ' + customerValidations[0].codePass}}</span>
        </div>
        <button class="btn btn-info"
          [disabled]="!findTripForm?.controls.customerInfoGroup?.controls.customerValidation?.value || customerEmail?.value"
          (click)="checkUserInfo(findTripForm?.controls.customerInfoGroup?.controls.customerValidation?.value, 2)"><i class="fas fa-search"></i>
        </button>
      </div>
    </ng-container>
    <!-- Customer validation select enabled on email check -->
    <ng-template #validationSelect>
      <div class="col-sm-6 form-group">
        <label><i class="fas fa-id-card fa-2x"></i> TESSERA CLIENTE</label>
        <select type="text" id="customerValidation" class="form-control" ngModel name="customerValidation" customerValidation #customerValidation="ngModel" required>
          <option value="">Scegli</option>
          <option *ngFor="let validation of customerValidations; let i = index" value="{{validation.id}}">{{(validation.metadata ? validation.metadata.firstName + ' ' + validation.metadata.lastName : validation.customer.name + ' ' + validation.customer.surname) + ' - ' + validation.codePass}}</option>
        </select>
      </div> 
    </ng-template>

    <div class="mt-4 col-xs-12 text-center" style="margin-top: 1.4rem;">
      <button class="btn btn-info btn-block btn-lg"
        [disabled]="findTripForm?.controls.customerInfoGroup?.controls.customerEmail?.value && !findTripForm?.controls.customerInfoGroup?.controls.customerValidation?.value"
        (click)="confirmAction()" ><i class="fas fa-check fa-1x"></i> Conferma</button>
    </div>
  </div>
</div>