import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { AppInfo, ListService } from '../../../../services/list.service';
import { LoggingService } from '../../../../services/logging.service';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['../../../../app.component.css']
})
export class FileUploadComponent implements OnChanges {
    @Input() uploadType: string;
    @Input() uploadUrl: string;
    @Input() title: string;
    @Input() description: string;
    @Input() fileTypes: string;
    @Input() companyId: string;
    @Input() noBorder: boolean = false;
    @Input() appInfo: AppInfo;

    @Output() onUpload = new EventEmitter<void>();

    @ViewChild('fileSelector') selector: ElementRef;

    uploader: FileUploader;
    fileToUpload: File | null = null;
    uploadProgress = 0;

    constructor(
        private listService: ListService,
        private logger: LoggingService
    ) {
        this.uploader = new FileUploader({
            url: environment.restBaseUrl + this.uploadUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item: any) => {
                return new Promise( (resolve, reject) => {
                    resolve({
                    fileEncoded: item.formData
                    });
                });
            }
        });
    }

    ngOnChanges(): void {
        this.removeFileInput();
    }

    handleFilesInput(files: FileList): void {
        if (this.uploader.queue.length > 1) {
          this.uploader.removeFromQueue(this.uploader.queue[0])
        };
        this.fileToUpload = files.item(0);
    }

    handleFile(item: FileItem) {
        item.isUploading = true;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileToUpload);
        reader.onload = () => {
            console.log(reader.result);
            this.uploadFile(reader.result, item)
        };
    }

    controllerApkReady(): boolean {
        return this.appInfo ? (this.appInfo && !!this.appInfo.version) : true; 
    }

    // --------- PRIVATE METHODS --------- //

    private uploadFile(base64File: any, item: FileItem) {
        base64File = base64File.substr(base64File.indexOf(',') + 1);
        const idCompany = this.companyId ? this.companyId : localStorage.getItem('idCompany');
        const payload = this.listService.generateUploadBody(
            this.uploadType,
            this.fileToUpload,
            base64File,
            idCompany,
            this.appInfo ? this.appInfo : null
        );
        this.uploadProgress = 80;
        this.logger.log('Apk upload body', payload, 200);
        this.listService
            .newObject(payload, this.uploadUrl)
            .subscribe(
            response => {
                console.log('Console', response);
                if (response.outcome.success === true) {
                    swal.fire('File uploadati con successo', '', 'success');
                    this.onUpload.emit();
                } else {
                    swal.fire('Attenzione', 'Errore nell\'importazione. Controlla le indicazioni e riprova.', 'warning');
                }
                this.selector.nativeElement.value = '';
                item.isUploading = false;
                this.removeFileInput();
            },
            error => {
                item.isUploading = false;
                this.logger.log('Error', error, 200);
                swal.fire(
                'Errore',
                'Spiacente, si è verificato un errore tecnico.',
                'error'
                );
            });
    }

    private removeFileInput() {
        this.uploader.clearQueue();
        this.fileToUpload = null;
        this.uploadProgress = 0;
    }

}
