import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "../../../services/utils.service";
import { ListService } from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import { NgForm } from "@angular/forms";
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-lines-manage',
  templateUrl: './lines-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class LinesManageComponent implements OnInit {

  @ViewChild("lineForm") formLine: NgForm;

  kindTitle: string;
  kindManage: number;
  idCompany: number;
  routeId: string;
  typeUser: string;
  isSoluzione1: boolean = false;
  companiesList: any[] = new Array();
  linesList: any[] = new Array();
  dataLine: LineModel;
  

  urlCompanies = "/company/companies";
  urlLines = "/route/routes";
  serviceToCallPath = "/route/routes/company";

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private listService: ListService,
    private logger: LoggingService,
    private router: Router
  ) {
    if (this.route.snapshot.toString().indexOf("new") !== -1) {
      this.kindTitle = "Nuova";
      this.kindManage = 1;
    } else {
      this.kindTitle = "Modifica";
      this.kindManage = 0;
    }
    this.getCompanies();
    this.getLines();
  }

  ngOnInit() {
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompanies).subscribe(
      response => {
        this.companiesList = response.results;
        this.logger.log("Lista rivenditori", this.companiesList, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getLines() {
    this.listService.getListSelect(this.urlLines).subscribe((response) => {
      this.linesList = response.results.filter(lines => !lines.idCompany);
      this.logger.log('Lista Linee assegnabili: ', this.linesList, 300);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  back() {
    this.router.navigate(["/lines/list"]);
  }

  onSubmit(): void {
    swal2.fire({
      title: "Operazione in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.dataLine = this.formLine.value;

    this.listService.newObject(this.dataLine, this.serviceToCallPath).subscribe(
      response => {
        this.logger.log('Creazione lista', response, 200);
        if (response.outcome.success === true) {
          swal2.fire("Nuova linea creata", "", "success");
          this.router.navigate(["/lines/list"]);
        } else {
          swal2.fire("Attenzione", "La linea selezionata è già stata assegnata ad un'azienda", "warning");
        }
      }, error => {
        this.logger.log("Error", error, 200);
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }
    );
  }

}

export interface LineModel {
  idCompany?: number;
  routeId: string;
}
