import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ActivityListComponent implements OnInit {

  errorMessage: string = "";

  activities: any[] = new Array();

  dataFilter: any[] = new Array();

 
  urlPath;
  supplierListPath;
  visibleFilter;
  valueOrderFilterDefault;
  UpOrDown;
  StringDateFrom;
  StringDateTo;
  viewUploadButton;

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private logger: LoggingService, private router: Router, public listService: ListService, public utils: UtilsService){
    this.listService.listRouting = "/activities/activity";
    this.listService.restURL = "/applogger/apploggers";
    this.listService.paging = true; // server pagination
    this.listService.rows = 8; // server pagination
    this.listService.page = 1;
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

    // Initialized to specific date (09.10.2018).
    private model: Object = { date: { year: 2018, month: 10, day: 9 } };

     @ViewChild('formActivityList') activityListForm: NgForm;


    activityFilterModel = {dateFrom: null, dateTo: null, agent: "", codeAgent: ""}




  ngOnInit() {
    this.supplierListPath = "/suppliers/suppliers";
    this.urlPath = "/commissions/commissions/";

    this.valueOrderFilterDefault = 0;
    this.loadElements();

    this.visibleFilter = false;
    this.UpOrDown = true;

    
    
  }


  changeStatus(){
    if (this.UpOrDown == true) {
      this.UpOrDown = false;
      this.logger.log('VIEE', this.UpOrDown, 200);
    }else{
      this.UpOrDown = true;
    }
  
  }
  
  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  filterSearch(){


    this.dataFilter = [];

    if( this.activityListForm.value.dateTo != null || this.activityListForm.value.dateTo != undefined)
    { this.dataFilter['dateTo'] = this.utils.convertDateToISO(this.activityListForm.value.dateTo); }

    if( this.activityListForm.value.dateFrom != null || this.activityListForm.value.dateFrom != undefined)
    {this.dataFilter['dateFrom'] = this.utils.convertDateToISO(this.activityListForm.value.dateFrom); }


    if( this.activityListForm.value.codeAgent != null || this.activityListForm.value.codeAgent != "")
    {this.dataFilter['codeAgent'] = this.activityListForm.value.codeAgent; }

    if( this.activityListForm.value.agent != null || this.activityListForm.value.agent != "")
    {this.dataFilter['agent'] = this.activityListForm.value.agent; }

    

   
  
 
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){
    this.activityListForm.reset();
    this.listService.visibleFilter = false;
    this.dataFilter = [];
    this.loadElements();
  }

  scroll(target){
    target.scrollIntoView();
  }

  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.activities = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

}
