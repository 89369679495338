<div class="loginColumns animated fadeInDown">
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
      <div class="ibox-content">
        <form (ngSubmit)="onSubmit()" #changePwdForm="ngForm">
          <p class="bg-info p-sm">{{activateMessage}}</p>
          <div class="form-group" >
            <label for="oldPassword">Vecchia password</label>
            <input type="password" id="oldPassword" class="form-control" [(ngModel)]="accountTest.oldPassword" name="oldPassword" placeholder="Inserisci la vecchia password"
              required password #password="ngModel" minlength="5" maxlength="15">
            </div>

            <div class="form-group">
              <label for="newPassword">Nuova Password</label>
              <div class="input-group">
                <input type={{showPassword}} id="newPassword" class="form-control" [(ngModel)]="account.password" name="newPassword" placeholder="Inserisci la nuova password"
                  required password #password="ngModel" minlength="5" maxlength="15">
                <span class="input-group-btn">
                  <button class="btn btn-secondary" (click)="showNewPassword()" type="button">
                    <i class={{icon}}></i>
                  </button>
                </span>
              </div>
              <div class="progress">
                <ngx-password-strength-bar [passwordToCheck]="account.password" [barLabel]="barLabel">
                </ngx-password-strength-bar>
              </div>
            </div>

            <br>

          

          <div class="clearfix"></div>

          <div class="form-group">

            <button type="submit" style="height: 30px" [disabled]="!changePwdForm.valid" class="btn btn-redBU block full-width m-b">Cambia Password</button>
          
            <button type="button" (click)="back()" style="height: 30px"  class="btn btn-default block full-width m-b">Annulla</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>