import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  NgForm
} from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ListService } from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import swal2, { SweetAlertResult } from "sweetalert2";
import { AuthService } from "../../../services/auth.service";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { itLocale } from "ngx-bootstrap/locale";
import { listLocales } from "ngx-bootstrap/chronos";
import { DatePipe } from "@angular/common";
import { IConfigValue } from "../../../interfaces/configuration.interface";
defineLocale("it", itLocale);

@Component({
  selector: 'app-ticket-promotions-manage',
  templateUrl: './ticket-promotions-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketPromotionsManageComponent implements OnInit {
    locale = "it";
    locales = listLocales();
    //testo in HTML per tipo pagina
    kindTitle;
    kindManage;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/ticket/promotion";
    //form html
    bsConfig: Partial<BsDatepickerConfig>;
    //oggetto contenente dati component
    ticketFaresData: any;
    stops: any[] = new Array();
    filterStops: any[] = new Array();
    lines: any[] = new Array();
    companies: any[] = new Array();
    blocks: any[] = new Array();
    pathListBlocks = "/block/blocks";
    zones: any[] = new Array();
    pathListZones = "/zone/zones";
    categories: any[] = new Array();
    pathListCategories = "/category/categories-grouped?type="; // filtro per ticket
    originStops: any[] = new Array();
    daterangepickerModel: Date[];
    bsRangeValue: Date[];
    sol1OrSa = false;
    tripsResponse: any[] = new Array();
    counterRules: any[] = new Array();
    pathListStops = "/stop/stops/v2";
    errorMessage;
    stopsListPath;
    lineListPath;
    pathToCallCompaniesPath;
    urlChannelPath ;
    /*isCheckApp;
    isCheckEcommerce;
    isCheckResale;
    isCheckDesk;*/
    tempLineValue;

    // gestione categorie
    ticketTypes: IConfigValue[];
    typeTicket: string; // T o H o altri tipi in futuro, presi dalla Utils per ogni compagnia
    groupCategories;
    groupCategoryId;
    passengers: any[] = new Array();
  
    pathRules = "/ticket/promotion/promotions/rules/"
    destinationStops: any[] = new Array();
    channelArray: any[] = new Array();
    dataChannel: any[] = new Array();
    colorTheme = "theme-orange";
    model = {
      code: "",
      name: "",
      idCompany: 1
    };
    pathListTrips = "/trip/trips/v2";
    originalStops: any[] = new Array();
  
    constructor(
      public utils: UtilsService,
      private localeService: BsLocaleService,
      private route: ActivatedRoute,
      private authService: AuthService,
      private logger: LoggingService,
      public listService: ListService,
      private router: Router
    ) {
      //Se creo nuovo veicolo
      this.bsConfig = Object.assign(
        {},
        { containerClass: this.colorTheme },
        { dateInputFormat: "DD-MM-YYYY" },
        { rangeInputFormat: "DD-MM-YYYY" }
      );
      this.localeService.use(this.locale);
      if (route.snapshot.toString().indexOf("new") != -1) {
        this.kindTitle = "Nuova";
        this.tempLineValue = "New";
        this.kindManage = 1;
      } else {
        //se modifico veicolo
        this.kindTitle = "Modifica";
        this.kindManage = 0;
        if (localStorage.getItem("dataPassed") == "") {
          this.router.navigate(["/voucher/promotion"]);
          return;
        }
  
        this.ticketFaresData = JSON.parse(localStorage.getItem("dataPassed"));
  
        let dateString = this.ticketFaresData['dateFrom']
        let newDate = new Date(dateString);
        let dateStringTo = this.ticketFaresData['dateTo']
        let newDateTo = new Date(dateStringTo);
  
        var date = new Date();    
        var firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
        var lastDay = new Date(newDateTo.getFullYear(), newDateTo.getMonth() , newDateTo.getDate());
        this.bsRangeValue = [firstDay, lastDay];
        
        this.channelArray = this.ticketFaresData['ticketChannel'];

        this.getRules(this.ticketFaresData['idTicketPromotion'])
  
        localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
      }
      this.typeTicket = this.utils.manageTicketTypes()[0].id;
    }
  
    @ViewChild("ticketRatesForm")
    ticketRatesForm: NgForm;
  
    async ngOnInit() {
      this.listService.resetList();
      this.ticketTypes = this.utils.manageTicketTypes();
      this.listService.backToList = true;
      this.listService.listRouting = "/voucher/promotion";
      this.stopsListPath = "/stop/stops";
      this.lineListPath = "/route/routes";
      this.pathToCallCompaniesPath = "/company/companies";
      this.urlChannelPath = "/ticket/channel/channels";
      
      this.getSelectStops();
      this.getSelectLines();
      this.getCompanies();
      await this.getCategories();
      this.getBlocks();
      this.getZones();
      this.getStopsFrom();
      this.getChannel();
      this.sol1OrSa = this.utils.checkSadminOrSol1();
    }
  
    removeRules(data){
      this.counterRules = this.counterRules.filter(obj => obj !== data);
    }
  
    getStopsFrom(): void {
      this.listService.getListSelect(this.pathListStops).subscribe(
        response => {
          this.originStops = response.results;
          this.logger.log("Lista fermate partenza", this.originStops, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
    getRules(id): void {
      this.listService.getListSelect(this.pathRules + id).subscribe(
        response => {
          this.counterRules = response.results;
  
          for(let c of this.counterRules){
  
            if(c.routeId != null){
                c.kindName = "LINEA"
            }
            if(c.blockId != null ){
              c.kindName  =  "TRATTA"
            }
            if(c.originId != null ){
              c.kindName  =  "TRA DUE SPECIFICHE FERMATE"
            }
            if(c.originZoneId != null ){
              c.kindName  =  "ZONA"
            }
            if(c.tripId != null ){
              c.kindName  =  "CORSA"
            }
            if(c.tag != null ){
              c.kindName  =  "TAG"
            }
            if (this.isRuleForEveryone(c)) {
              c.kindName = "PER TUTTI"
            }
            
          }
          this.logger.log("Lista rules", this.counterRules, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }

    isRuleForEveryone(rule) {
      if (rule.routeId == null && rule.blockId == null && rule.originId == null && rule.destinationId == null && rule.tripId == null) {
        return true
      }
      return false
    }
    
    addNewRules() {
  
      var model = {
        routeId: null,
        blockId: null,
        originId: null,
        destinationId: null,
        originZoneId: null,
        destinationZoneId:null,
        tripId: null,
        choseKind: null,
        decided: false,
        kindName: null,
        tag: null
      };
      this.counterRules.push(model);
    }

    confirmNewRules() {
      this.counterRules[this.counterRules.length - 1]["decided"] = true;
  
      console.log(this.counterRules[this.counterRules.length - 1]);
    }

    isRulesAllConfirmed() {
      var confirmed = true;
  
      this.counterRules.forEach(function(rule) {
        if (rule.decided == false) {
          confirmed = false;
        }
      });
  
      return confirmed;
    }
  
    getStopsTo(idStop): void {
      this.listService.getListSelect(this.pathListStops + "/" + idStop).subscribe(
        response => {
          this.destinationStops = response.results;
          this.logger.log("Lista fermate fine", this.destinationStops, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
    choseKindRules(value, data) {
      data["choseKind"] = value;
      var name = "";
  
      if (value == "1") {
        data["kindName"] = "LINEA";
      } else if (value == "2") {
        data["kindName"] = "TRA DUE SPECIFICHE FERMATE";
      } else if (value == "3") {
        data["kindName"] = "TRATTA";
      } else if (value == "4") {
        data["kindName"] = "CORSA";
      } else if (value == "5") {
        data["kindName"] = "TAG";
      } else if (value == "6") {
        data["kindName"] = "PER TUTTE LE LINEE/CORSE";
      }else if (value == "7") {
        data["kindName"] = "ZONE";
      }
    }

    async onChangeType(value) {
      //console.log("VALLLLLL" + value.target.attributes.id.nodeValue);
      this.typeTicket = value;
      await this.getCategories();
    }
  
    onChangeStop(id, data) {
      console.log(id);
  
      data["destinationId"] = null;
      data["destinationZoneId"] = null;
      data["originZoneId"] = null;
      data["tripId"] = null;
      data["blockId"] = null;
      data["routeId"] = null;
      data["originId"] = id;
      data["tag"] = null;
      this.getStopsTo(id);
    }
  
    onChangeStopTrip(id) {
      console.log(id);
      this.getStopsTo(id);
    }
  
    onChangeStopDestination(id, data) {
      data["destinationId"] = id;
      data["destinationZoneId"] = null;
      data["originZoneId"] = null;
      data["tripId"] = null;
      data["blockId"] = null;
      data["routeId"] = null;
      data["tag"] = null;
    }

    onChangeZoneOrigin(id, data) {
      console.log(id);
  
      data["destinationId"] = null;
      data["originZoneId"] = id;
      data["tripId"] = null;
      data["blockId"] = null;
      data["routeId"] = null;
      data["originId"] = null;
    }
  
    onChangeZoneDestination(id, data) {
      console.log("ZONE_ID", id);
  
      data["originId"] = null;
      data["destinationId"] = null;
      data["destinationZoneId"] = id;
      data["tripId"] = null;
      data["blockId"] = null;
      data["routeId"] = null;
    }
  
    onChangeBlockId(id, data) {
      console.log("IDBLOCK", id);
  
      data["originId"] = null;
      data["destinationId"] = null;
      data["destinationZoneId"] = null;
      data["originZoneId"] = null;
      data["tripId"] = null;
      data["blockId"] = id;
      data["routeId"] = null;
      data["tag"] = null;
    }
  
    onChangeTripId(id, data) {
      data["tripId"] = id;
      data["originId"] = null;
      data["destinationId"] = null;
      data["destinationZoneId"] = null;
      data["originZoneId"] = null;
      data["blockId"] = null;
      data["routeId"] = null;
      data["tag"] = null;
    }
  
    choseKindRoute(value, data) {
      data["originId"] = null;
      data["destinationId"] = null;
      data["destinationZoneId"] = null;
      data["originZoneId"] = null;
      data["tripId"] = null;
      data["blockId"] = null;
      data["routeId"] = value;
      data["tag"] = null;
    }

    onInputTag(input, data) {
      data["tripId"] = null;
      data["originId"] = null;
      data["destinationId"] = null;
      data["destinationZoneId"] = null;
      data["originZoneId"] = null;
      data["blockId"] = null;
      data["routeId"] = null;
      data["tag"] = input;
    }
  
    getRouteName(id) {
      var string = id;
  
      for (let r of this.lines) {
        if (r.routeId == id) {
          string = r.routeLongName;
        }
      }
      return string;
    }
  
    getStopName(id){
      
  
      var string = id;
  
      for (let s of this.originStops) {
        if (s.stopId == id) {
          string = s.stopName;
        }
      }
      return string;
    }
  
    getChannel() {
      this.listService.getListSelect(this.urlChannelPath).subscribe((response) => {
        this.dataChannel = response.results;
        this.logger.log("Lista canali", this.dataChannel, 300);
      },
        (error) => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
  
    getBlockName(id){
      var string = id;
  
      for (let b of this.blocks) {
        if (b.id == id) {
          string = b.description;
        }
      }
      return string;
    }

    getZoneName(id){
      var string = id;
  
      for (let b of this.zones) {
        if (b.zoneId == id) {
          string = b.description;
        }
      }
      return string;
    }
  
    getForAll(c){
      if (c.choseKind == 6)
        return "Verrà applicata la tariffa a qualunque linea della compagnia"
    }


    isChecked(event) {
      if (event.target.checked) {
      } else {
        this.ticketRatesForm.value.toStop = null;
        this.filterStops = [];
      }
    }
  
    findList(idLine) {
      if (this.tempLineValue != "New") {
        console.log("GO INTO", idLine);
        this.filterStops = [];
        for (let stop of this.stops) {
          if (stop.routeShortName == idLine) {
            this.filterStops.push(stop);
          }
        }
      }
      console.log("LISTA", this.stops);
    }
  
    onChangeLine(idLine) {
      var string;
  
      string = idLine.substring(3);
      console.log("Entra", string);
      this.filterStops = [];
      for (let stop of this.stops) {
        if (stop.routeShortName == string) {
          this.filterStops.push(stop);
        }
      }
    }
  
    getSelectStops() {
      this.listService.getListSelect(this.stopsListPath).subscribe(
        response => {
          this.stops = response.results;
         
          this.findList(this.tempLineValue);
          this.logger.log("Lista aziende", this.stops, 300);
          //console.log("LISTA commesse: ",response.results);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
    getSelectLines() {
      this.listService.getListSelect(this.lineListPath).subscribe(
        response => {
          this.lines = response.results;
          this.logger.log("Lista Linee", this.lines, 300);
          //console.log("LISTA commesse: ",response.results);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
    getCompanies() {
      this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
        response => {
          this.companies = response.results;
          this.logger.log("Lista aziende", this.companies, 300);
          //console.log("LISTA commesse: ",response.results);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
    // Legge le categorie previste dall'azienda
  getCategories(): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.pathListCategories + this.typeTicket).subscribe((response) => {
        this.groupCategories = response.results;
        this.groupCategoryId = 0;
        this.loadCategories();
        this.logger.log("Lista categorie ", this.groupCategories, 300);
        res();
        },
        (error) => {
          this.logger.log("Error", error, 200);
          rej();
        }
      );
    });
  }

  //  dato il groupCategory carica le categorie da selezionare
  loadCategories() {
    const group = this.kindManage === 0 ? [this.groupCategories[this.groupCategoryId]] : this.groupCategories;
    this.categories = group.map(g => g.categories.map(c => { return {...c, groupedName: `${g.name} - ${c.description}`} })).reduce((acc, val) => acc.concat(val), [])
  
    if (this.kindManage === 0) {
      const category = this.categories.find(c => c.categoryId === this.ticketFaresData['idCategory']);
      if (category) {
        this.ticketRatesForm.controls['categoriesList'].patchValue([category]);
      }
    }
  }

  onChangeBtn(index) {
    this.groupCategoryId = index;
    this.logger.log("Cat selezionata", this.groupCategoryId, 200);
    this.loadCategories();
  }
  
    getBlocks() {
      this.listService.getListSelect(this.pathListBlocks).subscribe(
        response => {
          this.blocks = response.results;
  
          this.logger.log("Lista Categorie", this.blocks, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }


    getZones() {
      this.listService.getListSelect(this.pathListZones).subscribe(
        response => {
          this.zones = response.results;

          this.logger.log("Lista Zone", this.zones, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }

  
    setIdChannel(kind) {
      //this.logger.log("PRIMA", this.channelArray, 300);
      let i = 0;
      for(let val of this.channelArray){
        
        if( val.toString() == kind.toString())
        {
          this.channelArray.splice(i,1);
          //this.logger.log("DOPO", this.channelArray, 300);
          return;
        }
        i++;
      }
      this.channelArray.push(kind.toString());
      //this.logger.log("DOPO", this.channelArray, 300);
    }
  
  
    isCheckChannel(idChannel){
      for(let i of this.channelArray){
        //this.logger.log("i "+ i + "idC" + idChannel, i, 300);
        if( i.toString() == idChannel.toString()) return true;
      }
      return false;
    }

    /*onFilterChange(eve: any, kind) {
      if (kind == "APP") {
        this.isCheckApp = eve;
      } else if (kind == "ECO") {
        this.isCheckEcommerce = eve;
      } else if (kind == "DES") {
        this.isCheckDesk = eve;
      } else if (kind == "RES") {
        this.isCheckResale = eve;
      }
  
      console.log(eve);
    }*/
  
    onSubmit(): void {
      /*this.channelArray = [];
      if (this.isCheckApp) {
        this.channelArray.push(2);
      }
      if (this.isCheckDesk) {
        this.channelArray.push(5);
      }
      if (this.isCheckResale) {
        this.channelArray.push(3);
      }
      if (this.isCheckEcommerce) {
        this.channelArray.push(4);
      }
  */
      console.log("APP CHANNEL", this.channelArray);

      swal2.fire({
        title: "Operazione in corso...",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Indietro",
        text: "Stai creando una promozione con "+this.counterRules.length+" regola/e. Continuare?",
        confirmButtonText: "Procedi"
      }).then((ev: SweetAlertResult) => {
        if (ev.value) {
          swal2.fire({
            title: "Operazione in corso...",
            didOpen: function() {
              swal2.showLoading();
            }
          });
  
          console.log(this.bsRangeValue[0]);
          var dateFrom = new DatePipe("en-EN").transform(
            this.bsRangeValue[0],
            "yyyy-MM-dd"
          );
          var dateTo = new DatePipe("en-EN").transform(
            this.bsRangeValue[1],
            "yyyy-MM-dd"
          );
    
          if (this.kindManage) {
            var requestData: any = new Object();
            requestData.dateFrom = dateFrom;
            requestData.dateTo = dateTo;
            requestData.description = this.ticketRatesForm.value.description;
            requestData.price = this.ticketRatesForm.value.price;
            
            if (this.kindManage === 0) {
              requestData.categoryId = this.ticketFaresData['idCategory'];
            } else {
              requestData.categoryIds = this.ticketRatesForm.value.categoriesList.map(c => c.categoryId);
            }
  
            requestData.idTicketChannel = this.channelArray;
            requestData.promotionRules = this.counterRules;
            requestData.type  = this.ticketRatesForm.value.typology;
            requestData.nMax = this.ticketRatesForm.value.nMax;
            requestData.format = this.ticketRatesForm.value.format;
      
            this.listService.newObject(requestData, this.serviceToCallPath).subscribe(
              response => {
                if (response.outcome.success === true) {
                  this.router.navigate(["/voucher/promotion"]);
                  swal2.fire("La tariffa è stata creata", "", "success");
                } else {
                  this.errorMessage =
                    response.outcome.code + response.outcome.description;
                  swal2.fire("Attenzione", this.errorMessage, "warning");
                }
              },
              error => {
                this.logger.log("Error", error, 200);
                this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                swal2.fire(
                  "Errore",
                  "Spiacente, si è verificato un errore tecnico.",
                  "error"
                );
              }
            );
          } else {
            var requestData: any = new Object();
            requestData.dateFrom = dateFrom
            requestData.dateTo = dateTo 
            requestData.description = this.ticketRatesForm.value.description;
            requestData.price = this.ticketRatesForm.value.price;
            requestData.categoryId = this.ticketFaresData['idCategory'];
            requestData.idTicketChannel = this.channelArray;
            requestData.promotionId = this.ticketFaresData['idTicketPromotion']
            requestData.promotionRules = this.counterRules;
            requestData.type  = this.ticketFaresData['typology']
            requestData.nMax = this.ticketRatesForm.value.nMax
            requestData.format = this.ticketFaresData['format']   
  
  
            
      
            console.log("TARIFFA DA MOD", this.ticketFaresData);
      
            this.listService
              .edit(requestData, this.serviceToCallPath)
              .subscribe(
                response => {
                  if (response.outcome.success === true) {
                    this.router.navigate(["/voucher/promotion"]);
                    swal2.fire("La tariffa è stata modificata", "", "success");
                  } else {
                    this.errorMessage = "Credenziali non valide.";
                    swal2.fire("Attenzione", "Credenziali non valide", "warning");
                  }
                },
                error => {
                  this.logger.log("Error", error, 200);
                  this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                  swal2.fire(
                    "Errore",
                    "Spiacente, si è verificato un errore tecnico.",
                    "error"
                  );
                }
              );
          }
        }
      } , (dismiss) => {
        swal2.close()
      });
  
    }
  
    returnToList() {
      this.router.navigate(["/voucher/promotion"]);
    }
  
    choseTripList(stopto, stopfrom) {
      const dataRequest = {};
      dataRequest["origin"] = stopfrom;
      dataRequest["destination"] = stopto;
  
      var date = new Date();
      dataRequest["dateA"] = new DatePipe("en-EN").transform(date, "yyyy-MM-dd");
      console.log(dataRequest);
  
      //sessionStorage.setItem("dataRequestTrips", JSON.stringify(dataRequest));
      this.getTrips(dataRequest);
      //this.router.navigate(['/trip/list']);
    }
  
    getTrips(dataRequest): void {
      swal2.fire({
        title: "Caricamento in corso...",
        didOpen: function() {
          swal2.showLoading();
        }
      });
  
      this.listService.newObject(dataRequest, this.pathListTrips).subscribe(
        response => {
          swal2.close();
          this.tripsResponse = response.andata;
        },
        error => {
          swal2.close();
          this.logger.log("Error", error, 200);
        }
      );
    }
  }
  
