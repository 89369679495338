<div class="loginColumns animated fadeInDown">
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
      <div class="ibox-content">
        <img src="./assets/img/praticko.png" width="100%">
        <div [ngClass]="{hide: sessionExpired('expired')}" class="well"><i class="fa fa-lg fa-smile-o" aria-hidden="true"> </i> 
          Sei uscito correttamente dall'area riservata.
        </div>
        <div [ngClass]="{hide: sessionExpired('0')}" class="alert alert-danger"><i class="fa fa-lg fa-frown-o" aria-hidden="true"> </i> 
          Authentication failed.
        </div>
        <h3 style="line-height:1.5">Per eseguire nuovamente l'accesso torna alla pagina di Login.</h3><br>
        <button (click)="goToLogin()" type="button" class="btn btn-redBU block full-width m-b">Login</button>
      </div>
    </div>
  </div>
</div>