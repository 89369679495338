<div class="row wrapper border-bottom white-bg page-heading float-e-margins mTB20">
  <div class="col-lg-9">
    <h2><strong>Elenco ordini</strong></h2>
    <ol class="breadcrumb"><li> </li></ol>
  </div>
  <div class="col-lg-3">
    <button type="button"  *ngIf="isAdministrator" (click)="newOrder()" class="btn btn-redBU btn_new_element" style="float: right;margin-top:15px;"><i class="fa fa-plus"></i> Nuovo</button>  
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-content">
        <div class="row" *ngIf=isAdministrator>
          <form  #f="ngForm">
            <div class=' col-sm-12 col-lg-2'>
              <div class="form-group">
                <label>Fornitore</label>
                <div class='input-group col-md-12' >
                  <select
                    type="text"
                    id="idSupplier"
                    class="form-control"
                    ngModel
                    [ngModel]="defaultValue"
                    name ="idSupplier"
                    
                    required
                    idSupplier
                    #idSupplier="ngModel"
                    required>
                    <option [ngValue] = "0"> </option>
                    <option *ngFor="let s of suppliers" [ngValue]="s.idSupplier">{{s.businessName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class='col-sm-12 col-lg-2'>
              <div class="form-group">
                <label>Commessa</label>
                <div class='input-group col-md-12' >
                  <div class='input-group col-md-12' >
                    <select 
                      type="text"
                      id="idCommission"
                      class="form-control"
                      ngModel
                      [ngModel]="defaultValue"
                      name ="idCommission"
                      required
                      idCommission
                      #idCommission="ngModel" >
                      <option [ngValue] = 0 > </option>
                      <option *ngFor="let c of commissions" [ngValue]="c.idCommission">{{c.codeCommission}} </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-sm-12 col-lg-2'>
              <div class="form-group">
                <label>Stato</label>
                <div class='input-group col-md-12' >
                    <select 
                      type="text"
                      id="idStatus"
                      class="form-control"
                      ngModel
                      name ="idStatus"
                      required
                      idStatus
                      #idStatus="ngModel" >
                      <option value="0"></option>
                      <option value="open">Aperto</option>
                      <option value="closed">Chiuso</option>
                    </select>
                </div>
              </div>
            </div>

            
      
          <div class='col-sm-2'>
            <div class="form-group">
              <label style="color:white;width:100%;">Filtra</label>
              <button type="button"  (click)="search()" class="btn btn-default btn_new_element" > <i class="fa fa-filter"></i>  Filtra</button>
            </div>     
          </div>

          <div class='col-sm-2'>
            <div class="form-group">
                <label style="color:white;width:100%;">Reset</label>
              <button *ngIf="visibleFilter" type="button"  (click)=" reset()" class="btn btn-view btn_new_element" > <i class="fa fa-rotate-left"></i></button>
            </div>
          </div>

          <div class='col-sm-2' >
              <div class="form-group">
                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> <div class="clearfix"></div>
                    <!--<button type="button"  *ngIf="isAdministrator" (click)="newOrder()" class="btn btn-primary btn_new_element"><i class="fa fa-plus"></i> Nuovo</button> --> 
              </div>
          </div>
            </form>
        </div>
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example" >
            <thead>
              <tr>
                <th>Codice - Descrizione</th>
                <th>Fornitore</th>
                <th>Commessa</th>
                <th>Stato</th>
                <th>Tipologia</th>
                <th style="width:100px;"></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let order of orders"  style="cursor:pointer">
                <td >{{order.codeOrder}} - {{order.descOrder}}</td>
                <td >{{order.businessName}}</td>
                <td >{{order.descCommission}}</td>
                <td >{{order.orderStatus === 'OPEN' ? 'Aperto' : 'Chiuso'}}</td>
                <td >{{order.codeOrderType === 'NORMAL' ? 'Normale' : 'Slim'}}</td>
                <td style="width: 165px" class="col-md-4 text-center"> 
                  
                    <button type="button" class="btn btn-view btn-outline"    (click)="reviewDocuments(order.idOrder,order.codeOrderType,order.codeOrder, order.orderStatus)"><i class="fa fa-search"></i></button>
                  <button *ngIf="isAdministrator" type="button" class="btn btn-primary btn-outline" (click)="selectRow(order.idOrder,order.codeOrder)" ><i class="fa fa-pencil"></i></button>
                    
                    <button *ngIf="isAdministrator"  type="button" class="btn btn-danger btn-outline" (click)=" changeOrderID(order.idOrder,order.codeOrder)">
                      <i class="fa fa-trash-o"></i>
                    </button>
                    
                </td>
              </tr>
            </tbody>        
          </table>
          <ngb-pagination 
                *ngIf="listService.totalRows > listService.rows"
                class="pull-right" 
                [collectionSize]="listService.totalRows" 
                [maxSize]="10"
                [pageSize]="listService.rows" 
                [(page)]="listService.page"
                (pageChange)="pageChange($event)" >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
