import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal2 from "sweetalert2";
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sales-channels-manage',
  templateUrl: './sales-channels-manage.component.html',
  styleUrls: ['./sales-channels-manage.component.css']
})
export class SalesChannelsManageComponent implements OnInit {

  @ViewChild('channelForm') formChannel: NgForm;

  kindTitle: string;
  kindManage: number;
  dataChannel: TicketChannel;
  id: number;
  idCompany: number;
  errorMessage:string;
  domainsList: any[] = new Array();

  domainsPath = "/multiselect/ticketChannel/domain";
  serviceToCallPath = "/ticket-channel";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listService: ListService,
    private logger: LoggingService
  ) {
    //Se creo nuovo canale vendita
    if (this.route.snapshot.toString().indexOf("new") != -1) {
      this.kindTitle = "Nuovo";
      this.kindManage = 1;
    } else {
      //se modifico canale vendita
      this.kindTitle = "Modifica";
      this.kindManage = 0;

      this.id = this.listService.selectedID;
      this.idCompany = 1;

      this.getChannelInfo();
    }
  }

  ngOnInit(): void {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = "/salesChannels";
    this.getDomains();
  }

  getChannelInfo(): void {
    this.listService.getListSelect(`${this.serviceToCallPath}/${this.id}`).subscribe(
      response => {
        this.dataChannel = response.data;
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    )
  }

  getDomains(): void {
    this.listService.getListSelect(this.domainsPath).subscribe(
      response => {
        this.domainsList = response.results;
        this.logger.log("Lista domini", this.domainsList, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    )
  }

  onSubmit(): void {
    swal2.fire({
      title: "Operazione in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.dataChannel = this.formChannel.value;

    if (this.kindManage) {
      this.listService.newObject(this.dataChannel, this.serviceToCallPath).subscribe(
        response => {
          if (response.outcome.success === true) {
            swal2.fire("Nuovo canale di vendita creato", "", "success");
            this.back();
          } else {
            this.errorMessage = "Credenziali non valide.";
            swal2.fire("Attenzione", this.errorMessage, "warning");
          }
        },
        error => {
          this.logger.log("Error", error, 200);
          this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
          swal2.fire(
            "Errore",
            this.errorMessage,
            "error"
          );
        }
      )
    } else {
      this.dataChannel.idChannel = this.id;
      this.listService.edit(this.dataChannel, this.serviceToCallPath).subscribe(
        response => {
          if (response.outcome.success === true) {
            swal2.fire("Canale di vendita modificato", "", "success");
            this.back();
          } else {
            this.errorMessage = response.outcome.code + response.outcome.description;
            swal2.fire("Attenzione", this.errorMessage, "warning");
          }
        },
        error => {
          this.logger.log("Error", error, 200);
          this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
          swal2.fire(
            "Errore",
            this.errorMessage,
            "error"
          );
        }
      )
    }
  }

  back() {
    this.router.navigate(["/salesChannels"]);
  }

}

export interface TicketChannel {
  domain: string;
  idChannel: number;
  name: string;
}