<div class="row wrapper border-bottom white-bg page-heading float-e-margins mTB20">
    <div class="col-lg-9">
        <h2><strong>Elenco ordini-mese relativi all'ordine {{nameOrder}}</strong></h2>
    </div>
    <div class="col-lg-3">
        <button type="button" style="margin-top:15px;"  *ngIf="isSupplier" (click)="newDocument()" class="btn btn-redBU btn_new_element"><i class="fa fa-plus"></i> Nuova Cartella</button>
    </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
        <div class="row" *ngIf="viewMask; else viewDoc">
          <div class="col-lg-12">
            <div class="ibox float-e-margins">
              <div class="ibox-content">
          
                <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
                <p *ngIf="noChecklist" class="bg-info p-sm"><i class="fa fa-lg fa-info" aria-hidden="true"></i>  Nessun documento caricato per questo ordine</p>
                <div *ngIf="errorMessage === ''" class="table-responsive">
                  <table class="table table-striped table-bordered table-hover dataTables-example" >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Mese</th>
                        <th>Stato</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="errorMessage === ''">
                      <tr *ngFor="let d of documents" style="cursor:pointer">
                        <td class="text-center" style="width: 50px;">
                          <i class="fa fa-folder"></i>
                        </td>
                        <td>{{d.month === 01 ? 'Gennaio' : d.month === 02 ? 'Febbraio' : d.month === 03 ? 'Marzo' : d.month === 04 ? 'Aprile': 
                          d.month === 05 ? 'Maggio' : d.month === 06 ? 'Giugno' : d.month === 07 ? 'Luglio' : d.month === 08 ? 'Agosto':
                        d.month === 09 ? 'Settembre' : d.month === 10 ? 'Ottobre' : d.month === 11 ? 'Novembre' : 'Dicembre'}}  {{d.year}}
                        </td>
                        <td>
                          <button *ngIf="d.status  === 'OPEN'" class="btn btn-view" title="Aperto" ><i class="fa fa-ellipsis-h"></i></button>
                          <button *ngIf="d.status  === 'APPROVED'" class="btn btn-primary" title="Approvato" ><i class="fa fa-thumbs-o-up"></i></button>
                          <button *ngIf="d.status  === 'SUBMITTED'" class="btn btn-warning" title="Sotto esame" ><i class="fa fa-question"></i></button>
                          <button *ngIf="d.status  === 'REJECTED'" class="btn btn-danger" title="Rifiutato" ><i class="fa fa-thumbs-o-down"></i></button>
                           <button *ngIf="d.status  === 'CONDITIONALLY_APPROVED'" class="btn btn-warning" title="Approvato con riserva" ><i class="fa fa-exclamation"></i></button>  
                          
                          {{d.status  === 'OPEN' ? 'Aperto' : d.status === 'APPROVED' ? 'Approvato' : d.status === 'SUBMITTED' ? 'In attesa di approvazione' : d.status === 'CONDITIONALLY_APPROVED' ? '  Approvato con riserva' : 'Rifiutato' }}</td>
                        <td style="width: 230px;text-align:left;" class="col-md-4 text-center"> 
                            <button type="button" class="btn btn-view btn-outline" title="Visualizza" (click)="selectRow(d.idChecklist, d.year, d.month, d.status)"><i class="fa fa-search"></i></button>
                            <button *ngIf="isSupplier && statusOrder !== 'CLOSED' && (d.status == 'OPEN' || d.status == 'REJECTED' || d.status == 'CONDITIONALLY_APPROVED')" type="button" class="btn btn-primary btn-outline" data-toggle="tooltip" title="Chiedi validazione" (click)="requestValidationDocuments(d.idChecklist)"><i class="fa fa-thumbs-up"></i></button>
                            
                            
                            <button *ngIf="!isSupplier && d.status == 'APPROVED' "  type="button" class="btn btn-default btn-outline" data-toggle="tooltip" title="Report"(click)="downloadReportApprover(d.idChecklist)" ><i class="fa fa-file-pdf-o"></i> <i class="fa fa-user-circle"></i></button>
                           
                              <button *ngIf="!isSupplier &&  d.status == 'APPROVED' "  type="button" class="btn btn-default btn-outline" data-toggle="tooltip" title="Report"(click)="downloadReportSupplier(d.idChecklist)" ><i class="fa fa-file-pdf-o"></i> <i class="glyphicon glyphicon-briefcase"></i>    </button>
  
                              <button *ngIf="!isSupplier &&  d.status == 'APPROVED'"  type="button" class="btn btn-default btn-outline" data-toggle="tooltip" title="Invia Mail"(click)="sendMailReport(d.idChecklist)" ><i class="fa fa-envelope-o"></i>     </button>
  
  
                             <button *ngIf="!isSupplier &&  statusOrder !== 'CLOSED'  && (d.status == 'SUBMITTED')"  type="button" class="btn btn-info btn-outline" data-toggle="tooltip" title="Approva" (click)="ApproveValidationDocuments(d.idChecklist)"><i class="fa fa-thumbs-up"></i></button>
                           <button *ngIf="!isSupplier && statusOrder !== 'CLOSED'  && (d.status == 'SUBMITTED')"  type="button" class="btn btn-danger btn-outline" title="Rifiuta" (click)="rejectSwal(d.idChecklist,'reject')" ><i class="fa fa-thumbs-down"></i></button>
                          <button *ngIf="!isSupplier && statusOrder !== 'CLOSED'  && (d.status == 'SUBMITTED')"  type="button" class="btn btn-warning btn-outline" title="Approva Con Riserva" (click)="conditionallyApprove(d.idChecklist,'conditionally')"><i class="fa fa-exclamation"></i></button>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                  <ngb-pagination *ngIf="listService.totalRows > listService.rows"
                    class="pull-right" 
                    [collectionSize]="listService.totalRows" 
                    [maxSize]="10"
                    [pageSize]="listService.rows"
                    [(page)]="listService.page"
                    (pageChange)="pageChange($event)" >
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
          <app-documents-viewer #viewerDiv class="row" [ngClass]="{hidden: viewMask}"></app-documents-viewer> 
      </div>
      
      