import { Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import swal2 from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-users-ecommerce',
  templateUrl: './users-ecommerce.component.html',
  styleUrls: ['../../../app.component.css']
})
export class UsersEcommerceComponent implements OnInit {

  // FORM
  @ViewChild('formUser') userForm: NgForm;
  // OGGETTO LISTA ELEMENTI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();
  filterFilterRole: any[] = new Array();
  // PATH CHIAMATE
  urlDeletePath = '';
  urlCompany = '/company/companies';
  companyVisible;
  visibleFilter;
  defaultValue;
  UpOrDown;
  roleUser;
  typeUser;
  isSoluzione1;
  isCompany
  errorMessage = '';
  visiblePaging;

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    public listService: ListService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/users/ecommerce', false, true, 1, 10);
    this.listService.restURL = '/customer/customers';
  }

  async ngOnInit() {

    this.companiesList = await this.utils.getCompanyList();
    this.companyVisible = this.utils.visibleCompany(this.companyVisible);
    this.UpOrDown = true;
    this.roleUser = this.utils.getRoleUser();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.isCompany = this.utils.isComp(this.typeUser);

    this.loadElements()
    this.listService.paging = true;

  }

  translateTypeUser(typeUser) {
    return this.utils.translateTypeUser(typeUser);
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
      console.log('VIEE', this.UpOrDown)
    } else {
      this.UpOrDown = true;
    }
  }

  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === true) {
      return buttonColor =  'Attivo'
    }
    if (statusNumber === false) {
      return buttonColor =  'Non Attivo'
    }
  }


  getButtonCircle(statusNumber) {
    let buttonColor;
    if (statusNumber === true) {
      return   buttonColor =  'btn btn-primary btn-circle'
    }
    if (statusNumber === false) {
      return   buttonColor =  'btn btn-danger btn-circle'
    }
  }

  getBusinessName(id: string) {
    let string: string;
    for (let company of this.companiesList) {
      if (company.idCompany === id) {
        return string = company.legalBusinessName
      }
    }
  }

  translateCodeRole(codeRole) {
    return this.utils.translateCodeRole(codeRole);
  }

  translateCodeRoleDiff(typeUser) {
    return this.utils.translateCodeRoleDiff(typeUser);
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.userForm.value;

    console.log(this.userForm.value.typeUser)
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset() {
    this.userForm.value.typeUser = ''
    this.userForm.value.roleUser = ''
    this.userForm.value.username = ''
    this.userForm.value.status = ''
    this.dataFilter = []
    this.userForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        if (  this.listService.totalRows > 10) {
          this.visiblePaging = true;
        } else {
          this.visiblePaging = false;
        }
        console.log( 'RITORNO', this.listService.totalRows )

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      }, (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  getActivationLink (inviteUrl) {
    swal2.fire('Link di attivazione utente', inviteUrl, 'info')
  }
}
