
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins" style="margin:14px">

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                <div class="ibox  float-e-margins ">
                    <div class="ibox-title">
                        <h4>Crea una nuova cartella</h4>
                    </div>
                    <div class="ibox-content">
                        <div class="row">
                                <form  #f="ngForm"> 
                                    <div class="form-group col-md-6">
                                        <label>Anno</label>
                                        <select class="form-control" id="year" class="form-control" ngModel name="year" [ngModel]="yearsDefaultValue" year #year="ngModel">
                                            <option value=2017>2017</option>
                                            <option value=2018>2018</option>
                                            <option value=2019>2019</option>
                                            <option value=2020>2020</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Mese</label>
                                        <select class="form-control"  id="month" class="form-control" ngModel name="month" [ngModel]="monthDefaultValue" month #month="ngModel" >
                                            <option value=01>Gennaio</option>
                                            <option value=02>Febbraio</option>
                                            <option value=03>Marzo</option>
                                            <option value=04>Aprile</option>
                                            <option value=05>Maggio</option>
                                            <option value=06>Giugno</option>
                                            <option value=07>Luglio</option>
                                            <option value=08>Agosto</option>
                                            <option value=09>Settembre</option>
                                            <option value=10>Ottobre</option>
                                            <option value=11>Novembre</option>
                                            <option value=12>Dicembre</option>
                                        </select>
                                    </div>
                                </form>
                                <br>


                                <div class="form-group col-md-6" >
                                <button type="button" class="btn btn-redBU" (click)="createNewCheckList()">
                                                <span class="fa fa-folder-open-o"></span> Crea nuova cartella
                                            </button>
                                        </div>
                        </div>
                            </div>
                       
                               
                                
                                      
                             
                                         
                                          
                                            
                      
                      
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div #myModal class="modal fade show" id="myModal" tabindex="-1" role="dialog" 
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Errore caricamento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
                Non è stato possibile caricare il Documento perchè {{errorMessage}} {{errorDurcMessage}}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Chiudi</button>
        </div>
        </div>
    </div>
    </div>