import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilsService } from '../../../services/utils.service';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-voucher-channels-list',
  templateUrl: './voucher-channels-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class VoucherChannelsListComponent implements OnInit {

  @ViewChild('formVoucherChannel') voucherChannelForm: NgForm;
  @ViewChild('formChangeCompany') changeCompanyForm: NgForm;

  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();

  //PATH CHIAMATE
  urlCompany = "/company/companies";

  errorMessage: string = '';
  typeUser: string;
  isSoluzione1: boolean = false;
  modalRef: BsModalRef;
  details: any;
  detailCompanies: string [];
  detailCompany: number;

  constructor(
    public listService: ListService,
    private utils: UtilsService,
    private logger: LoggingService,
    private modalService: BsModalService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/voucherEmitChannel/channels/list", false, true, 1, 10);
    this.listService.restURL = "/voucherEmitChannel/channels";

    this.typeUser = this.utils.getTypeUser();

    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  async ngOnInit(): Promise<void> {
    //CHIAMATE PER SELECT
    this.companiesList = await this.utils.getCompanyList();

    //CHIAMATA LISTA ELEMENTI
    this.loadElements();
  }

  changeCompany(){
    this.filterSearch();
  }

  getBusinessName(id: number): string {
    for(let company of this.companiesList) {
      if(company.idCompany == id){
        return company.legalBusinessName
      }
    }
  }

  pageChange(page: number): void {
    this.listService.page = page;
    this.loadElements();
  }

  viewDetailVoucher(data, template: TemplateRef<any>): void {
    this.details = data;
    this.detailCompanies = this.details.companies ? this.details.companies.map(c => c.legalBusinessName) : ['--']
    this.openModal(template)
  }

  closeModal(): void {
    this.modalRef.hide()
    this.detailCompany = null;
  }

  saveVoucherCompany(): void {
    swal2.fire({
      title: 'Attivazione in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    });
    this.listService.associateVoucherCompany({ name: this.details.name, idCompany: this.detailCompany }).subscribe(
      (response) => {
        swal2.close();
        this.closeModal();
        this.logger.log("Associazione compagnia a voucher", response, 300);
        swal2.fire({title: 'Compagnia associata correttamente', icon: 'success', timer: 1000, showCancelButton: false, showConfirmButton: false});
        this.loadElements();
      },
      (error) => {
        swal2.close();
        this.logger.log("Error", error, 200);
        swal2.fire({title: 'Error', text: 'Errore associazione compagnia', icon: 'error', timer: 1000, showCancelButton: false, showConfirmButton: false});
      }
    )
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.voucherChannelForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){
    this.voucherChannelForm.value.filterIdCompany = 'all';
    this.voucherChannelForm.value.name = '';
    this.voucherChannelForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  // --------- PRIVATE METHODS --------- //

  //SERVIZIO LISTA ELEMENTI
  private loadElements(): void {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response: ", response, 300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error: ", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  private openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

}
