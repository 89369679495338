<div class="row" style="padding-top: 20px"></div>

<div class="">


  <div  class="col-lg-12"><!-- *ngIf="!qrCodeWindow" -->
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-md-6">
          <strong>Analisi Voucher</strong>
        </h2>
      </div>
      <div class="ibox-content">

        <form #formStatsVoucher="ngForm" class="row">


          <div *ngIf="isSol1" class="form-group col-lg-2 col-xs-6">

            <label>Azienda di trasporto</label>
            <div class='input-group col-xs-12'>
              <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel"
                required [(ngModel)]="dataFilter['idCompany']">
                <option [ngValue]="'all'"> Tutti </option>
                <option *ngFor="let c of dataCompany" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
              </select>
            </div>

          </div>
          
          <div class="form-group  col-xs-12 col-sm-6 col-md-3 col-lg-2">
            <label>Canali:</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="emitChannel" class="form-control" name="emitChannel" emitChannel #emitChannel ngModel [(ngModel)]="dataFilter['emitChannel']">
                <option [value]="'all'">Tutti</option>
                <option *ngFor="let c of channelList" [value]="c.name">{{ c.name }}</option>
              </select>
            </div>
          </div>

          <div class="form-group col-lg-2 col-xs-6">
            <label>Id Ticket</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <input type="text" id="idTicket" class="form-control" ngModel name="idTicket" required idTicket #idTicket="ngModel" [(ngModel)]="dataFilter['idTicket']">
            </div>
          </div>

          <div class="form-group col-lg-2 col-xs-6">
            <label>Codice Ticket</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <input type="text" id="codeTicket" class="form-control" ngModel name="codeTicket" required codeTicket #codeTicket="ngModel" [(ngModel)]="dataFilter['codeTicket']">

            </div>
          </div>

          <div class='col-xs-12 col-sm-6 col-md-12 col-lg-3 form-group'>
            <label>Vendita da - a:</label>
            <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue" ngModel bsDaterangepicker
              #bsDaterangepicker placement="right" [bsConfig]="bsConfig" placement="bottom" autocomplete="off">
          </div>

          <div class="form-group col-lg-2 col-xs-6">
            <label>Codice Voucher</label>
            <input type="text" id="codeVoucher" class="form-control" ngModel name="codeVoucher" codeVoucher #codeVoucher="ngModel" [(ngModel)]="dataFilter['codeVoucher']">
          </div>

          <!--<div class="form-group col-lg-2 col-xs-4">
            <label>Stato</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="status" class="form-control" ngModel name="status" required status #status="ngModel" [(ngModel)]="dataFilter['status']">
                <option [ngValue]="'all'"> Tutti </option>
                <option value="NEW">Nuovo</option>
                <option value="ACTIVE">Attivo</option>
                <option value="VALIDATED">Obliterato</option>
                <option value="CANCELED">Cancellato</option>
              </select>
            </div>
          </div>-->

          <!-- <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Canale</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="idTicketChannel" class="form-control" ngModel name="idTicketChannel" required idTicketChannel #idTicketChannel="ngModel"
                required>
                <option [ngValue]="'all'"> Tutti </option>
                <option *ngFor="let c of dataChannel" [ngValue]="c.idChannel">{{c.name}}</option>
              </select>
            </div>
          </div> -->

          <!-- <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Rivendita</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="idResale" class="form-control" ngModel name="idResale" required idResale #idResale="ngModel">
                <option [ngValue]="'all'"> Tutte </option>
                <option *ngFor="let r of dataResale" [ngValue]="r.id">{{r.legalBusinessName}} </option>
              </select>
            </div>
          </div> -->

          <!-- <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Desk</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="idDesk" class="form-control" ngModel name="idDesk" required idDesk #idDesk="ngModel">
                <option [ngValue]="'all'"> Tutte </option>
                <option *ngFor="let d of dataDesk" [ngValue]="d.id">{{d.name}} </option>
              </select>
            </div>
          </div> -->

          <!-- <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Linea</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select (change)="onChange($event.target.value)" type="text" id="routeId" class="form-control" ngModel name="routeId" routeId
                #routeId="ngModel" required [(ngModel)] = "modTicketRoute">
                <option [ngValue]="'all'"> Tutte </option>
                <option *ngFor="let o of lineList, let x = index" [value]='x'>{{o.routeId}}, {{o.routeLongName}}</option>
            </select>
            </div>
          </div> -->

          <!-- <div class="form-group col-lg-2 col-xs-4">
            <label for="direction">Direzione </label>
            <select type="text" id="direction" (change)="getDirection($event.target.value)" class="form-control" ngModel name="direction"
                required direction #direction="ngModel" [(ngModel)] = "modTicketDir">
                <option *ngFor="let d of modDirectionList, let f = index" [value]="f">{{d.arrival}} </option>
            </select>
          </div> -->

          <!-- <div *ngIf="!isResale" class="form-group col-lg-2 col-xs-4">
            <label>Corsa</label>
            <div class='input-group col-lg-12  col-xs-12'>
              <select type="text" id="tripId" (change)="getTrip($event.target.value)" class="form-control" ngModel name="tripId" required tripId #tripId="ngModel" [(ngModel)] = "modTicketTrip">
                <option [ngValue]="null"> Tutte </option>
                <option  *ngFor="let data of modTripList,let i = index" [value]="i">{{data.departureTime}} - {{data.arrivalTime}} ({{data.tripId}})</option>
            </select>
            </div>
          </div> -->

          <div class="col-xs-12"></div>
          
          <div class="form-group col-xs-12 col-sm-6 col-sm-6 col-md-3 col-lg-3">
            <div class="">
              <label></label>
              <div class='input-group col-md-12'>
                <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                  <i class="fa fa-rotate-left"></i>
                </button>
                <!-- <button *ngIf="!isResale" type="button" (click)="getCsv()" style="margin-left: 10px" class="btn btn-info btn-outline">
                  Esporta</button> -->
              </div>
            </div>
          </div>

        </form>

        <div class="clearfix"></div>

        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
        </p>

        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>ID Ticket</th>
                <th>ID Voucher</th>
                <th>Codice Voucher</th>
                <th>Corsa</th>
                <th>Emesso da</th>
                <th>Obliterato il</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList" style="cursor:pointer">
                <td>{{data.idTicket}}</td>
                <td>{{data.idVoucher}}</td>
                <td>{{data.codeVoucher}}</td>
                <td>{{data.idTrip}}</td>
                <td>{{data.voucherEmitChannel}}</td>
                <td>{{data.purchasedForDate | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                <td style="width: 100px" class="col-md-4 text-center">
                  <!--<button *ngIf="data.ticketIds.length > 0 || data.transactionId != null" (click)="viewDetailsTicket(data, ticketTemplate)" title="{{utils.checkTitleTickets(data.status)}}"
                    style="width: 40px" type="button" class="btn btn-success">                 
                    <i *ngIf="data.ticketIds.length > 0" class="fa fa-info"></i>
                    <i *ngIf="data.transactionId != null" class="fa fa-ticket"></i>
                  </button>-->
  
                  <button style="float: left" type="button" class="btn btn-primary btn-outline "
                    *ngIf="data.voucherEmitChannel != 'FLIXBUS'" (click)="goToVoucherDetail( data?.codeVoucher )"
                    tooltip="" placement="top">
                    <i class="fa fa-gift"></i>
                  </button>
  
                  <button style="float: left" type="button" class="btn btn-primary btn-outline "
                    *ngIf="data.voucherEmitChannel == 'FLIXBUS'" (click)="goToStatisticsObliterations( data?.idTicket )"
                    tooltip="" placement="top">
                    <i class="fa fa-check"></i>
                  </button>
  
                  <button type="button" style="float: right" *ngIf="data.voucherEmitChannel == 'FLIXBUS'"
                    class="btn btn-primary btn-outline" (click)="openQrCodeModal(data?.codeEncrypted, qrCodeTemplate)"
                    tooltip="" placement="top">
                    <i class="fa fa-qrcode"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
            [maxSize]="10"[pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ticketTemplate>

  <div tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Dettaglio</h4>
      </div>
      <br>
      <ng-container *ngFor="let ticket of dataTickets">
        <div>
          <h2 style="margin-left: 25px">Ticket <span *ngIf="ticket.idTicket != null"> num. {{ticket.idTicket}}</span></h2>
          <div class="row" style="margin-left: 15px;">
            <div class="col-xs-12">
              <h3 ><i class="fas fa-calendar-alt" ></i> Usabile nel periodo {{ticket.usableFrom | date:'dd-MM-yyyy HH:mm'}} - {{ticket.usableTo | date:'dd-MM-yyyy HH:mm'}}</h3>
            </div>
            <div class="col-xs-12">
              <h3 ><i class="fas fa-flag-checkered" ></i> {{ticket.ticketRoute}}</h3>
            </div>
            <div class="col-xs-12">
              <h3 ><i class="fas fa-money" ></i> Venduto il {{ticket.ticketSaleDate | date:'dd-MM-yyyy HH:mm'}}</h3>
            </div>
            <div class="col-sm-6">
              <h3 *ngIf="ticket.origin"><i class="fas fa-map-marker-alt" ></i> {{ticket.origin}}</h3>
              <h3 *ngIf="!ticket.origin"><i class="fas fa-map-marker-alt" ></i> N/D</h3>
            </div>

            <div class="col-sm-6">
              <h3 *ngIf="ticket.destination"><i class="fas fa-map-marker-alt" ></i> {{ticket.destination}}</h3>
              <h3 *ngIf="!ticket.destination"><i class="fas fa-map-marker-alt" ></i> N/D</h3>
            </div>

            <div class="col-sm-6">
              <h3 *ngIf="ticket.price"><i class="fas fa-euro-sign"></i> {{ticket.price}}</h3>
              <h3 *ngIf="!ticket.price"><i class="fas fa-euro-sign"></i> N/D</h3>
            </div>

            <div class="col-sm-6">
              <h3 *ngIf="ticket.idTrip"><i class="fas fa-route"></i> {{ticket.idTrip}}</h3>
              <h3 *ngIf="!ticket.idTrip"><i class="fas fa-route"></i> N/D</h3>
            </div>

            <div class="col-sm-6">
              <h3 *ngIf="ticket.fareName"><i class="fas fa-user-tag"></i> {{ticket.fareName}}</h3>
              <h3 *ngIf="!ticket.fareName"><i class="fas fa-user-tag"></i> N/D</h3>
            </div>
            
            <div class="col-sm-6">
              <h3 *ngIf="ticket.status"  ><i class="fa fa-ticket"></i> <span class="{{utils.checkTextColorTickets(ticket.status)}}"> {{utils.checkTitleTickets(ticket.status)}}</span></h3>
              <h3 *ngIf="!ticket.status"><i class="fa fa-ticket"></i> N/D</h3>
            </div>

          </div>
        </div>
      </ng-container>
      <div class="modal-footer"></div>
    </div>

  </div>

</ng-template>

<ng-template #qrCodeTemplate>

  <div tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">QrCode:</h4>
      </div>
      <br>

      <div class="modal-body">
        <ng-container>
          <div class="row text-center">
            <qr-code [value]="qrcodeString" [size]="150"></qr-code>
          </div>

          <div class="row">
            <div class="text-center col-lg-12">
              <small>{{qrcodeString}}</small>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</ng-template>