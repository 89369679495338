import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { ICompany } from '../../../interfaces/configuration.interface';

@Component({
  selector: 'app-blocks-list',
  templateUrl: './blocks-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class BlocksListComponent implements OnInit {

  // FORM
  @ViewChild('formBlock') blockForm: NgForm;
  // OGGETTO LISTA ELEMENTI
  dataList: any[] = new Array();
  //  dataList = [{  name: 'BUS01',  brand: 'Mercedes',  plate: 'FF 000 EB',  status: 1}];
  dataFilter: any[] = new Array();
  // PATH CHIAMATE
  urlDeletePath = '';
  errorMessage = '';
  visibleFilter;
  defaultValue;
  stringLenght;
  visibleContentFilter;
  stringLenghtTables;
  companiesList: ICompany[] = new Array();
  urlCompany = '/company/companies';
  typeUser;
  isSoluzione1;
  UpOrDown;
  classString;

  constructor(
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    public utils: UtilsService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/blocks/list', false, true, 1, 5);
    this.listService.restURL = '/block/blocks';

  }

  async ngOnInit() {
    this.UpOrDown = true;
    this.visibleContentFilter = false;
    this.stringLenght = 'col-md-3'
    this.stringLenghtTables = 'col-md-12';
    this.classString = 'theme-config-box';
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.loadElements();
    this.companiesList = await this.utils.getCompanyList();
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
    } else {
      this.UpOrDown = true;
    }
  }

  visibleFilters(value) {
    if (value  === 'open' ) {
      this.visibleContentFilter = true;
      this.stringLenghtTables = 'col-md-9';
    } else {
      this.visibleContentFilter = false;
      this.stringLenghtTables = 'col-md-12';
      this.filterReset();
    }
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.blockForm.value;
    this.loadElements();
    this.listService.visibleFilter = true;
  }

  filterReset() {
    this.blockForm.value.name = ''
    this.blockForm.value.plate = ''
    this.blockForm.value.idCompany = ''
    this.blockForm.value.obliterator = ''
    this.dataFilter = [];
    this.listService.visibleFilter = false;
    this.blockForm.reset();
    this.loadElements();
  }

  getCompanyName(idCompany) {
    let stringCompanyName: string;
    for (let company of this.companiesList) {
      if (company.idCompany === idCompany) {
        stringCompanyName = company.legalBusinessName
        return stringCompanyName;
      }
    }
  }

  getStatusDescription(statusNumber: string) {
    let buttonColor: string;
    if (statusNumber === '1') {
      return buttonColor = 'Attivo';
    }
    if (statusNumber === '2') {
      return buttonColor = 'Non Attivo';
    }
  }



  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['id'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/blocks/edit']);
  }

  newBlock() {
    this.listService.backToList = true;
    this.router.navigate(['/blocks/new']);
  }

  closeFilter() {
    if ( this.classString === 'theme-config-box show') {
      this.classString = 'theme-config-box '
    } else {
    this.classString = 'theme-config-box show'
   }
  }

}
