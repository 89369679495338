<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">

  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Recupero PDF</strong>
      </h2>
    </div>


    <div class="ibox-content">
      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              
              <th>Transazione</th>
              <th>Canale</th>
              <th>Data Vendita</th>
              <th>N. di ticket</th>
           
              <th>Operatore</th>
              <th>Tipo di Pagamento</th>

              <th class="text-right">Importo</th>
              <th style="width:100px;"></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
            
              <td>{{data.idTransaction}}</td>
              <td>{{data.channel}}</td>
              <td>{{data.dateSell | date:'dd-MM-yyyy HH:mm:ss'}}</td>
              <td>{{data.numTicket}}</td>
        
              <td>{{data.userName}}</td>
              <td>{{getPaymentType(data.paymentType)}}</td>
              <td class="text-right">{{data.amount}} €</td>
  
  
              <td style="width: 50px" class="col-md-4 text-center">
                <button type="button" title="Ristampa" data-toggle="modal"  class="btn btn-view btn-outline" (click)="viewDetailsTicket(data.idTransaction, template)">
                  <i class="fa fa-file"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" 
            [maxSize]="10" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>




<ng-template #template>
<div>
    <div class="modal-content  modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="closeModal()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Dettagli Biglietti</h4>

      </div>

      <br>

      <h2 style="padding-left: 15px">Venduto da: {{resale}}</h2>

      <br>

      <div *ngFor="let t of transactionData">

        <div class="ibox-content">


          <div class="table-responsive">
            <table class="table shoping-cart-table">

              <tbody>
                <tr>
                  <td class="text-center" width="90">



                    <button style="height: 60px; width: 60px" title="{{utils.checkTitleTickets(t.status)}}" type="button" class="{{utils.checkColorTickets(t.status)}}">
                      <i class="{{utils.checkIconTickets(t.status)}}"></i>
                    </button>

                  </td>
                  <td class="desc">
                    <h3>
                      <a class="text">
                        {{t.ticketFareName}}
                      </a>
                    </h3>
                    <p class="small">
                      {{getTypeTicket(t.ticketTypeName)}}
                    </p>
                    <dl class="small m-b-none">
                      <dt>Venduto il: {{t.dateSell | date:'dd/MM/yyyy HH:mm:ss'}}</dt>

                    </dl>


                  </td>


                  <td>
                    <h4>
                      {{t.price | number : '1.2-2'}} €
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>



      <div class="modal-footer">

        <button title="Ristampa PDF" type="button" (click)="getTicketPdf()" class="btn btn-info">
         Ristampa
        </button>
      </div>
    </div>
  </div>

</ng-template>