<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-8 col-sm-offset-3 col-md-offset-2">
      <div class="ibox float-e-margins">
        <form (ngSubmit)="onSubmit()" #lineForm="ngForm">
          <div class="ibox-title">
            <h2>{{kindTitle}} linea</h2>
          </div>

          <div class="ibox-content">
            <div *ngIf="isSoluzione1" class="form-group col-12">
              <label for="idCompany">Azienda di Trasporto</label>
              <select
                type="text"
                class="form-control"
                name ="idCompany"
                [(ngModel)]="idCompany"
                required>
                <option *ngFor="let o of companiesList" [ngValue]="o.idCompany">{{o.legalBusinessName}}</option>
              </select>
            </div> 

            <div class="form-group col-12">
              <label for="routeId">Linea</label>
              <select
                type="text"
                class="form-control"
                name="routeId"
                [disabled]="isSoluzione1 && !idCompany"
                [(ngModel)]="routeId"
                required>
                <option *ngFor="let line of linesList" [ngValue]="line?.routeId">{{line?.routeId}}, {{line?.routeLongName}}</option>
              </select>
            </div>
          </div>

          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!lineForm.valid">Salva</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>