import { Component, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import { LoggingService } from '../../../services/logging.service';
import { AuthService } from "../../../services/auth.service";
import swal2, { SweetAlertResult } from 'sweetalert2'

@Component({
  selector: 'change-pwd',
  templateUrl: 'change-pwd.component.html',
  styleUrls: ['../../../app.component.css']
})

export class ChangePwdComponent {   
  constructor(
    private router: Router,
    private logger: LoggingService, 
    private authService: AuthService 
    
  ) {}

  @ViewChild('changePwdForm') changePwdForm: NgForm;
  
  errorMessage: string;
  changePWD: boolean;
  showPassword: string = "password";
  icon: string = "fa fa-eye"
   activateMessage = " La password deve comprendere 5 caratteri, e per essere efficace devono esserci una maiuscola, un numero e un carattere speciale";

  public accountTest = {
    oldPassword: <string>null,
    newPassword: <string>null
  };
  public account = {
        password: <string>null
  };
  
  public barLabel: string = "Efficacia :";

    
  onSubmit(): void {

    //swal("Modifica in corso...", {icon: 'info',  button: false,});
    swal2.fire({
      title: 'Modifica in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })
    
    this.authService.changePwd(this.account).subscribe(
      (response) => {

        if (response.outcome.success === true) {
            // link email sent
            this.changePWD = true;
            swal2.fire("Modifica password", "Password modificata con successo","success").then((ev: SweetAlertResult) => {
              if (ev.value) {
                this.router.navigate(['/user-profile/myProfile']);
              }
            });
            

        } else {
            // email not valid
            swal2.fire("Modifica password", "Errore durante la modifica password. Riprova più tardi.","error");
            this.errorMessage = "errore nel cambio pwd";
            this.changePWD = false;
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        this.changePWD = false;
      }
    );
  }

  showNewPassword(){
    
        if(this.showPassword === "text"){
     this.showPassword = "password"
     this.icon = "fa fa-eye"
        }else{
          this.showPassword = "text"
          this.icon = "fa fa-eye-slash"
    
        }
     
      }

      back(){
        this.router.navigate(['/user-profile/myProfile']);
      }

  getToken(): string {
    return localStorage.getItem("token");
  }
}