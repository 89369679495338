import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import {BrowserModule} from "@angular/platform-browser";
import { DeskListComponent } from './desk-list/desk-list.component';
import { DeskManageComponent } from './desk-manage/desk-manage.component';

@NgModule({
    imports: [
      CommonModule,
      NgbModule,
      FormsModule,
      BrowserModule,
      ReactiveFormsModule,
      FileUploadModule
    
    ],
    exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule
    ],
    declarations: [DeskListComponent, DeskManageComponent]
  })
  export class DeskModule { }