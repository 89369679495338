<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <!-- TITLE -->
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Canali voucher</strong>
        </h2>
      </div>
      <!-- CONTENT -->
      <div class="ibox-content">
        <!-- FILTERS -->
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <form #formVoucherChannel="ngForm">
                <div class='col-sm-6 col-md-4 col-lg-3'>
                  <div class="form-group">
                    <label>Azienda di trasporto</label>
                    <div class='input-group col-xs-12'>
                      <select (change)="changeCompany()" type="text" id="filterIdCompany" class="form-control" ngModel name="filterIdCompany" required filterIdCompany #filterIdCompany="ngModel">
                        <option [ngValue]="'all'" >Tutte</option>
                        <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4 col-lg-3'>
                  <div class="form-group">
                    <label>Nome</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="name" name="name" class="form-control" ngModel vouchername #vouchername="ngModel">
                    </div>
                  </div>
                </div>

                <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                      <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline">Cerca</button>
                      <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                        <i class="fa fa-rotate-left"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- TABLE -->
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i>
          {{ errorMessage }}
        </p>
        <div *ngIf="!errorMessage" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>Azienda di trasporto</th>
                <th>Nome</th>
                <th>Descrizione</th>
                <th>Tipo codice</th>
                <th>Pattern codice</th>
                <th class="text-center">API</th>
                <th class="text-center">Esterno</th>
                <th class="text-center">Lotti abilitati</th>
                <th class="text-center">Azioni</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of dataList" style="cursor:pointer" >
                <td><span *ngFor="let company of data.companies">- {{company.legalBusinessName}}<br/></span></td>
                <td>{{data.name}}</td>
                <td>{{data.description}}</td>
                <td>{{data.defaultCodeType}}</td>
                <td>{{data.codePattern}}</td>
                <td class="text-center"><i class="fa" [ngClass]="data.api ? 'fa-check' : 'fa-times'"></i></td>
                <td class="text-center"><i class="fa" [ngClass]="data.external ? 'fa-check' : 'fa-times'"></i></td>
                <td class="text-center"><i class="fa" [ngClass]="data.enabledLots ? 'fa-check' : 'fa-times'"></i></td>
                <td class="text-center">
                  <button type="button" class="btn btn-success btn-outline float-left" (click)="viewDetailVoucher(data, changeCompanyTemplate)">
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
            [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #changeCompanyTemplate>
  <div tabindex="-1" role="dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="closeModal()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Associazione azienda di trasporto ({{details.name}})</h4>
      </div>
      <br />
      <div style="margin: 0 2rem;">
        <div class="detail-modal-info">
          <p style="margin: auto 0;">Aziende attualmente associate</p>
          <p style="margin: auto 0; max-width: 35rem;"><b>{{detailCompanies.join(', ')}}</b></p>
        </div>
        <hr class="w-100" />
        <div class="detail-modal-info">
          <p style="margin: auto 0;">Nuova azienda di trasporto</p>
          <div style="width: 35rem;">
            <select class="form-control" type="text" name="idChangeCompany" [(ngModel)]="detailCompany">
              <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}}</option>
            </select>
          </div>
        </div>
        <hr class="w-100" />
      </div>
      <div class="modal-footer">
        <button type="button" (click)="closeModal()" class="btn btn-view btn-outline">Annulla</button>
        <button type="button" [disabled]="!detailCompany" (click)="saveVoucherCompany()" class="btn btn-view">Salva</button>
      </div>
    </div>
  </div>
</ng-template>