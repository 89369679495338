  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-8 col-sm-offset-3 col-md-offset-2">
        <div class="ibox float-e-margins">
            <form (ngSubmit)="onSubmit()" #vehicleForm="ngForm">
              <div class="ibox-title">
                  <h2>{{kindTitle}} veicolo</h2>
              </div>
              <div class="ibox-content">
                  <div *ngIf="isSoluzione1" class="form-group col-xs-12 col-sm-12 col-md-12">
                      <label for="idCompany">Azienda di Trasporto</label>
                      <select
                        type="text"
                        id="idCompany"
                        class="form-control"
                        ngModel
                        [ngModel]="dataVehicle?.idCompany"
                        name ="idCompany"
                        required
                        idCompany
                        #idCompany="ngModel"
                        required>
                        <option *ngFor="let o of companiesList" [ngValue]="o.idCompany">{{o.legalBusinessName}}</option>
                      </select>
                    </div> 
           
                <div class="form-group col-xs-6 col-sm-6 col-md-6">
                  <label for="name">Nome</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control "
                    ngModel
                    [ngModel]="dataVehicle?.name"
                    required
                    
                    #name= "ngModel">
                </div>

                <div *ngIf="!isSoluzione1 && kindManage == 0" hidden class="form-group col-xs-6 col-sm-12 col-md-6">
                    <label for="name">CompanyId</label>
                    <input
                      type="text"
                      id="idCompany"
                      name="idCompany"
                      class="form-control "
                      ngModel
                      [ngModel]="dataVehicle?.idCompany"
                      required
                      idCompany
                      #idCompany= "ngModel">
                  </div>
                 
            
                
                <div class="form-group col-xs-6 col-sm-6 col-md-6">
                  <label for="brand"> Marca</label>
                  <input
                    type="text"
                    id="brand"
                    class="form-control"
                    ngModel
                    [ngModel]="dataVehicle?.brand"
                    name="brand"
                    required
                    brand
                    #brand="ngModel">
                </div>
  
                <div class="form-group col-xs-6 col-sm-6 col-md-6">
                  <label for="plate">Targa</label>
                  <input
                    type="text"
                    id="plate"
                    class="form-control"
                    ngModel
                    [ngModel]="dataVehicle?.plate"
                    name="plate"
                    required
                    plate
                    #plate="ngModel">
                </div>

                <div class="form-group col-xs-6 col-sm-6 col-md-6">
                  <label for="maxSeat">Posti disponibili</label>
                  <input
                    type="text"
                    id="maxSeat"
                    class="form-control"
                    ngModel
                    [ngModel]="dataVehicle?.maxSeat"
                    name="maxSeat"
                    required
                    maxSeat
                    #maxSeat="ngModel">
                </div>
             
                <div class="clearfix"></div>
          </div>
          
          <div class="ibox-footer">
            <button class="btn btn-default" type="button"  (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right"  [disabled]="!vehicleForm.valid" >Salva </button>

        </div>
      </form>
        </div>
      </div>
    </div>
  </div>    




