<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div  class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11"><strong>Utenti</strong></h2>
      
      <div class="ibox-tools col-xs-1 pull-right">
          <button type="button" (click)="newUser()" class="btn btn-view btn-outline"> <i class="fa fa-plus"></i></button>
      </div>

    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
    
            <form #formUser="ngForm">

              <div *ngIf="isSoluzione1" class='ccol-sm-6 col-md-4  col-lg-2'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                      <option [ngValue] = "'all'" > Tutte </option>
                      <ng-container *ngFor="let r of companiesList">
                        <option [ngValue]="r.idCompany">{{r.legalBusinessName}} </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              
              <div class=' col-xs-12 col-sm-4 col-md-2 col-lg-2'>
                <div class="form-group">
                  <label>Username</label>
                  <div class='input-group col-xs-12'>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    class="form-control"
                    ngModel
                    required
                    username
                    #username= "ngModel">
                  </div>
                </div>
              </div>
              <div *ngIf="isSoluzione1" class=' col-xs-12 col-sm-4 col-md-2 col-lg-2'>
                  <div class="form-group">
                    <label>Tipologia</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="typeUser" class="form-control" ngModel name="typeUser" required typeUser #typeUser="ngModel"
                        required>
                        <option [ngValue] = "'all'" > Tutte </option>
                          <option *ngFor="let t of typeList" [ngValue]="t.typeUser">{{translateTypeUser(t.typeUser)}}</option>
                        </select>
                    </div>
                  </div>
                </div>

              <div class=' col-xs-12 col-sm-4 col-md-2 col-lg-2'>
                <div class="form-group">
                  <label>Ruolo</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="roleUser" class="form-control" ngModel name="roleUser" required roleUser #roleUser="ngModel"
                      required>
                      <option [ngValue] = "'all'" > Tutti </option>
                      <option *ngFor="let r of filterFilterRole" [ngValue]="r.roleUser">{{translateCodeRole(r.codeRole)}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-4 col-md-2 col-lg-2'>
                <div class="form-group">
                  <label>Stato</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="status" class="form-control" ngModel name="status" required status #status="ngModel">
                      <option [ngValue] = "'all'" > Tutti </option>  
                      <option [ngValue] = 'true' > Attivo</option>
                      <option [ngValue] = 'false' > Non Attivo </option>
                    </select>
                  </div>
                </div>
              </div> 

              <div class='col-xs-12 col-sm-4 col-md-2 col-lg-2'>
                <div class="form-group">
                  <label>Vedi utenti</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="deleted" class="form-control" ngModel name="deleted" deleted #deleted="ngModel" [(ngModel)]="dataFilter['deleted']">
                      <option [ngValue] = "'all'" > Tutti </option>  
                      <option [ngValue] = 'false'> Solo esistenti </option>  
                      <option [ngValue] = 'true' > Solo eliminati</option>
                    </select>
                  </div>
                </div>
              </div> 
              
              <div class='col-xs-12 col-sm-3 col-md-3'>
                  <div class="form-group">
                      <label></label>
                      <div class='input-group col-md-12'>
                          <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                          <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                      </div>
                  </div>
              </div>

            </form>

        </div>
      </div>
    </div>

    <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example" >
          <thead>
            <tr>
              <th *ngIf="isSoluzione1" style="width: 200px;">Azienda di trasporto</th>
              <th>Nominativo</th>
              <th>Username</th>
           
              <th>Ruolo</th>
              <th>Abilitato</th>
              <th class="text-center" style="width: 60px;">Sblocca</th>
              <th class="text-center" *ngIf="isVisiblePdf">PDF</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList"  style="cursor:pointer" [ngClass]="{'text-danger': data.deleted == true}">
              <td *ngIf="isSoluzione1"> {{getBusinessName(data.idCompany)}}</td>
              <td>{{data.name}} {{data.surname}}</td>
              <td>{{data.username}}</td>
             
              <td>{{translateCodeRoleDiff(data.roleUser)}}</td>
              <td  style="width: 50px"  class="text-center" > <button  title="{{getStatusDescription(data.enabled)}}" class="{{getButtonCircle(data.enabled)}}" type="button">
                </button>
              </td>
              <td>
                <button *ngIf="data.typeUser == 'COMPANY' && data.roleUser == 'OPERATOR' && data.refreshPassword == false" title="Resetta la password" (click)="refreshPassword(data)" type="button" class="{{getClassRefresh(data.pdf)}}" >
                  <i class="{{getClassIconRefresh(data.refreshPassword)}}"></i>
                </button>
                <button *ngIf="data.typeUser == 'COMPANY' && data.roleUser == 'OPERATOR' && data.refreshPassword == true"  title="La password è in attesa di essere cambiata dall'utente" type="button" class="{{getClassRefresh(data.pdf)}}" >
                  <i class="{{getClassIconRefresh(data.refreshPassword)}}"></i>
                </button>
              </td>
              <td  *ngIf="isVisiblePdf"  style="width: 70px" class="col-md-4 text-center">
                <button *ngIf="data.typeUser == 'COMPANY' && data.roleUser == 'OPERATOR' " (click)="enablePdf(data.idUser, data.pdf)" type="button" class="{{getClassEnablePdf(data.pdf)}}" >
                    <i class="{{getClassIcon(data.pdf)}}"></i>
                  </button>
              </td>
              <td  [ngStyle]="{'width': isSoluzione1|| idCompanyAdmin() ? '110px' : '60px'}"  class="text-center">
                <button type="button" class="btn btn-success btn-outline " (click)="selectRow(data.idUser)" style="float: left;"><i class="fa fa-pencil"></i></button>
                <button *ngIf="isSoluzione1 || idCompanyAdmin()" type="button" [ngClass]="{'btn-danger': data.deleted == false, 'btn-success': data.deleted == true}" class="btn  btn-outline" (click)="deleteElement(data.idUser,!data.deleted)" style="float: right; margin-left: 8px"><span *ngIf="data.deleted == false" title="Elimina utente"><i class="fa fa-user-times"></i></span><span *ngIf="data.deleted == true"><i class="fa fa-user-plus"></i></span></button>
              </td>
            </tr>
          </tbody>       
        </table>
        <ngb-pagination 
        *ngIf="visiblePaging"
        class="pull-right" 
        [collectionSize]="listService.totalRows"
        [maxSize]="10"
        [pageSize]="listService.rows" 
        [(page)]="listService.page"
        (pageChange)="pageChange($event)" >
      </ngb-pagination>
      </div>

    </div>
  </div>
</div>

