import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Chart } from "../../charts/chart.model";
import { ChartsService } from "../../charts/charts.service";
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {ListService} from "../../services/list.service";
import { LoggingService } from "../../services/logging.service";
import {BrowserModule, DomSanitizer,SafeResourceUrl} from '@angular/platform-browser'
import swal2 from 'sweetalert2'

@Component({
    selector: 'mainView',
    templateUrl: 'main-view.component.html',
    styleUrls: ['../../app.component.css']
})


export class MainViewComponent {
    //variabili del pdf
    pdfSrc: string = '#';
    page: number = 1;
    pageurl:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);

    isAnAdmin :any = false;
    isASupplier:any = false;
    ggValiditaPath;
    ggValiditaUpdatePath = "/admin/configuration";
    ggValiditaText;
    id;
    idSupplier;
    pathDocSupplier;
    name;
    surname;
    dateToday;

    valData : any = {
        codeConfiguration: "",
        valueConfiguration: ""
    }
    valConf : any = {
        codeConfiguration: "",
        valueConfiguration: ""
    }
    mailAdminText;

    documentsSupplier: any[] = new Array ();
    documentsInvalidToShow: any [] = new Array();

    constructor(private route: ActivatedRoute,public listService: ListService, private router: Router, private logger: LoggingService,private domSanitizer:DomSanitizer) { }
    
    @ViewChild('formConfigMailAdmin') signupFormMailAdmin: NgForm;
    @ViewChild('formConfigValidity') signupFormValidity: NgForm;
    @ViewChild('configSection') configSec:ElementRef;
    @ViewChild('docFormConfig') docFormConfig:NgForm;

    ngOnInit() {
        
   

    }

    getLayout(){
        var currentDate = new Date()
        var day = currentDate.getDate()
        var month = currentDate.getMonth() + 1
        var year = currentDate.getFullYear()
        this.dateToday = day + "/" + month + "/" + year;
        this.name = localStorage.getItem("name");
        this.surname = localStorage.getItem("surname");
    }

    getConfigurations(){
        this.listService.getObject(this.ggValiditaPath).subscribe( (response) => {

            for (this.valData in response.results) {
                
                let value = response.results[this.valData];
                this.logger.log("Val. conf.:",value.valueConfiguration,300);
                if(value.codeConfiguration === "DURATION_CHECK_DAYS")
                    this.ggValiditaText = value.valueConfiguration;  
                if(value.codeConfiguration === "EMAIL_ADMINISTRATION")
                    this.mailAdminText = value.valueConfiguration;
            }
          
        },    
        (error) => { 
          this.logger.log("Error",error,200);
        }
      );
    }  

    changePwd(){
        this.router.navigate(['/change-pwd']);
    }

    goToReviewDocument(){
        this.router.navigate(['/documents/supplier/documents'])
    }

    editAnagrafica(){
        this.router.navigate(['/supplier/detail']);
    }

    onSubmitMailAdmin() : void {
        this.onSubmit("EMAIL_ADMINISTRATION",this.signupFormMailAdmin.value.mailAdmin);
    }

    onSubmitValidity() : void {
        this.onSubmit("DURATION_CHECK_DAYS",this.signupFormValidity.value.ggValidita);
    }

    onSubmit(key,value) : void {
        if(this.isAdmin()){

            this.valConf.codeConfiguration = key;
            this.valConf.valueConfiguration = value;

            this.listService.edit(this.valConf,this.ggValiditaUpdatePath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.fire("Configurazione modificata", "Configurazione modificata con successo","success");
                        
                    } else {
                        this.logger.log("Response",response,200);
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                }
            );
        }
    }

    isAdmin() : boolean {
        if(localStorage){
            if(localStorage.getItem("role") == "ADMINISTRATOR" ){
                this.isAnAdmin = true;
                return true;
            } 
        }
        return false;
    }

    isSupplier() : boolean {
        if(localStorage){
            if(localStorage.getItem("role") == "SUPPLIER" ){
                this.isASupplier = true;
                return true;
            } 
        }
        return false;
    }


  getListDocuments() {

    this.listService.getList(this.pathDocSupplier).subscribe((response) => {
    this.documentsSupplier = [];

    this.documentsSupplier = response.results;
    var actualDocType = "";

    this.logger.log("Lista documenti fornitori: ",this.documentsSupplier,300);

    for(let d of this.documentsSupplier){
     var count = 0;
     for(let documentsToView of d.documents){
     
        if( documentsToView.status !== "APPROVED" && documentsToView.status !== "CONDITIONALLY_APPROVED"  && (documentsToView.codeDocumentType  === "00" || documentsToView.codeDocumentType  === "01" || documentsToView.codeDocumentType  === "05" ) ){
             this.documentsInvalidToShow.push(d);
        } else{
       
      }}
    }
    
    });
    this.logger.log("Lista documenti fornitori: ", this.documentsInvalidToShow,300);

}
  
}

