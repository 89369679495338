import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'


@Component({
    selector: 'app-dialog',
    templateUrl: './dialog-viewer.component.html',
    styleUrls: ['../../../app.component.css']
})
export class DialogViewerComponent implements OnInit{
    
    idDocToConfirm: number;
    statusDocToConfirm: string;
    msgNote: string;
    kindDocument: string


    constructor(private router: Router, public listService: ListService, private logger: LoggingService) {}

    ngOnInit() {
        
    }

    //Indico quando modifico un valore nella textarea della dialog
    doTextareaValueChange(ev) {
      try {
        this.msgNote = ev.target.value;
      } catch(e) {
        console.info('could not set textarea-value');
      }
    }

    //Se esci dalla modale cancella testo
    deleteText(){
      this.msgNote = "";
    }

    //Metodo che effettua approvazione con riserva o rifiuto (passando la nota)
    confirmOperation(){
      this.logger.log("Confirm:",this.idDocToConfirm + " - " + this.msgNote + " - " + this.statusDocToConfirm,200);
        this.listService.revision( this.idDocToConfirm,this.msgNote,this.statusDocToConfirm, this.kindDocument ).subscribe(
          (response) => {
     
            if (response.outcome.success === true) {
              
                swal2.fire("Operazione confermata","Status aggiornato con successo","success");
            } else {
              swal2.fire("Errore","Errore durante l'operazione. Riprova più tardi.","error");
            }
          },    
          (error) => {
            this.logger.log("Error",error,200);
          }
        );
    
      }

}