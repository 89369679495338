import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface CheckboxEl {
  code: string;
  itemName: string;
}

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css']
})
export class CheckboxGroupComponent implements OnChanges {
  @Input() sectionTitle: string;
  @Input() elements: string[] | CheckboxEl[] = []; /** List of elements to be displayed as checkboxes */
  @Input() default: string[] = []; /** List of elements automatically selected */

  @Output() selectChange: EventEmitter<any> = new EventEmitter();

  selectedElements: string[] = [];
  allElements = false;

  constructor() { }

  ngOnChanges(): void {
    /** If elements is a list of strings it is then converted to a list of specific checboxes elements */
    if (this.elements) {
      this.elements = this.elements.map((el: string | CheckboxEl) => {
        return typeof(el) === 'string' ? { code: el, itemName: el } : el;
      })
    }

    /** Default elements are automatically */
    if (this.default) {
      this.selectedElements = this.default;
      this.allElements = (this.selectedElements.length === this.elements.length) ? true : false;
    }
  }

  /** Method that checks whether a checkbox must be selected  */
  isElementSelected(routeCode: string): boolean {
    return !!this.selectedElements.find(r => r === routeCode);
  }

  /** Method that manages checkbox selection */
  manageSelectedElements(routeCode: string): void {
    this.selectedElements.includes(routeCode)
      ? this.selectedElements.splice(this.selectedElements.indexOf(routeCode), 1)
      : this.selectedElements.push(routeCode);

    this.allElements = (this.selectedElements.length === this.elements.length) ? true : false;
    this.selectChange.emit(this.selectedElements);
  }

  /** Method that manages selection button, in order to select or deselect all routes */
  elementSelectManagement(): void {
    if (this.selectedElements.length === this.elements.length) {
      this.allElements = false;
      this.selectedElements = [];
    } else {
      this.allElements = true;
      this.selectedElements = this.elements.map(r => r.code);
    }
    this.selectChange.emit(this.selectedElements);
  }
}
