<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Analisi passeggeri</strong>
      </h2>
      <div class="ibox-tools col-xs-1 pull-right">

      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <form #formResale="ngForm">
              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Linea</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="routeId" class="form-control" ngModel name="routeId" routeId #routeId="ngModel">
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let i of lineList" [ngValue]="i.routeId"> {{i.routeId}} </option>

                    </select>
                  </div>
                </div>
              </div>


              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Anno</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" [ngModel]="year?.year" id="year" class="form-control" ngModel name="year" year #year="ngModel">
                      <option [ngValue]="'all'">Tutte</option>
                      <option *ngFor="let n of yearsList" [ngValue]="n">
                        {{ n }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>



              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-4 col-lg-4'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                    <button type="button" (click)="getCsv()" style="margin-left: 10px" class="btn btn-info btn-outline">Esporta</button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table *ngFor="let i of dataResale" class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th></th>
              <th>Gennaio</th>
              <th>Febbraio</th>
              <th>Marzo</th>
              <th>Aprile </th>

              <th class="text-right">Maggio</th>
              <th class="text-right">Giugno</th>
              <th class="text-right">Luglio</th>


              <th class="text-center">Agosto</th>
              <th class="text-center">Settembre</th>
              <th class="text-center">Ottobre</th>
              <th class="text-center">Novembre</th>
              <th class="text-center">Dicembre</th>
              <th class="text-center">Totale</th>




            </tr>
          </thead>


          <tbody >


            <tr>
              <td  colspan="14">
                <h3 class="text-center col-lg-12">{{i.routeId}} - {{i.routeLongName}}</h3>
              </td>
            </tr>

            <tr *ngFor="let n of i.rowsFascia" style="cursor:pointer">
              <td>{{n.timeSlot}}</td>
              <td *ngFor="let t of n.monthPassengers"> {{t}}</td>

              <td> {{n.totalMonthPassengers}}</td>



            </tr>
            

            <tr style="background-color: gainsboro">
              <td class="text-right">
                Totale Passeggeri
              </td>
              <td *ngFor="let t of i.totalPassenger"> {{t}}</td>


              <td>
                {{getSum(i.totalPassenger)}}
              </td>

            </tr>

            <tr style="background-color: gainsboro" >
              <td class="text-right">
                Totale Corse
              </td>
              <td *ngFor="let t of i.totalTrip"> {{t}}</td>

              <td>
                {{getSum(i.totalTrip)}}
              </td>
            </tr>
          </tbody>

        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>