<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Desk</strong>
      </h2>
      <div class="ibox-tools col-xs-1 pull-right">
        <button type="button" (click)="newResale()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <form #formResale="ngForm">

              

              <div *ngIf="isSoluzione1" class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let t of companiesList" [ngValue]="t.idCompany">{{t.legalBusinessName}} </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class=' col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label>Nome</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="name" class="form-control" ngModel name="name" required  #name="ngModel">
                    </div>
                  </div>
                </div>


            




              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th *ngIf="isSoluzione1">Azienda di trasporto</th>
              <th>Nome</th>
              <th>Descrizione </th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataResale" style="cursor:pointer">
              <td style="width: 200px;" *ngIf="isSoluzione1"> {{ getBusinessName(data.idCompany)}} </td>
              <td>{{data.name}}</td>
              <td style="width: 300px;">{{data.description}} </td>
              <td [ngStyle]="{'width': isSoluzione1 ? '160' : '50px'}" class="col-md-4 text-center">
                <button *ngIf="isSoluzione1" title="Elimina" style="float: right; margin-left: 8px" type="button" class="btn btn-danger btn-outline"
                  (click)=" changeUserID(data.id , data.name)">
                  <i class="fa fa-trash"></i>
                </button>
                <button title="Modifica" style="float: right;margin-left: 8px " type="button" class="btn btn-success btn-outline " (click)="selectRow(data)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>