import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { StatisticsEarningsComponent } from './statistics-earnings/statistics-earnings.component';
import { StatisticsSoldComponent } from './statistics-sold/statistics-sold.component';
import { StatisticsTicketComponent } from './statistics-ticket/statistics-ticket.component';
import { QrCodeModule } from 'ng-qrcode';
import { StatisticPassengersComponent } from './statistic-passengers/statistic-passengers.component';
import { StatisticsOperatorsComponent } from './statistics-operators/statistics-operators.component';
import { StatisticsObliterationComponent } from './statistics-obliteration/statistics-obliteration.component';
import { StatisticsVoucherComponent } from './statistics-voucher/statistics-voucher.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { StatisticsExportComponent } from './statistics-export/statistics-export.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { WebDataRocksPivot } from "./../../webdatarocks/webdatarocks.angular4";

import { NgSelectModule } from '@ng-select/ng-select';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    QrCodeModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule,
    NgSelectModule,
    TooltipModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [StatisticsEarningsComponent, 
    StatisticsSoldComponent, 
    StatisticsTicketComponent, 
    StatisticPassengersComponent, 
    StatisticsOperatorsComponent,
    StatisticsExportComponent,
    StatisticsObliterationComponent,
    WebDataRocksPivot,
    StatisticsVoucherComponent]
})
export class StatisticsModule {}