<div class="loginColumns animated fadeInDown">
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
      <div class="ibox-content">
        <div class="text-center">
          <img src="./assets/img/Bu.JPG" width="50%">
        </div>
        <form (ngSubmit)="onSubmit()" #forgotForm="ngForm">

          <label for="password">Password</label>
          <div class="input-group">


            <input type={{showPassword}} id="password" class="form-control" [(ngModel)]="account.password" name="password" placeholder="Inserisci la nuova password"
              required password #password="ngModel" minlength="5" maxlength="15">
            <span class="input-group-btn">
              <button class="btn btn-secondary" (click)="showNewPassword()" type="button">
                <i class={{icon}}></i>
              </button>
            </span>

          </div>

         
          <span style="margin-top: 5px; margin-bottom: 10px">La password deve contenere minimo 5 caratteri</span>
          <br>
<br>




          <button type="submit" tyle="margin-top: 15px; margin-bottom: 10px" [disabled]="!forgotForm.valid" class="btn btn-redBU block full-width m-b">Conferma Password</button>



        </form>

      </div>
    </div>
  </div>
</div>