import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { CompaniesManageComponent } from './companies-manage/companies-manage.component';
//import {import { ToastrService } from 'ngx-toastr';} from 'ng2-toastr/ng2-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CompaniesDetailsComponent } from './companies-details/companies-details.component'
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot()
  
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CompaniesListComponent, CompaniesManageComponent, CompaniesDetailsComponent]
})
export class CompaniesModule { }