<div class="modal-dialog">
    <div class="modal-body">
        <h1>Carica CSV tariffe</h1>      
        <div style="margin-top: 2rem; display: flex; justify-content: space-between; align-items: end;">
            <input type="file" ng2FileSelect
                accept=".csv"
                [uploader]="uploader"
                (change)="handleCsvUpload($event.target.files)" />
            <div class="btn-group mL10 pull-right" role="group" aria-label="...">
                <button type="button" class="btn btn-toggler" [ngClass]="{'active btn-success':  uploadCode === 'stop'}" (click)="toggleUploadCode('stop')">Fermate</button>
                <button type="button" class="btn btn-toggler" [ngClass]="{'active btn-primary': uploadCode === 'zone'}" (click)="toggleUploadCode('zone')">Zone</button>
            </div>
        </div>
    </div>

    <div class="modal-footer" style="padding: 15px 30px 0 30px;">
        <button (click)="closeModal()" title="Chiudi dialog" type="button" class="btn btn-default">Chiudi</button>
        <button (click)="uploadCsv()" [disabled]="!fileToUpload" title="Carica file CSV tariffe" type="button" class="btn btn-danger">Carica CSV</button>
    </div>
</div>