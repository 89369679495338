<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">




  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Analisi Venduto</strong>
      </h2>
    </div>




    <div class="ibox-content">

      <div class="row">
        <div class="col-lg-12">





          <form #formStatsSold="ngForm">
            <div class="row">

              <div *ngIf="isSoluzione1" class='col-xs-12  col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel"
                      required>
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let c of dataCompany" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
                    </select>
                  </div>
                </div>
              </div>





              <!--     <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>Transazione</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="idTransaction" class="form-control" ngModel name="idTransaction" required idTransaction #idTransaction="ngModel">
                      
                  </div>
                </div>
              </div> -->


              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>Rivendita</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idResale" class="form-control" ngModel name="idResale" required idResale #idResale="ngModel">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let r of dataResale" [ngValue]="r.id">{{r.legalBusinessName}} </option>
                    </select>
                  </div>
                </div>
              </div>


              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <label>Vendita da:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateSellFrom"
                    id="dateSellFrom"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <label>Vendita a:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateSellTo"
                    id="dateSellTo"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

            </div>

            <div class="row">

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>Canale</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idTicketChannel" class="form-control" ngModel name="idTicketChannel" required idTicketChannel #idTicketChannel="ngModel"
                      required>
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let c of dataChannel" [ngValue]="c.idChannel">{{c.name}}</option>
                    </select>
                  </div>
                </div>
              </div>


              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>

                  <label>Operatore</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idUser" class="form-control" ngModel name="idUser" required idUser #idUser="ngModel">
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let c of filterUserData" [ngValue]="c.idUser">{{c.name}} {{c.surname}} </option>
                    </select>
                  </div>

                </div>
              </div>




              <div class='col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group  col-sx-12 col-md-12'>

                    <button type="button" style="margin-left: 10px" (click)="filterSearch()" class="btn btn-view btn-outline">
                      Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" style="margin-left: 10px" (click)="filterReset()" class="btn btn-view btn-outline">
                      <i class="fa fa-rotate-left"></i></button>

                    <button type="button" (click)="getCsv()" style="margin-left: 10px" class="btn btn-info btn-outline">
                      Esporta</button>

                  </div>
                </div>
              </div>

            </div>

          </form>


        </div>
      </div>



      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th></th>
              <th>Transazione</th>
              <th>Tratta</th>
              <th>Canale</th>
              <th>Data Vendita</th>
              <th>N. di ticket</th>
              <th>Rivendita</th>
              <th>Operatore</th>
              <th>Tipo di Pagamento</th>

              <th class="text-right">Importo</th>
              <th style="width:100px;"></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
              <td style="width: 60px" class="col-md-4 text-center">
                <button style="width: 40px" title="{{utils.checkTitleChannel(data.channel)}}" type="button" class="{{utils.checkColorChannel(data.channel)}}">
                  <i class="{{utils.checkIconChannel(data.channel)}}"></i>
                </button>
              </td>
              <td>{{data.idTransaction}}</td>
              <td>{{data.idRoute}}</td>
              <td>{{data.channel}}</td>
              <td>{{data.dateSell | date:'dd-MM-yyyy HH:mm:ss' }}</td>
              <td>{{data.numTicket}}</td>
              <td>{{data.resale}}</td>
              <td>{{data.userName}}</td>
              <td>{{getPaymentType(data.paymentType)}}</td>
              <td class="text-right">{{data.amount | number : '1.2-2'}} €</td>
  
  
              <td style="width: 50px" class="col-md-4 text-center">
                <button type="button" data-toggle="modal" class="btn btn-view btn-outline" (click)="viewDetailsTicket(data.idTransaction, template)">
                  <i class="fa fa-ticket"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [maxSize]="10" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>






<ng-template #template>

  <div  tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Dettagli Biglietti</h4>

      </div>

      <br>

      <h2 style="padding-left: 15px">Venduto da: {{resale}}

      </h2>

      <br>

      <div *ngFor="let t of transactionData">

        <div class="ibox-content">


          <div class="table-responsive">
            <table class="table shoping-cart-table">

              <tbody>
                <tr>
                  <td class="text-center" width="90">



                    <button style="height: 60px; width: 60px" title="{{utils.checkTitleTickets(t.status)}}" type="button" class="{{utils.checkColorTickets(t.status)}}">
                      <i class="{{utils.checkIconTickets(t.status)}}"></i>
                    </button>

                  </td>
                  <td class="desc">
                    <h3>
                      <a class="text">
                        {{t.ticketFareName}}
                      </a>
                    </h3>
                    <p class="small">
                      {{t.ticketTypeName}}
                    </p>
                    <dl class="small m-b-none">
                      <dt>Venduto il: {{t.dateSell | date:'dd-MM-yyyy HH:mm:ss'}}</dt>

                    </dl>


                  </td>


                  <td>
                    <h4>
                      {{t.price}} €
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>



      <div class="modal-footer">


      </div>
    </div>
  </div>



</ng-template>