<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-8 col-sm-offset-3 col-md-offset-2">
            <div class="ibox float-e-margins">
                <form (ngSubmit)="onSubmit()" #channelForm="ngForm">
                    <div class="ibox-title">
                        <h2>{{kindTitle}} canale di vendita</h2>
                    </div>
                    <div class="ibox-content">
                        <div class="form-group col-xs-6 col-sm-6 col-md-6">
                            <label for="domain">Azienda di Trasporto</label>
                            <select
                                type="text"
                                id="domain"
                                class="form-control"
                                ngModel
                                [ngModel]="dataChannel?.domain"
                                name ="domain"
                                required
                                domain
                                #domain="ngModel"
                                required>
                                <option *ngFor="let d of domainsList" [ngValue]="d.key">{{d.value}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xs-6 col-sm-6 col-md-6">
                            <label for="name">Nome</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                class="form-control"
                                ngModel
                                [ngModel]="dataChannel?.name"
                                required
                                #name= "ngModel" />
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
                        <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!channelForm.valid" >Salva </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>