import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import {BrowserModule} from "@angular/platform-browser";
import { ResearchTripLineComponent } from './research-trip-line.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';



@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    FileUploadModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ResearchTripLineComponent]
})
export class ResearchModule { }