import { Component, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import { LoggingService } from '../../../services/logging.service';
import { AuthService } from "../../../services/auth.service";

import swal2, { SweetAlertResult } from 'sweetalert2'

@Component({
  selector: 'forgot-pwd',
  templateUrl: 'forgot-pwd.component.html',
  styleUrls: ['../../../app.component.css']
})

export class ForgotPwdComponent {   
  constructor(
    private router: Router,
    private logger: LoggingService, 
    private authService: AuthService 
  ) {}

  @ViewChild('f') loginForm: NgForm;
  
  errorMessage: string;
  emailSent: any = false;
    
  onSubmit(): void {
    //swal("Modifica in corso...", {icon: 'info',  button: false,});
    swal2.fire({
      title: 'Modifica in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })

    this.authService.forgotPwd(this.loginForm.value.email).subscribe(
      (response) => {

        if (response.outcome.success === true) {
            // link email sent
            this.emailSent = true;
            swal2.fire("Password inviata","Una mail con la nuova password è stata inviata all'indirizzo con cui risulti registrato.", "success").then((ev: SweetAlertResult) => {
              if (ev.value) {
                this.router.navigate(['/login']);
              }
            });
        } else {
            // email not valid
            this.errorMessage = "Email non valida."
            swal2.fire("Errore","Email non valida.","error").then((ev: SweetAlertResult) => {
              if (ev.value) {
                this.router.navigate(['/login']);
              }
            });
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.","error").then((ev: SweetAlertResult) => {
          if (ev.value) {
            this.router.navigate(['/login']);
          }
        });
        this.emailSent = true;
        this.errorMessage = "";


      }
    );
  }

  getToken(): string {
    return localStorage.getItem("token");
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  
}