<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2    class="col-md-5"><strong>Profilo Utente</strong></h2>
        </div>
        <form (ngSubmit)="onSubmit()" #formUserProfile="ngForm">
          <div class="ibox-content">
            <div class="form-group col-sm-6">
              <label for="nome">Nome</label>
              <input
                type="text"
                id="nome"
                name="nome"
                class="form-control"
                ngModel
                [ngModel]="dataUser?.name"
                required
                nome
                #nome= "ngModel">
            </div>

            <div class="form-group  col-sm-6">
              <label for="surname">Cognome</label>
              <input
                type="text"
                id="surname"
                name="surname"
                class="form-control"
                ngModel
                [ngModel]="dataUser?.surname"
                required
                surname
                #surname= "ngModel">
            </div>

            <div class="form-group  col-sm-6">
              <label for="username">Username</label>
              <input
                
                type="text"
                id="username"
                name="username"
                class="form-control"
                ngModel
                [ngModel]="dataUser?.username"
                required
                username
                ng-disabled
                #username= "ngModel">
            </div>

            <!--<div class="form-group">
              <label for="email">Email</label>
              <input
              
                type="text"
                id="email"
                name="email"
                class="form-control"
                ngModel
                [ngModel]="dataUser?.email"
                required
                email
                #email= "ngModel">
            </div>-->

            <div class="form-group  col-sm-6">
              <label for="phone">Telefono</label>
              <input
                
                type="text"
                id="phone"
                name="phone"
                class="form-control"
                ngModel
                [ngModel]="dataUser?.phone"
                required
                phone
                
                #phone= "ngModel">
            </div>

           <!-- <div class="form-group">
              <label for="enabled">Tipologia</label>
              <select
                type="text"
                id="enabled"
                class="form-control"
                ngModel
                name ="enabled"
                required
                enabled
                #enabled="ngModel"
                disabled
                [ngModel]="dataUser?.enabled"
                required>
                  <option value=true>Attivo</option>
                  <option value="false">Non attivo</option>
              </select>
            </div>

            <div class="form-group">
              <label>Ruolo</label>
              <div class='input-group col-md-12'>
                <select type="text" id="roleUser" class="form-control" ngModel name="roleUser"  roleUser #roleUser="ngModel"
                  required>
                    <option [ngValue] = "0"> </option>
                    <option *ngFor="let r of roleList" [ngValue]="r.roleUser">{{r.roleUser}}</option>
                  </select>
              </div>
            </div>-->
            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">

              <button class="btn btn-redBU" (click)="changePwd()" >Modifica Password</button>  
              <button class="btn btn-redBU btn-right" type="submit"  [disabled]="!formUserProfile.valid" >Salva</button>
              <!--<button class="btn btn-default" type="submit"  >Annulla</button>-->
         
          </div>
        </form>
      </div>
    </div>
  </div>
</div>    