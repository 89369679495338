import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from "../../services/logging.service";
import { ListService } from "../../services/list.service";
import { UtilsService } from '../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import swal2 from 'sweetalert2';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-recover-pdf',
  templateUrl: './recover-pdf.component.html',
  styleUrls: ['../../app.component.css']
})
export class RecoverPdfComponent implements OnInit {

  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  dataOperator: any[] = new Array();
  dataChannel: any[] = new Array();
  dataResale: any[] = new Array();
  transactionData: any [] = new Array();
  dataCompany: any[] = new Array();
  filterUserData: any[] = new Array();

  resale;
  idTransaction; 

  //PATH CHIAMATE
  urlDeletePath = "";
  urlChannelPath = "/ticket/channel/channels";
  urlResalePath = "/resale/resales";
  urlOperatorPath = "/user/users";
  urlTransactionPath = "/stats/sold/analysis/ticket?idTransaction="
  urlCompanyPath = "/company/companies";
  urlPathReprint = "/ticket/sellprint/bytransaction?idTransaction="
  totalAmount; 
  isSoluzione1;
  typeUser;

  modalRef: BsModalRef;
  
  errorMessage;

  //DATEPICKER FILTRI

  constructor(
    private modalService: BsModalService,
    public utils : UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/statistics/sold", false, true, 1, 12);
    this.listService.restURL = "/stats/sold/byUser";
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide()
  }

  ngOnInit() {
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.loadElements();
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  getPaymentType(string) {
    var value;

    if(string == "CASH") {
      value = "Contante"
    }
    else{
      value = "POS"
    }
    return  value;
  }

  //SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.dataFilter["lastSeconds"] = 900
    this.listService.getListFilter(this.dataFilter, true, false).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.totalAmount = response.totalAmount

        if(response.total == 0) {
          swal2.fire("Nessun pdf disponibile alla ristampa", "", "warning")
        }

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      }, (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/statistics/ticket']);
  }

  viewDetailsTicket(idTransaction, template: TemplateRef<any>) {
    this.idTransaction = idTransaction
    this.getDetailsTicket(idTransaction, template);
  }


  getDetailsTicket(idTransaction, template: TemplateRef<any>) {
    this.loadingService.presentLoader();

    this.listService.getListSelect(this.urlTransactionPath + idTransaction).subscribe((response) => {
      this.transactionData = response.results;
      
      for (let t of this.transactionData) {
        if(t.channel == "Rivendita") {
        this.resale = t.resale
      } else {
        this.resale = t.channel
      }}

      this.loadingService.hideLoader();

      this.openModal(template);
    }, _ => {
      this.loadingService.hideLoader();
    });
  }

  getTypeTicket(data) {
    if (data == "S") {
      return "Abbonamento"
    } else {
      return "Biglietto"
    }
  }


  checkName(string) {
    var value;
    if( string == "Wallet recharge") {
      value = "Ricarica"
    }else{
      value = "Vendita Biglietto"
    }
    return value;
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
      this.dataCompany = response.results;
      this.logger.log("Lista canali", this.dataChannel, 300);
      //console.log("LISTA commesse: ",response.results);
    }, (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  async getTicketPdf() {
    await this.utils.getPdfFromTransaction(this.idTransaction);
    this.closeModal();
  }

}
