import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import {BrowserModule} from '@angular/platform-browser';
import { ResalesListComponent } from './resales-list/resales-list.component';
import { ResalesManageComponent } from './resales-manage/resales-manage.component';
import { ResalesDetailsComponent } from './resales-details/resales-details.component';
import { ComponentsModule } from '../../common/components/components.module';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    FileUploadModule,
    ComponentsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ResalesListComponent, ResalesManageComponent, ResalesDetailsComponent]
})
export class ResalesModule { }
