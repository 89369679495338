import { Component, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ListService} from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import swal2 from 'sweetalert2'
import { AuthService } from "../../../services/auth.service";
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-tickets-typology-manage',
  templateUrl: './tickets-typology-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketsTypologyManageComponent implements OnInit {

  //testo in HTML per tipo pagina
    kindTitle;kindManage;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/ticket/type";
    //form html
    @ViewChild('ticketForm') ticketForm: NgForm;
    //oggetto contenente dati component
    ticketTypologyData: any = new Object;

    errorMessage;
    pathToCallCompaniesPath = "/company/companies"
    companiesList :any[] = new Array();
    typeUser;
    isSol1;

    tempValidity;

  constructor(private utils: UtilsService, private route: ActivatedRoute, private authService: AuthService, private logger: LoggingService, public listService: ListService, private router: Router ) {
   //Se creo nuovo veicolo
   
        if(route.snapshot.toString().indexOf("new") != -1) {
            this.kindTitle = "Nuova";
            this.kindManage = 1;
        }else{ //se modifico veicolo
            this.kindTitle = "Modifica";
            this.kindManage = 0;
            if(localStorage.getItem("dataPassed")=="")  {this.router.navigate(['/tickets/typology']); return;}
            this.ticketTypologyData = JSON.parse(localStorage.getItem("dataPassed"));



            console.log("VEDI OGGETTO", this.ticketTypologyData) //TO DO DA ELIMINARE
            localStorage.setItem("dataPassed",""); //svuoto dato in localstorage
        }
  }

  @ViewChild('f') signupForm: NgForm;

  ngOnInit() {
    
        this.listService.resetList();
        this.listService.backToList = true;
        this.listService.listRouting = "/tickets/typology"
        console.log(this.listService)
        this.getCompanies();
        this.typeUser = this.utils.getTypeUser();
        this.isSol1 = this.utils.isSol1(this.typeUser);
   
  
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
    this.companiesList = response.results;
    this.logger.log("Lista aziende", this.companiesList, 300);
    //console.log("LISTA commesse: ",response.results);
    },
    (error) => {
        this.logger.log("Error", error, 200);
    }
    );
}

  onSubmit()  : void {

        swal2.fire({title: 'Operazione in corso...', didOpen: function () {swal2.showLoading()}});

        if(this.kindManage){


            this.ticketTypologyData = this.ticketForm.value;
         //   this.ticketTypologyData["name"] = this.ticketForm.value.name; 
            this.listService.newObject(this.ticketTypologyData, this.serviceToCallPath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.fire("Tipologia creata con successo", "","success");
                        this.router.navigate(['/tickets/typology'])

                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire("Attenzione", this.errorMessage,"warning");
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                    this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                    swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
                }
            );
        }else{

            var extra = this.ticketTypologyData.extra
            var name = this.ticketForm.value.name
            var ar = this.ticketTypologyData.ar

           // this.ticketTypologyData = this.ticketForm.value;

          this.ticketTypologyData.name = name 

        


            this.listService.edit(this.ticketTypologyData, this.serviceToCallPath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.fire("La tipologia del biglietto è stata modificata", "","success");
                        this.router.navigate(['/tickets/typology'])
    
                    } else {
                        this.errorMessage = response.outcome.code +  response.outcome.description
                        swal2.fire("Attenzione",  this.errorMessage);
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                    this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                    swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
                }
            );
        }
    }

    back(){
        this.router.navigate(['/tickets/typology']);
    }

}

