import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
import { LoggingService } from '../../../services/logging.service';
import { NgForm } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-sales-channels-list',
  templateUrl: './sales-channels-list.component.html',
  styleUrls: ['../../../app.component.css', './sales-channels-list.component.css']
})
export class SalesChannelsListComponent implements OnInit {

  @ViewChild('formChannels') channelsForm: NgForm;

  typeUser: string;
  isSoluzione1: boolean;
  errorMessage: string = "";
  selectedChannel: SalesChannelHeader;
  companiesList: any[] = new Array();
  dataList: SalesChannelHeader[] = new Array();
  dataFilter: any[] = new Array();
  dataChannel: any[] = new Array();

  //PATH CHIAMATE
  urlDeletePath = "";
  urlChannelPath = "/ticket/channel/channels";
  urlEditPromotionPath = "/company/ticketChannelPromotion";

  constructor(
    public listService: ListService,
    public utils: UtilsService,
    private router: Router,
    private logger: LoggingService,
    private modalService: BsModalService
  ) {
    this.listService.resetList();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.listService.configurationServiceCall(
      '',
      false,
      true,
      1,
      10
    );
    this.listService.restURL = "/company/ticketChannel";
  }

  async ngOnInit(): Promise<void> {
    this.dataFilter['idTicketChannel'] = 'all';

    if (this.isSoluzione1) {
      this.companiesList = await this.utils.getCompanyList();
      this.dataFilter['idCompany'] = 'all';
    }
    this.getChannels();
    this.loadElements();
  }

  newSalesChannel() {
    this.listService.backToList = true;
    this.router.navigate(['/salesChannels/new']);
  }

  filterSearch() {
    this.dataFilter = this.channelsForm.value;
    this.loadElements();
    this.listService.visibleFilter = true;
  }

  filterReset() {
    if (this.isSoluzione1) {
      this.channelsForm.value.idCompany = 'all';
    }
    this.channelsForm.value.idTicketChannel = 'all';

    this.listService.visibleFilter = false;
    this.loadElements();
  }

  getCompanyName(idCompany: number) {
    let stringCompanyName: string;
    for (let company of this.companiesList) {
      if (company.idCompany === idCompany) {
        stringCompanyName = company.legalBusinessName
        return stringCompanyName;
      }
    }
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  selectRow(channelId: number) {
    this.listService.selectedID = channelId;
    this.listService.backToList = true;
    this.router.navigate(['/salesChannels/edit']);
  }

  editPromotionAmount(data: SalesChannelHeader, modalTemplate: TemplateRef<any>): void {
    this.modalService.show(modalTemplate);
    this.selectedChannel = {...data};
  }

  savePromotion(): void {
    swal2.fire({
      title: 'Salvataggio in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    });

    this.listService.edit(this.selectedChannel, this.urlEditPromotionPath).subscribe(
      response => {
        this.closeModal();
        if (response.outcome.success === true) {
          this.loadElements();
          swal2.fire('Supplemento aggiornato correttamente', '', 'success');
        } else {
          swal2.fire('Attenzione', 'Si è verificato un errore, riprovare', 'warning');
        }
      },
      error => {
        this.closeModal();
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          this.errorMessage,
          "error"
        );
      }
    )
  }

  closeModal(): void {
    this.modalService.hide();
  }

  // --------- PRIVATE METHODS ---------- //

  private loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response:",response,300);
      },
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    )
  }

  //SERVIZI SELECT
  private getChannels() {
    this.listService.getListSelect(this.urlChannelPath).subscribe(
      response => {
        this.dataChannel = response.results;
        this.logger.log("Lista canali", this.dataChannel, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

}

export interface SalesChannelHeader {
  id: number;
  idCompany: number;
  idTicketChannel: number;
  supplementAmount: number;
}
