<div class="row" style="padding-top: 20px"> </div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Stampanti</strong>
        </h2>

        <div class="ibox-tools col-xs-1 pull-right">
          <button *ngIf="isSoluzione1" type="button" (click)="newPrinter()" class="btn btn-view btn-outline">
            <i class="fa fa-plus"></i>
          </button>

        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <form #formPrinter="ngForm">
                <div *ngIf="companyVisible" class='ccol-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Azienda di trasporto</label>
                    <div class='input-group col-xs-12'>
                      <select (change)="changeCompany($event.target.value)"  type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div  *ngIf="!isResale && !isOperator" class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Rivendita</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="idResale" class="form-control" ngModel name="idResale" required idResale #idResale="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option [ngValue] = "'-1'" > Nessuna Associazione </option>
                        <option *ngFor="let r of filterResaleList" [ngValue]="r.id">{{r.legalBusinessName}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div  *ngIf="!isResale && !isOperator" class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Desk</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="idDesk" class="form-control" ngModel name="idDesk" required idDesk #idDesk="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option [ngValue] = "'-1'" > Nessuna Associazione </option>
                        <option *ngFor="let d of filterDesksList" [ngValue]="d.id">{{d.name}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Modello</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="model" name="model" class="form-control" ngModel model #model="ngModel">
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4 col-lg-2'>
                  <div class="form-group">
                    <label>Serial Number</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="serialNumber" name="serialNumber" class="form-control" ngModel serialNumber #serialNumber="ngModel">
                    </div>
                  </div>
                </div>

                <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                      <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                      <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                        <i class="fa fa-rotate-left"></i>
                      </button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>

        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th *ngIf="isResale || isOperator">Preferita</th>
                <th *ngIf="companyVisible">Azienda di trasporto</th>
                <th *ngIf="!isResale && !isOperator">Rivendita</th>
                <th *ngIf="!isResale && !isOperator">Desk</th>
                <th>Nome</th>
                <th>Modello</th>
                
                <th>Serial Number</th>
                <th>MAC Ad.</th>

                <th *ngIf="visibleCreation"></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList" style="cursor:pointer" >
                <td *ngIf="isResale || isOperator" style="width: 50px" class="text-center">
              
  
                  <span title="{{tooltip(data.pcUUID)}}"  class="{{classStars(data.pcUUID)}}" (click)="assignOrUnAssign(data)" style=  "cursor:pointer;" [style.color]="data.pcUUID == machineUUID ? 'orange' : null"></span> 
               
                </td>
  
                <td *ngIf="companyVisible"> {{getBusinessName(data.idCompany)}} </td>
                <td *ngIf="!isResale && !isOperator"> {{(getResaleName(data.idResale)) || "Nessuna Associazione"}}</td>
                <td *ngIf="!isResale && !isOperator"> {{(getDeskName(data.idDesk)) || "Nessuna Associazione"}}</td>
  
                <td>{{data.name}}</td>
                <td>{{data.model}}</td>
             
                <td>{{data.serialNumber}}</td>
                <td>{{data.macAddress}}</td>
      
                <td *ngIf="visibleCreation" style="width: 100px" class="col-md-4 text-center">
  
  
                  <button *ngIf="visibleCreation" type="button" class="btn btn-success btn-outline" (click)="selectRow(data)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button *ngIf="visibleCreation && isSoluzione1" type="button" class="btn btn-danger btn-outline" (click)="deletePrinter(data.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
            [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>