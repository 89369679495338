import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'
import { AuthService } from '../../../services/auth.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-wallet-recharge-manage',
  templateUrl: './wallet-recharge-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class WalletRechargeManageComponent implements OnInit {

   //testo in HTML per tipo pagina
   kindTitle;kindManage;
   //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
   serviceToCallPath = "/rechargetype";
   //form html
   @ViewChild('formRecharge') rechargeForm: NgForm;
   //oggetto contenente dati component
   ticketTypologyData: any = new Object;

   errorMessage;
   typeUser;
   isSoluzione1;
   pathToCallCompaniesPath = "/company/companies"
   companiesList :any[] = new Array();
   idRechargeTypeTemp; 
 


 constructor(private utils:UtilsService,  private route: ActivatedRoute, private authService: AuthService, private logger: LoggingService, public listService: ListService, private router: Router ) {
  //Se creo nuovo veicolo
  
       if(route.snapshot.toString().indexOf("new") != -1) {
           this.kindTitle = "Nuovo";
           this.kindManage = 1;
       }else{ //se modifico veicolo
           this.kindTitle = "Modifica";
           this.kindManage = 0;
           if(localStorage.getItem("dataPassed")=="")  {this.router.navigate(['/wallet/recharge/list']); return;}
           this.ticketTypologyData = JSON.parse(localStorage.getItem("dataPassed"));
           this.idRechargeTypeTemp = this.ticketTypologyData.idRechargeType


           console.log("VEDI OGGETTO", this.ticketTypologyData) //TO DO DA ELIMINARE
           localStorage.setItem("dataPassed",""); //svuoto dato in localstorage
       }
 }

 @ViewChild('f') signupForm: NgForm;

 ngOnInit() {

  this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
   
       this.listService.resetList();
       this.listService.backToList = true;
       this.listService.listRouting = "/wallet/recharge/list"
       console.log(this.listService)
       this.getCompanies();
  
 
 }

 getCompanies() {
   this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
   this.companiesList = response.results;
   this.logger.log("Lista aziende", this.companiesList, 300);
   //console.log("LISTA commesse: ",response.results);
   },
   (error) => {
       this.logger.log("Error", error, 200);
   }
   );
}

 onSubmit()  : void {

       swal2.fire({title: 'Operazione in corso...', didOpen: function () {swal2.showLoading()}});

       if(this.kindManage){


           this.ticketTypologyData = this.rechargeForm.value;
        //   this.ticketTypologyData["name"] = this.ticketForm.value.name; 
           this.listService.newObject(this.ticketTypologyData, this.serviceToCallPath).subscribe(
               (response) => {
           
                   if (response.outcome.success === true) {
                       swal2.fire("Il nuovo taglio di ricarica è stato creato con successo", "","success");
                       this.router.navigate(['/wallet/recharge/list'])

                   } else {
                       this.errorMessage =  response.outcome.code +  response.outcome.description
                       swal2.fire("Attenzione", this.errorMessage,"warning");
                   }
               },    
               (error) => {
                   this.logger.log("Error",error,200);
                   this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                   swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
               }
           );
       }else{
           this.ticketTypologyData = this.rechargeForm.value;
           this.ticketTypologyData.idRechargeType = this.idRechargeTypeTemp
           this.listService.edit(this.ticketTypologyData, this.serviceToCallPath).subscribe(
               (response) => {
           
                   if (response.outcome.success === true) {
                       swal2.fire("Il taglio della ricarica è stato modificato con successo", "","success");
                       this.router.navigate(['/wallet/recharge/list'])
   
                   } else {
                       this.errorMessage = response.outcome.code +  response.outcome.description
                       swal2.fire("Attenzione",  this.errorMessage);
                   }
               },    
               (error) => {
                   this.logger.log("Error",error,200);
                   this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                   swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
               }
           );
       }
   }

   back(){
       this.router.navigate(['/wallet/recharge/list']);
   }

}
