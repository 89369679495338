import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import swal2, { SweetAlertResult } from 'sweetalert2'
import { DocumentsViewerComponent } from '../documents-viewer/documents-viewer.component';
import { DialogViewerComponent } from '../dialog/dialog-viewer.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UtilsService } from '../../../services/utils.service';

const URL = 'https://185.56.9.94/prs-rest/rest/document/uploadDocSupplier';
const AUTHTOKEN = localStorage.getItem("token");
const HEADER = 'X-AUTH-TOKEN';
declare var jQuery: any;

@ViewChild(DocumentsViewerComponent)

@Component({
  selector: 'app-documents-supplier',
  templateUrl: './documents-supplier.component.html',
  styleUrls: ['../../../app.component.css']
})
export class DocumentsSupplierComponent implements OnInit {




     public emissionDocument = {
    // other options...
   dateFormat: 'yyyy-mm-dd',
          inline: false,
        editableDateField: false
    
  };


  public beginValidity = {
    // other options...
 dateFormat: 'yyyy-mm-dd',
          inline: false,
        editableDateField: false
  };

  public endValidity = {
    // other options...

  dateFormat: 'yyyy-mm-dd',
          inline: false,
        editableDateField: false
  };


    
  id: number;
 private selectedDate: string = '14.11.2016';

  pathUrlCodeDoc;
  visuraCameraleCodeDocValue;
  DurcCodeDocValue;
  idSupplier;
  yearsDefaultValue;
  monthDefaultValue;

  statusUpload;
  errorMessage;

  statusDurcUpload;
  errorDurcMessage;
  urlDeletePath;

  //path servizio
  pathDocSupplier;
  pathDocSupplierToApprove;

  //array documenti fornitore
  documentsSupplier: any[] = new Array();
  documentsSupplierToApprove: any[] = new Array ();

  //variabili per ViewPdf
  viewMask: boolean = true;
  viewDoc: boolean = false;

  //codaUpload
  codeControlUpload;
  uploadButtonVisible;

  lastCodeSelected;

  isAppr;
  isAdmin;
  office;

  year: number;
  month;
  day;


  viewUpload; 

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  private sub: any;

  @ViewChild('viewerDiv') viewerDiv: DocumentsViewerComponent;
  @ViewChild('f') visualForm: NgForm;
  @ViewChild('d') durcForm: NgForm;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('dateForm') dateForm: NgForm;
  @ViewChild('dialogView') dialogView: DialogViewerComponent;





  public uploader: FileUploaderCustom = new FileUploaderCustom({ url: URL, authTokenHeader: HEADER, authToken: AUTHTOKEN }, this);
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;

  }

  constructor(private logger: LoggingService, private route: ActivatedRoute, public listService: ListService, private router: Router, private domSanitizer: DomSanitizer, private utils: UtilsService) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
    });

this.lastCodeSelected = "toSelect"
    this.idSupplier = localStorage.getItem("idSupplier");
    this.pathDocSupplier = "/supplier/documents";
    this.pathDocSupplierToApprove = "/suppliers/"+ this.id+ "/documents"
    this.office = localStorage.getItem("idOffice");
   
    this.isApprover();
    this.isAdministrator();
    if(this.isAppr || this.isAdmin)
    {
        this.getListDocumentsToApprove();
    }
    else{
      this.getListDocuments();
    }
   // this.getListDocuments();  
  //  this.getListDocumentsToApprove();

    this.errorMessage = '';
    this.errorDurcMessage = '';
    this.isApprover();
    this.uploader.authToken = localStorage.getItem("token");
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.statusUpload = false
    var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');

    var year = utc.substring(0,4)
    this.year  = parseInt(year)

  }

  isApprover() {
    if (localStorage.getItem("role") === "APPROVER") {
      this.isAppr = true
    } else {
      this.isAppr = false
    }
  }

    isAdministrator() {
    if (localStorage.getItem("role") === "ADMINISTRATOR") {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  }

  getListDocuments() {
    this.listService.getList(this.pathDocSupplier).subscribe((response) => {
      this.documentsSupplier = [];


      this.documentsSupplier = response.results;
      var actualDocType = "";
      this.logger.log("Lista fornitori: ",this.documentsSupplier,300);
    });
  }



  getListDocumentsToApprove() {
    this.listService.getList(this.pathDocSupplierToApprove).subscribe((response) => {
      this.documentsSupplierToApprove = [];
     // this.documentsSupplierToApprove = response.results;

   var list  = response.results;
        for(let doc of list){
          
          for(let docRevision of doc.documents){
            if( docRevision.status === "MISSING" && (docRevision.codeDocumentType  === "S04" || docRevision.codeDocumentType  === "06") ){

            }
            else{
              this.documentsSupplierToApprove.push(doc)
            }
          }
        }
      
      var actualDocType = "";
      this.logger.log("Lista documenti fornitori: ",this.documentsSupplierToApprove,300);
    });
  }

  approveDocumentFromViewer(idDoc){


    this.approve(idDoc);



  }



  codaUpload(code) {

    this.codeControlUpload = code;
    if(this.lastCodeSelected !== this.codeControlUpload && this.lastCodeSelected !== "toSelect"){
     this.uploader.queue = this.uploader.queue.slice(-1)
     }


    this.uploadButtonVisible = true;
    this.lastCodeSelected = code;

  }
  modalOpen(idDoc,status){
    this.dialogView.statusDocToConfirm = status;
    this.dialogView.idDocToConfirm = idDoc;

    
  }

  approvaRiservaRespingiDoc(idDoc,status){
    this.dialogView.statusDocToConfirm = status;
    this.dialogView.idDocToConfirm = idDoc;
    var mySelf = this;
    var titleBox;
    if(status == 'reject') titleBox = 'Rifiuta documento';
    else  titleBox = 'Approva documento con riserva';

    swal2.fire({
      title: titleBox,
      text: 'Aggiungi una motivazione',
      input: 'textarea',
      inputPlaceholder: 'Scrivi la motivazione',
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText:'Conferma',
      confirmButtonColor: '#ed5565',
    }).then(function (text) {
      if (text) {
        mySelf.approveReservedOrReject(idDoc,text,status);
      }else{
        swal2.fire('Motivazione non inserita', 'Inserire una motivazione valida', 'error');
      }
    },function (dismiss) {
      if (dismiss === 'close') {
        swal2.close();
      }
    });

  }

  approve(idDoc){
    
    this.listService.approve(idDoc).subscribe(
        (response) => {
   
          if (response.outcome.success === true) {
            swal2.fire("Operazione confermata","Documento approvato con successo","success")
            .then((ev) => {
              if (ev.value) {
                this.closeDocViewer();
                this.getListDocumentsToApprove();
                this.codeControlUpload = null;
                this.closeDocViewer();
              }
            });
          } else {
            if(response.outcome.code === "0012"){
              this.errorMessage = "Non hai il permesso di approvare il documento";
              swal2.fire("Errore", this.errorMessage, "warning");
            }else
              swal2.fire("Errore","Il documento non è stato approvato. Riprova più tardi.","error");
              this.logger.log("Response",response,200);
          }
        },    
        (error) => {
          this.logger.log("Error",error,200);
       
        }
      );
  
  }

  approveReservedOrReject(idDoc,msgNote,status){
    this.listService.revision( idDoc,msgNote,status, "document" ).subscribe(
      (response) => {
 
        if (response.outcome.success === true) {
          this.getListDocumentsToApprove()
          this.closeDocViewer();
        } else {
          if(response.outcome.code === "0012"){
            this.errorMessage = "Non hai il permesso di operare sul documento";
            swal2.fire("Attenzione", this.errorMessage, "warning");
          }else
            swal2.fire("Errore","Il documento non è stato approvato o respinto. Riprova più tardi.","error");
            this.logger.log("Response",response,200);
        }
      },    
      (error) => {
        //this.logger.log("Error",error,200);
     
      }
    );
  }


  multipleUpload(code) {

    swal2.fire("Caricamento file", "Il file è stato caricato correttamente", "success")
    if (code === "01") {

      this.uploader.options.additionalParameter = {
        codeDocumentType: code,
        idSupplier: this.idSupplier,
        dtReleased: this.utils.convertDateToISO(this.dateForm.value.emissionDocument),
        dtValidityStart: this.utils.convertDateToISO(this.dateForm.value.beginValidity),
        dtValidityEnd: this.utils.convertDateToISO(this.dateForm.value.endValidity)
      }

    } else if(code === "06"){

       this.uploader.options.additionalParameter = {
        codeDocumentType: code,
        idSupplier: this.idSupplier,
        dtReleased: "",
        dtValidityStart:  "",
        dtValidityEnd: "",
        descDocument : this.dateForm.value.descTextArea
      }
    }

    else {
      this.uploader.options.additionalParameter = {
        codeDocumentType: code,
        idSupplier: this.idSupplier,
        dtReleased: "",
        dtValidityStart: this.utils.convertDateToISO(this.dateForm.value.beginValidity),
        dtValidityEnd: this.utils.convertDateToISO(this.dateForm.value.endValidity)
      }


    }
    this.uploader.uploadAllFiles();

  }




  deleteDocument(idDocument) {
    var mySelf = this;
    this.urlDeletePath = "/document/" + idDocument;

    swal2.fire({
      title: 'Conferma eliminazione',
      text: "Sei sicuro di voler eliminare il file?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.callServiceDeleteDocument();
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })
  }

  callServiceDeleteDocument(){
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {

        if (response.outcome.success === true) {
          swal2.fire("Eliminazione completata", "Il documento è stato eliminato con successo", "success");
          this.getListDocuments();

        } else {
          swal2.fire("Errore", "Errore durante l'operazione. Riprova più tardi.", "error");
          this.logger.log("Response",response,200);
          
        }
      },
      (error) => {
        this.logger.log("Error",error,200);

      }
    );
  }

  openErrorModal() {
    jQuery(this.myModal.nativeElement).modal('show');
  }


  removeFromList(item) {
    item.remove();
    this.uploader.queue.splice(item)
  }

  closeDocViewer() {
    this.viewMask = true;
  }

  viewDocument(idDoc, status) {

    this.viewMask = false;
  //  this.viewerDiv.viewDoc(idDoc, this, status);
  }

  download(idDoc, status: String, infos: String) {
    this.viewMask = false;
//this.viewerDiv.viewDocWithInfo(idDoc, this, status, infos);
  }

  downloadTemplate(filename : String){
    this.viewMask = false;
 //   this.viewerDiv.viewTemplateDoc(filename, this, filename);
  }

}

export class FileUploaderCustom extends FileUploader {

  docComponent: DocumentsSupplierComponent;


  constructor(options: FileUploaderOptions, doc: DocumentsSupplierComponent) {
    super(options);
    this.docComponent = doc
  }


  uploadAllFiles(): any {

   swal2.fire({
      title: 'Caricamento documento in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })

    var xhr = new XMLHttpRequest();
    var sendable = new FormData();
    var fakeitem: FileItem = null;
    this.onBuildItemForm(fakeitem, sendable);
    var response: any

    for (const item of this.queue) {
      item.isReady = true;
      item.isUploading = true;
      item.isUploaded = false;
      item.isSuccess = true;
      item.isCancel = false;
      item.isError = false;
      item.progress = 0;
      item.withCredentials = false;

      if (typeof item._file.size !== 'number') {
        throw new TypeError('The file specified is no longer valid');
      }
      sendable.append("files", item._file, item.file.name);
    }

    if (this.options.additionalParameter !== undefined) {
      Object.keys(this.options.additionalParameter).forEach((key) => {
        sendable.append(key, this.options.additionalParameter[key]);
      });
    }

    xhr.onload = () => {
      var gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
      var method = 'on' + gist + 'Item';
      this[method](fakeitem, null, xhr.status, null);

    };
    xhr.onerror = () => {
      this.onErrorItem(fakeitem, null, xhr.status, null);
    };




    xhr.onabort = () => {
      this.onErrorItem(fakeitem, null, xhr.status, null);
    };

    xhr.open("POST", this.options.url, true);
    xhr.withCredentials = false;
    if (this.options.headers) {
      for (var _i = 0, _a = this.options.headers; _i < _a.length; _i++) {
        var header = _a[_i];
        xhr.setRequestHeader(header.name, header.value);
      }
    }
    if (this.authToken) {
      xhr.setRequestHeader(this.authTokenHeader, this.authToken);
    }
    xhr.send(sendable);



    return xhr.onloadend = () => {
      var res = JSON.parse(xhr.response);
      if (res.outcome.success) {

        swal2.fire("Caricamento file", "Il file è stato caricato correttamente", "success")

        this.docComponent.getListDocuments();
        this.docComponent.uploader.queue = [];
        this.docComponent.codeControlUpload = "ND";
        return res
      }
      else {
        var msgTxt;
        if(res.results[0].outcome == "0013")
          msgTxt = "Un file con questo nome è già presente";
        else
          msgTxt = "Non è stato possibile completare l'operazione. Riprova più tardi.";

        swal2.fire("Errore", msgTxt , "error");

        this.docComponent.getListDocuments();
        this.docComponent.uploader.queue = [];
        this.docComponent.codeControlUpload = "ND"
        return res
      }

    }








  };

}

