import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { LoadingService } from '../../../services/loading.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import swal2, { SweetAlertResult } from 'sweetalert2';

defineLocale('it', itLocale);

@Component({
  selector: 'app-statistics-ticket',
  templateUrl: './statistics-ticket.component.html',
  styleUrls: ['../../../app.component.css', './statistics-ticket.component.scss']
})
export class StatisticsTicketComponent implements OnInit {
  @ViewChild('formStatsTicket') statsTicketForm: NgForm;
  @ViewChild('formModifyTicket') modifyTicketForm: NgForm;
  @ViewChild('formInvalidTicket') invalidTicketForm: NgForm;
  @ViewChild('routeList') routeList: NgSelectComponent;

  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  kindMenu;
  dataFilter: any[] = new Array();
  dataOperator: any[] = new Array();
  dataChannel: any[] = new Array();
  dataResale: any[] = new Array();
  dataDesk: any[] = new Array();
  dataVehicles: any[] = new Array();
  dataFare: any[] = new Array();
  // dataTypology: any[] = new Array();
  urlCompanyPath = '/company/companies';
  dataCompany: any[] = new Array();
  dataOptionTicket: any[] = new Array();
  dataSubOptionTicket: any[] = new Array();
  urlTransactionPath = '/stats/sold/analysis/ticket?idTransaction=';
  name;
  urlLine = '/route/routes'
  lineList: any[] = new Array();


  typeUser;
  isResale;
  paginationVisible;
  idRoute;
  ticketFareName;
  destination;
  origin;
  purchasedForDate;
  vehiclePlate;
  vehicleId;
  dateSell;
  price;
  supplementPrice;
  totalPrice;
  paymentType;
  idTransaction;
  sellerName;
  sellerOperator;
  ticketCategoryName;
  cardId;
  optionSelected = null;
  viewSubOption  = false;
  channel;
  deskName = ''
  status;

  // PATH CHIAMATE
  urlDeletePath = '';
  urlChannelPath = '/ticket/channel/channels';
  urlResalePath = '/resale/resales';
  urlInvalidatePath = '/ticket/invalidate';
  urlInvalidateOptionPath = '/ticket/invalidate-options/';
  urlDeskPath = '/desk/desks'
  urlTypologyPath = '/ticket/type/types';
  urlPathReprint = '/ticket/sellprint/bytransaction?idTransaction='
  urlFarePath = '/ticket/fare/fares';
  urlCsvSoldPath = '/stats/sold/analysis/ticket/csv'
  pathStopsList = '/stop/stops/v2';
  pathRoutesList = '/route/routes';
  pathLinetripList = '/trip/linetriplist';
  pathModifyTicket = '/ticket/move-date';
  pathVehicleList = '/vehicle/vehicles';
  urlOperatorPath = '/user/users?deleted=false'; // cerco utenti NON eliminati

  errorMessage = '';
  roleUser = '';
  qrCodeWindow = false;
  stringQrCode = 'ND';
  visibleFilter;
  defaultValue;
  UpOrDown;
  classString;
  isSol1;
  idTicketToInvalidate;
  modalRef: BsModalRef;
  colorTheme = 'theme-orange';
  bsConfig: Partial<BsDatepickerConfig>;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  locale = 'it';
  locales = listLocales();
  stringFirstDay
  stringLastDay

  // MODIFICA TICKET
  modTripList: any[] = new Array();
  modTripListStops: any[] = new Array();
  // modIdLine;
  // modDirectionValue;
  // modDirectionString;
  modDirectionList: any[] = new Array();
  modOriginList: any[] = new Array();
  modDestinationList: any[] = new Array();
  modStringDataLine;
  modRouteLongName;
  modTicketRoute = null;
  modTicketDir = null;
  modTicketOrig = null;
  modTicketData = null;
  modTicketTrip = null;
  modTicketStop = null;
  modTicketTransaction = null;

  modalModTripList: any[] = new Array();
  modalModDirectionList: any[] = new Array();
  modalModTicketRoute = null;
  modalModTicketDir = null;
  modalModTicketData = null;
  modalModTicketTrip = null;
  modalModTicketStop = null;

  ticketToModified: TicketModified = new TicketModified();

  chosedSearchType: any;
  showFilters = false;

  idTrip: any;
  isLoading = false;
  errorMessageRefund = '';

  filterUserData: any[] = new Array();

  refundErrorMsg = 'Rimborso in fase di elaborazione. Se il ticket richiesto non dovesse risultare rimborsato entro 48h, contattare l\'assistenza';

  invalidationStatus = [
    { value: 'REFUNDED', groupedName: 'Rimborsato in contanti' },
    { value: 'REFUNDED_PAYPAL', groupedName: 'Rimborsato via PayPal' },
    { value: 'REFUNDED_SERVICEPAY', groupedName: 'Rimborsato via ServicePay' },
    { value: 'LOST', groupedName: 'Perso' },
    { value: 'STOLE', groupedName: 'Rubato' },
    { value: 'TECH_ERROR', groupedName: 'Errore tecnico' },
    { value: 'REFUSED', groupedName: 'Rifiutato da operatore' }
  ];

  ticketStatusText = 'Gli stati rappresentano il valore o il gruppo di valori che un ticket assume nel sistema Praticko.<br />-OPERATIVO: significa i biglietti in stato Attivo, Invalido, Rif. Rimborso o Obliterato.<br />-INVALIDO sono i biglietti che hanno previsto un\'operazione di invalidazione (comparirà un altro filtro per specificare il tipo di invalidazione richiesta).<br />-OBLITERATO indica i ticket che son stati validati dal sistema tramite procedura manuale o automatica.<br />Rif. Rimborsato indica le righe di storno legate ad un rimborso di un ticket.';

  constructor(
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.maxDate.setDate(lastDay.getDate());
    this.bsRangeValue = [firstDay, this.maxDate];
    console.log(this.bsRangeValue)
    this.localeService.use(this.locale);
    this.listService.configurationServiceCall(
      '/statistics/ticket',
      false,
      true,
      1,
      12
    );
    this.listService.restURL = '/stats/sold/analysis/ticket';
  }

  async ngOnInit() {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      {dateInputFormat: 'DD-MM-YYYY'},
      {rangeInputFormat: 'DD-MM-YYYY'}
    );

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    //  this.chosedSearchType = 'linea'

    this.stringFirstDay = this.utils.formatDate(firstDay)
    this.stringLastDay = this.utils.formatDate(lastDay)

    this.typeUser = this.utils.getTypeUser();
    this.roleUser = this.utils.getRoleUser();

    this.isSol1 = this.utils.isSol1(this.typeUser);
    this.isResale = this.utils.isRes(this.typeUser);

    this.paginationVisible = false;

    // When a user is Sol1 (SA) companies has to be displayed first, otherwise the selects are directly available and selectable
    this.isSol1 = this.utils.isSol1(this.typeUser);
    if (this.isSol1) {
      this.getCompanies();
      this.dataFilter['idCompany'] = 'all';
    } else {
      await this.companySelectsFill();
    }
  }

  close() {
    this.modalRef.hide()
  }

  /** For ticket edit modals a callback is attached on onHide event, in order to reset filters */
  openModal(template: TemplateRef<any>, ticketEdit = false) {
    this.optionSelected = null;
    this.viewSubOption = false;
    this.modalRef = this.modalService.show(template);

    if (ticketEdit) {
      const ticketModalHide = this.modalService.onHide.subscribe(_ => {
        this.clearModalValues();
        ticketModalHide.unsubscribe();
      });
    }
  }

  closeModal() {
    this.modTicketTrip = null;
    this.modTicketStop = null;
    this.modalModTicketDir = null;
    this.modalModTicketRoute = null;
    this.modalRef.hide();
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  // FILTRI
  filterSearch() {
    this.getFilter();

    this.listService.visibleFilter = true;
    this.loadElements();
  }

  getFilter() {
    this.dataFilter = this.statsTicketForm.value;
    this.dataFilter['bsDaterangepicker'] = 'all'
    this.stringFirstDay = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
    this.stringLastDay = this.utils.stringTotalFRomDate(this.bsRangeValue[1])
    this.dataFilter['dateSellFrom'] = this.stringFirstDay
    this.dataFilter['dateSellTo'] =   this.stringLastDay
    // se uso la corsa non uso la linea e viceversa.
    if (this.modTicketTrip !== null ) {
      this.dataFilter['tripId'] =  this.modTripList[this.modTicketTrip].tripId;
      this.dataFilter['routeId'] = null;
    } else {
      if (this.modTicketRoute !== null) {
        this.dataFilter['tripId'] = null;
        this.dataFilter['routeId'] = this.lineList[this.modTicketRoute].routeId;
      }
    }
    this.dataFilter['isVoucher'] = false
    // elimino la direzione che non mi serve nei filtri
    this.dataFilter['direction'] = null;
    console.log( 'GetFilter - this.statsTicketForm.value' , this.statsTicketForm.value )
    console.log( 'this.chosedSearchType' , this.chosedSearchType )
    
    if ( this.dataFilter['routeIdRoute'] ) {
      this.addMultipleParams('routeIdRoute', 'routeId');
    }
    
    console.log( 'this.chosedSearchType' , this.chosedSearchType )
    console.log( this.chosedSearchType )
    if ( this.chosedSearchType === 'linea' ) {
      this.dataFilter['tripId'] = null;
      this.dataFilter['tripDate'] = null;
    } else if ( this.chosedSearchType === 'corsa' ) {
      this.dataFilter['routeId'] = this.modTicketRoute ? this.lineList[this.modTicketRoute].routeId : null;
      this.dataFilter['direction'] = this.modTicketDir ? this.modTicketDir : null;
      this.dataFilter['tripDate'] = this.modTicketData ? this.utils.convertDateToISO(this.modTicketData) : null;
    }

    if ( this.dataFilter['filterStatusReason'] ) {
      this.addMultipleParams('filterStatusReason');
    }
    
    delete this.dataFilter['routeIdRoute'];
  }

  getCsv() {
    swal2.fire({
      title: 'Download in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    });

    this.getFilter();
    let queryString = this.utils.getQueryWithFilters(this.dataFilter);
    let finalQueryString;
    if (queryString === '' || queryString === null) {
      finalQueryString = this.urlCsvSoldPath;
    } else {
      finalQueryString = this.urlCsvSoldPath + '?' + queryString;
    }

    this.listService.getCsv(finalQueryString).subscribe(
      response => {
        if (response) {
          let blob = new Blob([response], { type: 'text/csv' });
          let ieEDGE = navigator.userAgent.match(/Edge/g);
          let ie = navigator.userAgent.match(/.NET/g); //  IE 11+
          let oldIE = navigator.userAgent.match(/MSIE/g);

          let fileName = ''
          let currentDate = new Date()
          let day = currentDate.getDate()
          let month = currentDate.getMonth() + 1
          let year = currentDate.getFullYear()
          fileName = day + '/' + month + '/' + year + '_statistics_ticket.csv';

          if (ie || oldIE || ieEDGE) {
            console.log(' EDGE !');
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          } else {
            console.log(' NON E\' EDGE !');
            // let url= window.URL.createObjectURL(blob);
            //  window.open(url, 'test.csv');
            let elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          swal2.fire('Il csv è stato scaricato', '', 'success');
        } else {
          //  response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  viewDetailsTicket(data, template: TemplateRef<any>) {
    if (data.ticketTypeName === 'T') {
      this.kindMenu = 'Biglietto'
    } else if (data.ticketTypeName === 'S') {
      this.kindMenu = 'Abbonamento'
    } else if (data.ticketTypeName === 'H') {
      this.kindMenu = 'HopOnHopOff'
    } else {
      this.kindMenu = 'N/A'
    }
    this.getDetailsTicket(data);
    this.openModal(template)
  }

  getDetailsTicket(data) {
   this.idRoute = data.idRoute;
   this.ticketFareName = data.ticketFareName;
   this.destination = data.destination;
   this.origin = data.origin;
   this.purchasedForDate = data.purchasedForDate;
   this.vehiclePlate = data.plate;
   this.vehicleId = data.idVehicle;
   this.dateSell = data.dateSell;
   this.price = data.price;
   this.supplementPrice = data.supplementPrice;
   this.totalPrice = data.totalPrice;
   this.paymentType = data.paymentType;
   this.idTransaction = data.idTransaction;
   this.sellerName = data.sellerName;
   this.name = data.channel === 'Commerce' ? data.customerName : data.sellerName;
   this.sellerOperator = data.channel === 'Commerce' ? data.customerUsername : data.sellerOperator;
   this.ticketCategoryName = data.ticketCategoryName;
   this.cardId = data.cardId;
   this.channel = data.channel;
   this.deskName = data.desk ? data.desk : '';
   this.idTrip = data.idTrip;
   this.status = data.status;
  }

  filterReset() {
    this.statsTicketForm.value.idTicketFare = 'all';
    this.statsTicketForm.value.idTicketType = '';
    this.statsTicketForm.value.dateSellFrom = '';
    this.statsTicketForm.value.dateSellTo = '';
    this.statsTicketForm.value.status = 'all';
    this.statsTicketForm.value.filterTransStatus = 'CONFIRMED';
    this.statsTicketForm.value.idResale = 'all';
    this.statsTicketForm.value.idTicketChannel = 'all';
    this.statsTicketForm.value.idTicket = '';
    // this.statsTicketForm.value.idCompany = 'all';
    this.statsTicketForm.value.routeId = '';
    this.statsTicketForm.value.tripDate = '';
    this.statsTicketForm.value.idUser = 'all';
    this.statsTicketForm.value.direction = '';
    this.statsTicketForm.value.tripId = '';
    this.statsTicketForm.value.filterStatusReason = '';
    this.statsTicketForm.value.idDesk = 'all';
    this.statsTicketForm.value.idVehicle = 'all';

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.maxDate.setDate(lastDay.getDate());
    this.bsRangeValue = [firstDay, this.maxDate];

    this.stringFirstDay = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
    this.stringLastDay = this.utils.stringTotalFRomDate(this.bsRangeValue[1])

    this.listService.visibleFilter = false;
    this.clearSearchFilters();
    this.loadElements();
  }

  closeFilter() {
    if (this.classString === 'theme-config-box show') {
      this.classString = 'theme-config-box ';
    } else {
      this.classString = 'theme-config-box show';
    }
  }

  // SERVIZI SELECT
  getChannels(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlChannelPath + (filterByCompany ? `?idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe(
        response => {
          this.dataChannel = response.results;
          this.logger.log('Lista canali', this.dataChannel, 300);
          res();
        },
        error => {
          this.logger.log('Error', error, 200);
          rej();
        }
      );
    });
  }

  checkName(string) {
    let value;
    if (string === 'Wallet recharge') {
      value = 'Ricarica';
    } else {
      value = 'Vendita Biglietto';
    }
    return value;
  }

  isReprinted(status) {
    let value;
    if (status === true) {
      value = 'Sì';
    } else {
      value = 'No';
    }
    return value;
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe(
      response => {
        this.dataCompany = response.results;
        this.logger.log('Lista canali', this.dataCompany, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getResales(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlResalePath + (filterByCompany ? `?idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe(
        response => {
          this.dataResale = response.results;
          this.logger.log('Lista rivendite: ', this.dataResale, 300);
          res();
        }, _ => {
          rej();
        }
      );
    });
  }

  getDesks(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlDeskPath + (filterByCompany ? `?idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe(
        response => {
          this.dataDesk = response.results;
          this.logger.log('Lista desk: ', this.dataDesk, 300);
          res();
        }, _ => {
          rej();
        }
      );
    });
  }

  // SERVIZI SELECT
  getVehicles(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.pathVehicleList + (filterByCompany ? `?idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe((response) => {
        this.dataVehicles = response.results;
        this.logger.log('Lista veicoli', this.dataVehicles, 300);
        res();
        }, error => {
          this.logger.log('Error', error, 200);
          rej();
        }
      );
    });
  }

  getFares(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlFarePath + (filterByCompany ? `?idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe(
        response => {
          this.dataFare = response.results;
          this.logger.log('Lista tariffe: ', this.dataFare, 300);
          res();
        }, _ => {
          rej();
        }
      );
    });
  }

  getLineList(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlLine + (filterByCompany ? `?idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe((response) => {
        this.lineList = response.results;
        this.logger.log('Lista linee', this.lineList, 300);
        res();
      }, error => {
        this.logger.log('Error', error, 200);
        rej();
      });
    })
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.loadingService.presentLoader();
    this.dataFilter['dateSellFrom'] = this.stringFirstDay;
    this.dataFilter['dateSellTo'] = this.stringLastDay;
    this.dataFilter['isVoucher'] = false;

    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.listService.rows = 12;

        if (this.listService.totalRows > this.listService.rows) {
          this.paginationVisible = true;
        }

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    ).add(() => {
      this.loadingService.hideLoader();
    });
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['id'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/statistics/ticket']);
  }

  viewQrCode(stringQr) {
    this.qrCodeWindow = true;
    this.stringQrCode = stringQr;
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;

      console.log('VIEE', this.UpOrDown);
    } else {
      this.UpOrDown = true;
    }
  }

  closeQrCodeTicketDetails() {
    this.qrCodeWindow = false;
  }

  getidTicket(id, ticketChannel: string, template: TemplateRef<any> ) {
    this.idTicketToInvalidate = id;

    this.invalidateOptionTicket(this.idTicketToInvalidate, ticketChannel, template);
  }

  invalidateOptionTicket(idTicket, ticketChannel: string, template: TemplateRef<any> ) {
    this.listService.getSingleObject(this.urlInvalidateOptionPath + idTicket).subscribe(
      response => {
        if (response.outcome.success === true) {
          this.dataOptionTicket = response.results;

          if (!ticketChannel.includes('Operatore Mobile')) {
            const index = this.dataOptionTicket.findIndex(x => x.value ===  'TECH_ERROR')
            if (index >= 0) { this.dataOptionTicket.splice(index, 1); }
          }

          this.openModal(template);
        } else {
          this.errorMessage = response.outcome.code + response.outcome.description;
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  /** Method fired on refund action, that displays when reuqired, a modal asking to keep supplement value on refund calculation */
  manageTicketInvalidation() {
    const subStatusReason = this.invalidTicketForm.controls.subStatusReason?.value;
    const onErrorInvalidation = this.invalidTicketForm.controls.statusReason?.value === this.dataOptionTicket.findIndex(x => x.value ===  'TECH_ERROR');
    const hasSupplement = !!this.dataList.find(ticket => ticket.idTicket === this.idTicketToInvalidate).supplementPrice;

    let model = {
      idTicket: this.idTicketToInvalidate,
      statusReason: this.optionSelected,
      includeAdditionalPrice: false
    };

    /**
     * Alert is displayed only on refund (subStatusReason available) or technical error (onErrorInvalidation)
     * and when the ticket has a supplement applied (otherwise the modal is not required).
     */
    if ((subStatusReason || onErrorInvalidation) && hasSupplement) {
      swal2.fire({
        title: 'Includere supplemento in totale rimborso?',
        text: 'Qualora si rimborsi il prezzo senza supplemento, il supplemento non potrà essere rimborsato successivamente',
        icon: 'question',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No',
      }).then((ev: SweetAlertResult) => {
        if (ev.value) {
          model.includeAdditionalPrice = true;
        }
        this.invalidateTicket(model);
      });
    } else {
      this.invalidateTicket(model);
    }
  }

  invalidateTicket(model: { idTicket: number; statusReason: string; includeAdditionalPrice: boolean }) {
    this.errorMessage = '';
    this.errorMessageRefund = '';

    //  swal2.showLoading();
    swal2.fire({
      allowOutsideClick	: false,
      allowEscapeKey: false,
      didOpen: () => {
        swal2.showLoading()
      }
    });

    this.listService.edit(model, this.urlInvalidatePath).subscribe(
      response => {
        if (response.outcome.success === true) {
          this.listService.page = 1;
          this.closeModal();

          if (response.outcome.code === 'PENDING') {
            swal2.fire({
              title: 'Attenzione',
              text: this.refundErrorMsg,
              icon: 'warning',
              showConfirmButton: true,
              didClose: () => { this.loadElements(); }
            });
          } else {
            swal2.fire({
              title: 'Biglietto invalidato',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false
            });

            setTimeout(() => {
              swal2.close();
              this.loadElements();
            }, 2000);
          }
        } else {
          swal2.fire(
            'Errore',
            this.viewSubOption
              ? 'Rimborso non completato, fondi insufficienti. Attendere un giorno e riprovare'
              : response.outcome.code + ' ' + response.outcome.description,
            'error'
          );
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessageRefund = 'Spiacente, si è verificato un errore tecnico.';
        this.isLoading = false;
        swal2.fire(
          'Errore',
          this.errorMessageRefund,
          'error'
        );
      }
    );

    console.log(model);
  }

  //  MODIFICA TICKET

  // apro modale e segno ticket da modificare
  modifyTicket(dataTicket, template: TemplateRef<any> ) {
    this.ticketToModified.id = dataTicket.idTicket;
    this.modTicketTransaction = dataTicket.idTransaction;
    this.logger.log('Ticket', this.ticketToModified, 300);
    this.openModal(template, true);
  }

  /** Method that changes the stored route value, different between filters and modal (modTicketRoute, modalModTicketRoute) */
  onChange(indexRoute, fromTicketEdit = false) {
    this.modTicketTrip = null;
    this.modTicketStop = null;

    // salvo valore route selezionata
    fromTicketEdit ? this.modalModTicketRoute = indexRoute : this.modTicketRoute = indexRoute;
    fromTicketEdit ? this.modalModTripList = [] : this.modTripList = [];

    this.getDirections(fromTicketEdit);

    // useful only in modal
    if (fromTicketEdit) {
      this.getDirectionsFrom();
    }
  }

  getDirections(fromTicketEdit = false) {
    fromTicketEdit ? this.modalModDirectionList = [] : this.modDirectionList = [];
    console.log('LINEA RILEVATA', this.lineList[fromTicketEdit ? this.modalModTicketRoute : this.modTicketRoute].routeId);
    // let stringPath = '?routeId=' + this.lineList[this.modTicketRoute].routeId;
    let stringPath = '/' + this.lineList[fromTicketEdit ? this.modalModTicketRoute : this.modTicketRoute].routeId;

    this.listService.getListSelect(this.pathRoutesList + stringPath).subscribe(
      response => {
        fromTicketEdit ? this.modalModDirectionList = response.results : this.modDirectionList = response.results;
        this.logger.log('Ticket', this.ticketToModified, 300);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  /** Method that changes the stored direction value, different between filters and modal (modTicketDir, modalModTicketDir) */
  getDirection(indexDir, fromTicketEdit = false) {
    fromTicketEdit ? this.modalModTicketDir = indexDir : this.modTicketDir = indexDir;
  }

  onDateChanged(stringData: Date, fromTicketEdit = false) {
    if(stringData == null) return;
    this.logger.log('stringData', stringData, 200);
    this.modStringDataLine = this.utils.formatDate(stringData) ;

    if ((fromTicketEdit && this.modifyTicketForm.value.routeId !== undefined && this.modifyTicketForm.value.direction !== undefined)
       || (!fromTicketEdit && this.statsTicketForm.value.routeId !== undefined && this.statsTicketForm.value.direction !== undefined)) {
      this.logger.log('Entro', this.utils.formatDate(stringData), 200);
      this.modTicketTrip = null;
      this.modTicketStop = null;
      if ((fromTicketEdit && this.modalModTicketDir) || (!fromTicketEdit && this.modTicketDir)) {
        this.getHourTrip(fromTicketEdit);
      }
    }
  }

  onDateChangedModal(stringData) {
    this.modTicketTrip = null;
    this.modTicketStop = null;
    this.modTripListStops = [];
    this.modStringDataLine = this.utils.stringToServerFormat(stringData.formatted)
    this.getHourTrip(true);
  }

  onTicketStatusChange() {
    if (this.statsTicketForm.value.status !== 'INVALID') {
      this.statsTicketForm.value.filterStatusReason = '';
    }
  }

  getHourTrip(fromTicketEdit = false) {
    this.modTicketTrip = null;
    this.modTicketStop = null;
    // setto il ticket modificato alla data prevista
    this.ticketToModified.usableFrom = this.modStringDataLine;
    this.ticketToModified.idTrip = null
    this.modifyTicketForm.value.trip = null;

    this.modTripList = []
    let filterPAth =
      '?date=' +
      this.modStringDataLine +
      '&line=' +
      this.lineList[fromTicketEdit ? this.modalModTicketRoute : this.modTicketRoute].routeId +
      '&directionId=' +
      (fromTicketEdit ? this.modalModDirectionList[this.modalModTicketDir] : this.modDirectionList[this.modTicketDir]).directionId
      + '&paging=false';

    this.listService
      .getListSelect(this.pathLinetripList + filterPAth)
      .subscribe(
        response => {
          // console.log(response)
          this.logger.log('Lista Corse NOT DELETED', response.results, 300);

          fromTicketEdit
            ? this.modalModTripList = this.getTripNotDeleted(response.results)
            : this.modTripList = this.getTripNotDeleted(response.results);

          this.logger.log('Lista Corse  DELETED', this.modTripList, 300);
          this.logger.log('Ticket', this.ticketToModified, 300);
        },
        error => {
          this.logger.log('Error', error, 200);
        }
      );
  }

  getTrip(idArrayTrip, fromTicketEdit = false) {
    // prendo la posizione della select legata alla corsa
    this.modTicketTrip = idArrayTrip;

    if (fromTicketEdit) {
      // setto l'array delle fermate per scegliere l'orario:
      this.modTripListStops = this.modalModTripList[idArrayTrip].stopsData;
      // imposto idtrip per il payload
      this.ticketToModified.idTrip = this.modalModTripList[this.modTicketTrip].tripId;
    }

    // impostavo usableFrom primma di gestire anche la fermata
    // this.ticketToModified.usableFrom = this.modStringDataLine + ' ' + this.modTripList[idArrayTrip].departureTime;
    this.logger.log('Ticket', this.ticketToModified, 300);
  }

  getStopTime(idStopSequenceTime) {
    // prendo la posizione della select legata all orario di fermata
    this.modTicketStop = idStopSequenceTime;
    // imposto usableFrom
    this.ticketToModified.usableFrom = this.modStringDataLine + ' ' + this.modTripListStops[this.modTicketStop].departureTime;
    this.logger.log('Ticket', this.ticketToModified, 300);
  }

  getTripNotDeleted(tripDeletedAndNot: any) {
    if (tripDeletedAndNot === null || tripDeletedAndNot === undefined || tripDeletedAndNot.length === 0) { return []; }

    let filteredArray = tripDeletedAndNot.filter(function(itm: any) {
      return itm.deleted === false;
    });

    return filteredArray;
  }

  getDirectionsFrom() {
    this.modOriginList = [];
    let stringPath = '?routeId=' + this.lineList[this.modalModTicketRoute].routeId;

    this.listService.getListSelect(this.pathStopsList + stringPath).subscribe(
      response => {
        this.modOriginList = response.results;
        this.logger.log('From', this.ticketToModified, 300);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getDirectionsTo(value) {
    this.modDestinationList = [];
    this.modTicketOrig = this.modOriginList[value].stopName;
    let stringPath = '/' + this.modOriginList[value].stopId + '?routeId=' + this.lineList[this.modalModTicketRoute].routeId;

    this.listService.getListSelect(this.pathStopsList + stringPath).subscribe(
      response => {
        this.modDestinationList = response.results;
        this.logger.log('To', this.ticketToModified, 300);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }


  confirmModifyTicket() {
    let mySelf = this;
    this.ticketToModified.idTrip = this.modalModTripList[this.modalModTicketTrip].tripId;
    this.ticketToModified.destination = this.modDestinationList[this.modifyTicketForm.value.destination].stopName;
    this.ticketToModified.origin = this.modTicketOrig; //  this.modDirectionList[this.modTicketDir].departure;
    this.logger.log('Ticket', this.ticketToModified, 300);

    swal2.fire({  title: 'Caricamento in corso...', allowOutsideClick: false, didOpen: function () { swal2.showLoading()}});

    this.listService.edit(this.ticketToModified, this.pathModifyTicket).subscribe(
      response => {

        this.ticketToModified = new TicketModified();
        this.modalModDirectionList = response.results;
        //  this.logger.log('Modified result', response, 300);
        if (response.outcome.code === null) {
          //  swal2.fire('Biglietto modificato', 'Biglietto modificato con successo','success');
          swal2.fire({
            title: 'Biglietto modificato',
            text: 'Biglietto modificato con successo',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Stampa ticket',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(function (ev: SweetAlertResult) {
            if (ev.value) {
              //  elimino dati della ricerca per cambiare dati pdf. così li toglie dal filtro
              //  mySelf.lineList = []; SERVE
              mySelf.modTripList = [];
              mySelf.modalModDirectionList = [];

              mySelf.getPdfFromtransaction() 
            }
          }, function (dismiss) {
            if (dismiss === 'cancel') {
              swal2.close();
            }
          })
          this.closeModal();
          // this.loadElements();
        } else {
          swal2.fire('Errore', response.outcome.message, 'error');
        }
      },
      error => {
        swal2.fire('Errore', error, 'error');
        this.logger.log('Error', error, 200);
      }
    );
  }

  getPdfFromtransaction() {
    this.listService.getReprintDoc(this.urlPathReprint + this.modTicketTransaction).subscribe(
      response => {
        if (response) {
          let blob = new Blob([response], { type: 'application/pdf' });
          let ieEDGE = navigator.userAgent.match(/Edge/g);
          let ie = navigator.userAgent.match(/.NET/g); //  IE 11+
          let oldIE = navigator.userAgent.match(/MSIE/g);

          if (ie || oldIE || ieEDGE) {
            (window.navigator as any).msSaveOrOpenBlob(blob, 'myPDFdoc.pdf');
          } else {
            let url = window.URL.createObjectURL(blob);
            window.open(url);
          }
          this.closeModal();
          swal2.fire({ title: 'Il Pdf è stato recuperato', icon: 'success', didClose: () => this.pageChange(1) })
        } else {
          // this.errorMessage =
           //  response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }
  //  FINE MODIFICA TICKET

  selectOption(pos) {
    pos = pos.split(':')[0];
    // SE NON ESISTONO FIGLI
    if (!this.dataOptionTicket[pos].items) {
      this.optionSelected = this.dataOptionTicket[pos].value;
      this.viewSubOption = false;
    } else {
      this.dataSubOptionTicket = this.dataOptionTicket[pos].items;
      this.optionSelected = null;
      this.viewSubOption = true;
    }
  }

  selectSubOption(value) {
      value = value.split(': ')[1];
      this.optionSelected = value;
  }

  checkNameKind(string) {
    let ret = ''
    if (string === 'T') {
      ret = 'Biglietto'
    } else if (string === 'S') {
      ret = 'Abbonamento';
    } else if (string === 'H') {
      ret = 'Hop On Hop Off';
    } else {
      ret = 'N/A';
    }
    return ret;
  }

  changeSearchType(event) {
    // console.log( 'changed searchType' , event )
    this.chosedSearchType = event
    this.clearSearchFilters();
    // console.log( 'this.chosedSearchType' , this.chosedSearchType )
  }

  getOperators(filterByCompany: boolean = false): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlOperatorPath + (filterByCompany ? `&idCompany=${this.statsTicketForm.value.idCompany}` : '')).subscribe(
        response => {
          this.filterUserData = [];
          this.dataOperator = response.results;
          if (localStorage.getItem('typeUser') === 'RESALE') {
            this.filterUser('SELLER');
          } else {
            this.filterUser('OPERATOR');
            this.logger.log('Lista operatori: ', this.dataOperator, 300);
          }
          res();
        }, _ => {
          rej();
        }
      );
    });
  }

  filterUser(roleUser) { // filtro per operatori o venditori di
    for (let u of this.dataOperator) {
      if (u.roleUser === roleUser) {
        this.filterUserData.push(u);
      }
    }
  }

  async getTicketPdf(ticket: any) {
    /** Param object must be formatted as when requiring the ticked pdf after sale */
    this.utils.getPdfFromTickets({
      paymentType: ticket.paymentType,
      operationCode: 1,
      total: 1,
      tickets: [{ idTicket: ticket.idTicket, codeEncrypted: ticket.codeEncrypted }]
    });
  }

  /** Method fired on company change that clears the displayed data and gets new selected company data */
  async onCompanySelect() {
    this.dataList = [];
    this.listService.visibleFilter = false;

    if (this.statsTicketForm.value.idCompany === 'all') {
      this.showFilters = false;
    } else {
      await this.companySelectsFill(true);
    }
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that clears filters on search type change or detail modal closing */
  private clearSearchFilters(): void {
    // ROUTE - LINEA
    this.modTicketRoute = null;
    if (this.routeList && this.routeList.hasValue) {
      this.routeList.clearModel();
    }

    // DIRECTION
    this.modTicketDir = null;
    this.modDirectionList = [];

    // TRIP DATE
    this.modTicketData = null;

    // TRIP
    this.modTicketTrip = null;
  }

  /** Method that clears the values related to ticket edit modal */
  private clearModalValues(): void {
    this.modalModTicketDir = null;
    this.modalModTicketRoute = null;
    this.modalModTicketData = null;
    this.modalModDirectionList = [];
    this.modalModTripList = [];

    this.modOriginList = [];
    this.modDestinationList = [];
    this.modTripListStops = [];
  }

  /** Method that add queryParams when multiple of the same type are required */
  private addMultipleParams(filterName: string, apiParamName?: string): void {
    let _filter = '';
    let _isFirstValue = true;
    if (Array.isArray(this.dataFilter[filterName])) {
      this.dataFilter[filterName].forEach(reason => {
        if ( _isFirstValue ) {
          _isFirstValue = false
          _filter += reason[apiParamName ? apiParamName : 'value']
        } else {
          _filter += `&${apiParamName ? apiParamName : filterName}=` + reason[apiParamName ? apiParamName : 'value']
        }
      });
    } else {
      _filter = this.dataFilter[filterName];
    }
    this.dataFilter[apiParamName ? apiParamName : filterName] = _filter;
  }

  private async companySelectsFill(filterByCompany: boolean = false): Promise<void> {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    // CHIAMATE PER SELECT
    await this.getOperators(filterByCompany);
    await this.getChannels(filterByCompany);
    await this.getResales(filterByCompany);
    await this.getFares(filterByCompany);
    await this.getDesks(filterByCompany);
    await this.getVehicles(filterByCompany);
    await this.getLineList(filterByCompany);

    // CHIAMATA LISTA ELEMENTI
    this.UpOrDown = true;
    this.classString = 'theme-config-box';

    this.dataFilter['idTicketFare'] = 'all';
    this.dataFilter['status'] = 'all';
    this.dataFilter['filterTransStatus'] = 'CONFIRMED';
    this.dataFilter['idUser'] = 'all';
    this.dataFilter['idTicketChannel'] = 'all';
    this.dataFilter['idResale'] = 'all';
    this.dataFilter['idDesk'] = 'all';
    this.dataFilter['idVehicle'] = 'all';

    this.chosedSearchType = 'linea';

    this.showFilters = true;

    swal2.close();
  }

}
export class TicketModified {
  usableFrom;
  origin;
  destination;
  idTrip;
  id;

  constructor() {}
}
