import { Component, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ListService} from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import swal2 from 'sweetalert2'
import { AuthService } from "../../../services/auth.service";
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-subscription-list-manage',
  templateUrl: './subscription-list-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SubscriptionListManageComponent implements OnInit {

    dateEndTemp;
    dateStartTemp;
    validityConstraintLine;
    stringNameLine;
  
    //testo in HTML per tipo pagina
    kindTitle;
    kindManage;
    ArVisible;
    tempTipology;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/ticket/pricelist";
    //form html
    @ViewChild("formTicketList") ticketListForm: NgForm;
    //oggetto contenente dati component
    ticketListData: any;
    ticketTypesList: any[] = new Array();
    ticketFaresList: any[] = new Array();
    companyList: any[] = new Array();
  
    tolerance;
  
    pathFaresList = "/ticket/fare/fares";
    pathTypology = "/ticket/type/types?subscription=any";
    pathCompany = "/company/companies";
    idTicketFareTemp;
    isSol1;
    typeUser; 
    disabled;
    isExtra;
    
    model = {
      idCompany: 0,
      idTicketFare: 0,
      idTicketType: 0,
      name: "",
      price: "",
      dateStart: 0,
      dateEnd: 0,
      status: 0,
      ticketChannels: [],
      validityOpen: 0,
      validityOpenR: 0,
      tolerance: "",
      toleranceR: "",
      idTrip: "",
      numPerson: 0,
      tripMaxPeriod: 0,
      tripMaxDay: 0
    };
  
    errorMessage;
    colorTheme = "theme-orange";
    bsConfig: Partial<BsDatepickerConfig>;
  
    constructor(
      private utils: UtilsService,
      private route: ActivatedRoute,
      private logger: LoggingService,
      public listService: ListService,
      private router: Router
    ) {
     
      if (route.snapshot.toString().indexOf("new") != -1) {
        this.kindTitle = "Nuovo";
        this.kindManage = 1;
        this.validityConstraintLine = false;
      } else { 
        this.disabled = true
        this.kindTitle = "Modifica";
        this.kindManage = 0;
        if (localStorage.getItem("dataPassed") == "") {
          this.router.navigate(["/subscription/list"]);
          return;
        }
        this.ticketListData = JSON.parse(localStorage.getItem("dataPassed"));
        this.dateStartTemp = this.ticketListData["dateStart"];
        if (this.ticketListData["tolerance"] != null || this.ticketListData["idTrip"] != null) {
          this.validityConstraintLine = true;
        }
        this.dateEndTemp = this.ticketListData["dateEnd"];
        this.tempTipology = this.ticketListData["idTicketType"];
  
  
        if(
          this.ticketListData["validityOpenR"] == null &&
          this.ticketListData["validityOpen"] == null &&
          this.ticketListData["tolerance"] == null &&
          this.ticketListData["toleranceR"] == null 
        ){
       this.isExtra = true
        }
  
  
        
  this.idTicketFareTemp = this.ticketListData["idTicketFare"]
        console.log("DATAAA", this.ticketListData["status"]);
  
        this.ticketListData["dateStart"] = this.utils.fromStringToDataFormatted(
          this.ticketListData["dateStart"]
        );
        this.ticketListData["dateEnd"] = this.utils.fromStringToDataFormatted(
          this.ticketListData["dateEnd"]
        );
  
        localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
      }
    }
  
    checkLine(idTicketFare)
  {
  console.log("ARRIVA",idTicketFare)
  console.log("ARRAY",this.ticketFaresList)
  
  var string;
  for(let f of this.ticketFaresList){
    if(idTicketFare == f.idTicketFare){
      this.stringNameLine = f.routeShortName
    }
  }
  
  }
    getTicketFares() {
      this.listService.getListSelect(this.pathFaresList).subscribe(
        response => {
          this.ticketFaresList = response.results;
          this.logger.log("Lista linee", this.ticketFaresList, 300);
  
  
          if(this.kindManage == 0){
           this.checkLine(this.idTicketFareTemp) 
          }
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
    getCompanies() {
      this.listService.getListSelect(this.pathCompany).subscribe(
        response => {
          this.companyList = response.results;
          this.logger.log("Lista linee", this.companyList, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
    getTicketTypology() {
      this.listService.getListSelect(this.pathTypology).subscribe(
        response => {
          this.ticketTypesList = response.results;
  
          if (this.tempTipology != null) {
            for (let t of this.ticketTypesList) {
              if (t.idTicketType == this.tempTipology) {
                console.log("ENTRATO QUA ", this.tempTipology);
  
                if(t.ar == true ){
                  return (this.ArVisible = true);
                }else{
                  return (this.ArVisible = false);
                }
              
              }
            }
          }
  
          this.logger.log("Lista linee", this.ticketTypesList, 300);
        },
        error => {
          this.logger.log("Error", error, 200);
        }
      );
    }
  
    onChange(TicketTypologyValue) {
      var TicketTypologyValueCustom = TicketTypologyValue.substring(2);
      console.log("ID TICKET", TicketTypologyValueCustom);
      for (let typology of this.ticketTypesList) {
        if (typology.idTicketType == TicketTypologyValueCustom) {
          console.log("OK");
  
          if (typology.ar == true) {
            this.ArVisible = true;
            console.log("VISIBILE");
            break;
          }  if (typology.ar == false) {
            console.log("ENTRA PERCHE E FALSO");
            this.ArVisible = false;
          }
  
          if (typology.extra == false) {
            console.log("ENTRA PERCHE E FALSO");
            this.isExtra = false;
          }
  
          if (typology.extra == true) {
            console.log("Visibile");
            this.isExtra = true;
          }
  
  
  
  
  
  
  
        } else {
          console.log("NON ENtra");
        }
      }
    }
  
    changeFare(idTicketFare) {
      var valueFare = idTicketFare.substring(2);
      console.log("ID TICKETFARE", valueFare);
  
      for (let fare of this.ticketFaresList) {
        if (fare.idTicketFare == valueFare) {
          console.log("OK");
  
          if (fare.routeShortName != null) {
            this.validityConstraintLine = true;
            this.stringNameLine = fare.routeShortName
            console.log("VISIBILE");
            break;
          } else {
            console.log("ENTRA PERCHE NO LINE");
            this.validityConstraintLine = false;
            this.stringNameLine = ""
          }
        } else {
          console.log("NON ENtra");
        }
      }
    }
  
    onDateChanged(stringData: Date) {
      console.log("DATA CAMBIATA", stringData);
  
    }
  
    ngOnInit() {
      this.listService.resetList();
      this.listService.backToList = true;
      this.listService.listRouting = "/subscription/list";
      console.log(this.listService);
      this.getTicketFares();
      this.getTicketTypology();
      this.getCompanies();
      this.ArVisible = false;
  
      this.typeUser = this.utils.getTypeUser();
      this.isSol1 = this.utils.isSol1(this.typeUser);
    }
  
    onSubmit(): void {
      swal2.fire({
        title: "Operazione in corso...",
        didOpen: function() {
          swal2.showLoading();
        }
      });
  
      if (this.kindManage) {
        this.model = this.getDataObj();
  
        this.ticketListData = this.model;
        this.listService
          .newObject(this.ticketListData, this.serviceToCallPath)
          .subscribe(
            response => {
              if (response.outcome.success === true) {
                this.router.navigate(["/subscription/list"]);
                swal2.fire(
                  "Il Listino  è stato creato con successo",
                  "",
                  "success"
                );
              } else {
                this.errorMessage =
                  response.outcome.code + response.outcome.description;
                swal2.fire("Attenzione", this.errorMessage, "warning");
              }
            },
            error => {
              this.logger.log("Error", error, 200);
              this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
              swal2.fire(
                "Errore",
                "Spiacente, si è verificato un errore tecnico.",
                "error"
              );
            }
          );
      } else {
        this.model = this.getDataObj();
  
        this.ticketListData = this.model;
        this.ticketListData.idTicketPricelist = this.ticketListForm.value.idTicketPricelist;
        this.listService
          .edit(this.ticketListData, this.serviceToCallPath)
          .subscribe(
            response => {
              if (response.outcome.success === true) {
                this.router.navigate(["/subscription/list"]);
                swal2.fire(
                  "Il Listino  è stato Modificato con successo",
                  "",
                  "success"
                );
              } else {
                this.errorMessage = "Credenziali non valide.";
                swal2.fire("Attenzione", "Credenziali non valide", "warning");
              }
            },
            error => {
              this.logger.log("Error", error, 200);
              this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
              swal2.fire(
                "Errore",
                "Spiacente, si è verificato un errore tecnico.",
                "error"
              );
            }
          );
      }
    }
  
    back() {
      this.router.navigate(["/subscription/list"]);
    }
  
    getDataObj() {
      var arrayChannel: any[] = new Array();
      if (this.ticketListForm.value.checkResale == true) {
        arrayChannel.push("3");
      }
      if (this.ticketListForm.value.checkMobile == true) {
        arrayChannel.push("2");
      }
      if (this.ticketListForm.value.checkWeb == true) {
        arrayChannel.push("5");
      }
      if (this.ticketListForm.value.checkAPI == true) {
        arrayChannel.push("1");
      }
  
      if (
        this.ticketListForm.value.dateEnd == null ||
        this.ticketListForm.value.dateEnd == undefined
      ) {
        this.model.dateEnd = this.dateEndTemp;
      } else {
        this.model.dateEnd = +this.utils.convertDateToISO(
          this.ticketListForm.value.dateEnd
        );
      }
  
      if (
        this.ticketListForm.value.dateStart == null ||
        this.ticketListForm.value.dateStart == undefined
      ) {
        this.model.dateStart = this.dateStartTemp;
      } else {
        this.model.dateStart = +this.utils.convertDateToISO(
          this.ticketListForm.value.dateStart
        );
      }
  console.log("IDCOMPANY", this.ticketListForm.value.idCompany)
      this.model.idCompany = this.ticketListForm.value.idCompany;


      if(this.kindManage == 0){
        this.model.idTicketFare = this.ticketListData["idTicketFare"];
        this.model.idTicketType = this.ticketListData["idTicketType"];
        this.model.idTrip = this.ticketListData["idTrip"];
      }

      if(this.kindManage == 1){
        this.model.idTicketFare = this.ticketListForm.value.idTicketFare;
        this.model.idTicketType = this.ticketListForm.value.idTicketType;
      }


      this.model.name = this.ticketListForm.value.name;
      this.model.price = this.ticketListForm.value.price;
      this.model.status = this.ticketListForm.value.status;
  
      this.model.ticketChannels = arrayChannel;
        for (let f of this.ticketFaresList) {
          if (f.idTicketFare == this.ticketListForm.value.idTicketFare) {
            this.model.idTrip = f.routeShortName;
          }
        }
      this.model.numPerson = 1;
      this.model.tolerance = this.ticketListForm.value.tollerance;
      this.model.toleranceR =  this.ticketListForm.value.tolleranceR;
      this.model.validityOpen = this.ticketListForm.value.validityOpen;
      this.model.validityOpenR = this.ticketListForm.value.validityOpenR;

      this.model.tripMaxPeriod = this.ticketListForm.value.tripMaxPeriod;
      this.model.tripMaxDay = this.ticketListForm.value.tripMaxDay;

      return this.model;
    }
  }
  


