import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketCategoryType, TicketType } from '../../../enums/configuration.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../../services/utils.service';
import { IConfigValue } from '../../../interfaces/configuration.interface';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-ticket-category-manage',
  templateUrl: './ticket-category-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketCategoryManageComponent implements OnInit {

  id: number;
  groupId: number;
  categoryId: number;
  isGrouped: boolean;
  errorMessage: string;
  kindTitle: string;
  kindDetail: string;
  kindManage: number;
  categoryType: string = TicketCategoryType.categoriesGroup; // 0 - gruppo categorie, 1 - categoria
  companiesList: any[] = [];
  categoriesList: any[] = [];
  ticketTypes: IConfigValue[] = [];
  subscriptionTypes: IConfigValue[] = [];
  channelForm: FormGroup;
  dataTicketCategory: TicketCategory;

  cagegoryPath = '/category';
  categoriesGroupPath = '/category/categories-grouped';

  TicketCategoryType = TicketCategoryType;
  TicketType = TicketType;

  withSubscription: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private router: Router,
    private listService: ListService,
    private logger: LoggingService
  ) {
    //Se creo nuova categoria/gruppo
    if (this.route.snapshot.toString().indexOf('new') !== -1) {
      this.kindManage = 1;
    } else {
      this.kindManage = 0;
      this.id = this.listService.selectedID;
    }
  }

  async ngOnInit(): Promise<void> {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/categories';

    this.route.params.subscribe(params => {
      this.categoryType = params['categoryType'];
      this.isGrouped = this.categoryType === TicketCategoryType.categoriesGroup;
      // Check on kindManage and valorization of kindTitle and kindDetail depending on type
      this.kindTitle = this.kindManage === 1
        ? this.isGrouped ? 'Nuovo' : 'Nuova'
        : 'Modifica';
      this.kindDetail = this.isGrouped ? 'gruppo di categorie' : 'categoria';
      this.manageChannelForm();
      if (!this.kindManage) {
        this.getCategoryInfo();
      }
    });
    
    this.companiesList = await this.utils.getCompanyList();
    
    if (this.categoryType === TicketCategoryType.category) {
      this.categoriesList = await this.utils.getCategories(this.kindManage ? null : this.channelForm.get('idCompany').value);
      this.ticketTypes = this.utils.manageTicketTypes(this.withSubscription);
      this.subscriptionTypes = this.utils.manageSubscriptionTypes();
    }
  }

  getCategoryInfo(): void {
    this.listService.getListSelect(`${this.isGrouped ? this.categoriesGroupPath : this.cagegoryPath}/${this.id}`).subscribe(
      response => {
        this.dataTicketCategory = response.data;
        this.groupId = this.dataTicketCategory.groupId;
        this.categoryId = this.dataTicketCategory.categoryId;
        this.manageCategoryInfo();
      }, error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  onSubTypeChange(): void {
    this.channelForm.controls['type'].value === TicketType.subId ? this.channelForm.controls['subscription'].setValidators(Validators.required) : this.channelForm.controls['subscription'].setValidators(null);
  }

  /** When editing a category the list of categories available must be changed on company selection */
  async onCompanyChange(): Promise<void> {
    if (this.categoryType === TicketCategoryType.category) {
      this.categoriesList = await this.utils.getCategories(this.channelForm.get('idCompany').value);
      this.manageCategoryDefault();
    }
  }

  back(): void {
    this.router.navigate(['/categories']);
  }

  onSubmit(): void {
    swal2.fire({
      title: 'Operazione in corso...',
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.dataTicketCategory = this.channelForm.value;

    if (this.kindManage) {
      this.listService.newObject(this.dataTicketCategory, this.isGrouped ? this.categoriesGroupPath : this.cagegoryPath).subscribe(
        response => {
          this.responseHandler(response);
        }, error => {
          this.utils.apiErrorHandler(error);
        }
      );
    } else {
      this.manageEditIds();
      this.listService.edit(this.dataTicketCategory, this.isGrouped ? this.categoriesGroupPath : this.cagegoryPath).subscribe(
        response => {
          this.responseHandler(response);
        }, error => {
          this.utils.apiErrorHandler(error);
        }
      )
    }
  }

  // ---------- PRIVATE METHODS ----------- //

  private manageChannelForm(): void {
    /** Form controls are different based on the managed category type */
    this.channelForm = this.formBuilder.group({
      idCompany: [null, Validators.required],
      name: [null, Validators.required],
      description: [null],
      deleted: [false, Validators.required],
      ...(this.categoryType === TicketCategoryType.category) && {
        mode: [null],
        type: [null, Validators.required],
        subscription: [null],
        groupId: [null, Validators.required]
      }
    });
  }

  private manageCategoryInfo(): void {
    this.channelForm.patchValue({
      idCompany: this.dataTicketCategory.idCompany,
      name: this.dataTicketCategory.name,
      description: this.dataTicketCategory.description,
      deleted: this.dataTicketCategory.deleted,
      ...(this.categoryType === TicketCategoryType.category) && {
        mode: this.dataTicketCategory.mode,
        type: this.dataTicketCategory.type,
        subscription: this.dataTicketCategory.subscription,
        groupId: this.dataTicketCategory.groupId
      }
    })
  }

  /** Method that sets a default category based on the selected company, in order to have a 'base' default value automatically detected */
  private manageCategoryDefault(): void {
    const baseIdx = this.categoriesList.findIndex(c => c.groupId === -1);
    this.channelForm.controls['groupId'].patchValue(baseIdx !== -1 ? this.categoriesList[baseIdx]?.groupId : this.categoriesList[0]?.groupId);
  }

  private manageEditIds(): void {
    if (this.isGrouped) {
      this.dataTicketCategory.groupId = this.groupId;
    } else {
      this.dataTicketCategory.categoryId = this.categoryId;
    }
  }

  private responseHandler(response: any): void {
    if (response.outcome.success === true) {
      swal2.fire(`${this.kindTitle} ${this.kindDetail} ${this.kindManage ? 'creat' : 'modificat'}${this.isGrouped ? 'o' : 'a'}`, '', 'success');
      this.back();
    } else {
      this.errorMessage = response.outcome.code + response.outcome.description;
      swal2.fire('Attenzione', this.errorMessage, 'warning');
    }
  }

}

export interface TicketCategory {
  idCompany: number;
  name: string;
  description: string;
  deleted: boolean;
  mode?: string;
  type?: string;
  subscription?: string;
  groupId?: number;
  categoryId?: number;
}