<div class="row" style="padding-top: 20px"> </div>

<div class="">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>Riepilogo Vendita </strong>
                </h2>
                <div class="ibox-tools col-xs-1 pull-right"></div>
            </div>
            <div class="ibox-content">
                <div class="row">
                    <div class="col-lg-6">
                        <button (click)="deleteSold()" class="btn btn-danger btn-block btn-lg" type="button">Annulla Vendita</button>
                    </div>
                    <div class="col-lg-6">
                        <button *ngIf="arrayTicketPrinted.length > 0" data-toggle="modal" class="btn btn-primary btn-block btn-lg" (click)="openModal(template)" type="submit" >Valida </button>
                    </div>
                </div>
                <hr>
                <div class="wrapper wrapper-content animated fadeInRight">
                    <div class="row">
                        <div *ngFor="let t of arrayTicketPrinted" class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <div  class="ibox">
                                <div class="ibox-content product-box">
                                    <div class="product-desc">
                                        <h3> ID Ticket: {{t.idTicket}}</h3>
                                        <!--<a class="product-name"> {{t.companyName}}</a>
                                        <small class="text-muted">{{t.companyLongName}}</small>-->
                                        <h5> Valido dal: {{t.usableFrom | date:'dd-MM-yyyy HH:mm:ss'}}</h5>
                                        <h5 *ngIf="t.idCard"> Fino al: {{t.usableTo| date:'dd-MM-yyyy HH:mm:ss'}}</h5>
                                        <h5> Venduto il: {{t.soldOn | date:'dd-MM-yyyy'}} </h5>
                                        <h5 *ngIf="t.idCard"> ID Card: {{t.idCard}}</h5>
                                        <small *ngIf="t.idLinkedTicket">(Ritorno)</small>
                                        <span class="product-price">
                                            €{{t.price + (t.supplementPrice ? t.supplementPrice : 0) | number : '1.2'}}
                                        </span>
                                    <!--</div>
                                    <div style="padding: 0px 0;" class="product-imitation">
                                        <img src="./assets/img/qr_code.png" style="position: relative; width: 100%">
                                    </div>
                                    <div class="product-desc">-->
                                        <div class="small m-t-xs">
                                            <h5>{{t.typeTicket}}</h5>
                                            <h5>{{t.fareName}}</h5>
                                            <h5 *ngIf="t.arrival">Destinazione: {{t.arrival}}</h5>
                                            <h5 *ngIf="t.departure">Partenza: {{t.departureTime | date:'dd-MM-yyyy HH:mm'}} {{t.departure}}</h5>
                                        </div>
                                    </div>
                                    <div style="
                                    padding: 4px;
                                    background: green; 
                                    background: linear-gradient(green, darkgreen);" class="product-desc">
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>

                     
                        <div *ngFor="let t of arrayTicketError"  class="clearfix  col-xs-12 col-sm-6 col-md-4 col-lg-2">
                            <div class="ibox">
                                <div class="ibox-content product-box">
                                    <div class="product-desc">
                                        <strong> ID Ticket: {{t.idTicket}}</strong>
                                        <!--<a class="product-name"> {{t.companyName}}</a>
                                        <small class="text-muted">{{t.companyLongName}}</small>-->
                                        <strong> Valido da: {{t.usableFrom | date:'dd-MM-yyyy HH:mm:ss'}}</strong>
                                        <strong *ngIf="t.idCard"> Fino al: {{t.usableTo| date:'dd-MM-yyyy HH:mm:ss'}}</strong>
                                        <strong> Venduto il: {{t.soldOn | date:'dd-MM-yyyy'}} </strong>
                                        <strong *ngIf="t.idCard"> ID Card: {{t.idCard}}</strong>
                                        <small *ngIf="t.idLinkedTicket">(Ritorno)</small>
                                    </div>
                                    <div style="padding: 0px 0;" class="product-imitation">
                                        <img src="./assets/img/qr_code.png" width="80%">
                                    </div>
                                    <div class="product-desc">
                                        <div class="small m-t-xs">
                                            <strong>{{t.typeTicket}}</strong><br>
                                            <strong>{{t.fareName}}</strong><br>
                                            <strong *ngIf="t.arrival">Destinazione: {{t.arrival}}</strong><br>
                                            <strong *ngIf="t.departure">Partenza: {{t.departureTime | date:'dd-MM-yyyy HH:mm'}} {{t.departure}}</strong>
                                        </div>
                                        <span class="product-price">
                                            €{{t.price + (t.supplementPrice ? t.supplementPrice : 0) | number : '1.2'}}
                                        </span>
                                    </div>
                                    <div style="
                                            padding: 4px;
                                            background: red; 
                                            background: linear-gradient(red, darkred);" class="product-desc">
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ibox-footer">
            </div>
        </div>
    </div>
</div>


<ng-template #template>
<div >
    <div class="modal-dialog">
     
            <div class="modal-body">

                <div class="row">
                    <h1 style="padding-left: 17px"> Il pagamento è avvenuto con:</h1>
                </div>
                <br>

                <div class="row">
                    <ng-container *ngFor="let typeOfPayment of paymentTypes">
                        <a *ngIf="typeOfPayment.description != '-'" (click)="confirmSold(typeOfPayment.code)" class="col-md-12">
                            <div class="col-lg-12 text-center payment-card">
                                <span class="h3" style="padding-left: 10px;">{{typeOfPayment.description}}</span>
                            </div>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</ng-template>