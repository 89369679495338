<style>
    .my-drop-zone { border: dotted 3px lightgray; }
    .nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
    .another-file-over-class { border: dotted 3px green; }
 
    html, body { height: 100%; }
</style>
 
<div class="row" style="padding-top: 20px"> </div>

<div>
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <!-- TITLE SECTION -->
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>Gestione chiavi</strong>
                </h2>

                <div class="ibox-tools col-xs-1 pull-right">
                    <button type="button" (click)="newKey()" class="btn btn-view btn-outline">
                      <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <!-- KEYS SECTION -->
            <div class="ibox-content">
                <div class="table-responsive">
                    <ng-container *ngIf="keysList.length > 0; else noKeys">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover dataTables-example">
                                <thead>
                                    <tr>
                                        <th *ngIf="isSoluzione1">Nome</th>
                                        <th>Descrizione</th>
                                        <th class="text-center">Azioni</th>
                                        <th class="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let key of keysList" style="cursor:pointer">
                                        <td class="col-md-5">{{ key.name }}</td>
                                        <td class="col-md-5">{{ key.description }}</td>
                                        <td  style="width: 50px"  class="text-center">
                                            <button 
                                                title="{{ getStatusDescription(key.actions) }}"
                                                class="{{ getButtonCircle(key.actions) }}"
                                                type="button">
                                            </button>
                                        </td>
                                        <td class="col-md-1 text-center">
                                            <button type="button" class="btn btn-view btn-outline float-left"
                                                (click)="selectRow(key)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ngb-pagination 
                                *ngIf="visiblePaging"
                                class="pull-right" 
                                [collectionSize]="listService.totalRows"
                                [maxSize]="10"
                                [pageSize]="listService.rows" 
                                [(page)]="listService.page"
                                (pageChange)="pageChange($event)" >
                            </ngb-pagination>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- NO KEYS MESSAGE -->
<ng-template #noKeys>
    <h3 style="text-align: center;">Nessuna chiave assegnata</h3>
</ng-template>
