<div class="row" style="padding-top: 20px"></div>
    
<div class="col-md-12">
  <div  class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-md-4"><strong>Consultazione Vendite</strong></h2>
   
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <form #formSalesList="ngForm">
              <div class="form-group col-xs-6 col-md-2">
                <label>Vendita da:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="beginValidity"
                    id="beginValidity"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class="form-group col-xs-6 col-md-2">
                <label>Vendita a:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="endValidity"
                    id="endValidity"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class=' col-xs-12 col-lg-2'>
                <div class="form-group">
                  <label>Tariffa</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idSupplier" class="form-control" ngModel name="idSupplier" required idSupplier #idSupplier="ngModel"
                      required>
                        <option [ngValue] = "0"> </option>
                        <option *ngFor="let s of dataFare" [ngValue]="s.idSupplier">{{s.businessName}}</option>
                      </select>
                  </div>
                </div>
              </div>
              <div class='col-xs-12 col-lg-2'>
                <div class="form-group">
                  <label>Operatore</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCommission" class="form-control" ngModel name="idCommission" required idCommission #idCommission="ngModel">
                      <option [ngValue] = 0 > </option>
                      <option *ngFor="let c of dataOperator" [ngValue]="c.idCommission">{{c.codeCommission}} </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                      <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                      <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                        <i class="fa fa-rotate-left"></i>
                      </button>
                    </div>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th>Data Vendita</th>
              <th>N. di ticket</th>
              <th>Importo</th>
              <th>Operatore</th>
              <th style="width:100px;"></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
              <td>{{data.dateSell}}</td>
              <td>{{data.numTicket}}</td>
              <td>{{data.amount}}</td>
              <td>{{data.operator}}</td>
              <td style="width: 50px" class="col-md-4 text-center">
                <button type="button" class="btn btn-view btn-outline" (click)="selectRow(data)"><i class="fa fa-ticket"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

