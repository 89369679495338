<div class="row border-bottom">
    <nav class="navbar navbar-static-top white-bg" role="navigation" style="margin-bottom: 0" [ngClass]="{sol1: typeUser == 'SOLUZIONE1' || typeUser == 'SUPERADMIN' , company: typeUser == 'AZIENDA DI TRASPORTO', resale: typeUser == 'RIVENDITA'}">
        <div class="navbar-header">
            <a class="minimalize-styl-2 btn btn-redBU " (click)="toggleNavigation()"><i class="fa fa-bars"></i> </a>
            <a *ngIf="listService.backToList == true" class="minimalize-styl-2 btn btn-redBU " (click)="backToList()"><i class="fa fa-arrow-left"></i></a>
        </div>
      
        <ul class="nav navbar-top-links navbar-right">
            <li>
                <a (click)="goToUserProfile()">
                    <i class="fa fa-user"></i>
                </a>
            </li>
            <li>
                <a (click)="logout()">
                    <i class="fa fa-sign-out"></i> Logout
                </a>
            </li>
        </ul>
    </nav>
</div>