import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import { AuthService} from "../../../services/auth.service";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-obliterators-manage',
  templateUrl: './obliterators-manage.component.html',
  styleUrls: ['../../../app.component.css']
})

export class ObliteratorsManageComponent implements OnInit {
    vehicleList: any[] = new Array;
    //testo in HTML per tipo pagina
    kindTitle;kindManage;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/obliterator";
    pathToCallCompaniesPath = "/company/companies"
    pathToCallVehicles = "/vehicle/vehicles";
    typeUser;
    isSol1;
    idCompanyTemp;
    softwareVersion;
    dataVersionObliterator:  any[] = new Array
    urlVersionObliteratorPath
    clear;
    colorTheme = "theme-orange";
    bsConfig: Partial<BsDatepickerConfig>;

    //form html
    @ViewChild('obliteratorForm') formObliterator: NgForm;
    //oggetto contenente dati component
    dataObliterator: any;
    companiesList: any[] = new Array;
    filterArray: any[] = new Array;

    // Initialized to specific date (09.10.2018).
   
    errorMessage;
    //DATI TEMPORANEI PER TENERE TRACCIA DI ID E DATA ACQUISTO STAMPANTE
    idTemp;
    dateSellTemp;

    constructor(private utils:UtilsService,  private route: ActivatedRoute, private authService: AuthService, private logger: LoggingService, public listService: ListService, private router: Router ) {
        

        //Se creo nuovo veicolo
        if(route.snapshot.toString().indexOf("new") != -1) {
            this.kindTitle = "Nuova";
            this.kindManage = 1;
        } else { //se modifico veicolo
            this.kindTitle = "Modifica";
            this.kindManage = 0;
        
            if(localStorage.getItem("dataPassed")=="")  {this.router.navigate(['/obliterators/list']); return;}
            this.dataObliterator = JSON.parse(localStorage.getItem("dataPassed"));
            
            this.idTemp = this.dataObliterator["idObliterator"];
            this.dateSellTemp = this.dataObliterator["dateSell"];
            this.idCompanyTemp = this.dataObliterator["idCompany"]
            this.softwareVersion = this.dataObliterator["softwareVersion"]

            //TO DO DA ELIMINARE
            localStorage.setItem("dataPassed",""); //svuoto dato in localstorage

            this.clear = false;
        }

        this.bsConfig = Object.assign(
            {},
            { containerClass: this.colorTheme },
            { dateInputFormat: "DD-MM-YYYY" },
            { rangeInputFormat: "DD-MM-YYYY" }
        );
    }

    ngOnInit() {
        this.listService.resetList();
        this.listService.backToList = true;
        this.listService.listRouting= "/obliterators/list"
        this.getVehicles();
        this.getCompanies();
        this.typeUser = this.utils.getTypeUser();
        this.isSol1 = this.utils.isSol1(this.typeUser);
        this.urlVersionObliteratorPath = "/obliterator/apk/apks"
        this.getVersion();
    }


    getCompanies() {
        this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
            this.companiesList = response.results;
            this.logger.log("Lista rivenditori", this.companiesList, 300);
            //console.log("LISTA commesse: ",response.results);
        },
        (error) => {
            this.logger.log("Error", error, 200);
        }
        );
    }

    getVehicles() {
        this.listService.getListSelect(this.pathToCallVehicles).subscribe((response) => {
            this.vehicleList = response.results;
            this.logger.log("Lista rivenditori", this.vehicleList, 300);
            if(this.kindManage == 0){
                this. checkToModify(this.dataObliterator["idCompany"])
            } 
        },
        (error) => {
            this.logger.log("Error", error, 200);
        }
        );
    }


    getVersion (){
        this.listService.getListSelect(this.urlVersionObliteratorPath).subscribe((response) => {
          this.dataVersionObliterator = response.results;
          this.logger.log("Lista veicoli", this.dataVersionObliterator, 300);
        },
          (error) => {
            this.logger.log("Error", error, 200);
          }
        );
      }

    onSubmit()  : void {

        swal2.fire({title: 'Operazione in corso...',didOpen: function () {swal2.showLoading()}});

        if(this.kindManage){

            this.dataObliterator = this.formObliterator.value
            this.dataObliterator["status"]  = "1"
            
            console.log(this.dataObliterator)
            this.listService.newObject(this.dataObliterator, this.serviceToCallPath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.close();
                         swal2.fire("L'obliteratrice è stata creata", "","success");
                         this.router.navigate(['/obliterators/list']);
                    } else {

                        if(response.outcome.code == "0013"){
                            this.errorMessage = "Il serial number inserito risulta essere già presente"
                        }else{
                            this.errorMessage =  response.outcome.code +  response.outcome.description
                        }
                       
                        swal2.fire("Attenzione", this.errorMessage,"warning");
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                    

                    this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                    swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
     
                }
            );
        }else{

            //COPIA DA FORM CON VALORI
            this.dataObliterator = this.formObliterator.value;
            //AGGIUNGE DATI COME ID E DATESELL NON PASSATI DALLA FORM
            this.dataObliterator["idObliterator"] = this.idTemp;
            this.dataObliterator["softwareVersion"] = this.softwareVersion;
            this.dataObliterator["status"]  = "1"

            if (this.formObliterator.value.dateSell == null || this.formObliterator.value.dateSell == undefined)
                this.dataObliterator["dateSell"] = this.dateSellTemp;
            else
                this.dataObliterator["idCompany"] = this.idCompanyTemp
                console.log("ID COMPANY ", this.dataObliterator["idCompany"] )
                console.log("OGGETTO ", this.dataObliterator )
        
            console.log(this.dataObliterator)
            this.listService.edit(this.dataObliterator, this.serviceToCallPath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.close();
                        swal2.fire("L'obliteratrice è stata modificata", "","success");
                        this.router.navigate(['/obliterators/list']);

                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire("Attenzione", this.errorMessage,"warning");
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                    this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                    swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
                }
            );
        }
    }


    checkVehicle(id){
        this.filterArray = []

        this.clear = true;


        this.formObliterator.value.idVehicle = "";
        this.formObliterator.controls["idVehicle"].reset();
        
        var stringTest = id.split(":")[1];
        var stringCompany = stringTest.substring(1);


        console.log(stringCompany)

        for(let v of this.vehicleList){
            if(v.idCompany == stringCompany){
                this.filterArray.push(v)


            }
        }

        this.clear = false;
    }

    checkToModify(id){
        this.filterArray = []

        console.log(id)

        for(let v of this.vehicleList){
            if(v.idCompany == id){
                this.filterArray.push(v)


            }
        }
    }


    back(){
        this.router.navigate(['/obliterators/list']);
    }
}
