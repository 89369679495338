import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2, { SweetAlertResult } from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketsListComponent implements OnInit {
  
  //FORM
  @ViewChild('formTicketList') ticketListForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();

  typologyList: any[] = new Array();
  
  fareList: any[] = new Array();
 
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  //PATH CHIAMATE
  urlFaresPath = "/ticket/fare/fares";
  urlTypologiesPath = "/ticket/type/types?subscription=no";
  serviceToCallPath = "/ticket/pricelist";
  urlCompaniesPath = "/company/companies";
  urlDeletePath = "";
  errorMessage: string = "";
  visibleFilter;
  defaultValue;
  UpOrDown;
  typeUser;
  isSoluzione1;
  isMobileOperator;
  roleUser;

  constructor(private utils: UtilsService, private logger: LoggingService, private router: Router, public listService: ListService){
    this.listService.resetList();
    this.listService.configurationServiceCall("/tickets/list",false,true,1,15);
    this.listService.restURL = "/ticket/pricelist/pricelists";
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  ngOnInit() {
    this.loadElements();
    this.UpOrDown = true;
    this.getTypologies();
    this.getFares();
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    
    this.roleUser = this.utils.getRoleUser();
    this.isMobileOperator = this.utils.isOperatorMobile(this.roleUser)
    
  }

  pageChange(page){
    this.listService.page = page; 
    this.loadElements();
  }

  changeStatus(){
    if(this.UpOrDown == true){
      this.UpOrDown = false;

      console.log("VIEE", this.UpOrDown)
    }else{
      this.UpOrDown = true;
    }
  
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.ticketListForm.value;
    this.dataFilter['subscription'] = "no"

    if( this.ticketListForm.value.validOn != null || this.ticketListForm.value.validOn != undefined)
    { this.dataFilter['validOn'] = this.utils.convertDateToISO(this.ticketListForm.value.validOn); }
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){

    this.ticketListForm.value.idTicketType = "";
    this.ticketListForm.value.idTicketFare = "";
    this.ticketListForm.value.idCompany = "";
    this.ticketListForm.value.ticketPricelistName = "";
    this.ticketListForm.value.validOn = ""
    this.ticketListForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  getTypeName(id){
    for(let type of this.typologyList){
      if(type.idTicketType == id){
        return type.name;
      }
    }

  }

  //SERVIZI SELECT
  getTypologies() {
    this.listService.getListSelect(this.urlTypologiesPath).subscribe((response) => {
      this.typologyList = response.results;
      this.logger.log("Lista tipologie", this.typologyList, 300);
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getCompanyName(id){
    for (let company of this.companiesList){
      if(company.idCompany == id){
        return company.legalBusinessName;
      }
    }
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompaniesPath).subscribe((response) => {
      this.companiesList = response.results;
      this.logger.log("Lista Aziende", this.typologyList, 300);
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getFareName(id){
for(let fare of this.fareList){
  if(fare.idTicketFare == id){
    return fare.name

  }
}

  }

  getFares() {
    this.listService.getListSelect(this.urlFaresPath).subscribe((response) => {
      this.fareList = response.results;
      this.logger.log("Lista Tariffe", this.fareList, 300);
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }


  findString(string){
    return this.utils.getStringChannel(string)
  }


  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    

    this.dataFilter['subscription'] = "no"
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {

        this.dataList=[]
       // this.dataList = response.results;
        this.listService.totalRows = response.total;

        for(let d of response.results){

          if(d.tripMaxDay == null){
            this.dataList.push(d)
          }

        }

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement){
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;

  
var StringChannels = dataElement.ticketChannels
console.log("stringa" ,StringChannels )
let subString1 = "1";
let subString2= "2";
let subString3 = "3";
let subString4 = "5";



    if(StringChannels.includes(subString3)) {
      dataElement.checkResale = true
    }else {
      dataElement.checkResale = false
    }

    if(StringChannels.includes(subString2)) {
      dataElement.checkMobile = true
    }else {
      dataElement.checkMobile = false
    }

    if(StringChannels.includes(subString4)) {
      dataElement.checkWeb = true
    }else {
      dataElement.checkWeb = false
    }
    if(StringChannels.includes(subString1)) {
      dataElement.checkAPI = true
    }else {
      dataElement.checkAPI = false
    }

if( dataElement.tolerance != null){
  dataElement.validityRadioBox = '1';
}
if( dataElement.validityOpen != null){
  dataElement.validityRadioBox = '2';
}
if(dataElement.validityOpenR != null){
  dataElement.validityReturnCheck = true;
}

    
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/tickets/list/edit']);
  }

  newTicketList(){
    this.listService.backToList = true;
    this.router.navigate(['/tickets/list/new']);
  }

  deleteTicketList(idList) {
    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione listino',
      text: "Sei sicuro di voler eliminare il listino?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idList);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  
  duplicate(data){

    var array : any[] = new Array();
    array = this.utils.getDeatilsToDuplicateTicketList(data.ticketChannels);

    var str =  data.name;
    var n = str.includes("Copia(");
    if(n == true)
    {
      str
      var str =  data.name;
      var res = str.substring(6, 7);


      if(res == " ") {

        var subString = data.name
        var dataName = str.substring(8);
        data.name = "Copia(1)" +dataName
      }else{
        var number = +res;
        number = number + 1

        var subString = data.name
        var dataName = str.substring(8);

        data.name = "Copia("+ number+")" +dataName
      }
     
    }else if(n == false){
      data.name ="Copia( )" + data.name
    }

    data.idTicketPricelist = null;
    data.ticketChannels = array;
    this.listService.newObject(data, this.serviceToCallPath).subscribe(
      (response) => {

  
          if (response.outcome.success === true) {

              this.router.navigate(['/tickets/list']);
              swal2.fire("Il Listino  è stato duplicato", "","success");
              this.loadElements();

          } else {
              this.errorMessage =  response.outcome.code +  response.outcome.description
              swal2.fire("Attenzione", this.errorMessage,"warning");
          }
      },    
      (error) => {
          this.logger.log("Error",error,200);
          this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
          swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
      }
  );
  }

  getButtonCircle(statusNumber){
    var buttonColor; 
    if(statusNumber == "1"){
   return   buttonColor =  "btn btn-primary btn-circle"
    }
    if(statusNumber == "0"){
      return   buttonColor =  "btn btn-danger btn-circle"
       }
  }

  getStatusDescription(statusNumber){
    var buttonColor; 
    if(statusNumber =="1"){
   return   buttonColor =  "Attivo"
    }
    if(statusNumber == "2"){
      return   buttonColor =  "Non Attivo"
       }
  }



  deleteElement(idElement) {
    this.urlDeletePath =  "/ticket/pricelist/"+ idElement;

    swal2.fire({title: 'Eliminazione in corso...', didOpen: function () {swal2.showLoading()}});
    
    this.logger.log("ID DELETE:",idElement,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
    
        if (response.outcome.success === true) {
              
          this.logger.log("Response value",response.value,200);
          swal2.fire("Il Listino è stato eliminato", "","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements(); 
            }
          });
        } else {   
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire("Attenzione", this.errorMessage,"warning");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

}
