<style>
    .my-drop-zone { border: dotted 3px lightgray; }
    .nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
    .another-file-over-class { border: dotted 3px green; }
 
    html, body { height: 100%; }
</style>
 
<div class="row" style="padding-top: 20px"> </div>

<div>
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <!-- TITLE SECTION -->
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>Gestione parametri</strong>
                </h2>

                <div class="ibox-tools col-xs-1 pull-right">
                    <button type="button" (click)="newConfig()" class="btn btn-view btn-outline">
                      <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <!-- KEYS SECTION -->
            <div class="ibox-content">
                <div *ngIf="isSoluzione1" class="row">
                    <div class="col-lg-12">
                        <div class="row">
                            <form [formGroup]="filters">
                                <div class='col-sm-12 col-md-6 col-lg-4'>
                                    <div class="form-group">
                                        <label>Azienda di trasporto</label>
                                        <div class='input-group col-xs-12'>
                                                <select 
                                                    type="text"
                                                    id="idCompany"
                                                    class="form-control"
                                                    formControlName="company">
                                                    <option [ngValue] = "'all'" >Tutte</option>
                                                    <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}}</option>
                                                </select>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                                    <div class="form-group">
                                        <label></label>
                                        <div class='input-group col-md-12'>
                                            <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                                            <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                                                <i class="fa fa-rotate-left"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <ng-container *ngIf="configurations.length > 0; else noParams">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover dataTables-example">
                                <thead>
                                    <tr>
                                        <th *ngIf="isSoluzione1">Azienda di trasporto</th>
                                        <th>Chiave</th>
                                        <th>Valore assegnato</th>
                                        <th class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let configuration of configurations" style="cursor:pointer">
                                        <td class="col-md-2" *ngIf="isSoluzione1">{{ getConfigurationCompany(configuration.idCompany) }}</td>
                                        <td class="col-md-4">{{ configuration.name }}</td>
                                        <td class="col-md-4">{{ configuration.value }}</td>
                                        <td class="col-md-1 text-center">
                                            <button type="button" class="btn btn-view btn-outline float-left"
                                                (click)="selectRow(configuration)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ngb-pagination 
                                *ngIf="visiblePaging"
                                class="pull-right" 
                                [collectionSize]="listService.totalRows"
                                [maxSize]="10"
                                [pageSize]="listService.rows" 
                                [(page)]="listService.page"
                                (pageChange)="pageChange($event)" >
                            </ngb-pagination>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- NO KEYS MESSAGE -->
<ng-template #noParams>
    <h3 *ngIf="listService.visibleFilter" style="text-align: center;">Nessuna chiave assegnata</h3>
</ng-template>
