<app-dialog #dialogView></app-dialog>
<div class="row wrapper border-bottom white-bg page-heading float-e-margins mTB20">
    <div class="col-lg-9">
        <h2>Documenti ordine {{nameOrder}} relativo al periodo {{monthOrder === 01 ? 'Gennaio' : monthOrder === 02 ? 'Febbraio' : monthOrder === 03 ? 'Marzo' : monthOrder === 04 ? 'Aprile': 
                monthOrder === 05 ? 'Maggio' : monthOrder === 06 ? 'Giugno' : monthOrder === 07 ? 'Luglio' : monthOrder === 08 ? 'Agosto':
              monthOrder === 09 ? 'Settembre' : monthOrder === 10 ? 'Ottobre' : monthOrder === 11 ? 'Novembre' : 'Dicembre'}}  {{yearOrder}}</h2>
    </div>
    <div class="col-lg-3">
        <button  *ngIf="!isAppr && !isAmm && statusCheckList !== 'APPROVED' && statusOrder!== 'CLOSED' &&  statusCheckList !== 'SUBMITTED' &&   !isAppr" style="float: right;margin-top:15px;" (click)= "requestValidationDocuments()" title="Documento caricato" class="btn btn-primary" ><i class="fa fa-thumbs-up"></i> Richiedi Validazione</button>
    </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
        
    <div class="row" *ngIf="viewMask; else viewDoc">
        <section *ngIf="isAppr || isAmm">
                        <div class="col-lg-12">
                            <div class="col-lg-12">
                                <div class="ibox float-e-margins">
                                    <div class="ibox-content">
                                        <div class="row">
                                            <div class='col-sm-12' ></div>
                                        </div>
                                        <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover dataTables-example" >
                                            <thead>
                                            <tr>
                                                <th colspan="2">File caricato</th>
                                                <th>Note</th>
                                                <th>Stato</th>
                                                <th></th>
                                            </tr>
                                            </thead> 
                                            <tbody *ngFor="let c of checklistItems; let i = index;">
                                                <tr>
                                                    <td colspan="5" class="font-bold text-center">{{c.descDocumentType}}</td>
                                                </tr>
                                                <tr *ngFor="let docs of c.documents; let m = index;" style="cursor:pointer">
                                                    <td class="text-center" style="width: 50px;">
                                                        <button *ngIf="docs.status  === 'NEW'" title="Documento caricato" class="btn btn-view" ><i class="fa fa-ellipsis-h"></i></button>
                                                        <button *ngIf="docs.status  === 'APPROVED'"  title="Approvato" class="btn btn-primary" ><i class="fa fa-thumbs-o-up"></i></button>
                                                        <button *ngIf="docs.status  === 'CONDITIONALLY_APPROVED'" title="Approvato con riserva" class="btn btn-warning" ><i class="fa fa-exclamation"></i></button>
                                                        <button *ngIf="docs.status  === 'REJECTED'" class="btn btn-danger" title="Rifiutato" ><i class="fa fa-thumbs-o-down"></i></button>
                                                        <button *ngIf="docs.status  === 'EXPIRED'" class="btn btn-danger" title="Scaduto" ><i class="fa fa-clock-o"></i></button>  
                                                        <button *ngIf="docs.status  === 'MISSING'" class="btn btn-info"  title="Mancato"><i class="fa fa-ban"></i></button>
                                                    </td>
                                                    <td>{{docs.fileName}}</td>
                                                    <td>{{docs.notes}}</td>
                                                    <td>{{docs.status  === 'NEW' ? 'Caricato' : docs.status  === 'APPROVED' ? 'Approvato' : docs.status === 'CONDITIONALLY_APPROVED' ? 'Approvato con riserva' : docs.status === 'REJECTED' ? 'Respinto' : docs.status === 'MISSING' ? 'Mancante' : docs.status === 'EXPIRED' ? 'Scaduto' : ' - ' }}</td>
                                                    <td style="width: 240px; text-align:left;" class="col-md-4 text-center"> 
                                                        <button title="Mancante" *ngIf="docs.status  != 'MISSING'" class="btn btn-view btn-outline"  (click)="download(docs.idDocument,docs.status,docs.fileName,docs.idOffice, isAmm)"><i class="fa fa-eye"></i></button>
                                                        <button title="Approva" *ngIf="statusCheckList === 'SUBMITTED' && statusOrder !== 'CLOSED'  && docs.status  != 'MISSING' &&  docs.status  != 'EXPIRED' && docs.status != 'APPROVED'  &&  (docs.idOffice == office || docs.idOffice == null || isAmm) " class="btn btn-primary btn-outline" (click)="approve(docs.idDocument)"><i class="fa fa-thumbs-o-up"></i></button>
                                                        <button  title="Rifiuta" *ngIf="statusCheckList === 'SUBMITTED' &&  statusOrder !== 'CLOSED'  && docs.status  != 'MISSING' &&  docs.status  != 'EXPIRED' && docs.status  != 'REJECTED'  &&  (docs.idOffice == office || docs.idOffice == null || isAmm)" class="btn btn-danger btn-outline" (click)="rejectSwal(docs.idDocument,'reject')"> <i class="fa fa-thumbs-o-down"></i></button> 
                                                        <button  title="Approva con riserva" *ngIf="statusCheckList === 'SUBMITTED'  && statusOrder !== 'CLOSED'  && docs.status  != 'MISSING' &&  docs.status  != 'EXPIRED' && docs.status != 'CONDITIONALLY_APPROVED'   &&  (docs.idOffice == office || docs.idOffice == null || isAmm)" class="btn btn-warning btn-outline" (click)="conditionallyApprove(docs.idDocument,'conditionally')"><i class="fa fa-exclamation"></i></button>
                                                        <button  title="Scarica esempio"*ngIf="c.templateLink != null && c.templateLink !='' " class="btn btn-default" (click)="downloadTemplate(c.templateLink)" > <span class="fa fa-sticky-note"></span></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </section> 

        <section *ngIf="!isAppr && !isAmm">
        <section *ngFor="let c of checklistItems; let i = index;">
            <div *ngIf=" i%2 == 0" class="clearfix"></div>
                <div class="col-lg-6">
                        <div class="ibox  float-e-margins ">
                            <div class="ibox-title">
                                <div class=''>
                                    <div class="form-group">
                                        <label>{{c.descDocumentType}}</label>
                                        <span *ngIf=" c.codeDocumentType === '00' || c.codeDocumentType === '01' || c.codeDocumentType === '05'" class="label label-greyBU pull-right">Documento aziendale</span>
                                    </div>
                                </div>
                                <div class="row ibox-content">
                                    <div class="form-group col-md-12">

                                        <div *ngIf="c.status === 'MISSING'" class="alert alert-info">
                                            Documento mancante
                                        </div>
                                        <div *ngIf="c.status === 'NEW'" class="alert alert-warning">
                                            Documento caricato in attesa di Approvazione
                                        </div>
                                        <div *ngIf="c.status === 'CONDITIONALLY_APPROVED'" class="alert alert-warning">
                                            Approvato con Riserva
                                        </div>
                                        <div *ngIf="c.status === 'REJECTED'" class="alert alert-danger">
                                            Documento Rifiutato
                                        </div>
                                        <div class="form-group">
                                            <table class="table">
                                                <thead>
                                                <tr>
                                                    <th colspan="3">File caricati</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr  *ngFor="let docName of c.documents" >
                                                        <td style="width:50px;">
                                                            <button title="Caricato" *ngIf="docName.status  === 'NEW'" class="btn btn-view" ><i class="fa fa-paper-plane"></i></button>
                                                            <button title="Approvato" *ngIf="docName.status  === 'APPROVED'" class="btn btn-primary" ><i class="fa fa-thumbs-o-up"></i></button>
                                                            <button title="Approvato con riserva" *ngIf="docName.status  === 'CONDITIONALLY_APPROVED'" class="btn btn-warning" ><i class="fa fa-exclamation"></i></button>
                                                            <button title="Rifiutato" *ngIf="docName.status  === 'REJECTED'" class="btn btn-danger" ><i class="fa fa-thumbs-o-down"></i></button>
                                                            <button title="Scaduto" *ngIf="docName.status  === 'EXPIRED'" class="btn btn-danger" ><i class="fa fa-clock-o"></i></button>  
                                                            <button title="Mancante" *ngIf="docName.status  === 'MISSING'" class="btn btn-info" ><i class="fa fa-ban"></i></button>
                                                        </td>
                                                        <td>
                                                            <p>{{docName.fileName}}</p>
                                                            <div *ngIf="docName.fileName === null" class="alert alert-info">
                                                            Nessun Documento è stato ancora caricato
                                                            </div>
                                                        </td>
                                                        <td *ngIf="docName.status !== 'MISSING'" style="width: 130px">       
                                                            <div style="float: right" *ngIf="docName.fileName !== null" >
                                                                
                                                                <button type="button"  (click)="download(docName.idDocument,docName.status,docName.fileName,docName.idOffice)"  class="btn btn-view btn-outline btn-s ">
                                                                    <span class="fa fa-eye"></span>
                                                                </button>
                                                                <button  *ngIf="c.codeDocumentType !== '01' &&  c.codeDocumentType !== '00' &&  c.codeDocumentType !== '05' && statusCheckList !== 'APPROVED' && statusCheckList !== 'SUBMITTED' && statusOrder !== 'CLOSED'"  style = "margin-left: 15px" (click)="deleteDocument(docName.idDocument)" type="button" class="btn btn-danger btn-s btn-outline">
                                                                    <span class="glyphicon glyphicon-trash"></span>
                                                                </button>
                                                
                                                                
                                                            </div>
                                                        </td>




                                                    </tr>
                                                            
                                            

                                                        
                                                </tbody>

                                          
                                            </table>
                                         

<div *ngIf="( c.codeDocumentType === '00' || c.codeDocumentType === '01' || c.codeDocumentType === '05') " class="alert alert-danger">
                                           Il documento aziendale non è valido per questo periodo, per modificarlo vai alla sezione Documenti
                                        </div>
                                           

                                               

                                        
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <div class="row">
                                            <div *ngIf="!isAppr && !isAmm">
                                                <br>
                                                <div *ngIf="!isAppr && !isAmm" class="col-md-12" style="margin-bottom: 15px">
                                                    <div *ngIf="c.codeDocumentType === codeControlUpload">
                                                        <table class="table">
                                                            <thead *ngIf="c.codeDocumentType === codeControlUpload">
                                                                <tr>
                                                                    <th width="50%">Da Caricare:</th>
                                                                    <span>Dimensione massima consentita 10MB</span>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of uploader.queue">
                                                                    <td>{{ item?.file?.name }}</td>

                                                                    <td nowrap>
                                                                    
                                                                <div style="float: right" >
                                                                        <button  type="button" class="btn btn-danger btn-s btn-outline" (click)="item.remove()">
                                                                        <span class="glyphicon glyphicon-trash"></span>
                                                                    </button>
                                                                    <!--<button type="button"  style = "margin-left: 15px"class="btn btn-default btn-s">
                                                                        <span class="fa fa-eye"></span>
                                                                    </button>-->
                                                                </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <dd>    
                                                            <div *ngIf="visible" class="progress progress-striped active m-b-xs"> 
                                                                <div style="width: 100%;" class="progress-bar">Caricando i File</div>
                                                            </div>
                                                        </dd>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button style="float: left" *ngIf="!isAppr && !isAmm && c.codeDocumentType !== '00'   && c.codeDocumentType !== '01' && c.codeDocumentType !== '05'  && c.codeDocumentType !== '06' && statusCheckList !== 'APPROVED'  && statusCheckList !== 'SUBMITTED'" (click)="fileInput.click()" class="btn btn-default"> <i class="fa fa-plus"></i>     Aggiungi </button>
                                        <span style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                                            <input (change)="test(c.codeDocumentType)" type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple/>
                                        </span>
                                    <button  style="margin-left: 15px" *ngIf="uploadButtonVisible && c.codeDocumentType === codeControlUpload " class="btn btn-primary" (click)="multipleUpload(c.codeDocumentType)" > <span class="glyphicon glyphicon-upload"></span>    Carica</button>
                                    <button  style="margin-left: 15px" *ngIf="c.templateLink != null && c.templateLink !='' " class="btn btn-default" (click)="downloadTemplate(c.templateLink)" > <span class="glyphicon glyphicon-download"></span>    File d'esempio</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        </section>
    </div>
    <app-documents-viewer #viewerDiv class="row" [ngClass]="{hidden: viewMask}"></app-documents-viewer>    
</div>

