import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../services/utils.service';

declare var jQuery: any;

@Component({
  selector: 'basic',
  templateUrl: 'basic.component.html'
})
export class BasicComponent implements OnInit {

  configLoaded = false;

  constructor(public utils: UtilsService) { }

  async ngOnInit() {
    // recupero configurazione
    await this.utils.getJSONConfiguration();
    this.configLoaded = true;
  }

}