import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SalesListComponent implements OnInit {

  @ViewChild('formSalesList') salesListForm: NgForm;
  visibleFilter;
  defaultValue;
  UpOrDown;
  
    //OGGETTO LISTA ELEMENTI & FILTRI
    dataList: any[] = new Array();
   
   
    dataFilter: any[] = new Array();
    dataOperator: any[] = new Array();
    dataFare: any[] = new Array();
  
    //PATH CHIAMATE
    urlDeletePath = "";
    urlOperatorPath = "/user/users/1";
    urlFarePath = "/ticket/fares";
    errorMessage: string = "";
    colorTheme = "theme-orange";
    bsConfig: Partial<BsDatepickerConfig>;
  
    constructor(private logger: LoggingService, private router: Router, public listService: ListService) {
      this.listService.resetList();
      this.listService.configurationServiceCall("/sales/list",false,true,1,20);
      this.listService.restURL = "/stats/sold/ticket";
      this.bsConfig = Object.assign(
        {},
        { containerClass: this.colorTheme },
        { dateInputFormat: "DD-MM-YYYY" },
        { rangeInputFormat: "DD-MM-YYYY" }
      );
    }
  
    ngOnInit() {
      //CHIAMATE PER SELECT
      this.getOperators();
      this.getFares();
   
       //CHIAMATA LISTA ELEMENTI
       this.loadElements();
       this.UpOrDown = true;
    }
  
    pageChange(page) {
      this.listService.page = page;
      this.loadElements();
    }
  
    //FILTRI
    filterSearch(){
      this.dataFilter = this.salesListForm.value;
      this.listService.visibleFilter = true;
      this.loadElements();
    }
  
    filterReset(){
      this.salesListForm.reset();
      this.listService.visibleFilter = false;
      //this.loadElements();
    }
  
    //SERVIZI SELECT
    
    getOperators() {
      this.listService.getList(this.urlOperatorPath).subscribe((response) => {
        this.dataOperator = response.results;
        this.logger.log("Lista operatori: ", this.dataOperator, 300);
      },
        (error) => {
       
        }
      );
    }
  
    getFares() {
      this.listService.getList(this.urlFarePath).subscribe((response) => {
        this.dataFare = response.results;
        this.logger.log("Lista tariffe: ", this.dataFare, 300);
      },
        (error) => {
       
        }
      );
    }
  
    //SERVIZIO LISTA ELEMENTI
    loadElements(){
      this.listService.getListFilter(this.dataFilter).subscribe(
        (response) => {
          this.dataList = response.results;
          this.listService.totalRows = response.total;
  
          this.logger.log("Response:",response,300);
          this.errorMessage = "";
        },    
        (error) => { 
          this.logger.log("Error",error,200);
          this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        }
      );
    }
  
    changeStatus(){
      if(this.UpOrDown == true){
        this.UpOrDown = false;
  
        console.log("VIEE", this.UpOrDown)
      }else{
        this.UpOrDown = true;
      }
    
    }

    selectRow(dataElement){
      this.listService.selectedID = dataElement["id"];
      this.listService.backToList = true;
      localStorage.setItem("dataPassed",JSON.stringify(dataElement));
      this.router.navigate(['/statistics/ticket']);
    }

    
  }
  
  
  
  