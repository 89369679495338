<div class="row" style="padding-top: 20px"> </div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Gestione ACL</strong>
        </h2>

        <div class="ibox-tools col-xs-1 pull-right">
          <button *ngIf="isSoluzione1" type="button" (click)="newAcl()" class="btn btn-view btn-outline">
            <i class="fa fa-plus"></i>
          </button>

        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <form #formAcl="ngForm">
                <div *ngIf="companyVisible" class='ccol-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Azienda di trasporto</label>
                    <div class='input-group col-xs-12'>
                      <select (change)="changeCompany($event.target.value)"  type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Utenti</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="idUser" class="form-control" ngModel name="idUser" required idUser #idUser="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option [ngValue] = "'-1'" > Nessuna Associazione </option>
                        <option *ngFor="let r of usersList" [ngValue]="r.id">{{r.surname}} {{r.name}} </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>ECommerce</label>
                    <div class='input-group col-xs-12'>
                      <select type="text" id="idEcommerce" class="form-control" ngModel name="idEcommerce" required idEcommerce #idEcommerce="ngModel">
                        <option [ngValue] = "'all'" > Tutte </option>
                        <option [ngValue] = "'-1'" > Nessuna Associazione </option>
                        <option *ngFor="let d of eCommerceList" [ngValue]="d.id">{{d.name}} </option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Contesto</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="context" name="context" class="form-control" ngModel context #context="ngModel">
                    </div>
                  </div>
                </div>


                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Extra</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="extra" name="extra" class="form-control" ngModel extra #extra="ngModel">
                    </div>
                  </div>
                </div>


                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Campo</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="field" name="field" class="form-control" ngModel field #field="ngModel">
                    </div>
                  </div>
                </div>


                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Tipo</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="type" name="type" class="form-control" ngModel type #type="ngModel">
                    </div>
                  </div>
                </div>


                <div class='col-sm-6 col-md-4  col-lg-2'>
                  <div class="form-group">
                    <label>Operazione</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="operation" name="operation" class="form-control" ngModel operation #operation="ngModel">
                    </div>
                  </div>
                </div>

                <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                      <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                      <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                        <i class="fa fa-rotate-left"></i>
                      </button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>

        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>Azienda di trasporto</th>
                <th>Utente</th>
                <th>eCommerce</th>
                <th>Operazione</th>
                <th>Tipo</th>
                <th>Entità</th>
                <th>Campo</th>
                <th>Extra</th>
                <th>Contesto</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList" style="cursor:pointer" >
                <td>{{getBusinessName(data.idCompany)}} </td>
                <td>{{(getUserName(data.idUser)) || "Nessuna Associazione"}}</td>
                <td>{{(getECommerceName(data.idEcommerce)) || "Nessuna Associazione"}}</td>
                <td>{{(getOperation(data.operation)) || "Nessuna Operazione"}}</td>
                <td>{{(getType(data.type)) || "Nessun Tipo"}}</td>
                <td>{{(getEntity(data.entity)) || "Nessuna Entità"}}</td>
                <td>{{data.field}}</td>
                <td>{{data.extra}}</td>
                <td>{{data.context}}</td>
      
                <td style="width: 100px" class="col-md-4 text-center">
                  <button type="button" class="btn btn-success btn-outline float-left" (click)="selectRow(data)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-outline float-right" (click)="deleteACL(data.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
            [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>