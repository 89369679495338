import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2, { SweetAlertResult } from 'sweetalert2'

@Component({
  selector: 'app-wallet-recharge',
  templateUrl: './wallet-recharge.component.html',
  styleUrls: ['../../../app.component.css']
})
export class WalletRechargeComponent implements OnInit {
  urlRechargeType;
  rechargeTypeList : any[] = new Array();
  dataResale;
  idResale;
  nameResale;
  rechargeData;
  serviceToCallPath; 

  isVisiblePaymentKind;

 constructor( private route: ActivatedRoute, private logger: LoggingService, private router: Router, public listService: ListService) {


    if(localStorage.getItem("dataPassed")=="")  {this.router.navigate(['/resales/list']); return;}
    this.dataResale = JSON.parse(localStorage.getItem("dataPassed"));
    this.idResale = this.dataResale["id"];
    this.nameResale = this.dataResale["legalBusinessName"]

  
    console.log("OBJ", this.dataResale) //TO DO DA ELIMINARE
    localStorage.setItem("dataPassed",""); //svuoto dato in localstorage
}
  


  @ViewChild('formRecharge') rechargeForm: NgForm;

  errorMessage: string = "";

  ngOnInit() {

this.urlRechargeType = "/rechargetype/rechargetypes"
this.serviceToCallPath = "/transaction/recharge/"
this.getRechargeType(); 
this.isVisiblePaymentKind = true;
  }



  getRechargeType(){
    this.listService.getListSelect(this.urlRechargeType).subscribe((response) => {
      this.rechargeTypeList = response.results;
      this.logger.log("Lista Ricariche", this.rechargeTypeList, 300);
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }


 
  viewChoseAmount(value){


    if(value == true){
      this. isVisiblePaymentKind = false;
    }
  else{
    this. isVisiblePaymentKind = true;
  }

  }



  Recharge(){
    var myself = this;
    swal2.fire({
      title: 'Ricarica wallet',
      text: "Sei sicuro di procedere alla ricarica?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, ricarica',
      cancelButtonText: 'No',
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        myself.confirmRecharge()
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })
  }

  back(){





    swal2.close()
    this.router.navigate(['/resales/list']);
  }



  confirmRecharge(){



this.rechargeData = this.rechargeForm.value

console.log(this.rechargeData)
this.rechargeData.idResale = this.idResale



    this.listService.newObject(this.rechargeData, this.serviceToCallPath).subscribe(
      (response) => {
  
          if (response.outcome.success === true) {
              swal2.fire("La ricarica è avvenuta con successo", "","success");
            //  this.router.navigate(['/wallet/recharge/list'])

          } else {
              this.errorMessage =  response.outcome.code +  response.outcome.description
              swal2.fire("Attenzione la ricarica non è stata effettuata", this.errorMessage,"warning");
          }
      },    
      (error) => {
          this.logger.log("Error",error,200);
          this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
          swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
      }
  );

  }




  ticketsDetails() {
    swal2.fire(
      'Dettaglio Biglietto!',
      '',
      'success'
    )
  }





  reset() {
  
    this.rechargeForm.reset();


  }


}

