import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import swal2 from 'sweetalert2'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-sales-recap-operator',
  templateUrl: './sales-recap-operator.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SalesRecapOperatorComponent implements OnInit {

  @ViewChild('formSalesRecapOperator') salesRecapOperatorForm: NgForm;
  @ViewChild('formSendSummary') sendSummaryForm: NgForm;

  visibleFilter;
  defaultValue;
  UpOrDown;

  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any;
  dataAdministrator: any[] = new Array();

  dataFilter: any[] = new Array();
  dataFilterAdmin: any[] = new Array();
  dataRequest: any;
  dataOperator: any[] = new Array();
  filterUserData: any[] = new Array();

  // PATH CHIAMATE
  urlDeletePath = '';
  urlOperatorPath = '/user/users?deleted=false'; // cerco utenti NON eliminati
  urlFarePath = '/ticket/fares';
  errorMessage = '';

  urlCompany = '/company/companies';
  companiesList: any[] = [];
  userAdmin: boolean;
  userCompanyAdmin: boolean;
  selectedCompany: string;

  isOp: boolean = false;
  isMob: boolean = false;

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    public utils: UtilsService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/sales/recap/operator', false, true, 1, 20);
    this.listService.restURL = '/dashboard/sellsummary';
    this.userAdmin = localStorage.getItem('typeUser') === 'SUPERADMIN';
    this.userCompanyAdmin = (localStorage.getItem('typeUser') === 'COMPANY' && localStorage.getItem('roleUser') === 'ADMINISTRATOR');
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  async ngOnInit() {
    this.isOp = this.utils.isOp();
    this.isMob = this.utils.isMob(this.utils.getRoleUser());
    this.UpOrDown = true;
    
    if (this.userAdmin) {
      this.companiesList = await this.utils.getCompanyList();
    } else {
      this.getOperators();
    }
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.salesRecapOperatorForm.value;

    if (this.salesRecapOperatorForm.value.fromDate) {
      this.dataFilter['fromDate'] = this.utils.addAndFormatTime(this.utils.convertDateToISO(this.salesRecapOperatorForm.value.fromDate),this.salesRecapOperatorForm.value.fromDateTime);
      this.dataFilter['fromDateTime'] = null;
    }

    if (this.salesRecapOperatorForm.value.toDate) {
      this.dataFilter['toDate'] = this.utils.addAndFormatTime(this.utils.convertDateToISO(this.salesRecapOperatorForm.value.toDate),this.salesRecapOperatorForm.value.toDateTime);
      this.dataFilter['toDateTime'] = null;
    }

    if ( this.utils.isSol1 || this.utils.isComp ) {
      this.dataFilter['idUser'] = this.salesRecapOperatorForm.value.idUser
    }

    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset() {
    this.salesRecapOperatorForm.reset();
    if (this.sendSummaryForm) {
      this.sendSummaryForm.reset();
    }
    this.listService.visibleFilter = false;
    // this.loadElements();
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.restURL = '/dashboard/sellsummary';
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response;
        // this.listService.totalRows = response.total;
        this.loadAdmin();
        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  // SERVIZIO LISTA ELEMENTI
  loadAdmin() {
    this.dataFilterAdmin['roleUser'] = 'ADMINISTRATOR'
    this.dataFilterAdmin['typeUser'] = 'COMPANY'
    this.listService.restURL = '/user/users';
    this.listService.getListFilter(this.dataFilterAdmin).subscribe(
      (response) => {
        this.dataAdministrator = response.results;
        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  /** Operators list is filtered by companyId, valorized when a company is selected and the connected user is a sa */
  getOperators() {
    const path = `${this.urlOperatorPath}&idCompany=${this.userAdmin
      ? (this.selectedCompany !== 'all' ? this.selectedCompany : '')
      : localStorage.getItem('idCompany')}`;
    this.listService.getListSelect(path).subscribe(
      response => {
        this.dataOperator = response.results;
        if (localStorage.getItem('typeUser') === 'RESALE') {
          this.filterUser('SELLER');
        } else {
          this.filterUser('OPERATOR');
        }
        this.logger.log('Lista operatori: ', this.dataOperator, 300);
      }
    );
  }

  filterUser(roleUser: string) { // filtro per operatori di company o di rivendita
    this.filterUserData = [];
    for (let u of this.dataOperator) {
      if (u.roleUser === roleUser) {
        this.filterUserData.push(u);
      }
    }
  }

  printSummary() {
    const path =
      '/dashboard/printsellsummary?fromDate=' + this.dataFilter['fromDate']
      + '&toDate=' + this.dataFilter['toDate']
      + ((this.userAdmin || this.userCompanyAdmin) ? '&idUser=' + this.dataFilter['idUser'] : '');
    swal2.showLoading();
    this.listService.getReprintDoc(path).subscribe(
      response => {
        if (response) {
          const blob = new Blob([response], { type: 'application/pdf' });
          const ieEDGE = navigator.userAgent.match(/Edge/g);
          const ie = navigator.userAgent.match(/.NET/g); //  IE 11+
          const oldIE = navigator.userAgent.match(/MSIE/g);

          console.log('COSA E?', ieEDGE );
          console.log(blob);

          if (ie || oldIE || ieEDGE) {
            console.log(' EDGE !');
            (window.navigator as any).msSaveOrOpenBlob(blob, 'myPDFdoc.pdf');
          } else {
          console.log(' NON E\' EDGE !')
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          }
          swal2.fire('Il Pdf è stato generato con successo', '', 'success');
        } else {
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico, si prega di riprovare.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
        this.filterReset();
      }
    );
  }

  sendSummary() {
    this.dataRequest = this.sendSummaryForm.value;
    this.dataRequest['emails'] = new Array(this.sendSummaryForm.value.emails);
    this.dataRequest['fromDate'] = this.dataFilter['fromDate'];
    this.dataRequest['toDate'] = this.dataFilter['toDate'];
    this.dataRequest['idUser'] = this.dataFilter['idUser'];
    if (this.userAdmin) {
      this.dataRequest['idCompany'] = this.selectedCompany;
    }

    swal2.showLoading();
    this.listService.newObject(this.dataRequest, '/dashboard/sendsellsummary').subscribe(
      (response) => {
        swal2.fire(
          'Riepilogo',
          'Il riepilogo è stato inviato con successo.',
          'success'
        );
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  checkTypePayment(type) {
    return this.utils.checkTypePayment(type);
  }
}
