<div class="row" style="padding-top: 20px"></div>
<div class="">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title col-md-12 col-sm-12">
                <h2 class="col-xs-12">
                    <strong>Linee - Corse</strong>
                </h2>
                
            </div>
            <div class="ibox-content">
                <div class="row">
                    <form #formLines="ngForm">

                        <div class="form-group col-md-4 col-sm-6">

                            <label for="idCompany"> Linee </label>
                            <select (change)="onChange($event.target.value)" type="text" id="routeId" class="form-control" ngModel name="routeId" routeId
                                #routeId="ngModel" required>
                                <option *ngFor="let o of dataLines" [value]="o.routeId">{{o.routeId}}, {{o.routeLongName}}</option>
                            </select>
        
        
                        </div>

                        <div class=' col-xs-12 col-sm-6 col-md-4'>
                            <label for="direction">Destinazione </label>
                            
                            <select type="text" id="direction" (change)="getDirection($event.target.value)" class="form-control" ngModel name="direction"
                                required direction #direction="ngModel">
                                <option *ngFor="let d of directionList" [ngValue]="d.directionId">{{d.arrival ? d.arrival : d.tripHeadsign }} </option>
                            </select>
                        </div>


                        <div  class='col-xs-12 col-sm-6 col-md-4'>
                            <div class="form-group">
                                <label>Data</label>
                                <div class='input-group col-xs-12'>
                                <input type="text"
                                    class="form-control"
                                    name="date"
                                    id="date"
                                    ngModel
                                    required
                                    bsDatepicker
                                    #bsDatepicker
                                    placement="bottom"
                                    autocomplete="off"
                                    (bsValueChange)="onDateChanged($event)"
                                    [bsConfig]="bsConfig" />
                                </div>
                            </div>
                        </div>
                     
                        <div  class='col-xs-12'>
                            <span >Per visualizzare gli orari delle corse, è necessario inserire tutti i dati. In rosso le corse cancellate e non visibili per la vendita.</span>
                        </div>

                        <div *ngIf="roleUser === 'SUPERADMIN'"  class='col-xs-12'>
                            <br />
                            <span>Solo le compagnie di trasporto possono annullare le corse</span>
                        </div>

                    </form>
                </div>

                <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
                    <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
                <div  style="margin-top: 15px" *ngIf="errorMessage === '' && tripList.length != 0" class="table-responsive">
                    <table class="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                            <tr>
                                <th>Corsa</th>
                                <th>Nome Corsa</th>
                                <th>Orario</th>
                                <th *ngIf="roleUser === 'Amministratore'" class="text-center">Azioni</th>
                                <th class="text-center">Occupazione</th>
                                <th class="text-center">Fermate</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="errorMessage === ''">
                            <tr *ngFor="let data of tripList" style="cursor:pointer" [ngClass]="{'text-danger': data.deleted == true}">
                                <td>{{data.tripId}}</td>
                                <td>{{routeLongName}}</td>
                                <td>{{data.departureTime}} - {{data.arrivalTime}}</td>
                                <td *ngIf="roleUser === 'Amministratore'" style="width: 80px" class="text-center">
                                   <ng-container *ngIf="!data.deleted">
                                       <button class="btn btn-danger btn-outline"
                                            title="Cancella"
                                            (click)="tripAction(data.tripId, 0)"> 
                                            <i class="fas fa-eraser"></i> 
                                        </button>
                                        <button class="btn btn-warning btn-outline"
                                            title="Modifica"
                                            [disabled]="sentEditMsgs.includes(data.tripId)"
                                            (click)="tripAction(data.tripId, 2)"> 
                                            <i class="fa fa-pencil"></i> 
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="data.deleted">
                                        <button *ngIf="data.deletedFromTripCancelled"
                                            class="btn btn-primary btn-outline"
                                            title="Ripristina"
                                            (click)="tripAction(data.tripId, 1)"> 
                                            <i class="fas fa-undo-alt"></i> 
                                        </button>
                                        <small *ngIf="!data.deletedFromTripCancelled"> Cancellato da {{data.deletedBy == null ? 'GTFS' : data.deletedBy}}</small>
                                    </ng-container>
                                </td>
                                <td style="width: 80px" class="text-center">
                                    <a data-toggle="modal" (click)="getTripPrediction(data.tripId, tripPredictionTemplate)" class="btn btn-success btn-outline"> <i class="fa fa-info" style="width: 1.3rem;"></i></a>
                                </td>
                                <td style="width: 80px" class="text-center">
                                    <a data-toggle="modal"  (click)="getStops(data.tripId, template)" class="btn btn-success btn-outline" > <i class="fa fa-search"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <ngb-pagination *ngIf="totalRow > 10" class="pull-right" [collectionSize]="totalRow"
                        [maxSize]="10" [pageSize]="10" [(page)]="page" (pageChange)="pageChange($event)">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- STOPS MODAL TEMPLATE -->
<ng-template #template>
    <div >
        <div class="">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="ibox-title" ><h2>Elenco fermate</h2></div>
                <div class="ibox-content" id="ibox-content">
                  <div id="vertical-timeline" class="vertical-container dark-timeline center-orientation">
                    <div *ngFor="let s of stopsDataList" class="vertical-timeline-block">
                      <div class="vertical-timeline-icon blue-bg"></div>
                      <div class="vertical-timeline-content">
                        <h2>{{s.stopName}}</h2>
                        <span class="vertical-date"><strong>{{s.departureTime}}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</ng-template>

<!-- TRIP PREDICTION TEMPLATE -->
<ng-template #tripPredictionTemplate>
    <div>
        <div class="">
          <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="ibox-title" ><h2>Prenotazioni corsa {{tripIdOnFocus}}</h2></div>
                    <div class="ibox-content mt-2" id="ibox-content">
                        <div class="table-responsive">
                            <table *ngIf="tripTypes.length; else noTrips" style="margin: 1rem 0;"
                                class="table table-striped table-bordered table-hover dataTables-example">
                                <thead>
                                    <tr>
                                        <th>Compagnia</th>
                                        <th>Posti</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let trip of tripTypes" style="cursor:pointer">
                                        <td>{{trip.code}}</td>
                                        <td>{{trip.tickets}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-template #noTrips>
                                <h4 class="text-center">Nessun biglietto acquistato per la corsa selezionata</h4>
                            </ng-template>
                        </div>
                    </div>
                    <div class="ibox-footer"
                        style="text-align: right; margin-top: 1rem; border: none; padding-bottom: 0;">
                        <button class="btn btn-default" type="button" (click)="closeModal()">Chiudi</button>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</ng-template>