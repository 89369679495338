import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { TabsModule } from 'ngx-bootstrap/tabs'

import { TicketsTypologyComponent } from './tickets-typology/tickets-typology.component';
import { TicketsTypologyManageComponent } from './tickets-typology-manage/tickets-typology-manage.component';

import { TicketsFaresComponent } from './tickets-fares/tickets-fares.component';
import { TicketsFaresManageComponent } from "./tickets-fares-manage/tickets-fares-manage.component";
import { TicketFaresUploaderComponent } from "./ticket-fares-uploader/ticket-fares-uploader.component";

import { TicketsListComponent } from './tickets-list/tickets-list.component';
import { TicketsListManageComponent } from './tickets-list-manage/tickets-list-manage.component';

import { TicketCategoryComponent } from './ticket-category/ticket-category.component';
import { TicketCategoryManageComponent } from './ticket-category-manage/ticket-category-manage.component';

// or
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TicketPromotionsManageComponent } from './ticket-promotions-manage/ticket-promotions-manage.component';
import { TicketPromotionsComponent } from './ticket-promotions/ticket-promotions.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { ComponentsModule } from '../../common/components/components.module';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    NgSelectModule,
    FileUploadModule,
    ComponentsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [TicketsTypologyComponent, TicketsFaresComponent, TicketsListComponent, TicketsTypologyManageComponent, TicketsFaresManageComponent , TicketsListManageComponent, TicketPromotionsManageComponent, TicketPromotionsComponent, TicketCategoryComponent, TicketFaresUploaderComponent, TicketCategoryManageComponent]
})
export class TicketsModule {}