<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2  class="col-md-2"><strong>Date Corse</strong></h2>
        <div class="ibox-tools col-md-9 pull-right">
        </div>
      </div>
      <div class="ibox-content">
        <div class="row">

          <form #formTimes="ngForm">
       
              <div class="form-group col-xs-6 col-md-2">
                  <label>Data</label>
                  <div class='input-group col-xs-12'>
                    <input type="text"
                      class="form-control"
                      name="date"
                      id="date"
                      ngModel
                      required
                      bsDatepicker
                      #bsDatepicker
                      placement="bottom"
                      autocomplete="off"
                      [bsConfig]="bsConfig" />
                  </div>
                </div>
    
              <div class=' col-sm-12 col-lg-2'>
                <div class="form-group">
                  <label>Service ID</label>
                  <div class='input-group col-md-12'>
                  <input
                    type="text"
                    id="serviceId"
                    name="serviceId"
                    class="form-control"
                    ngModel
              
                    serviceId
                    #serviceId= "ngModel">
                  </div>
                </div>
              </div>





              <div class='col-xs-6 col-sm-3 col-md-3'>
                  <div class="form-group">
                      <label></label>
                      <div class='input-group col-md-12'>
                          <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                          <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                      </div>
                  </div>
              </div>
            </form>
        </div>

        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example" >
            <thead>
              <tr>
                <th>Service Id</th>
                <th>Data</th>
                <th>Tipo Eccezione</th>
             
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList"  style="cursor:pointer">
                <td>{{data.serviceId}}</td>
                <td>{{data.date | date:'dd-MM-yyyy'}}</td>
                <td>{{data.exceptionType}}</td>
              </tr>
            </tbody>         
          </table>
          <ngb-pagination 
            *ngIf="listService.totalRows > listService.rows"
            class="pull-right" 
            [collectionSize]="listService.totalRows"
            [maxSize]="10"
            [pageSize]="listService.rows" 
            [(page)]="listService.page"
            (pageChange)="pageChange($event)" >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
