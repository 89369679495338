import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-scroll-to-bottom',
  templateUrl: './scroll-to-bottom.component.html',
  styleUrls: ['./scroll-to-bottom.component.css']
})
export class ScrollToBottomComponent {
  bottom: boolean = false;
  scrollingOnBottom: boolean = false;

  @HostListener('window:scroll')
  handleScroll(): void {
    this.scrollingOnBottom = this.checkScrollingOnBottom();
  }

  constructor() { }

  scrollingAction(): void {
    window.scrollTo({
      top: this.checkScrollingOnBottom() ? 0 : document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });

    this.bottom = !this.bottom;
  }

  /** Method that checks whether the page is scrolled on the bottom */
  checkScrollingOnBottom(): boolean {
    return ((window.innerHeight + (window.scrollY + 500)) >= document.body.scrollHeight) ? true : false; 
  }

}
