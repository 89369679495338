import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ListService, UploadType } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['../../../app.component.css']
})
export class FilesComponent implements OnInit {
  UploadType = UploadType;

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  reloadList = false;

  appInfoLoaded = false;
  appInfo = {
    version: '',
    enabled: false,
    forced: false
  }

  constructor(
    private listService: ListService,
    private logger: LoggingService,
    private toaster: ToastrService
  ) {
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
  }

  ngOnInit(): void {
    this.loadAppVersion();
  }

  /** Mehod that reloads the list of file entries after an upload process is completed */
  reloadFilesList(): void {
    this.reloadList = true;
    setTimeout(() => {
      this.reloadList = false;
    }, 200);
  }

  // ---------- PRIVATE METHODS ---------- //

  loadAppVersion(): void {
    this.listService.getControllerAppInfo().subscribe(
      (response) => {
        const { version, enabled, forced } = response;
        this.appInfo = { version, enabled, forced };
        this.appInfoLoaded = true;
      },
      (error) => {
        this.logger.log("Error", error,200);
        this.toaster.show('Impossibile ottenere informaizoni sull\'app', 'Errore');
      }
    );
  }

}
