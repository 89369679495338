import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { CookieService } from 'ngx-cookie-service';
import { UUID } from 'angular2-uuid';
import { UtilsService } from '../../../services/utils.service';
import swal2, { SweetAlertResult } from 'sweetalert2'

@Component({
  selector: 'app-printers-list',
  templateUrl: './printers-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class PrintersListComponent implements OnInit {

  @ViewChild('formPrinter') printerForm: NgForm;
  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  modelsList: any[] = new Array();
  resalesList: any[] = new Array();
  filterResaleList: any[] = new Array();
  desksList: any[] = new Array();
  filterDesksList: any[] = new Array();
  companiesList: any[] = new Array();

  // PATH CHIAMATE
  urlModelsPath = '/printer/models';
  urlResalesPath = '/resale/resales';
  urlDesksPath = '/desk/desks';
  urlCompany = '/company/companies';
  urlDeletePath;
  urlAssignPrintPath = '/printer/assign';
  errorMessage = '';
  machineUUID;
  companyVisible;
  activeStatus;

  visibleFilter;
  defaultValue;
  UpOrDown;
  typeUser;
  isSoluzione1;
  isResale;
  isOperator;
  classStar;
  tooltipStar;
  styleStarClass;

  visibleCreation;





  // VARIABILI PER SELECT


  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private cookieService: CookieService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/printers/list', false, true, 1, 10);
    this.listService.restURL = '/printer/printers';

    this.typeUser = this.utils.getTypeUser();

    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.isResale = this.utils.isRes(this.typeUser);
    this.isOperator = this.utils.isOp();

    if (this.isResale) {
      this.visibleCreation = false
    } else {
      this.visibleCreation = true
    }
  }

  async ngOnInit() {
    this.activeStatus = 'btn btn-danger btn-circle';
    // CHIAMATE PER SELECT
    this.getResales();
    this.getDesks();

    // CHIAMATA LISTA ELEMENTI
    this.loadElements();

    // CREA ID UNIVOCO MACCHINA UTILIZZATA
    this.detectUUID();

    this.companiesList = await this.utils.getCompanyList();

    // CHECK SOLUZIONE1
   this.companyVisible = this.utils.visibleCompanyAndSol(this.companyVisible);
   this.UpOrDown = true;
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;

      this.logger.log('VIEE', this.UpOrDown, 300)
    } else {
      this.UpOrDown = true;
    }
  }

  getBusinessName(id) {
    let string: string;
    for (let company of this.companiesList) {
      if (company.idCompany === id) {
        return string = company.legalBusinessName
      }
    }
  }


  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === 1) {
   return   buttonColor =  'Attivo'
    }
    if (statusNumber === 0) {
      return   buttonColor =  'Non Attivo'
       }

       if (statusNumber === 'PAUSED') {
        return   buttonColor =  'In Pausa'
         }

         if (statusNumber === 'READY') {
          return   buttonColor =  'Attiva'
           }
  }

  getButtonCircle(statusNumber) {
    let buttonColor;
    if (statusNumber === 1) {
   return   buttonColor =  'btn btn-primary btn-circle '
    }
    if (statusNumber === 0) {
      return   buttonColor =  'btn btn-danger btn-circle'
       }

       if (statusNumber === 'PAUSED') {
        return   buttonColor =  'btn btn-warning btn-circle'
         }


         if (statusNumber === 'READY') {
          return   buttonColor =  'btn btn-primary btn-circle '
           }
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.printerForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset() {
    this.printerForm.value.idCompany = ''
    this.printerForm.value.serialNumber = ''
    this.printerForm.value.model = ''
    this.printerForm.value.status = ''
    this.printerForm.value.idResale = ''
    this.printerForm.value.idDesk = ''
    this.listService.visibleFilter = false;
    this.loadElements();
    this.printerForm.reset();
  }

  // SERVIZI SELECT
  getResales() {
    this.listService.getListSelect(this.urlResalesPath).subscribe((response) => {
      this.resalesList = response.results;

      if (this.isSoluzione1 === false) {
        this.filterResaleList = response.results;
      }
      this.logger.log('Lista rivenditori', this.resalesList, 300);
      // this.logger.log('LISTA commesse: ',response.results);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getDesks() {
    this.listService.getListSelect(this.urlDesksPath).subscribe((response) => {
      this.desksList = response.results;

      if (this.isSoluzione1 === false) {
        this.filterDesksList = response.results;
      }
      this.logger.log('Lista desk', this.desksList, 300);
      // this.logger.log('LISTA commesse: ',response.results);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  classStars(pcUUID) {
    if (this.machineUUID === pcUUID) {
     return this.classStar = 'fa fa-star fa-2x'
    } else {
   return   this.classStar = 'fa fa-star-o fa-2x'
    }

  }

  tooltip(pcUUID) {
    if (this.machineUUID === pcUUID) {
     return this.tooltipStar = 'Rimuovi preferita'
    } else {
   return   this.tooltipStar = 'Assegna preferita'
    }

  }

  styleStar(pcUUID) {   this.logger.log ( ' ENTRA QUI  ', this.machineUUID + ' - ' + pcUUID, 300)
    if (this.machineUUID === pcUUID) {
      this.logger.log ( ' ENTRA QUI  ', this.machineUUID + ' - ' + pcUUID, 300)
      return this.styleStarClass = 'cursor:pointer; color:orange;'
     } else {
    return   this.styleStarClass = 'cursor:pointer;'
     }

  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    if (this.isOperator && this.utils.getSelectedDesk() !== null) {
      this.dataFilter['idDesk'] = this.utils.getSelectedDesk();
    }
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  selectRow(dataElement) {
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/printers/edit']);
  }

  checkStatus(status) {
    return this.utils.checkStatus(status);
  }


  changeCompany(idCompany) {
    this.filterResaleList = []
    const stringTest = idCompany.split(':')[1];
    const stringId = stringTest.substring(1);
    for ( let r of this.resalesList) {
      if (r.idCompany === stringId) {
        this.filterResaleList.push(r)
      }
    }
  }




assignOrUnAssign(dataElement) {
  const myself = this
  const data = dataElement
  this.logger.log('DATO UUID', data.pcUUID, 300)
  this.logger.log('DATa', data, 300)


  if (dataElement.pcUUID === null) {

    this.logger.log('ENTRA', null, 300)

    let dataPassed = {};
    if (this.isResale) {
      dataPassed = {  idResale: dataElement.idResale, id: dataElement.id, pcUUID: this.machineUUID};
    } else if (this.isOperator) {
      dataPassed = {  idDesk: dataElement.idDesk, id: dataElement.id, pcUUID: this.machineUUID};
    }

    this.listService.edit(dataPassed, this.urlAssignPrintPath).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
         this.loadElements();
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  } else {
    let dataPassed = {  idResale: dataElement.idResale, id: dataElement.id, pcUUID: null};

    this.listService.edit(dataPassed, this.urlAssignPrintPath).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
        this.loadElements();
      }, (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }
  }
  newPrinter() {
    this.listService.backToList = true;
    this.router.navigate(['/printers/new']);
  }




getCompanyInternalUse(companyInternalUse) {

  let val;
  if (companyInternalUse === true) {
    val = 'Sì'
  } else {
    val = 'No'
  }

  return val;
}

  deletePrinter(idPrinter: number) {
    let mySelf = this;

    swal2.fire({
      title: 'Eliminazione stampante',
      text: 'Sei sicuro di voler eliminare la stampante?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        const urlDeletePath =  '/printer/' + idPrinter;
        mySelf.utils.deleteElement(urlDeletePath).then(_ => {
          swal2.fire('Eliminazione completata', 'Stampante eliminata con successo', 'success').then((ev: SweetAlertResult) => {
            if (ev.value) {
              mySelf.loadElements();
            }
          });
        }).catch(err => {
          mySelf.errorMessage = err;
          swal2.fire('Attenzione', mySelf.errorMessage, 'warning');
        });
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  detectUUID() {
    if (this.cookieService.get('BU-UUID') === undefined) {
      this.logger.log('Genero UUID macchina', null, 300);
      let uuid = UUID.UUID();
      this.cookieService.set('BU-UUID', uuid);
    } else {
      this.logger.log('UUID macchina =>', this.cookieService.get('BU-UUID'), 300);
    }
    this.machineUUID = this.cookieService.get('BU-UUID');
  }


  getResaleName(idResale) {
    let stringResaleName;
    for (let resaleModel of this.resalesList) {
      if (resaleModel.id === idResale) {
        stringResaleName = resaleModel.legalBusinessName;
      }
    }
    return stringResaleName;
  }

  getDeskName(idDesk) {
    let stringDeskName;
    for (let deskModel of this.desksList) {
      if (deskModel.id === idDesk) {
        stringDeskName = deskModel.name;
        break;
      }
    }
    return stringDeskName;
  }

}

