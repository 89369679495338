import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";

import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from './logout/logout.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { ActivateComponent } from './activate/activate.component';
import { NgxPasswordStrengthBarModule } from 'ngx-password-strength-bar';
import { SelectDeskComponent } from './select-desk/select-desk.component';


@NgModule({
    declarations: [LoginComponent, LogoutComponent, ForgotPwdComponent, ChangePwdComponent, ActivateComponent, SelectDeskComponent],
    imports     : [BrowserModule, NgxPasswordStrengthBarModule, FormsModule],
})

export class AuthModule {}