import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';

import swal2, { SweetAlertResult } from 'sweetalert2';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-statistic-passengers',
  templateUrl: './statistic-passengers.component.html',
  styleUrls: ['../../../app.component.css']

})
export class StatisticPassengersComponent implements OnInit {
  @ViewChild('formResale') resaleForm: NgForm;

  errorMessage = '';

  dataFilter: any[] = new Array();
  visileNewResale = false;
  typeUser;
  companiesList: any[] = new Array();
  urlCompany = '/company/companies';
  urlLine = '/route/routes'
  lineList: any[] = new Array();


  serviceToEnablePdf = '/ticket/pdfenable';
  urlDeletePdfPath = '/ticket/pdfenable/'

  userID = 0;
  urlDeletePath;

  serviceToCallPath;

  visibleFilter;
  defaultValue;

  isSoluzione1;

  UpOrDown;

  year;
  startingYear = 2018;
  yearsList: number[];

  urlCsvPassengerPath = '/stats/passengers/csv'

  dataResale: any[] = new Array();

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private loadingService: LoadingService
  ) {
    this.listService.restURL = '/stats/passengers';
    this.listService.configurationServiceCall(
      '/resales/list',
      false,
      true,
      1,
      10
    );
  }

  async ngOnInit() {
    this.year = new Year()

    const date = new Date()
     this.year.year = date.getFullYear()
      console.log('ANNO', this.year.year);

    this.yearsList = this.utils.createAvailableYearsList(this.year.year, this.startingYear);

    this.serviceToCallPath = '/resale';
    this.UpOrDown = true;
    this.typeUser = this.utils.getTypeUser();
    this.visileNewResale = this.utils.newResaleManager(this.typeUser);
    this.companiesList = await this.utils.getCompanyList();
    this.getLineList();

    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;

      console.log('VIEE', this.UpOrDown);
    } else {
      this.UpOrDown = true;
    }
  }

  eligibiltyPrintPDF(idPrinter) {
    const mySelf = this;

    swal2.fire({
      title: 'Abilitazione stampa PDF',
      text: 'Procedere?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      function(ev: SweetAlertResult) {
        if (ev.value) {
          //   mySelf.deleteElement(idPrinter);
        }
      },
      function(dismiss) {
        if (dismiss === 'cancel') {
          swal2.close();
        }
      }
    );
  }

  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === 1) {
      return (buttonColor = 'Attivo');
    }
    if (statusNumber === 0) {
      return (buttonColor = 'Non Attivo');
    }
  }

  getButtonCircle(statusNumber) {
    let buttonColor;
    if (statusNumber === 1) {
      return (buttonColor = 'btn btn-primary btn-circle');
    }
    if (statusNumber === 0) {
      return (buttonColor = 'btn btn-danger btn-circle');
    }
  }

  getBusinessName(id) {
    let stringName;
    for (let company of this.companiesList) {
      if (company.idCompany === id) {
        return (stringName = company.legalBusinessName);
      }
    }
  }

  getClassEnablePdf(pdf) {
    let value;

    if (pdf !== null) {

      value = 'btn btn-primary'
    } else {
      value = 'btn btn-danger'
    }

    return value
  }

  getClassIcon(pdf) {
    let value;
    if (pdf !== null) {
      value = 'fa fa-check';
    } else {
      value = 'fa fa-square';
    }

    return value;
  }

  getStyle() {
    let value;
    if (this.isSoluzione1) {
      value = 'min-width: 150px';
    } else {
      value = 'min-width: 100px';
    }

    return value;
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements(false);
  }

  filterSearch() {
    this.dataFilter = this.resaleForm.value;
    this.listService.visibleFilter = true;
    this.loadElements(false);
  }

  filterReset() {
    this.resaleForm.value.legalBusinessName = '';
    this.resaleForm.value.year = ''
    this.resaleForm.value.routeId = ''
    this.resaleForm.value.legalCity = '';
    this.resaleForm.value.status = '';
    this.resaleForm.value.internal = '';
    this.listService.visibleFilter = false;
    this.loadElements(false);
    this.resaleForm.reset();
    // this.loadElements();
  }

  loadElements(first) {
    this.loadingService.presentLoader();
    if (first === true) {
      this.dataFilter['year']  = this.year.year
    }
    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.dataResale = response.results;
        this.listService.totalRows = response.total;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    ).add(() => { this.loadingService.hideLoader(); });
  }

  checkKind(type) {
    let string = ''
    if (type === true) {
      string = 'Interna'
    } else {
      string = 'Esterna'
    }
    return string;
  }

  getLineList() {
    this.listService.getListSelect(this.urlLine).subscribe((response) => {
      this.lineList = response.results;
      this.logger.log('Lista rivenditori', this.lineList, 300);
      // console.log('LISTA commesse: ',response.results);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getSum(list): number {
    let sum = 0;
    for (let i = 0; i < list.length; i++) {
      sum = sum + list[i]
    }
    return sum;
  }

  getCsv() {
    let finalQueryString;

    const queryString = this.utils.getQueryWithFilters(this.resaleForm.value)
    if (queryString === '' || queryString === null) {
      console.log('entra qui string vuota');
      finalQueryString = this.urlCsvPassengerPath;
    } else {
      finalQueryString = this.urlCsvPassengerPath + '?' + queryString;
    }

    this.listService.getCsv(finalQueryString).subscribe(
      response => {
        if (response) {
          const blob = new Blob([response], { type: 'text/csv' });
          const ieEDGE = navigator.userAgent.match(/Edge/g);
          const ie = navigator.userAgent.match(/.NET/g); // IE 11+
          const oldIE = navigator.userAgent.match(/MSIE/g);

          let fileName = ''
          const currentDate = new Date()
          const day = currentDate.getDate()
          const month = currentDate.getMonth() + 1
          const year = currentDate.getFullYear()
          fileName = day + '/' + month + '/' + year + '_statistics_passengers.csv';

          if (ie || oldIE || ieEDGE) {
            console.log(' EDGE !');
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          } else {
            console.log(' NON E\' EDGE !');
            // const url= window.URL.createObjectURL(blob);
            // window.open(url, 'test.csv');
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          swal2.fire('Il csv è stato scaricato', '', 'success');
        } else {
          // response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }
}


export class Year {
  year;

  constructor() {}
}
