<form #formFindTrip="ngForm">
  <div class="row" style="padding-top: 20px"></div>
  <div class="col-md-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Vendita Biglietti</strong>
        </h2>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-xs-12">
            <div class="col-sm-4">
              <label><i class="fa fa-ticket fa-2x"></i> TIPOLOGIA</label>
              <p *ngFor="let type of ticketTypes" class="h4" (click)="setKindSell(type.id)" style="cursor: pointer" [ngStyle]="{'font-weight': kindSell == type.id  ? '900' : '50'}">{{type.value}} </p>
            </div>
            <div class="col-sm-4">
              <label><i class="fa fa-code-fork fa-2x"></i> TRAGITTO</label>
              <p *ngFor="let round of roundTripTypes"  class="h4" (click)="isRoundTripMethod($event, round.id)" style="cursor: pointer" [ngStyle]="{'font-weight': kindRound == round.id  ? '900' : '50'}">{{round.value}} </p>
            </div>
            <div class="col-sm-4">
              <p *ngIf="channel == '3'" class="col-xs-8 text-right" style="font-weight:900 ; font-size: 30px; float:right;"   [ngStyle]="{'color': avaiableCredit  >= 0  ? 'green' : 'red'}"  >Credito residuo: {{avaiableTrust + avaiableCredit | number : '1.2-2'}}   <i class="fas fa-euro-sign"></i> </p>
            </div>
            <hr style="clear:both;" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            
            <div class="col-xs-12 ">
              <h1 class="text-center mt-2" style="font-weight: 800">Imposta i dati di viaggio</h1>
            </div>
            <hr style="clear:both;" />

            <div class="col-xs-12 col-md-3">
              <div class="form-group">
                <label><i class="fas fa-calendar fa-2x"></i> QUANDO </label>
                <input *ngIf="isRoundTrip == true && tripParametersList == true " class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="daterangepickerModel"
                  ngModel bsDaterangepicker #bsDaterangepicker placement="right" [bsConfig]="bsConfig" [minDate]="minDate"
                  placement="bottom" [maxDate]="maxDate" autocomplete="off">
                <input *ngIf="isRoundTrip == false && tripParametersList == true" class="form-control" id="bsDatepicker" name="bsDatepicker" [(ngModel)]="datepickerModel"
                  ngModel bsDatepicker #bsDatepicker placement="right" [bsConfig]="bsConfig" [minDate]="minDate" placement="bottom"
                  [maxDate]="maxDate" autocomplete="off">
                <p *ngIf="tripParametersList == false ">{{tripParametersDataName.dateA | date:'dd/MM/yyyy' }}   {{tripParametersDataName.dateR | date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
            <ng-container *ngIf="ticketDataTypes[0].id === ticketData.fullId">
              <div class="col-md-3">
                <div class="form-group">
                  <label>
                    <i class="fas fa-map-marker-alt fa-2x"></i> PARTENZA </label>
                  <select *ngIf="tripParametersList == true " type="text" id="origin" class="form-control" ngModel name="origin" origin #origin="ngModel" (change)="onChangeStop()"
                    required>
                    <option [ngValue]="">--</option>
                    <option *ngFor="let stop of originStops" [ngValue]="stop">{{stop.stopName}}</option>
                  </select>
                  <p *ngIf="tripParametersList == false ">{{tripParametersDataName.origin}}</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="ticketDataTypes[0].id === ticketData.fullId">
              <div class="col-md-3">
                <div class="form-group">
                  <label>
                    <i class="fas fa-map-marker-alt fa-2x"></i> ARRIVO </label>
                  <select *ngIf="tripParametersList == true " type="text" id="destination" class="form-control" ngModel name="destination" destination #destination="ngModel" (change)="onChangeDestinationStop()" required>
                    <option [ngValue]="">--</option>
                    <option *ngFor="let stop of destinationStops" [ngValue]="stop">{{stop.stopName}}</option>
                  </select>
                  <p *ngIf="tripParametersList == false ">{{tripParametersDataName.destination}}</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="ticketDataTypes[0].id === ticketData.fullId">
              <div class="col-md-3">
                <div class="form-group">
                  <label><i class="fas fa-clock-o fa-2x"></i> CORSA </label>
                  <select *ngIf="tripParametersList == true " type="text" id="trip" class="form-control" ngModel name="trip" trip #trip="ngModel" (change)="onChangeTrip($event.target.value, 0)" required>
                    <option value="">--</option>
                    <option *ngFor="let trip of tripsAndata" [ngValue]="trip">{{trip.arrivalTimeOrigin}} - {{trip.arrivalTimeDestination}} ({{trip.tripId}})</option>
                  </select>
                  <p *ngIf="tripParametersList == false ">{{tripParametersDataName.arrivalTimeOrigin}} - {{tripParametersDataName.arrivalTimeDestination}}</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="ticketDataTypes[0].id === ticketData.fullId && isRoundTrip == true">
              <div class="col-md-3">
                <div class="form-group">
                  <label><i class="fas fa-clock-o fa-2x"></i> CORSA RITORNO</label>
                  <select *ngIf="tripParametersList == true " type="text" id="tripR" class="form-control" ngModel name="tripR" tripR #tripR="ngModel" (change)="onChangeTripR($event.target.value,0)" required>
                    <option value="">--</option>
                    <option *ngFor="let trip of tripsRitorno" [ngValue]="trip">{{trip.arrivalTimeOrigin}} - {{trip.arrivalTimeDestination}} ({{trip.tripId}})</option>
                  </select>
                  <p *ngIf="tripParametersList == false ">{{tripParametersDataName.arrivalTimeOriginR}} - {{tripParametersDataName.arrivalTimeDestinationR}}</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="ticketDataTypes[0].id === ticketData.fullId"><div class="clearfix"></div></ng-container>
            <div  *ngIf="tripParametersList == true " class="col-xs-12 text-center ">
              <button (click)="saveTripParametersData()" class="btn btn-info btn-block btn-lg"><i class="fas fa-floppy-o fa-1x"></i> Imposta dati viaggio </button>
            </div>
          </div>
        </div>

        <div *ngIf="searchTripList" class="row">
          <div class="col-xs-12">
            <div class="col-xs-12 text-center">
              <button (click)="backToChoseTripList()" class="btn btn-default btn-block"><i class="fas fa-angle-up"></i> Modifica i parametri corsa <i class="fas fa-angle-up"></i></button>
              <br>
            </div>
          </div>
          
          <div class="clearfix"></div>

          <!-- CUSTOMER INFO MANAGEMENT (ONLY WHEN COMPANY IS CONFIGURED AS SALE_CUSTOMER) -->
          <ng-container class="customer-info" *ngIf="this.saleModes && this.saleModes[0]?.id === saleMode.customerId">
            <app-customer-info-manager [userInfoMode]="userInfoMode" [findTripForm]="formFindTrip" (onClear)="clearCategoriesSelection()" (onConfirm)="getCategories($event)"></app-customer-info-manager>
          </ng-container>

          <ng-container class="trip-info" *ngIf="this.saleModes[0].id === saleMode.standardId || (this.saleModes[0].id === saleMode.customerId && groupCategories)">
            <div *ngIf="tripsAndata != undefined || tripsRitorno != undefined">
              <h1 class="text-center mt-2" style="font-weight: 800">Seleziona passeggeri e promozioni</h1>
            </div>
  
            <div class="col-sm-12">
              <hr style="clear:both;" />
              <div class="col-sm-2 form-group">
                <label>
                  <i class="fas fa-map-marker-alt fa-2x"></i> CATEGORIA
                </label>
                <select type="text" id="groupCategoryId" class="form-control" ngModel name="groupCategoryId" groupCategoryId #groupCategoryId="ngModel" (change)="onChangeGroupCategory($event.target.value)"
                  required [ngModel]="storedGroupCategoryId">
                  <option value="">Scegli</option>
                  <option *ngFor="let cat of groupCategories; let i = index" value="{{i}}">{{cat.name}}</option>
                </select>
              </div>
              <div class="col-sm-4 form-group">
                <label> <i class="fas fa-users fa-2x"></i> PASSEGGERI </label>
                <div id="dropdown-div" class="form-group form-group col-xs-12 col-sm-12" style="padding-left: 0px; padding-right: 0px" dropdown
                  #dropdown="bs-dropdown" [autoClose]="false">
  
                  <input dropdownToggle type="text" readonly style="background-color: white" id="name" class="form-control" ngModel name="name"
                    required #name="ngModel" [(ngModel)]="inputTextPerson">
                  <ul id="dropdown-triggers-manual" style="background-color: white" *dropdownMenu class="dropdown-menu col-sm-12 col-xs-12"
                    role="menu" aria-labelledby="button-triggers-manual">
                    <ng-container *ngFor="let cat of categories; let i = index">
                      <li role="menuitem">
                        <a class="dropdown-item">
                          <div class="row">
                            <div class="col-sm-6 col-xs-6">
                              <strong>{{cat.name}}</strong>
                              <br>
                              <span>{{cat.description}}</span>
                            </div>
                            <div class="col-sm-6 col-xs-6">
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button type="button" class="btn btn-default btn-number" data-type="minus" value="counter_{{i}}" data-field="counter" (click)="minusValueInput(i)">
                                    <span class="fa fa-minus"></span>
                                  </button>
                                </span>
                                <input type="text" name="counter_{{i}}" class="form-control input-number select-input" value="{{passengers[i]['total']}}" min="0" max="10"
                                  (change)="saveValueInput(i,$event)">
                                <span class="input-group-btn">
                                  <button type="button" style="margin-left:0px;" class="btn btn-default btn-number" data-type="plus" value="counter_{{i}}"
                                    data-field="counter " (click)="plusValueInput(i)">
                                    <span class="fa fa-plus"></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li class="divider dropdown-divider"></li>
                    </ng-container>
                    <li role="menuitem" class="text-center">
                      <button type="button" (click)="preventClose($event)" class="btn btn-secondary btn-block text-center">Chiudi</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-3">
                <div  *ngIf="searchTripList" class="form-group ">
                  <label> <i class="fas fa-bullhorn fa-2x"></i> Promozioni opzionali </label>
                  <div class='input-group col-md-12'>
                      <select type="text" [ngModel]="idPromozioneApplicata" id="idPromozione" class="form-control" ngModel name="idPromozione" required idPromozione
                          #idPromozione="ngModel" (change)="getPromozione($event.target.value)" >
                          <option [value]="0"> Nessuna promozione </option>
                          <option *ngFor="let promoOptional of arrayPromotionOptional" [value]="promoOptional.idPromotion">{{promoOptional.promotionOptionDescription}}</option>
                      </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div  *ngIf="searchTripList" class="form-group ">
                  <label> <i class="fas fa-bullhorn fa-2x"></i> Promozioni condizionali </label>
                  <div class='input-group col-md-12'>
                      <select type="text" [ngModel]="idPromozioneCondizionale" id="idPromozioneCondizionale" class="form-control" ngModel name="idPromozioneCondizionale" required idPromozioneCondizionale
                          #idPromozioneCondizionale="ngModel" (change)="getPromozioneCondizionale($event.target.value)" >
                          <option [value]="'0'"> Nessuna promozione </option>
                          <option *ngFor="let promoConditional of arrayPromotionConditional" [value]="promoConditional.tag">{{promoConditional.tag}}</option>
                      </select>
                  </div>
                </div>
              </div>

              <!-- Confirm button section -->
              <div class="row">
                <div class="col-xs-12">
                  <ng-container>
                    <div *ngIf="searchTripList && !isRoundTrip"  class="col-xs-12">
                      <button (click)="putIntoCart()" [disabled]="!(this.saleModes[0].id === saleMode.customerId) && (tripsAndata != undefined && (tripParametersData['tripIdA'] == -1 || tripParametersData['tripIdA'] == null))" class="btn btn-info btn-block btn-lg">
                        <i class="fas fa-calculator fa-1x"></i> Calcola
                      </button>
                    </div>
                    <div *ngIf="searchTripList && isRoundTrip"  class="col-xs-12">
                      <button (click)="putIntoCart()" [disabled]="!(this.saleModes[0].id === saleMode.customerId) && (tripsAndata != undefined && (tripParametersData['tripIdA'] == -1 || tripParametersData['tripIdA'] == null || tripParametersData['tripIdR'] == -1 || tripParametersData['tripIdR'] == null))" class="btn btn-info btn-block btn-lg">
                        <i class="fas fa-calculator fa-1x"></i> Calcola
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="cart.length > 0" class="row">
          <br>
          <br>
          <div class="col-xs-12">
            <h1 class="text-center" style="font-weight: 800">Riepilogo biglietti</h1>
          </div>
          <hr style="clear:both;" />
        </div>

        <div *ngIf="cart.length > 0" class="row">
          <div class="col-xs-12">
            <p *ngIf="isCorrectCart == false" class="bg-info p-sm"><i class="fa fa-lg fa-info" aria-hidden="true"></i> Una o più tariffe non disponibili per la selezione scelta</p>
          </div>
          <div class="col-xs-1"></div>
          <div class="col-xs-11">
            <ng-container *ngIf ="isCorrectCart == true">
              <div *ngFor="let i of cart" class="row">
                <div class="col-xs-2">
                  <i class="fas fa-ticket-alt fa-7x"></i>
                </div>

                <div class="col-xs-6">
                  <ng-container *ngIf="i.origin != undefined && i.destination != undefined">
                    <h2><i class="fas fa-map-marker-alt "></i> {{i.origin}} </h2>
                    <h2><i style="margin-left: 4px" class="fas fa-ellipsis-v"></i></h2>
                    <h2><i class="fas fa-map-marker-alt  fa-rotate-180"></i> {{i.destination}} </h2>
                  </ng-container>
                  <span *ngIf="i.dateA != null" style="font-size: 20px; font-weight: 900"> {{i.dateA | date:'dd/MM/yyyy'}}</span>
                  <span *ngIf="i.dateR != null && kindRound=='ROUNDTRIPOPEN'" style="font-size: 20px; font-weight: 900">Data aperta</span>
                  <span *ngIf="i.dateR != null && kindRound=='ROUNDTRIP'" style="font-size: 20px; font-weight: 900">{{i.dateR | date:'dd/MM/yyyy'}}</span>
                  
                  <br>
                  <div *ngIf="i.promotion.amount !== 0 && i.fare.amount > i.promotion.amount; else  descriptionElse">
                    <h4 *ngFor="let f of i.promotion.passengerList">{{f.total}}x {{f.descriptionPromotion ? f.descriptionPromotion : f.categoryDescription}} </h4>
                  </div>
                  <ng-template #descriptionElse>
                      <h4 *ngFor="let f of i.fare.passengerList">{{f.total}}x {{f.categoryDescription}} </h4>
                  </ng-template>
                  <br>
                </div>

                <div class="col-xs-2">
                  <br>
                  <br>
                  <div *ngIf="i.promotion.amount !== 0 && i.fare.amount > i.promotion.amount; else  contentElse">
                    <h1 style="font-weight: 900" class="cancelNum">{{formFindTrip.value.supplement ? i.fare.totalAmount : i.fare.amount}}
                      <i class="fas fa-euro-sign"></i>
                    </h1>
                    <h1 style="font-weight: 900">{{formFindTrip.value.supplement ? i.promotion.totalAmount : i.promotion.amount}}
                        <i class="fas fa-euro-sign"></i>
                    </h1>
                  </div>
                  <ng-template #contentElse>
                    <h1 style="font-weight: 900">{{total / cart.length}}
                      <i class="fas fa-euro-sign"></i>
                    </h1>
                  </ng-template>
                </div>

                <div class="col-xs-2">
                  <br>
                  <br>
                  <button class="btn btn-danger" (click)="removeFromCart(i)"><i class="fas fa-cart-arrow-down"></i>  Rimuovi</button>
                </div>

                <hr style="clear:both;" />
              </div>
            </ng-container>
          </div>

          <hr style="clear:both;" />

          <div class="row">
            <div class="col-xs-1"></div>
            <div class="col-xs-11">
              <div class="checkbox">
                <div class="input-container">
                  <input type="checkbox" name="supplement"
                    [checked]="formFindTrip.value.supplement"
                    (change)="onSupplementChange()"
                    [(ngModel)]="formFindTrip.value.supplement">
                  <label>Includi supplemento del canale di vendita</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-1"></div>
            <div class="col-xs-9">
              <h1 style="font-weight: 900">TOTALE:</h1>
            </div>
            <div class="col-xs-2" style="padding-right: 0px">
              <h1 style="font-weight: 900; font-size: 40px">{{total}}
                <i class="fas fa-euro-sign"></i>
              </h1>
            </div>
            <hr style="clear:both;" />
          </div>
        </div>

        <div class="clearfix"></div>

        <div *ngIf="cart.length > 0" class="row">
          <div class="col-xs-12">
            <div class="col-xs-4" style="margin-bottom: 15px;">
              <label><i class="fas fa-print"></i> Stampante</label>
              <select (change)="getSerialNumber($event.target.value)" type="text" id="idPrinter" class="form-control" ngModel name="idPrinter"
                [ngModel]="defaultIdPrinter" required idPrinter #idPrinter="ngModel">
                <option *ngIf="isPdfEnabled" [ngValue]="0"> Stampa come pdf </option>
                <option *ngFor="let s of printerList" [ngValue]="s.id">{{s.name}}</option>
              </select>
            </div>
            
            <div class="col-xs-12 text-right">
              <button (click)="initializeSoldTicket()"  [disabled]="!idPrinter.valid || isCorrectCart == false" class="btn btn-info btn-block btn-lg">
                <i class="fas fa-money"></i> Vendi</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>