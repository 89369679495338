<div class="ibox-content" style="border-top: 0 !important;">
    <div class="table-responsive">
        <ng-container *ngIf="dataList.length > 0">
            <h3>Storico file caricati</h3>

            <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
                <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
            </p>
            
            <div *ngIf="errorMessage === ''" class="table-responsive">
                <table class="table table-striped table-bordered table-hover dataTables-example">
                    <thead>
                        <tr>
                            <th>{{ filesType !== UploadType.APK ? 'Nome file' : 'Versione' }}</th>
                            <th *ngIf="filesType !== UploadType.APK">Orario upload</th>
                            <th *ngIf="filesType !== UploadType.APK && filesType !== UploadType.TIMETABLES">Aggiornamento Praticko</th>
                            <th *ngIf="filesType === UploadType.GTFS" class="text-center">Esito</th>
                            <th *ngIf="downloadable"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="errorMessage === ''">
                        <tr *ngFor="let data of dataList" style="cursor:pointer">
                            <td>{{ filesType !== UploadType.APK ? data.fileName : data.version }} ({{ filesType !== UploadType.APK ? (data.size/1024/1024 | number:'.2') + ' MB' : '' }}) </td>
                            <td *ngIf="filesType !== UploadType.APK">{{ data.lastUpdate | date: (utils.isToday(+data.lastUpdate) ? 'HH:mm' : 'dd/MM/yyyy - HH:mm') }}</td>
                            <td *ngIf="filesType !== UploadType.APK && filesType !== UploadType.TIMETABLES">
                                {{ data.lastImportedDate | date: (utils.isToday(data.lastUpdate) ? 'HH:mm' : 'dd/MM/yyyy - HH:mm') }}
                            </td>
                            <td style="width: 50px" class="text-center" *ngIf="filesType === UploadType.GTFS">
                                <button class="{{ getButtonCircle(data.esito) }}" type="button"
                                    (click)="showUploadInfo(data)"></button>
                            </td>
                            <td style="width: 100px" class="col-md-4 text-center" *ngIf="downloadable">
                                <button style="float: left" type="button" class="btn btn-view btn-outline" (click)="downloadArchivedFile(data)">
                                    <i class="fa fa-download"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
                    [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
                </ngb-pagination>
            </div>
        </ng-container>
    </div>
</div>