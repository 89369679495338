import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'


const URL = 'https://185.56.9.94/prs-rest/rest/document/uploadDocChecklist';
const AUTHTOKEN = localStorage.getItem("token");
const HEADER = 'X-AUTH-TOKEN';
declare var jQuery:any;


@Component({
  selector: 'app-documents-new',
  templateUrl: './documents-new.component.html',
  styleUrls: ['../../../app.component.css']
})
export class DocumentsNewComponent implements OnInit {

pathUrlCodeDoc;
visuraCameraleCodeDocValue;
DurcCodeDocValue;
idSupplier;
yearsDefaultValue; 
monthDefaultValue;

statusUpload;
errorMessage;
codes: any[] = new Array(); 
statusDurcUpload;
errorDurcMessage;
defaultCodeDocumentType;
isAppr;
pathUrl;

 @ViewChild('f') visualForm: NgForm;



checkListObject =  {
  idOrder:1,
  year:2017,
  month:10
}


 



 constructor(private logger: LoggingService, private route: ActivatedRoute, public listService: ListService,  public router: Router){
 this.listService.listRouting = "orders/checklists/";
    
  }

  ngOnInit() {
    this.idSupplier = localStorage.getItem("idSupplier");
    this.pathUrlCodeDoc = "/docType/docTypes/";
    this.yearsDefaultValue = new Date().getFullYear();
    this.monthDefaultValue = ("0" + (new Date().getMonth() + 1)).slice(-2);
    this.getCode();
    this.pathUrl = "/checklist"

    this.defaultCodeDocumentType = "02A"
    this.errorMessage = '';
    this.statusUpload = false
    this.isAppr = localStorage.getItem("role") == 'APPROVER';
 
  }
  getCode(){
    this.listService.getList(this.pathUrlCodeDoc).subscribe( (response) => {
    this.codes = response.results;
    });
  }



 




createNewCheckList(){

   
    this.checkListObject.year = parseInt(this.visualForm.value.year);
    this.checkListObject.month = parseInt(this.visualForm.value.month);


      this.listService.newObject(this.checkListObject, this.pathUrl).subscribe(
      (response) => {
 
        if (response.outcome.success === true) {
            // login successfull
            swal2.fire("Creazione Cartella", "La cartella è stata creata", "success")

        } else {
          this.logger.log("Response",response,200);
          this.errorMessage = "Credenziali non valide."
          swal2.fire("Attenzione!", this.errorMessage, "warning")
        }
      },    
      (error) => {
        
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";

        swal2.fire("Errore", this.errorMessage,"error");
      }
    );

 
}




}




