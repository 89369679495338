import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class WalletListComponent implements OnInit {


  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  credit;
  trust;
  urlCredit; 

  commissions: any[] = new Array();
  suppliers: any[] = new Array();

  urlOperatorPath = "/user/users?deleted=false";//cerco utenti NON eliminati 
  paymentTypeList: any[] = new Array();

  constructor(private utils:UtilsService, private logger: LoggingService, private router: Router, public listService: ListService) {
    this.listService.configurationServiceCall("/wallet/list",false,true,1,12);
    this.listService.restURL = "/transaction/transactions";
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }


  @ViewChild('formWallet') walletForm: NgForm;

  // Initialized to specific date (09.10.2018).
  private model: Object = { date: { year: 2018, month: 10, day: 9 } };



  errorMessage: string = "";

  deletePath;
  

  visibleFilter;
  defaultValue;
  avaiableCredit; 
  cost;
  revenue;

  resaleCreditId

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  ngOnInit() {

    var resaleCredit  = localStorage.getItem("idResaleCredit")
    this.resaleCreditId = resaleCredit

    if(resaleCredit != null && resaleCredit != "") {
      this.dataFilter['idResale'] = this.resaleCreditId;

      this.urlCredit = "/transaction/resalescredit?idResale=" + this.resaleCreditId
      localStorage.setItem("idResaleCredit", "")

      this.listService.backToList = true;
      this.listService.listRouting = "/resales/list"

      console.log(this.dataFilter)
    } else {
      this.urlCredit = "/transaction/resalescredit/"
    }

    this.getOperators();
    this.getPaymentTypes();

    this.getCredit();
    
    this.loadElements();

  }

  pageChange(page){
    this.listService.page = page; 
    this.loadElements();
  }

  checkTitle(string){

   var value;
    if( string == "Wallet recharge"){
      value = "Ricarica"
    }else{
      value = "Vendita ticket"
    }
    return value;
  }

  checkColor(string){

    var value;
    if( string == "Wallet recharge"){
value = "btn btn-primary"
    }else{
      value = "btn btn-danger"
    }
    return value;

  }



  calculateAggio(amount, agio){

  var value; 

value =   amount * agio / 100 

return value 
   


  }

  checkIcon(string){

    var value;
    if( string == "Wallet recharge"){
value = "fa fa-credit-card"
    }else{
      value = "fa fa-ticket"
    }
    return value;

  }

  checkName(string){

    var value;
    if( string == "Wallet recharge"){
value = "Ricarica"
    }else{
      value = "Vendita Biglietto"
    }
    return value;

  }

  getCredit() {
    this.listService.getListSelect(this.urlCredit).subscribe((response) => {
      this.credit = response.results;
      this.logger.log("Lista rivenditori", this.credit, 300);

      for(let c of this.credit)
      {
        this.avaiableCredit = c.creditAmount
        this.cost = c.cost
        this.revenue = c.revenue
        this.trust = c.trust
      }

     this.checkColorCreditAvaiable()
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }


  checkColorCreditAvaiable()
  {
    var value;
    if(this.avaiableCredit >= 0){

      value = "widget style1 navy-bg"
    }
    else{
      value = "widget style1 red-bg"
    }
return value
   
  }

  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
       
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }
  onDateChanged(stringData) {
    console.log("DATA CAMBIATA", stringData);
  }




  filterSearch(){
    this.dataFilter = this.walletForm.value;

if(this.walletForm.value.dateFrom != null || this.walletForm.value.dateFrom != undefined){


  if( this.walletForm.value.dateFrom != null || this.walletForm.value.dateFrom != undefined)
  { this.dataFilter['dateFrom'] = this.utils.convertDateToISO(this.walletForm.value.dateFrom)
  
  }
  
}

if(this.walletForm.value.dateTo != null || this.walletForm.value.dateTo != undefined){

if( this.walletForm.value.dateTo != null || this.walletForm.value.dateTo != undefined)

{ this.dataFilter['dateTo'] = this.utils.convertDateToISO(this.walletForm.value.dateTo);

}
}


if(this.resaleCreditId != null && this.resaleCreditId != ""){
  this.dataFilter['idResale'] = this.resaleCreditId;
}

    this.loadElements();
    this.listService.visibleFilter = true;
  }


  selectRow(idOrder, nameOrder) {
    this.listService.selectedID = idOrder;
 //   this.listService.orderName = nameOrder;
    this.listService.backToList = true;
    this.router.navigate(['/orders/edit/' + idOrder]);
  }

  goToRecharge(){
    this.router.navigate(['/wallet/recharge']);
  }


  ticketsDetails() {
    swal2.fire(
      'Dettaglio Biglietto!',
      '',
      'success'
    )
  }



  newOrder() {
    this.listService.backToList = true;
    this.router.navigate(['/orders/new']);
  }

  filterReset(){
    this.walletForm.value.transactionType = ""
    this.walletForm.value.dateTo = ""
    this.walletForm.value.dateFrom = ""
    this.walletForm.value.paymentType = ""


    this.listService.visibleFilter = false;
    this.loadElements();
     this.walletForm.reset();

     if(this.resaleCreditId != null && this.resaleCreditId != ""){
      this.dataFilter['idResale'] = this.resaleCreditId;
    }
    
    //this.loadElements();
  }

  getPaymentType(string){
    return this.utils.checkTypePayment(string);
  }

  getOperators() {
    this.listService.getListSelect(this.urlOperatorPath).subscribe(
      response => {
        this.commissions = this.utils.filterUser(response.results , "SELLER");
        this.logger.log("Lista operatori: ", this.commissions, 300);
      },
      error => {}
    );
  }

  getPaymentTypes() {
    this.utils.paymentTypes().subscribe((resp) => {
      if( this.utils.checkResponse( resp ) ) {
        resp.results.map( el => {
          if( el.description !== '-') {
            this.paymentTypeList.push( el );
          }
        })
      }
    console.log('getPaymentTypes - this.paymentTypeList' , this.paymentTypeList );
    })
  }

 
}