import { Injectable } from '@angular/core';
import swal2 from 'sweetalert2'

@Injectable()
export class LoadingService {

    /** Method that creates a loader displayed on list retrieve, used with slower APIs that retrieve many data */
    presentLoader(): void {
        swal2.fire({
            title: 'Caricamento dati',
            text: 'Verifica di molti dati in corso, attendere prego',
            allowOutsideClick: false,
            didOpen: () => {
                swal2.showLoading();
            }
        });
    };

    /** Closes the list retrieve loader */
    hideLoader(): void {
        swal2.close();
    }

}
