import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';

import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { AuthService} from '../../../services/auth.service';
import swal2 from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-companies-manage',
  templateUrl: './companies-manage.component.html',
  styleUrls: ['../../../app.component.css']
})

export class  CompaniesManageComponent implements OnInit {

    // testo in HTML per tipo pagina
    kindTitle; kindManage;
    // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = '/company';
    // form html
    @ViewChild('companiesForm') formCompanies: NgForm;
    // oggetto contenente dati component
    dataCompany: any;
    id;
    viewEdit; // indica parti di html da visualizzare o meno in base alla modalità
    errorMessage;
    phone1;

    idCompany;

    urlSpecificUserPath = '/company/'

    typeUser;
    isSoluzione1;


    isActiveTabsLegal;
    isActiveLegal;

    isActiveTabsDetails;
    isActiveDetails;

    latRegex = new RegExp('^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$');
    lngRegex = new RegExp('^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$');

   constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
    ) {
        // Se creo nuovo veicolo
        if (route.snapshot.toString().indexOf('new') !== -1) {
            this.kindTitle = 'Nuova';
            this.kindManage = 1;
        } else { // se modifico veicolo
            this.kindTitle = 'Modifica';
            this.kindManage = 0;
            if (localStorage.getItem('dataPassed') === '')  {this.router.navigate(['/companies/list']); return; }
            this.dataCompany = JSON.parse(localStorage.getItem('dataPassed'));
            this.idCompany = this.dataCompany['idCompany'];
            console.log(this.dataCompany) // TO DO DA ELIMINARE
            this.loadSpecificUser();
            localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        }
    }

 ​ ​​  onChange() {
        console.log(this.formCompanies.value.companyID)
        this.viewEdit = true;
    }



    loadSpecificUser() {
        this.listService.getObject(this.urlSpecificUserPath + this.idCompany).subscribe( (response) => {

            this.dataCompany = response
            console.log('OBJECT ', this.dataCompany )

            this.logger.log('Response:', response, 300);
            localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        },
        (error) => {
            this.logger.log('Error', error, 200);
        });
    }

    ngOnInit() {
        // this.id = this.dataCompany.id
        //   this.listService.resetList();
        this.listService.backToList = true;

        this.typeUser = this.utils.getTypeUser();
        this.isSoluzione1 = this.utils.isSol1(this.typeUser);

        if (this.isSoluzione1 ) {
            this.listService.listRouting = '/companies/list'
        } else {
            this.listService.listRouting = '/companies/details'
        }

        console.log(this.listService)
        this.viewEdit = true;
        // this.companies.push(this.company);
        if ( ! this.kindManage) {

            this.viewEdit = true;

        }


        this.isActiveLegal = 'tab-pane active'
        this.isActiveDetails = 'tab-pane'

        this.isActiveTabsDetails = ''
        this.isActiveTabsLegal = 'active'
    }


    viewLegal() {
        this.isActiveLegal = 'tab-pane active'
        this.isActiveDetails = 'tab-pane'

        this.isActiveTabsDetails = ''
        this.isActiveTabsLegal = 'active'
    }

    viewDetails() {
        this.isActiveLegal = 'tab-pane'
        this.isActiveDetails = 'tab-pane active'

        this.isActiveTabsDetails = 'active'
        this.isActiveTabsLegal = ''
    }

    onSubmit(): void {

        swal2.fire({title: 'Operazione in corso...', didOpen: function () {swal2.showLoading()}});

        if (this.kindManage) {

            this.dataCompany = this.formCompanies.value

            console.log(this.dataCompany)
            this.listService.newObject(this.dataCompany, this.serviceToCallPath).subscribe(
                (response) => {
                    if (response.outcome.success === true) {
                            swal2.fire('Nuova azienda di trasporto creata', '', 'success');
                             this.router.navigate(['/companies/list']);
                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire('Attenzione', this.errorMessage, 'warning');
                    }
                },
                (error) => {
                    this.logger.log('Error', error, 200);
                    this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
                    swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
                }
            );
        } else {

            this.dataCompany = this.formCompanies.value
            // this.dataCompany.id = this.id

            this.listService.edit(this.dataCompany, this.serviceToCallPath).subscribe(
                (response) => {
                    if (response.outcome.success === true) {
                        swal2.fire('L\'azienda di trasporto è stata modificata', '', 'success');
                        this.router.navigate(['/companies/list']);
                    } else {
                        this.errorMessage = 'Credenziali non valide.'
                        swal2.fire('Attenzione', 'Credenziali non valide', 'warning');
                    }
                },
                (error) => {
                    this.logger.log('Error', error, 200);
                    this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
                    swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
                }
            );
        }
    }

    back() {
        if (this.isSoluzione1) {
            this.router.navigate(['/companies/list']);
        } else {
            this.router.navigate(['/companies/details']);
        }
    }
}
