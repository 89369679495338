<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Aziende di trasporto</strong>
      </h2>
      <div class="ibox-tools col-xs-1 pull-right">
        <button type="button" *ngIf='viewAll' (click)="newCompany()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <form #formCompany="ngForm">
              <div class='  col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Ragione Sociale</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="businessName" name="businessName" class="form-control" ngModel businessName #businessName="ngModel">
                  </div>
                </div>
              </div>

              <div class=' col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Città</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="city" name="city" class="form-control" ngModel city #city="ngModel">
                  </div>
                </div>
              </div>

              <div class='col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Stato</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="status" class="form-control" ngModel name="status" required status #status="ngModel">
                      <option [ngValue] = "'all'" > Tutti </option>
                      <option [ngValue]="'1'"> Attiva </option>
                      <option [ngValue]="'0'"> Non Attiva </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th>Ragione Sociale</th>
              <th>Indirizzo </th>
              
              <th>Stato</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of resales" style="cursor:pointer">
              <td style="width: 200px;">{{data.legalBusinessName}}</td>
              <td>{{data.legalAddress}},{{data.legalCity}},{{data.legalZipCode}}, {{data.legalProv}} </td>
           
  
              <td style="width: 50px" class="text-center">
                <button title="{{getStatusDescription(data.status)}}" class="{{getButtonCircle(data.status)}}" type="button">
                </button>
              </td>
  
              <td [style.width.px]='widthController' class="col-md-4 text-center">
  
                <a (click)="showModal(data.idCompany, template)" title= "Condividi Linea" style="float: left;margin-right: 8px;" data-toggle="modal" class="btn btn-success btn-outline" >
                  <i class="fa fa-code-fork"></i>
                </a>
  
                <button *ngIf='viewAll' style="float: right; " type="button" class="btn btn-danger btn-outline " (click)=" deleteCompany(data.idCompany,data.legalBusinessName)">
                  <i class="fa fa-trash"></i>
                </button>
              
             
  
                <button *ngIf='viewAll' style="float: right;margin-right: 8px;" type="button" class="btn btn-success btn-outline " (click)="selectRow(data)">
                  <i class="fa fa-pencil"></i>
                </button>
  
  
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [maxSize]="10" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

<form #formSharingCompany="ngForm">
<ng-template #template>
<div>

    
      <div class="modal-body">


          
        <div class="ibox-content">
          <h2>Condividi le linee con:</h2>
          <div class="row">



            <div class="form-group col-md-12 col-sm-12">

          

              <select type="text" id="shareWithCompany" class="form-control" ngModel name="shareWithCompany" shareWithCompany #shareWithCompany="ngModel" required>
                <option *ngFor="let o of dataListShare" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
              </select>
            </div>



          

          <div class="input-group col-md-12 col-sm-12">
              <button class="close" data-dismiss="modal" style="float: left;" type="button" (click)="closeModal()" class="btn btn-default btn-outline">
                  Annulla
                </button>
            <button style="float: right;" data-dismiss="modal" type="button" (click)="shareCompany()" class="btn btn-success btn-outline">
              <i class="fa fa-code-fork"></i> Condividi
            </button>
          </div>
        </div>


        </div>
       
      </div>
    </div>


</ng-template>
</form>