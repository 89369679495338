<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">

  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Estrazione Dati</strong>
      </h2>
    </div>
    <div class="ibox-content">
      <form #formExportTicket="ngForm">
        <div class='col-xs-12 col-sm-6 col-md-12 col-lg-3'>
          <label>Data da - a:</label>
          <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue" ngModel bsDaterangepicker
            #bsDaterangepicker placement="bottom right" [bsConfig]="bsConfig" autocomplete="off">
        </div>
        

        <div class='col-xs-12 col-sm-6 col-sm-6 col-md-3 col-lg-3'>
          <div class="form-group">
            <label></label>
            <div class='input-group col-md-12'>
              <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
            </div>
          </div>
        </div>

      </form>

      <div class="clearfix"></div>
      <div class="row">
        <div class="col-lg-12">
          <!-- <wbr-pivot #pivot1 [toolbar]="true"
                    [width]="'100%'"
                    [height]="500"
                    [report]="'/assets/data/datiMot.json'"> -->
          <wbr-pivot #pivot1 [toolbar]="true"
                    [width]="'100%'"
                    [height]="500"
                    [report]="dataList">
              WebDataRocks will appear here
          </wbr-pivot>
        </div>
      </div>
    </div>
  </div>