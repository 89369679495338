<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">


  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Vendita Biglietti</strong>
        <!-- <button (click)="downloadPdf()">ddd</button> -->
      </h2>
    </div>


  


    <div class="ibox-content">
      <div class="row">





        <form #formSalesNew="ngForm">

          <div class="col-lg-12">

            <div style="margin-bottom: 20px" class="col-md-12">

              <div class="col-md-6">
                <div class="panel-body">

                  <div class="row">

                    <div class="col-md-12">


                      <div class="row">

                        <div *ngIf="channel == '3'" class="col-xs-12">
                          <h2>Credito Residuo: {{avaiableCredit | number : '1.2-2'}}</h2>
                          </div>

                        <div class="col-xs-12">
                          <div class="form-group">
                            <label>TARIFFA</label>
                            <select (change)="onChangeFare($event.target.value)" type="text" id="fare" class="form-control" ngModel name="fare" required
                              fare #fare="ngModel" required>
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let f of fareList" [ngValue]="f.idTicketFare">{{f.name}}</option>

                            </select>
                          </div>
                        </div>

                        <div class="col-xs-12">
                          <div *ngIf=visiblePriceList class="form-group">
                            <label>LISTINO</label>
                            <select (change)="onChange($event.target.value)" type="text" id="list" class="form-control" ngModel name="list" required
                              list #list="ngModel" required>
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let f of filterPriceList" [ngValue]="f.idTicketPricelist">{{f.name}}</option>

                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">

                        <div *ngIf="directionsView" class='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                          <div class="form-group">
                            <label for="direction">DESTINAZIONE </label>
                            <small style="float: right">Linea {{valueIdLine}}</small>
                            <select type="text" id="direction" (change)="getDirection($event.target.value)" class="form-control" ngModel name="direction"
                              required direction #direction="ngModel">
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let d of directionList" [ngValue]="d.directionId">{{d.arrival}} </option>
                            </select>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div *ngIf="constraintData" class='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                          <div class="form-group">
                            <label>DATA VIAGGIO</label>
                            <div class='input-group col-xs-12'>
                              <input type="text"
                                class="form-control"
                                name="date"
                                id="date"
                                ngModel
                                required
                                bsDatepicker
                                #bsDatepicker
                                placement="bottom"
                                autocomplete="off"
                                (bsValueChange)="onDateChanged($event)"
                                [bsConfig]="bsConfig" />
                            </div>
                          </div>
                        </div>



                        <div *ngIf="constraintDataReturnWithoutLine" class='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                            <div class="form-group">
                              <label>DATA VIAGGIO RITORNO</label>
                              <div class='input-group col-xs-12'>
                                <input type="text"
                                  class="form-control"
                                  name="dateR"
                                  id="dateR"
                                  ngModel
                                  required
                                  bsDatepicker
                                  #bsDatepicker
                                  placement="bottom"
                                  autocomplete="off"
                                  (bsValueChange)="onDateChangedR($event)"
                                  [bsConfig]="bsConfig" />
                              </div>
                            </div>
                          </div>

                        




                        <div *ngIf="constraintHour" class='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
                          <div class="form-group">

                              
                     
                            

                         
                            <label>ORARIO CORSA</label>
                            <div class='input-group col-xs-12'>
                              
                                <div *ngIf="isVisibleLoader" class="sk-spinner sk-spinner-wave">
                                    <div class="sk-rect1"></div>
                                    <div class="sk-rect2"></div>
                                    <div class="sk-rect3"></div>
                                    <div class="sk-rect4"></div>
                                    <div class="sk-rect5"></div>
                                </div>
                              <select *ngIf="!isVisibleLoader" type="text" (change)="changeHourTrip($event.target.value)" id="time" class="form-control" ngModel name="time" required
                                time #time="ngModel">
                                <option [ngValue]=null> --- </option>
                                <option *ngFor="let t of tripList" [ngValue]="t.tripId"> {{t.departureTime}} </option>

                              </select>

                            </div>
                          </div>
                        </div>


                        <div *ngIf="constraintHour" class='col-xs-12 col-sm-2 col-md-2 col-lg-2'>
                          <div class="form-group text-center">

                            <label>FERMATE</label>
                            <a (click)="choseList('a', template)" data-toggle="modal" class="btn btn-primary btn-outline">
                              <i class="fas fa-search"></i>
                            </a>
                          </div>
                        </div>





                      </div>

                      <h2 *ngIf="constraintDataReturn">Ritorno</h2>
                      <hr *ngIf="constraintDataReturn" align=”left” size=”1″ width=”300″ color noshade>

                      <div *ngIf="constraintDataReturn" class="row">

                        <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12'>

                          <div class="form-group">
                            <label for="direction">DESTINAZIONE RITORNO</label>
                            <small style="float: right">Linea {{valueIdLine}}</small>
                            <select [disabled]="true" [ngModel]="idReturnDirectionModel" type="text" id="directionR" (change)="getDirectionR($event.target.value)"
                              class="form-control" ngModel name="directionR" required directionR #directionR="ngModel">
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let d of directionListR" [ngValue]="d.directionId">{{d.arrival}} </option>
                            </select>
                          </div>
                        </div>


                        <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                          <div class="form-group">
                            <label>DATA VIAGGIO RITORNO </label>
                            <div class='input-group col-xs-12'>
                              <input type="text"
                                class="form-control"
                                name="dateR"
                                id="dateR"
                                ngModel
                                required
                                bsDatepicker
                                #bsDatepicker
                                placement="bottom"
                                autocomplete="off"
                                (bsValueChange)="onDateChangedR($event)"
                                [bsConfig]="bsConfig" />
                            </div>
                          </div>
                        </div>




                      </div>


                      <h2 *ngIf="directionsViewR">Ritorno</h2>
                      <hr *ngIf="directionsViewR" align=”left” size=”1″ style="margin-top: 0px" width=”300″ color noshade>



                      <div *ngIf="directionsViewR" class="row">

                        <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                          <div class="form-group">
                            <label for="direction">DESTINAZIONE RITORNO</label>
                            <small style="float: right">Linea {{valueIdLine}}</small>
                            <select [disabled]="true" [ngModel]="idReturnDirectionModel" type="text" id="directionR" (change)="getDirectionR($event.target.value)"
                              class="form-control" ngModel name="directionR" required directionR #directionR="ngModel">
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let d of directionListR" [ngValue]="d.directionId">{{d.arrival}} </option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div *ngIf="directionsViewR" class="row">

                        <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                          <div class="form-group">
                            <label>DATA VIAGGIO</label>
                            <div class='input-group col-xs-12'>
                              <input type="text"
                                class="form-control"
                                name="dateR"
                                id="dateR"
                                ngModel
                                required
                                bsDatepicker
                                #bsDatepicker
                                placement="bottom"
                                autocomplete="off"
                                (bsValueChange)="onDateChangedR($event)"
                                [bsConfig]="bsConfig" />
                            </div>
                          </div>
                        </div>


                        <div class='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
                          <div class="form-group">
                            <label>ORARIO CORSA </label>
                            <div class='input-group col-xs-12'>
                                <div *ngIf="isVisibleLoaderR" class="sk-spinner sk-spinner-wave">
                                    <div class="sk-rect1"></div>
                                    <div class="sk-rect2"></div>
                                    <div class="sk-rect3"></div>
                                    <div class="sk-rect4"></div>
                                    <div class="sk-rect5"></div>
                                </div>
                              <select *ngIf="!isVisibleLoaderR" type="text" (change)="changeHourTripR($event.target.value)" id="timeR" class="form-control" ngModel name="timeR"
                                required timeR #timeR="ngModel">
                                <option [ngValue]=null> --- </option>
                                <option *ngFor="let t of tripListR" [ngValue]="t.tripId"> {{t.departureTime}} </option>

                              </select>

                            </div>
                          </div>
                        </div>

                        <div class='col-xs-12 col-sm-2 col-md-2 col-lg-2'>
                          <div class="form-group text-center">

                            <label>FERMATE</label>
                            <a (click)="choseList('r', template)" data-toggle="modal" class="btn btn-primary btn-outline" >
                              <i class="fa fa-search"></i>
                            </a>
                          </div>
                        </div>


                      </div>


                    </div>

                  </div>

                </div>






              </div>




              <div *ngIf="chooseList" class="col-md-6">

                <h2 class="font-bold m-b-xs">
                  {{listNameString}}
                </h2>



                <div class="row">

                  <div style="margin-top: 20px" class="col-md-2">
                    <label> {{singlePrice}} €</label>
                  </div>
                  <div style="margin-top: 10px;" class="col-md-4">




                    <button style="margin-right: 5px" type="button" class="btn btn-primary btn-outline" (click)="removeTicket()">
                      <i class="fa fa-minus"></i>
                    </button>
                    <label>{{ticketSum}}</label>

                    <button style="margin-left: 5px" type="button" class="btn btn-primary btn-outline" (click)="addTicket()">
                      <i class="fa fa-plus"></i>
                    </button>




                  </div>
                  <div class="col-md-3">
                    <div class="m-t-md">




                      <h2 class="product-main-price" style="color: #1ab394">{{ticketTotalPrice}} €

                      </h2>
                    </div>
                  </div>
                  <div style="margin-top: 10px; float: right" class="col-md-3">

                    <button style="float: right" class="btn btn-primary" [disabled]="!formSalesNew.valid" type="button" (click)="putInCart()">
                      <i class="fa fa-plus"></i> Aggiungi
                    </button>

                  </div>


                </div>
                <hr *ngIf = "visibleExtra == true">

                <h4 *ngIf = "visibleExtra == true">EXTRA:</h4>

                <div class="clearfix" *ngIf="visiblePriceList && visibleExtra == true">


                  <div *ngIf = "visibleExtra == true" class="col-lg-8">
                    <label>AGGIUNGI BIGLIETTO EXTRA</label>
                    <select type="text" id="extra" class="form-control" ngModel name="extra" required extra #extra="ngModel" required>
                      <option [ngValue]="'noExtra'"> Nessun Biglietto </option>
                      <option *ngFor="let f of dataTicketTypologyExtra" [ngValue]="f.idTicketPricelist">{{f.name}} - Prezzo unitario {{f.price}} €</option>

                    </select>
                  </div>

                  <div *ngIf = "visibleExtra == true" class="col-lg-4">

                  </div>

                </div>

                <hr>


                <h4>DETTAGLI BIGLIETTO:</h4>

                <div class="col-lg-6">
                  <dl class="medium m-t-md">
                    <dt>Tariffa:</dt>
                    <dd>
                      <strong class="text-navy">{{fareNameString}}</strong>
                    </dd>

                    <dt>Linea:</dt>
                    <dd>
                      <strong class="text-navy">{{valueIdLine}}, {{lineLongNameString}}</strong>
                    </dd>

                  </dl>

                </div>


                <div class="col-lg-6">
                  <dl class="medium m-t-md">

                    <dt>Tipologia Biglietto:</dt>
                    <dd>
                      <strong class="text-navy">{{ticketTypeNameString}}</strong>
                    </dd>


                    <dt *ngIf="directionString">Destinazione</dt>
                    <dd>
                      <strong class="text-navy">{{directionString}}</strong>
                    </dd>

                    <dt *ngIf="hourString">Ora</dt>
                    <dd>
                      <strong class="text-navy">{{hourString}}</strong>
                    </dd>

                    <dt *ngIf="directionStringR">Destinazione Ritorno</dt>
                    <dd>
                      <strong class="text-navy">{{directionStringR}}</strong>
                    </dd>

                    <dt *ngIf="hourStringR">Ora Ritorno</dt>
                    <dd>
                      <strong class="text-navy">{{hourStringR}}</strong>
                    </dd>



                  </dl>

                </div>

                <hr>

                <div>

                </div>

              </div>

            </div>

          </div>





          <div class="row" *ngIf="!cartIsVoid">
            <div class="col-md-12">


              <div *ngFor="let order of ticketCart" class="ibox-content">


                <div class="table-responsive">
                  <table class="table shoping-cart-table">

                    <tbody>
                      <tr>
                        <td width="90">
                          <div class="cart-product-imitation">
                            <i class="fa fa-ticket fa-2x"></i>
                          </div>
                        </td>
                        <td class="desc">
                          <h3>
                            <a class="text-navy">
                              {{getFareName(order.rate)}} - {{order.listNameString}}
                            </a>
                          </h3>


                          <div class="col-lg-12 col-sm-12" style="padding-left: 0px">
                            <div style="padding-left: 0px" class="col-lg-3 col-sm-3">
                              <dl class="small m-b-none">
                                <dt>Tipologia Biglietto</dt>
                                <dd>{{order.typeTicketString}}</dd>
                              </dl>
                            </div>

                            <div class="col-lg-3 col-sm-3">
                              <dl class="small m-b-none">
                                <dt>Linea</dt>
                                <dd>{{order.line}}</dd>
                              </dl>
                            </div>

                            <div *ngIf="order.hour" class="col-lg-3 col-sm-3">
                              <dl class="small m-b-none">
                                <dt *ngIf="!order.hourReturn">Data e Ora</dt>
                                <dt *ngIf="order.hourReturn">Data e Ora A/R</dt>
                                <dd *ngIf="!order.hourReturn">{{order.hour}} , {{order.date}}</dd>
                                <dd *ngIf="order.hourReturn">A: {{order.hour}} , {{order.date}}</dd>
                                <dd *ngIf="order.hourReturn">R: {{order.hourReturn}} , {{order.dateReturn}}</dd>
                              </dl>
                            </div>

                            <div *ngIf="order.direction" class="col-lg-3 col-sm-3">
                              <dl class="small m-b-none">
                                <dt *ngIf="!order.directionR">Destinazione</dt>
                                <dt *ngIf="order.directionR">Destinazione A/R</dt>
                                <dd *ngIf="order.directionR">A: {{order.direction}}</dd>
                                <dd *ngIf="!order.directionR">{{order.direction}}</dd>
                                <dd *ngIf="order.directionR">R: {{order.directionR}}</dd>
                              </dl>
                            </div>




                          </div>

                          <div class="m-t-sm">
                            <a (click)="deleteFromCart(order)" class="text-muted">
                              <i class="fa fa-trash"></i> Rimuovi dal carrello</a>
                          </div>
                        </td>


                        <td  style="float: right">
                            <h4>
                              {{order.totalPrice}} €
                            </h4>
                          </td>


                          <td  style="float: right">
                             
                                <i  style="margin-top: 10px" (click)="ticketExtraAdd(order)" class="fa fa-plus"></i>
                              
                            </td>
                      

                        <td   width="60px" style="float: right">


                          <input type="text" [readonly]="true" class="form-control" placeholder="{{order.amount}}">

                         
                        </td>

                        <td *ngIf = "order.amount != 1" style="float: right">
                           
                              <i  style="margin-top: 10px"  (click)="ticketExtraRemove(order)"  class="fa fa-minus"></i>
                      
                          </td>
                     

                        

                       

                     
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>




              <div class="col-md-12">

                <div style="padding-top: 10px" class='col-md-4'>
                  <label>Stampante</label>
                  <select (change)="getSerialNumber($event.target.value)" type="text" id="idPrinter" class="form-control" ngModel name="idPrinter"
                    [ngModel]="defaultIdPrinter" required idPrinter #idPrinter="ngModel">
                    <option *ngIf="isPdfEnabled" [ngValue]="0"> Stampa come pdf </option>
                    <option  *ngFor="let s of printerList" [ngValue]="s.id">{{s.name}}</option>
                  </select>


                </div>


                <div class="">

                  <div style="float: right">

                    <br>
                    <h2 style="color: #1ab394" class="font-bold">
                      TOTALE: {{commonPrice}} €
                    </h2>
                    <button data-toggle="modal" href="#modal-resume" type="button" class="btn btn-view btn-outline" style="margin-right:5px;"
                      [disabled]="!formSalesNew.valid" (click)="completeSell()"> Concludi Vendita</button>
                    <button type="button" class="btn btn-default" (click)="nullSold()"> Annulla</button>
                  </div>



                </div>
              </div>




            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<ng-template #template>



  <div class="modal-dialog">

      <div class="modal-body">
        <div class="row">
          <div class="ibox-content" id="ibox-content">

            <div id="vertical-timeline" class="vertical-container dark-timeline center-orientation">
              <div *ngFor="let s of stopsDataList" class="vertical-timeline-block">
                <div class="vertical-timeline-icon navy-bg">

                </div>

                <div class="vertical-timeline-content">
                  <h2>{{s.stopName}}</h2>


                  <span class="vertical-date">

                    <strong>{{s.departureTime}}</strong>
                  </span>
                </div>
              </div>




            </div>

          </div>
        </div>
      </div>
    </div>



</ng-template>