<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h2>{{kindTitle}} utente</h2>
                </div>
                <form (ngSubmit)="onSubmit()" #formUsers="ngForm">

                    <div class="ibox-content">

                        <div *ngIf="kindManage != 0" class="form-group col-md-6">
                            <label for="typeUser">Tipologia </label>
                            <select (change)="onChangeType(typeUser.value)" [disabled]="disabled" type="text" id="typeUser" class="form-control" ngModel
                                [ngModel]="userData?.typeUser" name="typeUser" typeUser #typeUser="ngModel" required>
                                <option *ngFor="let o of typeList" [ngValue]="o.typeUser">{{translateTypeUser(o.typeUser)}}</option>
                            </select>
                        </div>

                        <div *ngIf="kindManage == 0">
                            <input type="hidden" id="typeUser" name="typeUser" class="form-control" ngModel [ngModel]="userData?.typeUser" typeUser required
                                #typeUser="ngModel">
                        </div>

                        <div *ngIf="kindManage == 0">
                            <input type="hidden" id="email" name="email" class="form-control" ngModel [ngModel]="userData?.email" email required #email="ngModel">
                        </div>

                        <div *ngIf="kindManage == 0">
                            <input type="hidden" id="roleUser" name="roleUser" class="form-control" ngModel [ngModel]="userData?.roleUser" roleUser required
                                #roleUser="ngModel">
                        </div>


                        <div *ngIf="kindManage == 0">
                            <input type="hidden" id="idUser" name="idUser" class="form-control" ngModel [ngModel]="userData?.idUser" idUser required
                                #idUser="ngModel">
                        </div>

                        <div *ngIf="visibleRole && kindManage != 0" class="form-group col-md-6">
                            <label for="roleUser">Ruolo </label>
                            <select (change)="onchangeMobileOperator(roleUser.value)" type="text" id="roleUser" class="form-control" [disabled]="disabled"
                                ngModel [ngModel]="userData?.roleUser" name="roleUser" roleUser #roleUser="ngModel" required>
                                <option *ngFor="let o of roleFilterList" [ngValue]="o.codeRole">{{translateCodeRole(o.codeRole)}}</option>
                            </select>
                        </div>

                        <div style="margin-bottom: 40px" *ngIf="checkboxVisible" class="form-group col-md-12">
                            <label class="text-center"> Abilitazioni</label>
                            <div class="row col-md-12">
                                <div class="checkbox">
                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkSeller" [ngModel]="ticketListData?.checkResale" id="checkResale" checkResale #checKResale>
                                        <label>Venditore Mobile</label>
                                    </div>

                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkInternalSeller" [ngModel]="ticketListData?.checkInternalSeller" id="checkInternalSeller" checkInternalSeller #checkInternalSeller>
                                        <label>Venditore Interno</label>
                                    </div>

                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkReviewer" [ngModel]="ticketListData?.checkMobile" id="checkMobile" checkMobile #checkMobile>
                                        <label>Controllore</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkObliterator" [ngModel]="ticketListData?.checkWeb" id="checkWeb" checkWeb #checkWeb>
                                        <label>Obliteratore</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="visibleCompanyName && !isCompany && !isResales && kindManage != 0" class="form-group col-md-12">
                            <label for="idCompany">Azienda di trasporto </label>
                            <select type="text" id="idCompany" class="form-control" ngModel [ngModel]="userData?.idCompany" name="idCompany" required
                                idCompany #idCompany="ngModel" required>
                                <option *ngFor="let o of companyList" [ngValue]="o.idCompany">{{o.legalBusinessName}}</option>
                            </select>
                        </div>


                        <div *ngIf="kindManage == 0" class="form-group col-md-12">
                            <label for="typeUserView"> Tipologia</label>
                            <input type="text" id="typeUserView" class="form-control" disabled ngModel [ngModel]="translateTypeUser(userData?.typeUser)"
                                name="typeUserView" required typeUserView #typeUserView="ngModel">
                        </div>

                        <div *ngIf="kindManage == 0" class="form-group col-md-12">
                            <label for="roleUserView"> Ruolo</label>
                            <input type="text" id="roleUserView" class="form-control" disabled ngModel [ngModel]="translateCodeRole(userData?.roleUser)"
                                name="roleUserView" required roleUserView #roleUserView="ngModel">
                        </div>


                        <div style="margin-bottom: 40px" *ngIf=" kindManage == 0 && editMobileView" class="form-group col-md-12">
                            <label> Abilitazioni</label>
                            <div class="row col-md-12">
                                <div class="checkbox">
                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkSeller" [ngModel]="varSeller" id="checkResale" checkSeller #checkSeller>
                                        <label>Venditore Mobile</label>
                                    </div>

                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkInternalSeller" [ngModel]="varInternalReseller" id="checkInternalSeller" checkInternalSeller #checkInternalSeller>
                                        <label>Venditore Interno</label>
                                    </div>

                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkReviewer" [ngModel]="varReviewer" id="checkMobile" checkReviewer #checkReviewer>
                                        <label>Controllore</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="checkbox" name="checkObliterator" [ngModel]="varObliterator" id="checkObliterator" checkObliterator #checkObliterator>
                                        <label>Obliteratore</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="visibleResale && !isResales" class="form-group col-md-12">
                            <label for="idResale">Rivendita </label>
                            <select type="text" id="idResale" class="form-control" ngModel [ngModel]="userData?.idResale" name="idResale" required idResale
                                #idResale="ngModel" required>
                                <option *ngFor="let o of resaleList" [ngValue]="o.id">{{o.legalBusinessName}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="name">Nome</label>
                            <input type="text" id="name" name="name" class="form-control" ngModel [ngModel]="userData?.name" required #name="ngModel">
                        </div>

                        <div class="form-group col-md-6">
                            <label for="surname"> Cognome</label>
                            <input type="text" id="surname" class="form-control" ngModel [ngModel]="userData?.surname" name="surname" required surname
                                #surname="ngModel">
                        </div>

                        <div class="form-group col-md-6">
                            <label for="email">Email</label>
                            <input type="text" id="email" [disabled]="disabled" name="email" class="form-control" ngModel [ngModel]="userData?.email"
                                required email #email="ngModel">
                        </div>

                        <div class="form-group col-md-6">
                            <label for="phone"> Telefono</label>
                            <input type="text" id="phone" class="form-control" ngModel [ngModel]="userData?.phone" name="phone" required phone #phone="ngModel">
                        </div>

                        <div class="form-group form-group col-md-6">
                            <label>Stato</label>
                            <div class='input-group col-md-12'>
                                <select type="text" [ngModel]="userData?.enabled" id="enabled" class="form-control" ngModel name="enabled" required enabled
                                    #enabled="ngModel">
                                    <option [ngValue]=false> Non attivo </option>
                                    <option [ngValue]=true> Attivo </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group form-group col-md-6">
                                <label>Password Resettata</label>
                                <div class='input-group col-md-12'>
                                    <select type="text" [ngModel]="userData?.refreshPassword" id="refreshPassword" class="form-control" ngModel name="refreshPassword" required refreshPassword
                                        #refreshPassword="ngModel">
                                        <option [ngValue]=false> Nessuna Richiesta </option>
                                        <option [ngValue]=true> Abilitazione richiesta nuova password  </option>
                                    </select>
                                </div>
                            </div>

                        


                        <div class="clearfix"></div>
                    </div>
                    <div class="ibox-footer">



                        <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
                        <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!formUsers.valid">Salva </button>


                    </div>
                </form>
            </div>
        </div>
    </div>
</div>