<div class="ibox-content" style="border-bottom: 0 !important;"
    [ngClass]="{'no-border': noBorder}">
    <div class="table-responsive">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
        <input type="file" ng2FileSelect
            #fileSelector
            accept="{{ fileTypes }}"
            (change)="handleFilesInput($event.target.files)"
            [uploader]="uploader">
        <br><br>
        <ng-container *ngIf="uploader.queue.length">
            <h3>Stato caricamento </h3>
            <table class="table">
                <thead>
                    <tr>
                        <th width="50%">Nome file</th>
                        <th>Dimensione</th>
                        <th>Progresso</th>
                        <th>Status</th>
                        <th>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                        <td *ngIf="uploader.options.isHTML5">
                            <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploadProgress + '%' }"></div>
                            </div>
                        </td>
                        <td class="text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span class="" *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                            <span class="" *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-success btn-xs "
                                    (click)="handleFile(item)" [disabled]="item.isReady || item.isUploading || item.isSuccess || !controllerApkReady()">
                                <span class="glyphicon glyphicon-upload"></span> Upload
                            </button>
                            <button type="button" class="btn btn-danger btn-xs mL10"
                                    (click)="uploader.clearQueue();" [disabled]="item.isReady || item.isUploading">
                                <span class="glyphicon glyphicon-trash"></span> Rimuovi
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
        </ng-container>
    </div>
</div>