<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2  class="col-md-6"><strong>Orari Fermate</strong></h2>
        <div class="ibox-tools col-md-6 pull-right">
        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <form #formTimes="ngForm">
            <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
              <div class="form-group">
                <label>Id Corsa</label>
                <div class='input-group col-xs-12'>
                <input
                  type="text"
                  id="tripId"
                  name="tripId"
                  class="form-control"
                  ngModel
                  tripId
                  #tripId= "ngModel">
                </div>
              </div>
            </div>
          
            <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
              <div class="form-group">
                <label>Ora Partenza</label>
                <div class='input-group col-xs-12'>
                <input
                  type="text"
                  id="arrivalTime"
                  name="arrivalTime"
                  class="form-control"
                  ngModel
            
                  arrivalTime
                  #arrivalTime= "ngModel">
                </div>
              </div>
            </div>
            <div class='col-xs-6 col-sm-4 col-md-3  col-lg-2'>
                <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                        <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                        <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                    </div>
                </div>
            </div>
          </form>
        </div>

        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example" >
            <thead>
              <tr>
                <th>Id Corsa</th>
           
                <th>Ora Partenza</th>
                <th>Id Fermata</th>
                
                <th>Sequenza Fermata</th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList"  style="cursor:pointer">
                <td>{{data.tripId}}</td>
                <td>{{data.departureTime}}</td>
                <td>{{data.stopId}}</td>
                <td>{{data.stopSequence}}</td>
              </tr>
            </tbody>        
          </table>
          <ngb-pagination 
            *ngIf="listService.totalRows > listService.rows"
            class="pull-right"
            [maxSize]="10" 
            [collectionSize]="listService.totalRows" 
            [pageSize]="listService.rows" 
            [(page)]="listService.page"
            (pageChange)="pageChange($event)" >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

