<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} tipologia</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #ticketForm="ngForm">
          <div class="ibox-content">
            <div *ngIf="isSol1" class="form-group col-xs-6 col-sm-4 col-md-4">
              <label for="idCompany"> Azienda di trasporto </label>
              <select
              [disabled]="!isSol1"
              type="text"
              id="idCompany"
              class="form-control"
              ngModel
              [ngModel]="ticketTypologyData?.idCompany"
              name ="idCompany"
              idCompany
              #idCompany="ngModel"
                required>
                  <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
              </select>
            </div>

            <div class="form-group col-xs-6 col-sm-4 col-md-4">
              <label for="name">Tipologia</label>
              <input
                type="text"
                id="name"
                name="name"
                class="form-control"
                ngModel
                [ngModel]="ticketTypologyData?.name"
                required
                #name= "ngModel">
            </div>


            <div class="form-group col-xs-6 col-sm-4 col-md-4">
              <label  for="ar"> Validità</label>
              <select
              disabled="kindManage == 0"
              type="text"
              id="ar"
              class="form-control"
              ngModel
              [ngModel]="ticketTypologyData?.ar"
              name ="ar"
              ar
              #ar="ngModel"
                required>
                  <option  [ngValue]='true'>Andata/Ritorno</option>
                  <option  [ngValue]='false'>Solo Andata</option>
              </select>
            </div>

            <div class="form-group col-xs-6 col-sm-3 col-md-3">
                <label for="extra"> Extra</label>
                <select

                disabled="kindManage == 0"
                type="text"
                id="extra"
                class="form-control"
                ngModel
                [ngModel]="ticketTypologyData?.extra"
                name ="extra"
                extra
                #extra="ngModel"
                  required>
                    <option  [ngValue]='true'>Sì</option>
                    <option  [ngValue]='false'>No</option>
                </select>
              </div>

           

            <div hidden *ngIf='kindManage == 0' class="form-group col-xs-6 col-sm-6 col-md-6">
              <label for="idTicketType">idTicketType</label>
              <input
                type="text"
                id="idTicketType"
                name="idTicketType"
                class="form-control"
                ngModel
                [ngModel]="ticketTypologyData?.idTicketType"
                required
                idTicketType
                #idTicketType= "ngModel">
            </div>

            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            

            <button class="btn btn-default" type="button"  (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right"  [disabled]="!ticketForm.valid" >Salva</button>

          </div>
        </form>

      </div>
    </div>
  </div>
</div>    