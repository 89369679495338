<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Obliteratrici</strong>
      </h2>

      <div class="ibox-tools col-xs-1 pull-right">
        <button *ngIf="isSoluzione1" type="button" (click)="newObliterator()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <form #formFilter="ngForm">
              <div *ngIf="companyVisible" class='col-xs-12 col-sm-3 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                      <option [ngValue]="'all'"> Nessun Veicolo </option>
                      <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}} </option>
                    </select>
                  </div>
                </div>
              </div>



              <div class='col-xs-12 col-sm-3 col-md-2'>
                <div class="form-group">
                  <label>Serial Number</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="serialNumber" name="serialNumber" class="form-control" ngModel required serialNumber #serialNumber="ngModel">
                  </div>
                </div>
              </div>

              <div class=' col-xs-12 col-sm-3 col-md-2'>
                <div class="form-group">
                  <label>MacAddress</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="macAddress" name="macAddress" class="form-control" ngModel required macAddress #macAddress="ngModel">
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-3 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Veicolo</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idVehicle" class="form-control" ngModel name="idVehicle" required idVehicle #idVehicle="ngModel">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let r of vehiclesList" [ngValue]="r.id">{{r.name}} </option>
                    </select>
                  </div>
                </div>
              </div>






        

              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-6 col-lg-4'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="viewModal(allVersion)" style="margin-left: 5px" class="btn btn-view  btn-outline">
                      Allinea Versione
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th *ngIf="isSoluzione1">Azienda di trasporto</th>

              <th>Serial Number</th>
              <th>MAC Address</th>
              <th>Veicolo</th>

              <th>Nome</th>
              <th>Vers. SW Installata  </th>
              <th>Vers. SW Target</th>

              <th>Allineamento</th>

              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let o of obliterators" style="cursor:pointer">
              <td *ngIf="isSoluzione1" style="width: 200px;">{{getBusinessName(o.idCompany)}}</td>
  
              <td>{{o.serialNumber}}</td>
              <td>{{o.macAddress}}</td>
  
              <td>{{getVeihcleName(o.idVehicle)}}</td>
  
              <td>{{o.name}}</td>
              <td [ngStyle]="{'color': o.softwareVersion == o.softwareVersionTarget ? '#1ab394' : '#ec4758'}">{{o.softwareVersion}}</td>
              <td [ngStyle]="{'color': o.softwareVersion == o.softwareVersionTarget  ? '#1ab394' : '#ec4758'}">{{o.softwareVersionTarget}}</td>
  
              <td title="{{checkVersionTitle(o.softwareVersion, o.softwareVersionTarget)}}" class="text-center">
                <button type="button" class="{{checkVersion(o.softwareVersion, o.softwareVersionTarget)}}" (click)="viewModal(template, o)">
                  <i class="{{checkVersionIcon(o.softwareVersion, o.softwareVersionTarget)}}"></i>
                </button>
              </td>
  
  
              <td style="width: 100px" class="col-md-4 text-center">
                <button type="button" class="btn btn-success btn-outline " (click)="selectRow(o)">
                  <i class="fa fa-pencil"></i>
                </button>
  
                <button *ngIf="isSoluzione1" type="button" class="btn btn-danger btn-outline" (click)=" deleteObliterator(o.idObliterator)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>


<ng-template #template>
  <div>

    <div class="modal-content  modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="closeModal()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Cambia Versione Target</h4>

      </div>

      <div>

        <div class="ibox-content">
          <h3>Versione Attuale : {{ObliteratorModel.softwareVersion}}</h3>



          <br>


          <div class="form-group">
            <label>Versione Target</label>
            <select (change)="onChangeVersion($event.target.value)" type="text" id="targetVersion" name="targetVersion" class="form-control" ngModel required
            targetVersion #targetVersion="ngModel">
            <!--    <input (keyup)="onKey($event)" type="text" id="targetVersion" name="targetVersion" class="form-control" ngModel required targetVersion #targetVersion="ngModel">-->
            <option *ngFor="let r of dataVersionObliterator" [value]="r.version"> {{r.version}}</option>
          </select>
          </div>



        </div>

      </div>



      <div class="modal-footer">

        <button title="Cambia Versione Target" type="button" (click)="updateVersion()" class="btn btn-info">
          Cambia
        </button>
      </div>
    </div>
  </div>

</ng-template>



<ng-template #allVersion>
  <div>

    <div class="modal-content  modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="closeModal()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Cambia Versione Target</h4>

      </div>

      <div>

        <div class="ibox-content">
          <h3> Le versioni target delle seguenti obliteratrici saranno modificate:</h3>


          <div *ngFor="let o of obliterators">


            <strong> {{o.name}}</strong>



          </div>



          <br>


          <div class="form-group">
            <label>Versione Target</label>
            <div class='input-group col-xs-6'>

              <select  (change)="onChangeVersion($event.target.value)" type="text" id="targetVersion" name="targetVersion" class="form-control" ngModel required
                targetVersion #targetVersion="ngModel">
                <!--    <input (keyup)="onKey($event)" type="text" id="targetVersion" name="targetVersion" class="form-control" ngModel required targetVersion #targetVersion="ngModel">-->
                <option *ngFor="let r of dataVersionObliterator" [value]="r.version"> {{r.version}}</option>
              </select>
            </div>
          </div>



        </div>

      </div>



      <div class="modal-footer">

        <button title="Cambia Versione Target" type="button" (click)="updateAllVersion()" class="btn btn-info">
          Cambia
        </button>
      </div>
    </div>
  </div>

</ng-template>