<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} obliteratrice</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #obliteratorForm="ngForm">
          <div class="ibox-content">

            <div *ngIf="isSol1" class="form-group form-group col-xs-12 col-sm-6">
              <label for="idCompany">Azienda di trasporto</label>
              <select (change)="checkVehicle($event.target.value)" type="text" id="idCompany" class="form-control" [ngModel]="dataObliterator?.idCompany"
                ngModel name="idCompany"  idCompany #idCompany="ngModel" idResale>
                <option  [ngValue]=null>---</option>
                <option *ngFor="let r of companiesList" [ngValue]="r.idCompany"> {{r.legalBusinessName}}</option>
              </select>
            </div>

            <div class="form-group col-md-6">
              <label for="name">Nome</label>
              <input type="text" id="name" class="form-control" ngModel [ngModel]="dataObliterator?.name" name="name" required #name="ngModel">
            </div>

            <div class="form-group col-md-6">
              <label for="serialNumber">Serial Number</label>
              <input type="text" id="serialNumber" name="serialNumber" class="form-control" ngModel [ngModel]="dataObliterator?.serialNumber"
                required serialNumber #serialNumber="ngModel">
            </div>

            <div class="form-group col-md-6">
              <label for="macAddress"> Mac Address</label>
              <input type="text" id="macAddress" class="form-control" ngModel [ngModel]="dataObliterator?.macAddress" name="macAddress"
                required macAddress #macAddress="ngModel">
            </div>

            <div *ngIf= "kindManage == 0"  class="form-group col-md-6">
              <label for="softwareVersion">Versione Software</label>
              <input [disabled]="kindManage == 0" type="text" id="softwareVersion" name="softwareVersion" class="form-control" ngModel [ngModel]="dataObliterator?.softwareVersion"
                 softwareVersion #softwareVersion="ngModel">
            </div>
    
            <div *ngIf= "kindManage == 0" class="form-group col-md-6">
              <label for="softwareVersion">Versione Software Target</label>
              <select [ngModel]="dataObliterator?.softwareVersionTarget"  type="text" id="softwareVersionTarget" name="softwareVersionTarget" class="form-control" ngModel required softwareVersionTarget #softwareVersionTarget="ngModel">
                <option *ngFor="let r of dataVersionObliterator" [ngValue]="r.version">{{r.version}}</option>
              </select>
            </div>

            <div *ngIf="isSol1 && !clear" class="form-group col-md-6">
              <label for="idVehicle">Veicolo</label>
              <select type="text" id="idVehicle" class="form-control" ngModel [ngModel]="dataObliterator?.idVehicle" name="idVehicle" 
                idVehicle #idVehicle="ngModel" >
                <option  [ngValue]=null>---</option>
                <option *ngFor="let o of filterArray" [ngValue]="o.id">{{o.name}}</option>
              </select>
            </div>

            <div *ngIf="!isSol1 && !clear" class="form-group col-md-6">
              <label for="idVehicle">Veicolo</label>
              <select type="text" id="idVehicle" class="form-control" ngModel [ngModel]="dataObliterator?.idVehicle" name="idVehicle" 
                idVehicle #idVehicle="ngModel" >
                <option  [ngValue]=null>---</option>
                <option *ngFor="let o of vehicleList" [ngValue]="o.id">{{o.name}}</option>
              </select>
            </div>

            <div class="form-group col-md-6">
              <label for="dateSell">Data di vendita</label>
              <input type="text"
                class="form-control"
                name="dateSell"
                id="dateSell"
                [ngModel]="dataObliterator?.dateSell"
                required
                bsDatepicker
                #bsDatepicker
                placement="bottom"
                autocomplete="off"
                [bsConfig]="bsConfig" />
            </div>

            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!obliteratorForm.valid">Salva </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>