<!-- <div class="row" style="padding-top: 20px"></div> -->
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">

      <div class="ibox float-e-margins ">

        <div class="ibox-title">
          <h2 class="col-xs-11">
            <strong>Creazione Vouchers</strong>
          </h2>
        </div>
        
        <form (ngSubmit)="onSubmit()" #formVoucherNew="ngForm">

            <div class="ibox-content">
              <div class="row">

                <div class="col-sm-6 col-md-4 form-group">
                  <label for="description">Descrizione <span style="color: red;">*</span></label>
                  <input class="form-control" name="description" id="description" required #description ngModel />
                </div>
    
                <div class="col-sm-6 col-md-4 form-group">
                  <label for="selectChannel">Channel <span style="color: red;">*</span></label>
                  <!-- <input class="form-control" name="channel" id="channel" #channel ngModel /> -->
                  <select type="text" class="form-control" id="selectChannel" required [(ngModel)]="voucherChannel" name="selectChannel" #selectChannel
                    (change)="manageChannelChange()">
                    <option *ngFor="let c of channelList" [value]="c.name">{{c.name}}</option>
                  </select>
                </div>

                <div class="col-sm-6 col-md-4 form-group" *ngIf="voucherChannel !== 'PRATICKO'">
                  <label for="prefix">Prefisso</label>
                  <input class="form-control" name="prefix" id="prefix"
                    placeholder="Inserire codice di 3 lettere" maxlength="3"
                    oninput="this.value = this.value.toUpperCase()" #prefix ngModel />
                </div>
    
                <div class="col-sm-12"></div>
    
                <div class="col-sm-6 col-md-4 form-group">
                  <label for="numberOfCodes">Quantità <span style="color: red;">*</span></label>
                  <input type="number" min="1" class="form-control" required name="numberOfCodes" id="numberOfCodes" #numberOfCodes ngModel />
                </div>
    
                <div class="col-sm-6 col-md-4 form-group">
                  <label for="codeType">Tipologia codice <span style="color: red;">*</span></label>
                  <!-- <input class="form-control" name="codeType" id="codeType" #codeType ngModel /> -->
                  <select type="text" class="form-control" id="codeType" [(ngModel)]="selectedCodeType" required name="codeType" #codeType
                    [ngClass]="{'ptkDisabled': voucherChannel === 'PRATICKO'}">
                    <option *ngFor="let ct of codetypeList" [value]="ct">{{ct}}</option>
                  </select>
                </div>
    
                <div class="col-sm-6 col-md-4 form-group">
                  <label for="price">Prezzo <span style="color: red;">*</span></label>
                  <input type="number" step="0.05" class="form-control" name="price" id="price" required #price ngModel />
                </div>
    
                <div class="col-sm-6 col-md-4 form-group">
                  <label for="dateFrom">Valido dal</label>
                  <input type="text"
                    class="form-control"
                    name="dateFrom"
                    id="dateFrom"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
    
                <div class="col-sm-6 col-md-4 form-group">
                  <label for="dateTo">Valido a</label>
                  <input type="text"
                    class="form-control"
                    name="dateTo"
                    id="dateTo"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>

                <div class="col-sm-6 col-md-4 form-group">
                  <label for="routeId">Linea</label>
                  <!-- <input class="form-control" name="routeId" id="routeId" #routeId ngModel /> -->
                  <select type="text" class="form-control" id="routeId" name="routeId" (change)="onChangeRoute($event.target.value)" #routeId>
                    <option [value]="'all'">Tutte</option>
                    <option *ngFor="let r of routeList, let x = index" [value]="x">{{r.routeLongName}}</option>
                  </select>
                </div>
    
                <div class="col-sm-12"></div>

                <div class="form-group col-sm-6 col-md-4 " *ngIf="showTrip && utils.configurations?.company?.idCompany != 5">
                  <label for="direction">Direzione </label>
                  <select type="text" id="direction" (change)="getDirection($event.target.value)" class="form-control" ngModel name="direction"
                      direction #direction="ngModel" [(ngModel)] = "modTicketDir">
                      <option *ngFor="let d of modDirectionList, let f = index" [value]="f">{{d.arrival}} </option>
                  </select>
                </div>

                <div  class="col-sm-6 col-md-4" *ngIf="showTrip && utils.configurations?.company?.idCompany != 5">
                  <div class="form-group">
                      <label>Data di viaggio</label>
                      <div class='input-group col-xs-12'>
                        <input type="text"
                          class="form-control"
                          name="dateNewTicket"
                          id="dateNewTicket"
                          ngModel
                          required
                          bsDatepicker
                          #bsDatepicker
                          placement="bottom"
                          autocomplete="off"
                          (bsValueChange)="onDateChangedModal($event)"
                          [bsConfig]="bsConfig" />
                      </div>
                  </div>
                </div>

                <div class="form-group col-sm-6 col-md-4" *ngIf="showTrip && utils.configurations?.company?.idCompany != 5">
                  <label>Corsa</label>
                  <div class='input-group col-lg-12  col-xs-12'>
                    <select type="text" id="tripId" class="form-control" ngModel name="tripId" tripId #tripId="ngModel" [(ngModel)] = "modTicketTrip">
                      <option [ngValue]="null"> Tutte </option>
                      <option *ngFor="let data of modTripList,let i = index" [value]="data.tripId">{{data.departureTime}} - {{data.arrivalTime}} ({{data.tripId}})</option>
                  </select>
                  </div>
                </div>
    
                <div class="col-sm-12"></div>

                <ng-container *ngIf="formVoucherNew.value.codeType !== 'QRCODE'">
                  <div class="form-group col-sm-6 col-md-4">
                    <label for="typeTicket">Tipo <span style="color: red;">*</span></label>
                    <select  type="text" id="typeTicket" class="form-control" ngModel name="typeTicket"  (change)="onChangeType($event.target.value)" typeTicket #typeTicket="ngModel"
                    [ngModel]="ticketFaresData?.typeTicket" >
                      <option *ngFor="let c of ticketTypes" [value]="c.id">{{c.value}}</option>
                    </select>
                  </div>
      
                  <div class="form-group col-sm-6 col-md-4">
                    <label for="categoryGroupId">Gruppo <span style="color: red;">*</span></label>
                    <!-- <select type="text" id="categoryGroupId"></select> -->
                    <select type="text" id="categoryGroupId" class="form-control" ngModel name="categoryGroupId" (change)="onChangeBtn($event.target.value)" categoryGroupId #categoryGroupId="ngModel"
                      [ngModel]="ticketFaresData?.categoryGroupId" >
                      <option *ngFor="let g of groupCategories; let i = index" [value]="i">{{g.name}}</option>
                    </select>
                  </div>
                </ng-container>

                <div class="col-sm-6 col-md-4 form-group">
                  <label for="IdCategory">Categoria <span style="color: red;">*</span></label>
                  <select type="text" id="categoryId" class="form-control" ngModel name="categoryId" required
                    categoryId #categoryId="ngModel" [ngModel]="ticketFaresData?.idCategory" required>
                    <option [value]="0">Seleziona una categoria</option>
                    <option *ngFor="let c of categories" [value]="c.categoryId">{{c.description}}</option>
                  </select>
                </div>

              </div>  
            </div>
  
            <div class="ibox-footer">
              <button class="btn btn-default" (click)="returnToList()" type="button">Annulla</button>
              <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!formVoucherNew.valid">Salva </button>
            </div>

        </form>

      </div>

    </div>

  </div>
</div>