<div class="loginColumns animated fadeInDown">
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
      <div class="ibox-content">
        <div class="text-center">
          <i class="fas fa-store fa-9x"></i>
        </div>
        <br>

        <form  #f="ngForm">
          <p *ngIf="errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
          <p *ngIf="availableContent && dateDesk.length === 0 && !errorMessage" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i>
            Non ci sono desk associati alla compagnia di trasporto. Contatta un utente di ruolo Amministratore per creare un desk da poter utilizzare.
          </p>
          
          <div class="form-group" *ngIf="availableContent && dateDesk.length > 0">
            <br>
            <label for="username">Desk</label>
            <select type="text" id="desk" class="form-control" ngModel name="desk" required desk #desk="ngModel">
              <option  *ngFor="let d of dateDesk" [ngValue]="d.id"> {{d.name}} </option>
            </select>
          </div>
          <br>

          <button *ngIf="availableContent && dateDesk.length > 0" (click)="selectDeskAndGoToHome()" [disabled]="!f.valid"
            class="btn btn-redBU block full-width m-b">Scegli Desk</button>
          <button *ngIf="availableContent && dateDesk.length === 0" (click)="logout()" [disabled]="!f.valid"
            class="btn btn-redBU block full-width m-b">Logout</button>
        </form> 
      </div>
    </div>
  </div>
</div>
