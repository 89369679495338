<div class="row" style="padding-top: 20px"></div>
  <div class="col-md-12">
    <div  class="ibox float-e-margins ">
      <div class="ibox-title">
        <h2 class="col-xs-10"><strong>Tipologia Abbonamenti</strong></h2>
        <div class="ibox-tools col-xs-2 pull-right">
            <button type="button" (click)="newTicketTipology()" class="btn btn-view btn-outline"> <i class="fa fa-plus"></i></button>
        </div>
      </div>
      
      <div class="ibox-content">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <form #formSubscriptionTypology="ngForm">
                <div   *ngIf="isSoluzione1" class="form-group col-sm-6 col-md-4  col-lg-2">
                  <label for="idCompany"> Azienda di trasporto </label>
                  <select
                    type="text"
                    id="idCompany"
                    class="form-control"
                    ngModel
                    name ="idCompany"
                    idCompany
                    #idCompany="ngModel"
                    required >
                    <option [ngValue] = "'all'" > Tutte </option>
                      <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                  </select>
                </div>
                <div class='col-sm-6 col-md-4 col-lg-2'>
                  <div class="form-group">
                    <label>Tipologia</label>
                    <div class='input-group col-xs-12'>
                    <input
                      type="text"
                      id="ticketTypeName"
                      name="ticketTypeName"
                      class="form-control"
                      ngModel
                      required
                      ticketTypeName
                      #ticketTypeName= "ngModel">
                    </div>
                  </div>
                </div>
                <div class='col-xs-6 col-sm-3 col-md-3'>
                    <div class="form-group">
                        <label></label>
                        <div class='input-group col-md-12'>
                            <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                            <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                        </div>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example" >
            <thead>
              <tr>
                <th *ngIf="isSoluzione1" >Azienda di trasporto</th>
                <th>Tipologia</th>
                <th>Validità</th>
                <th *ngIf="!isMobileOperator"></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList"  style="cursor:pointer">
                <td *ngIf="isSoluzione1" style="width: 200px;" > {{getBusinessName(data.idCompany)}}</td>
                <td>{{data.name}}</td>
                <td>{{data.subscription}} Giorni </td>
                <td *ngIf="!isMobileOperator" style="width: 100px" class="col-md-4 text-center"> 
                  <button  style="float:left"  type="button" class="btn btn-success btn-outline " (click)="selectRow(data)"><i class="fa fa-pencil"></i></button>
                 
                 
                  <button style="float:right" *ngIf ="data.numPricelist == 0" type="button" class="btn btn-danger btn-outline " (click)=" deleteTypology(data.idTicketType)">
                      <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>         
          </table>
          <ngb-pagination 
            *ngIf="listService.totalRows > listService.rows"
            class="pull-right" 
            [collectionSize]="listService.totalRows"
            [maxSize]="10"
            [pageSize]="listService.rows" 
            [(page)]="listService.page"
            (pageChange)="pageChange($event)" >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
