import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersEcommerceComponent } from './users-ecommerce/users-ecommerce.component';
import { UsersManageComponent } from './users-manage/users-manage.component';
import { UsersPassesComponent } from './users-passes/users-passes-list/users-passes-list.component';
import { UsersPassesManageComponent } from './users-passes/users-passes-manage/users-passes-manage.component';
import { UsersValidationsComponent } from './users-validations/users-validations-list/users-validations-list.component';
import { UsersValidationsManageComponent } from './users-validations/users-validations-manage/users-validations-manage.component';
import { BrowserModule } from "@angular/platform-browser";
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    UsersListComponent,
    UsersManageComponent,
    UsersEcommerceComponent,
    UsersPassesComponent, 
    UsersPassesManageComponent,
    UsersValidationsComponent,
    UsersValidationsManageComponent
  ]
})
export class UsersModule {}