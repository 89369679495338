import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { BrowserModule} from "@angular/platform-browser";
import { DocumentsChecklistDetailsComponent } from './documents-checklist-details/documents-checklist-details.component';
import { DocumentsSupplierComponent } from './documents-supplier/documents-supplier.component';
import { DocumentsViewerComponent } from './documents-viewer/documents-viewer.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DocumentsNewComponent } from './documents-new/documents-new.component';
import { DialogViewerComponent } from './dialog/dialog-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    FileUploadModule,

  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [DocumentsListComponent, DocumentsChecklistDetailsComponent, DocumentsSupplierComponent, DocumentsNewComponent, DocumentsViewerComponent, DialogViewerComponent]
})
export class DocumentsModule { }
