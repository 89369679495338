import { Component } from '@angular/core';
import { smoothlyMenu } from '../../app.helpers';
import { Router } from '@angular/router';
import { ListService } from '../../services/list.service';
import { UtilsService } from '../../services/utils.service';

declare var jQuery: any;

@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.component.html'
})
export class TopnavbarComponent {

    typeUser;
    roleUser;
    classUser;

    constructor(private router: Router, public listService: ListService, private utils: UtilsService) {
        this.typeUser = this.utils.getTypeUser();
        this.roleUser = this.utils.getRoleUser();
        this.classUser = this.utils.getClassTypeUser();
    }

    toggleNavigation(): void {
        jQuery('body').toggleClass('mini-navbar');
        smoothlyMenu();
    }

    backToList() {
        // alert(this.listService.listRouting);
        this.router.navigate([this.listService.listRouting]);
    }

    goToUserProfile() {
        this.router.navigate(['/user-profile/myProfile']);
    }

    logout() {
        localStorage.clear();
        this.listService.clearConfigurations();
        this.router.navigate(['\logout\expired']);
    }

}
