import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import swal2 from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';


@Component({
  selector: 'app-acl-manage',
  templateUrl: './acl-manage.component.html',
  styleUrls: ['../../../app.component.css']
})

export class AclManageComponent implements OnInit {

    // testo in HTML per tipo pagina
    kindTitle;
    kindManage;
    // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = '/acl';
    urlUsersPath = '/user/users';
    urlEcommercesPath = '/ecommerce/ecommerces';
    urlCompanyPath = '/company/companies';
    // form html
    @ViewChild('aclForm') formAcl: NgForm;
    // oggetto contenente dati component
    dataAcl: any;
    usersList: any[] = new Array();
    ecommercesList: any[] = new Array();
    companiesList: any[] = new Array();
    isSol1;
    typeUser;
    idCompany: String;
    companyValue;

   checkedInternal: any  = new Array()

    public myDatePickerOptions = {
        // other options...
        dateFormat: 'dd-mm-yyyy',
    };

    errorMessage;

    constructor(
        private utils: UtilsService,
        private route: ActivatedRoute,
        private logger: LoggingService,
        public listService: ListService,
        private router: Router
    ) {
        // Se creo nuovo acl
        if (this.route.snapshot.toString().indexOf('new') !== -1) {
            this.kindTitle = 'Nuova';
            this.kindManage = 1;
        } else { // se modifico acl
            this.kindTitle = 'Modifica';
            this.kindManage = 0;
            if (localStorage.getItem('dataPassed') === '') {
                this.router.navigate(['/utility/acl/list']);
                return;
            }
            this.dataAcl = JSON.parse(localStorage.getItem('dataPassed'));
            localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        }
    }

    ngOnInit() {
        this.idCompany = localStorage.getItem('idCompany');
        this.companyValue =  Number(this.idCompany);
        console.log('result', this.idCompany);
        this.listService.resetList();
        this.getUsers();
        this.getEcommerces();
        this.listService.listRouting = '/utility/acl/list';
        this.listService.backToList = true;
        this.getCompanies();
        this.typeUser = this.utils.getTypeUser();
        this.isSol1 = this.utils.isSol1(this.typeUser);
    }

    // SERVIZI SELECT
    getUsers() {
        this.listService.getListSelect(this.urlUsersPath).subscribe((response) => {
            this.usersList = response.results;
            this.logger.log('Lista utenti', this.usersList, 300);
        },
        (error) => {
            this.logger.log('Error', error, 200);
        }
        );
    }

    getEcommerces() {
        this.listService.getListSelect(this.urlEcommercesPath).subscribe((response) => {
            this.ecommercesList = response.results;
            this.logger.log('Lista ecommerce', this.ecommercesList, 300);
        },
        (error) => {
            this.logger.log('Error', error, 200);
        }
        );
    }

    getCompanies() {
        this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
            this.companiesList = response.results;
            this.logger.log('Lista aziende', this.companiesList, 300);
        },
        (error) => {
            this.logger.log('Error', error, 200);
        }
        );
    }

    // MODIFICA - CREA OGGETTO
    onSubmit(): void {
        swal2.fire({title: 'Operazione in corso...', didOpen: function () {swal2.showLoading()}});

        // COPIA DA FORM CON VALORI
        const formDataAcl = this.formAcl.value;

        if (this.kindManage) {
            this.listService.newObject(formDataAcl, this.serviceToCallPath).subscribe(
                (response) => {
                    if (response.outcome.success === true) {
                        swal2.fire('La Regola è stata creata', '', 'success');
                        this.router.navigate(['/utility/acl/list']);
                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire('Attenzione', this.errorMessage, 'warning');
                    }
                },
                (error) => {
                    this.logger.log('Error', error, 200);
                    this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
                    swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
                }
            );
        } else {
            formDataAcl.id = this.dataAcl.id;
            this.listService.edit(formDataAcl, this.serviceToCallPath).subscribe(
                (response) => {
                    if (response.outcome.success === true) {
                        swal2.fire('La Regola è stata modificata', '', 'success');
                        this.router.navigate(['/utility/acl/list']);
                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire('Attenzione', this.errorMessage, 'warning');
                    }
                },
                (error) => {
                    this.logger.log('Error', error, 200);
                    this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
                    swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
                }
            );
        }
    }

    back() {
        this.router.navigate(['/utility/acl/list']);
    }
}
