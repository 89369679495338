import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { TooltipModule } from 'ngx-bootstrap/tooltip';

/* import { TicketsTypologyComponent } from './tickets-typology/tickets-typology.component';
import { TicketsTypologyManageComponent } from './tickets-typology-manage/tickets-typology-manage.component';

import { TicketsFaresComponent } from './tickets-fares/tickets-fares.component';
import { TicketsFaresManageComponent } from "./tickets-fares-manage/tickets-fares-manage.component"; */

import { VoucherListComponent } from './voucher-list/voucher-list.component';
import { VoucherLottiComponent } from './voucher-lotti/voucher-lotti.component';
/* import { TicketsListManageComponent } from './tickets-list-manage/tickets-list-manage.component'; */
import { VoucherNewComponent } from './voucher-new/voucher-new.component';

// or
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
/* import { TicketPromotionsManageComponent } from './ticket-promotions-manage/ticket-promotions-manage.component';
import { TicketPromotionsComponent } from './ticket-promotions/ticket-promotions.component'; */

import { NgxBarcode6Module } from 'ngx-barcode6';
import { QrCodeModule } from 'ng-qrcode';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    QrCodeModule,
    NgxBarcode6Module
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ VoucherListComponent, VoucherLottiComponent , VoucherNewComponent ],// TicketsTypologyComponent, TicketsFaresComponent, TicketsTypologyManageComponent, TicketsFaresManageComponent , TicketsListManageComponent, TicketPromotionsManageComponent, TicketPromotionsComponent
})
export class VouchersModule {}