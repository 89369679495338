import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-select-desk',
  templateUrl: './select-desk.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SelectDeskComponent implements OnInit {
  @ViewChild('f') loginForm: NgForm;

  dataFilter: any[] = new Array();
  dateDesk: any[] = new Array();
  errorMessage = "";
  availableContent = false;

  constructor(
    public listService: ListService,
    public utils: UtilsService,
    private router: Router,
    private logger: LoggingService
  ) {
    this.listService.restURL = "/desk/desks";
    this.listService.configurationServiceCall(
      "/desk/list",
      false,
      true,
      1,
      10
    );
  }

  ngOnInit() {
    this.loadElements();
  }

  selectDeskAndGoToHome(){
    var desk = this.loginForm.value.desk
    this.router.navigate(['/sale/ticket']);
    localStorage.setItem("deskId", desk);
  }

  getToken(): string {
    return localStorage.getItem("token");
  }

  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.availableContent = true;
        this.dateDesk = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:", response, 300);
        this.errorMessage = "";
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  logout(): void {
    this.listService.sessionExpired();
  }

}