import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import { LoggingService } from '../../../services/logging.service';
import { AuthService } from "../../../services/auth.service";

import swal2 from 'sweetalert2'

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['../../../app.component.css']
})

export class LoginComponent  implements OnInit{   
  
  
  ngOnInit() {

  }
  constructor(
    private router: Router,
    private logger: LoggingService, 
    private authService: AuthService 
  ) {}

  @ViewChild('f') loginForm: NgForm;
  
  errorMessage = "";
  loginData = {
    username: "",
    password: "",
  };

  onSubmit(): void {
    //this.visible = true; per lo spinner
    this.loginData.username = this.loginForm.value.username;
    this.loginData.password = this.loginForm.value.password;
    
    //swal("Login in corso...",{closeOnClickOutside: false,closeOnEsc: false,button: false,});
    swal2.fire({
      title: 'Login in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })

    this.authService.login(this.loginData).subscribe(
      (response) => {

        if (response.outcome.success === true) {
            // login successfull
            swal2.close();


          if(response.roleUser == "ADMINISTRATOR" && response.typeUser == "COMPANY" )   {
            
            this.router.navigate(['/companies/details']);
          }
          else if(response.roleUser == "OPERATOR" && response.typeUser == "COMPANY" ){
            this.router.navigate(['/choose/desk']);
          }
          else if(response.typeUser == "RESALE" ){
            this.router.navigate(['/resales/details']);
          }
          else{
            this.router.navigate(['/']);
          }

          this.authService.paymentTypes().subscribe((response) => {
 
              this.logger.log("CARDS - PTYPES", response, 200);
            },    
            (error) => {
              this.logger.log("Error",error,200);
              this.errorMessage = "Errore estrazione payment_types";
              swal2.fire("Errore", this.errorMessage,"error");
            }
          );
           
           // this.visible = false;
       
        } else {
            // login failed
           
            if(response.outcome.code == "0003"){
              this.errorMessage = "Credenziali non valide."
            } else if(response.outcome.code == "0004"){
              this.errorMessage = " La password è scaduta."
            } else if(response.outcome.code == "0006"){
              this.errorMessage = " Account disabilitato."
            } else if (response.outcome.code == "0051") {
              this.errorMessage = "Impossibile loggarsi, rivendita disabilitata."
            }

            swal2.fire("Errore", this.errorMessage, "error");
            
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire("Errore", this.errorMessage,"error");
      }
    );
  }

  getToken(): string {
    return localStorage.getItem("token");
   
  }

  forgotPwd(): void {
    this.router.navigate(['/forgot-pwd']);
  }
}