import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  NgForm
} from "@angular/forms";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from "../../../services/utils.service";


import swal2, { SweetAlertResult } from "sweetalert2";

@Component({
  selector: 'app-desk-list',
  templateUrl: './desk-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class DeskListComponent implements OnInit {
  @ViewChild("formResale") resaleForm: NgForm;

  errorMessage: string = "";

  dataFilter: any[] = new Array();
  visileNewResale = false;
  typeUser;
  companiesList: any[] = new Array();
  urlCompany = "/company/companies";

  serviceToEnablePdf = "/ticket/pdfenable";
  urlDeletePdfPath = "/ticket/pdfenable/"

  userID = 0;
  urlDeletePath;

  serviceToCallPath;

  visibleFilter;
  defaultValue;

  isSoluzione1;

  UpOrDown;

  dataResale: any[] = new Array();

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService
  ) {
    this.listService.restURL = "/desk/desks";
    this.listService.configurationServiceCall(
      "/desk/list",
      false,
      true,
      1,
      10
    );
  }

  async ngOnInit() {
    this.loadElements();
    this.serviceToCallPath = "/resale";
    this.UpOrDown = true;
    this.typeUser = this.utils.getTypeUser();
    this.visileNewResale = this.utils.newResaleManager(this.typeUser);
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.companiesList = await this.utils.getCompanyList();
  }
  changeStatus() {
    if (this.UpOrDown == true) {
      this.UpOrDown = false;

      console.log("VIEE", this.UpOrDown);
    } else {
      this.UpOrDown = true;
    }
  }

  eligibiltyPrintPDF(idPrinter) {
    var mySelf = this;

    swal2.fire({
      title: "Abilitazione stampa PDF",
      text: "Procedere?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sì",
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      function(ev: SweetAlertResult) {
        if (ev.value) {
          //   mySelf.deleteElement(idPrinter);
        }
      },
      function(dismiss) {
        if (dismiss === "cancel") {
          swal2.close();
        }
      }
    );
  }

  getStatusDescription(statusNumber) {
    var buttonColor;
    if (statusNumber == 1) {
      return (buttonColor = "Attivo");
    }
    if (statusNumber == 0) {
      return (buttonColor = "Non Attivo");
    }
  }

  getButtonCircle(statusNumber) {
    var buttonColor;
    if (statusNumber == 1) {
      return (buttonColor = "btn btn-primary btn-circle");
    }
    if (statusNumber == 0) {
      return (buttonColor = "btn btn-danger btn-circle");
    }
  }

  getBusinessName(id) {
    var stringName;
    for (let company of this.companiesList) {
      if (company.idCompany == id) {
        return (stringName = company.legalBusinessName);
      }
    }
  }

  getClassEnablePdf(pdf) {
    var value;

    if (pdf != null) {

      value = "btn btn-primary"
    }
    else{
      value = "btn btn-danger"
    }

    return value 
  }

  getClassIcon(pdf){
    var value;
    if (pdf != null) {
      value = "fa fa-check";
    } else {
      value = "fa fa-square";
    }

    return value;
  }

  getStyle() {
    var value;
    if (this.isSoluzione1) {
      value = "min-width: 150px";
    } else {
      value = "min-width: 100px";
    }

    return value;
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }
  recharge(resale) {
    localStorage.setItem("dataPassed", JSON.stringify(resale));
    this.listService.backToList = true;
    this.router.navigate(["/wallet/recharge"]);
  }


  transactionResales(idResaleCredit){

    this.listService.backToList = true;
    localStorage.setItem("idResaleCredit", idResaleCredit)



    this.router.navigate(["/wallet/list"]);
  }

  selectRow(resale) {
    localStorage.setItem("dataPassed", JSON.stringify(resale));
    this.listService.backToList = true;
    this.router.navigate(["/desk/edit"]);
  }

  checkStatus(status) {
    return this.utils.checkStatus(status);
  }

  newResale() {
    this.listService.backToList = true;
    this.router.navigate(["/desk/new"]);
  }

  filterSearch() {
    this.dataFilter = this.resaleForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset() {
    this.resaleForm.value.name = "";

    this.listService.visibleFilter = false;
    this.loadElements();
    this.resaleForm.reset();
    //this.loadElements();
  }

  loadElements() {
    console.log(this.dataFilter)
    this.listService.getListFilter(this.dataFilter).subscribe(
      
      response => {
        this.dataResale = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:", response, 300);
        this.errorMessage = "";
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  checkKind(type){
    var string = ""
    if(type == true){
      string = "Interna"
    }else{
      string = "Esterna"
    }
    return string;
  }


  openpopUp(id, pdf) {



    var mySelf = this;



    if(pdf == null){



      swal2.fire({
        title: "Abilitazione Rivendita",
        text: "Sei sicuro di voler abilitare la rivendita  alla stampa pdf?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sì, abilita",
        cancelButtonText: "No",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(
        function(ev: SweetAlertResult) {
          if (ev.value) {
            mySelf.enablePdf(id, pdf);
          }
        },
        function(dismiss) {
          if (dismiss === "cancel") {
            swal2.close();
          }
        }
      );

    }
    else{


      swal2.fire({
        title: "Disabilitazione Rivendita",
        text: "Sei sicuro di voler disabilitare la rivendita  alla stampa pdf?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sì, disabilita",
        cancelButtonText: "No",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(
        function(ev: SweetAlertResult) {
          if (ev.value) {
            mySelf.enablePdf(id, pdf);
          }
        },
        function(dismiss) {
          if (dismiss === "cancel") {
            swal2.close();
          }
        }
      );


    }




   

   
  }

  onSubmit(): void {}

  enablePdf(id, pdf) {

    if(pdf == null){

    
    var model = {
      idResale: id,
      idUser: null
    };
    this.listService.newObject(model, this.serviceToEnablePdf).subscribe(
      response => {
        if (response.outcome.success === true) {
          swal2.fire("Stampa pdf Abilitata", "", "success");
          this.loadElements()
        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }

    
    );}


    else{


      console.log()

      this.listService.delete(this.urlDeletePdfPath + pdf).subscribe(
        response => {
          if (response.outcome.success === true) {
            this.logger.log("Response value", response.value, 200);
            swal2.fire("La rivendita non può stampare Pdf", "", "success")
              this.loadElements();
       
          } else {
            this.errorMessage =
              response.outcome.code + response.outcome.description;
            swal2.fire("Attenzione", this.errorMessage, "warning");
          }
        },
        error => {
          this.logger.log("Error", error, 200);
          swal2.fire(
            "Errore",
            "Spiacente, si è verificato un errore tecnico.Riprova più tardi",
            "error"
          );
        }
      );


    }
  }

  changeUserID(id, resaleName) {
    this.userID = id;

    var mySelf = this;

    swal2.fire({
      title: "Eliminazione Desk",
      text: "Sei sicuro di voler eliminare il desk " + resaleName + " ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sì, elimina",
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      function(ev: SweetAlertResult) {
        if (ev.value) {
          mySelf.deleteUser(id);
        }
      },
      function(dismiss) {
        if (dismiss === "cancel") {
          swal2.close();
        }
      }
    );
  }

  deleteUser(idUser) {
    this.urlDeletePath = "/desk/" + idUser;

    swal2.fire({
      title: "Eliminazione in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.logger.log("ID DELETE:", idUser, 200);

    this.listService.delete(this.urlDeletePath).subscribe(
      response => {
        if (response.outcome.success === true) {
          this.logger.log("Response value", response.value, 200);
          swal2.fire("Il desk è stato eliminato", "", "success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.Riprova più tardi",
          "error"
        );
      }
    );
  }
}

