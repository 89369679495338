import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from "../../services/logging.service";
import { ListService } from "../../services/list.service";
import { UtilsService } from '../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-date-trips',
  templateUrl: './date-trips.component.html',
  styleUrls: ['../../app.component.css']
})
export class DateTripsComponent implements OnInit {

  @ViewChild('formTimes') timesForm: NgForm;
  //OGGETTO LISTA ELEMENTI
  dataList: any[] = new Array();
  // dataList = [{  name: "BUS01",  brand: "Mercedes",  plate: "FF 000 EB",  status: 1}];
  dataFilter: any[] = new Array();
  //PATH CHIAMATE
  urlDeletePath = "";
  errorMessage: string = "";
  visibleFilter;
  defaultValue;
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  
  constructor(private utils: UtilsService, private logger: LoggingService, private router: Router, public listService: ListService){
    this.listService.resetList();
    this.listService.configurationServiceCall("/time/trips",false,true,1,20);
    this.listService.restURL = "/trip/tripsdate";
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }
  
  ngOnInit() {
    this.loadElements();

  }
  
  pageChange(page){
    this.listService.page = page; 
    this.loadElements();
  }
  
 
  
  
  //FILTRI
  filterSearch(){
    this.dataFilter = this.timesForm.value;
    if(this.timesForm.value.date != null &&  this.timesForm.value.date != undefined){
      this.dataFilter["date"] = this.utils.convertDateToISO(this.timesForm.value.date)
    }

    this.loadElements();
    this.listService.visibleFilter = true;
  }
  
  filterReset(){
    this.timesForm.value.date = ""
    this.timesForm.value.serviceId = ""
    this.listService.visibleFilter = false;
  
     this.timesForm.reset();
    this.loadElements();
  }
  
  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
  
        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }
  
  
  
  }
  