<!-- CHECKBOX GROUP -->
<div id="checkbox-group">
    <label class="text-center">{{ sectionTitle }}</label>
    <div class="row col-md-12">
      <div class="checkbox" *ngIf="elements">
        <div *ngFor="let el of elements, let i = index" class="input-container col-md-3">
            <input type="checkbox" name="el" id="{{'el' + i}}"
              [checked]="isElementSelected(el.code)"
              (change)="manageSelectedElements(el.code)">
            <label>{{ el.itemName }}</label>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button type="button" class="select-btn btn" [ngClass]="allElements ? 'btn-danger' : 'btn-redBU'"
        (click)="elementSelectManagement()">
        {{ allElements ? 'Deseleziona' : 'Seleziona tutte' }}
      </button>
    </div>
</div>