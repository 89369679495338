import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../../../../services/utils.service';
import { ListService } from '../../../../services/list.service';
import { LoggingService } from '../../../../services/logging.service';
import { ICompany, ICustomer, ICustomerValidationTypology, IPass, IValidation } from '../../../../interfaces/configuration.interface';
import { Router } from '@angular/router';
import { concat } from 'rxjs';
import { SortingDirections, ValidationSortingParams, ValidationStatus } from '../../../../enums/configuration.enum';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-users-validations',
  templateUrl: './users-validations-list.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class UsersValidationsComponent implements OnInit {

  @ViewChild('validationsForm') validationsForm: NgForm;

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;
  today = new Date();
  roleUser: string;
  typeUser: boolean;
  isSoluzione1: boolean;
  isCompanyAdministrator: boolean;
  isOperator: boolean;
  visiblePaging: boolean;
  showFilters: boolean;
  modalRef: BsModalRef;
  checkedEmail: string;
  errorMessage: string;
  toggledValidationIdx: number;
  loading: boolean = false;
  selectedValidation: IValidation;
  sortingEl: string;
  sortDirection: string;
  validationStatuses = ValidationStatus;

  dataFilter: any[] = [];
  companiesList: ICompany[] = [];
  dataList: IValidation[] = [];
  dataValidationTypologies: ICustomerValidationTypology[] = [];
  dataStatuses: { key: string; value: string }[] = [];
  dataPasses: IPass[] = [];
  validationFiles: File[] = [];

  pathToCallCompaniesPath = "/company/companies"
  urlCustomersPath = '/customer/customers?paging=false';
  urlCustomerValidationTypologies = '/customer/validation/typologies';
  urlCustomerValidationStatuses = '/multiselect/validation/status';
  urlCustomerValidationChangeStatus = '/customer/validation/change/status';
  urlCustomerValidationChangeExpireDate = '/customer/validation/change/expireDate';
  urlPassesPath = '/pass?paginated=false&notAssociated=true';

  validationSortingParams = ValidationSortingParams;
  sortingDirections = SortingDirections;

  constructor(
    public listService: ListService,
    public utils: UtilsService,
    private modalService: BsModalService,
    private logger: LoggingService,
    private router: Router
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
    this.listService.resetList();
    this.listService.configurationServiceCall("/users/validations", false, true, 1, 10);
    this.listService.restURL = '/customer/validation/validations';
    this.roleUser = this.utils.getRoleUser();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.isCompanyAdministrator = this.utils.viewAdministratorCompany(this.typeUser, this.roleUser);
    this.isOperator = this.utils.isOperator(this.roleUser);
  }

  async ngOnInit(): Promise<void> {
    await this.getCompanies();

    if (this.isCompanyAdministrator || this.isOperator) {
      await this.companySelectsFill();
      this.loadElements();
    }
  }

  getStatus(status: string): string {
    return this.dataStatuses.find(s => s.key === status).value;
  }

  pageChange(page: number): void {
    this.listService.page = page;
    this.loadElements();
  }

  openModal(template: TemplateRef<any>, validation?: IValidation, convertDate?: boolean): void {
    this.selectedValidation = {...validation, ...(convertDate) && { expireDate: validation.expireDate ? new Date(validation.expireDate) : null } };
    this.modalService.show(template);
  }

  checkEmail(email: string): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.checkedEmail = email;
    const requestPath = this.isSoluzione1 ? `${this.urlCustomersPath}&idCompany=${this.validationsForm.value.idCompany}` : this.urlCustomersPath;
    this.listService.getListSelect(`${requestPath}&email=${email}`).subscribe((response) => {
      const customerData: ICustomer = response.results[0];
      this.manageCustomerData(customerData);
    });
  }

  newValidation(customerData?: ICustomer): void {
    this.listService.backToList = true;

    /** When a customer is available the entire object is passed, otherwise only the email address */
    if (customerData) {
      localStorage.setItem('customerData', JSON.stringify(customerData));
    } else {
      localStorage.setItem('customerEmail', this.checkedEmail);
    }

    this.modalService.hide();

    this.router.navigate(['/users/validations/new']);
  }

  async onCompanySelect(): Promise<void> {
    this.dataList = [];
    this.listService.visibleFilter = false;

    if (this.validationsForm.value.idCompany === 'all') {
      this.showFilters = false;
    } else {
      await this.companySelectsFill(true);
      this.filterSearch(true);
    }
  }

  filterSearch(fromLoad: boolean = false): void {
    this.dataFilter = this.validationsForm.value;
    this.addSortingParams();
    
    if (!fromLoad) {
      this.listService.visibleFilter = true;
    }

    this.loadElements();
  }

  filterReset(): void {
    this.validationsForm.value.email = null;
    this.validationsForm.value.namePass = null;
    this.validationsForm.value.idFareCategory = 'all';
    this.validationsForm.value.status = 'all';
    this.validationsForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  getCompanyName(idCompany: number): string {
    let stringCompanyName: string;
    for (let company of this.companiesList) {
      if (company.idCompany === idCompany) {
        stringCompanyName = company.legalBusinessName
        return stringCompanyName;
      }
    }
  }

  toggleDetails(validation: IValidation, idx: number): void {
    this.loading = true;

    if ((this.toggledValidationIdx !== null && this.toggledValidationIdx !== undefined) && this.toggledValidationIdx !== idx) {
      this.dataList[this.toggledValidationIdx].expanded = false;
    }
    this.dataList[idx].expanded = !this.dataList[idx].expanded;

    if (this.dataList[idx].expanded) {
      this.listService.getListSelect(`/customer/validation/${validation.customer.idCustomer}/${validation.id}/files`).subscribe((response) => {
        this.validationFiles = response.results;
        this.loading = false;
      }); 
    } else {
      this.validationFiles = [];
      this.loading = false;
    }

    this.toggledValidationIdx = idx;
  }

  downloadFile(validation: IValidation, fileId: number): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    this.listService.downloadObject(`/customer/validation/${validation.customer.idCustomer}/${validation.id}/download/${fileId}`).subscribe(_ => {
      swal2.close();
    }, _ => {
      swal2.fire('Attenzione', 'Non è stato possibile scaricare il documento. Si prega di riprovare', 'error');
    });
  }

  assignPass(idPass: number): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    const associate$ = this.listService.edit(null, `/pass/associate/${idPass}/${this.selectedValidation.id}`);
    const activate$ = this.listService.edit({ id: this.selectedValidation.id, status: 'APPROVED' }, this.urlCustomerValidationChangeStatus);

    concat(associate$, activate$).subscribe({
      complete: () => {
        this.modalService.hide();
        swal2.fire({
          title: 'Tessera associata con successo',
          icon: 'success',
          text: 'Vuoi direttamente attivare la validazione?',
          showConfirmButton: true,
          confirmButtonText: 'Attiva',
          showDenyButton: true,
          denyButtonText: 'Chiudi'
        }).then((result: SweetAlertResult) => {
          if (result.isConfirmed) {
            this.changeStatus(this.validationStatuses.ACTIVE);
          } else {
            this.loadElements();
          }
        });
      },
      error: (error) => {
        swal2.close();
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
      }
    });
  }

  changeExpireDate(expireDate: Date): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    const requestBody = {
      id: this.selectedValidation.id,
      expireDate: expireDate.getTime()
    };
    this.listService.edit(requestBody, this.urlCustomerValidationChangeExpireDate).subscribe({
      next: (response) => {
        swal2.close();
        if (response.outcome.success === true) {
          swal2.fire('Data di scadenza modificata con successo', '', 'success');
          this.modalService.hide();
          this.loadElements();
        } else {
          swal2.fire('Attenzione', 'Non è stato possibile completare il cambio data. Si prega di riprovare', 'warning');
        }
      },
      error: (error) => {
        swal2.close();
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
      }
    });
  }

  changeStatus(status: string): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    const requestBody = {
      id: this.selectedValidation.id,
      status
    }
    this.listService.edit(requestBody, this.urlCustomerValidationChangeStatus).subscribe(response => {
      swal2.close();
      if (response.outcome.success === true) {
        swal2.fire('Cambio stato effettuato con successo', '', 'success');
        this.modalService.hide();
        this.loadElements();
      } else {
        swal2.fire('Attenzione', 'Non è stato possibile completare il cambio stato. Si prega di riprovare', 'warning');
      }
    }, (error) => {
      swal2.close();
      this.logger.log('Error', error, 200);
      swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
    });
  }

  deleteValidation(idValidation: number): void {
    let mySelf = this;

    swal2.fire({
      title: 'Eliminazione validazione',
      text: 'Sei sicuro di voler eliminare la validazione?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        const urlDeletePath = '/customer/validation/' + idValidation;
        mySelf.utils.deleteElement(urlDeletePath).then(_ => {
          swal2.fire('Eliminazione completata', 'Validazione eliminata con successo', 'success').then((ev: SweetAlertResult) => {
            if (ev.value) {
              mySelf.loadElements();
            }
          });
        }).catch(err => {
          mySelf.errorMessage = err;
          swal2.fire('Attenzione', mySelf.errorMessage, 'warning');
        });
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })
  }

  handleListSorting(column: string): void {
    this.sortDirection = (!this.sortDirection || this.sortingEl !== column)
      ? this.sortingDirections.asc : this.sortDirection === this.sortingDirections.asc ? this.sortingDirections.desc : this.sortingDirections.asc;
    this.sortingEl = column;
    this.addSortingParams();
    this.loadElements();
  }

  // ---------- PRIVATE METHODS ---------- //

  private getCompanies(): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
        this.companiesList = response.results;
        this.logger.log("Lista aziende", this.companiesList, 300);
        res();
      }, (error) => {
        this.logger.log("Error", error, 200);
        rej();
      });
    });
  }

  private loadElements(): void {
    this.listService.getListFilter(this.dataFilter).subscribe((response) => {
      this.dataList = response.results.map((v: IValidation) => {
        v.expanded = false;
        return v;
      });
      this.listService.totalRows = response.total;

      this.visiblePaging = this.listService.totalRows > 10 ? true : false;

      this.logger.log('Response: ',response,300);

      this.errorMessage = '';
    }, (error) => {
      this.logger.log('Error', error, 200);
      this.errorMessage = 'Non è stato possibile recuperare la lista delle validazioni, si prega di riprovare';
    });
  }

  private async companySelectsFill(filterByCompany: boolean = false): Promise<void> {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    if (this.isCompanyAdministrator || this.isOperator) {
      this.dataPasses = await this.utils.getSelectValues(filterByCompany, this.urlPassesPath, this.validationsForm?.value.idCompany);
    }

    this.dataValidationTypologies = await this.utils.getSelectValues(filterByCompany, this.urlCustomerValidationTypologies, this.validationsForm?.value.idCompany);
    this.dataStatuses = await this.utils.getSelectValues(filterByCompany, this.urlCustomerValidationStatuses, this.validationsForm?.value.idCompany, false);

    this.validationsForm.value.idCustomerValidationTypology = 'all';
    this.validationsForm.value.status = 'all';

    this.showFilters = true;

    swal2.close();
  }

  private manageCustomerData(customerData: ICustomer): void {
    if (customerData) {
      customerData.newValidationEnabled ? this.newValidation(customerData) : swal2.fire('Attenzione', 'Validazione già presente per l\'utente indicato', 'warning');
    } else {
      this.newValidation(null);
    }
  }

  private addSortingParams(): void {
    this.dataFilter['direction'] = this.sortDirection;
    this.dataFilter['orderBy'] = this.sortingEl ? `cv.${this.sortingEl}` : null;
  }

}