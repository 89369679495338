<div class="row" style="padding-top: 20px"></div>
<div class="col-xs-12">
  <div  class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11"><strong>Utenti eCommerce</strong></h2>
      <div class="ibox-tools col-xs-1 pull-right"></div>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
    
            <form #formUser="ngForm">

              <div *ngIf="isSoluzione1" class='col-sm-4 col-md-4 col-lg-4'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                      <option [ngValue] = "'all'" > Tutte </option>
                        <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}} </option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div class=' col-xs-12 col-sm-4 col-md-4 col-lg-4'>
                <div class="form-group">
                  <label>Mail</label>
                  <div class='input-group col-xs-12'>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    class="form-control"
                    ngModel
                    username
                    #username= "ngModel">
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-4 col-md-4'>
                  <div class="form-group">
                      <label></label>
                      <div class='input-group col-md-12'>
                          <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                          <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                      </div>
                  </div>
              </div>

            </form>
        </div>
      </div>
    </div>

    <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example" >
          <thead>
            <tr>
              <th *ngIf="isSoluzione1" style="width: 200px;">Azienda di trasporto</th>
              <th>Nominativo</th>
              <th>Mail</th>
              <th style="width: 100px;">Invia link di attivazione</th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList"  style="cursor:pointer">
              <td *ngIf="isSoluzione1"> {{getBusinessName(data.idCompany)}}</td>
              <td>{{data.name}} {{data.surname}}</td>
              <td>{{data.username}}</td>
              <td  [ngStyle]="{'width': isSoluzione1 ? '100px' : '50px'}"  class="text-center">
                <button *ngIf="data.flagFirstLogin == true && data.inviteUrl != null" type="button" class="btn btn-success btn-outline " (click)="getActivationLink(data.inviteUrl)" style="float: left;"><i class="fa fa-share"></i></button>
              </td>
            </tr>
          </tbody>     
        </table>
        <ngb-pagination 
          *ngIf="visiblePaging"
          class="pull-right" 
          [collectionSize]="listService.totalRows"
          [maxSize]="10"
          [pageSize]="listService.rows" 
          [(page)]="listService.page"
          (pageChange)="pageChange($event)" >
        </ngb-pagination>
      </div>

    </div>
  </div>
</div>

