import { Injectable } from '@angular/core';
@Injectable()
export class LoggingService {

  actualLevel = 'ALL';
  logLevels = new Array({ 'ALL': 100, 'DEBUG': 200, 'INFO': 300, 'PROD': 400 })

  log(message: String, obj: any, level: number) {
    if (level >= this.logLevels[0][this.actualLevel]) {
      console.log(message, obj);
    }
  }
}
