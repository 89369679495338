import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { AuthService } from '../../../services/auth.service';
import { FileUploader } from 'ng2-file-upload';
import swal2 from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

const URL = 'https://185.56.9.94/prs-rest/rest/document/uploadDocChecklist';
const AUTHTOKEN = localStorage.getItem('token');
const HEADER = 'X-AUTH-TOKEN';

@Component({
  selector: 'app-resales-manage',
  templateUrl: './resales-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ResalesManageComponent implements OnInit {

  @ViewChild('formNewUser') newUserForm: NgForm;
  @ViewChild('resalesForm') formResales: NgForm;

  // testo in HTML per tipo pagina
  kindTitle; kindManage;
  // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = '/resale';
  // oggetto contenente dati component
  companiesList: any[] = new Array();
  pathToCallCompaniesPath = '/company/companies'
  urlSpecificUserPath = '/resale/'
  userPath = '/user'
  name
  username
  idUserToDelete;

  url = '/assets/img/void.jpg';

  urlDeletePath;

  // oggetto contenente dati component
  dataResale: any;
  idResale: number;
  idCompany: number;

  errorMessage;
  viewEditData;
  defaultValue;
  typeUser;
  isSoluzione1;

  companies: any = new Array();

  modalRef: BsModalRef;


  isActiveTabsPost;
  isActivePost;

  isActiveTabsLegal;
  isActiveLegal;
  isActiveAdministrators
  isActiveTabsDetails;
  isActiveDetails;
  isActiveTabsAdministrator

  routes: string[] = [];
  selectedRoutes: string[] = [];

  // PER UPLOAD FILE IMMAGINE
  public uploader: FileUploader = new FileUploader({ url: URL, authTokenHeader: HEADER, authToken: AUTHTOKEN });
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  constructor(
    private modalService: BsModalService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    if (route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuova';
      this.kindManage = 1;
      this.defaultValue = 0;
    } else { // se modifico veicolo
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      this.defaultValue = 1;
      if (localStorage.getItem('dataPassed') === '') { this.router.navigate(['/resales/list']); return; }
      this.dataResale = JSON.parse(localStorage.getItem('dataPassed'));
      this.idResale = this.dataResale['id'];
      this.idCompany = this.dataResale['idCompany'];
      this.loadSpecificUser();
      console.log('OBJ', this.dataResale);
      localStorage.setItem('dataPassed', '');
    }
  }

  onChange() {
    console.log(this.formResales.value.companyID)
    this.viewEditData = true;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (ev: any) => {
        this.url = ev.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/resales/list'
    console.log(this.listService)
    this.viewEditData = false;
    this.urlDeletePath = ''

    this.viewEditData = !this.kindManage;
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);

    this.isActivePost = 'tab-pane active';
    this.isActiveLegal = 'tab-pane'
    this.isActiveDetails = 'tab-pane'
    this.isActiveAdministrators = 'tab-pane'

    this.isActiveTabsDetails = ''
    this.isActiveTabsLegal = ''
    this.isActiveTabsPost = 'active'
    this.isActiveTabsAdministrator = ''

    if (this.isSoluzione1 && this.kindManage === 1) {
      this.viewEditData = false
    } else {
      this.viewEditData = true
    }

    if (this.isSoluzione1 && this.kindManage === 0) {
      this.viewEditData = true
    } else {
      this.viewEditData = true
    }

    this.getRoutes();
  }

  viewPost() {
    this.isActivePost = 'tab-pane active';
    this.isActiveLegal = 'tab-pane'
    this.isActiveDetails = 'tab-pane'
    this.isActiveAdministrators = 'tab-pane'
    this.isActiveTabsDetails = ''
    this.isActiveTabsLegal = ''
    this.isActiveTabsPost = 'active'
    this.isActiveTabsAdministrator = ''
  }

  viewDetails() {
    this.isActivePost = 'tab-pane';
    this.isActiveLegal = 'tab-pane'
    this.isActiveDetails = 'tab-pane active'
    this.isActiveAdministrators = 'tab-pane'
    this.isActiveTabsDetails = 'active'
    this.isActiveTabsLegal = ''
    this.isActiveTabsPost = ''
    this.isActiveTabsAdministrator = ''
  }


  viewLegal() {
    this.isActivePost = 'tab-pane';
    this.isActiveLegal = 'tab-pane active'
    this.isActiveDetails = 'tab-pane'
    this.isActiveAdministrators = 'tab-pane'
    this.isActiveTabsDetails = ''
    this.isActiveTabsLegal = 'active'
    this.isActiveTabsPost = ''
    this.isActiveTabsAdministrator = ''
  }

  viewAdministratorDetails() {
    this.isActivePost = 'tab-pane';
    this.isActiveLegal = 'tab-pane'
    this.isActiveDetails = 'tab-pane'
    this.isActiveAdministrators = 'tab-pane active'
    this.isActiveTabsDetails = ''
    this.isActiveTabsLegal = ''
    this.isActiveTabsPost = ''
    this.isActiveTabsAdministrator = 'active'
  }

  changeAdmin() {
    console.log('cambio Admin')
  }

  UpdateNewUser() {
    const userData = {
      email: this.newUserForm.value.emailAdmin,
      idResale: this.idResale,
      name: 'New Administrator',
      phone: this.newUserForm.value.phoneAdmin,
      roleUser: 'RESALE_ADMINISTRATOR',
      roleUserMobile: '',
      surname: 'New Administrator',
      typeUser: 'RESALE',
      enabled: false
    }

    this.listService
      .newObject(userData, this.userPath)
      .subscribe(
        response => {
          if (response.outcome.success === true) {
            this.authService.invite(response.idUser).subscribe(
              _ => {
                if (response.outcome.success === true) {
                  this.close()
                  this.loadSpecificUser()
                  swal2.fire('Il nuovo amministratore della rivendita è stato invitato ad usare il portale', '', 'success');

                } else {
                  this.errorMessage =
                    response.outcome.code + response.outcome.description;
                  swal2.fire('Attenzione', this.errorMessage, 'warning');
                }
              },
              error => {
                this.logger.log('Error', error, 200);
                this.errorMessage =
                  'Spiacente, si è verificato un errore tecnico.';
                swal2.fire(
                  'Errore',
                  'Spiacente, si è verificato un errore tecnico.',
                  'error'
                );
              }
            );

          }
        });
  }

  newUser(idResale) {

    const userData = {
      email: this.formResales.value.emailAdmin,
      idResale: idResale,
      name: 'New Administrator',
      phone: this.formResales.value.phoneAdmin,
      roleUser: 'RESALE_ADMINISTRATOR',
      roleUserMobile: '',
      surname: 'New Administrator',
      typeUser: 'RESALE',
      enabled: false
    }

    console.log('utenteDaCreare', userData)
    this.listService
      .newObject(userData, this.userPath)
      .subscribe(
        response => {
          if (response.outcome.success === true) {
            this.authService.invite(response.idUser).subscribe(
              _ => {
                if (response.outcome.success === true) {
                  swal2.fire('L\'amministratore della rivendita è stato invitato ad usare il portale', '', 'success');

                } else {
                  this.errorMessage =
                    response.outcome.code + response.outcome.description;
                  swal2.fire('Attenzione', this.errorMessage, 'warning');
                }
              },
              error => {
                this.logger.log('Error', error, 200);
                this.errorMessage =
                  'Spiacente, si è verificato un errore tecnico.';
                swal2.fire(
                  'Errore',
                  'Spiacente, si è verificato un errore tecnico.',
                  'error'
                );
              }
            );

          }
        });
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
      this.companiesList = response.results;
      this.logger.log('Lista aziende', this.companiesList, 300);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getRoutes() {
    this.listService.restURL = '/route/routes';
    this.listService.paging = false;
    this.listService.getListFilter().subscribe(
      (response) => {
        this.routes = response.results ? response.results.map(r => r.routeId) : [];
      }, (error) => {
        this.logger.log('Error', error, 200);
      }
    )
  }

  onSubmit(): void {
    swal2.fire({ title: 'Operazione in corso...', didOpen: function () { swal2.showLoading() } });

    if (this.kindManage) {
      this.dataResale = this.formResales.value
      this.dataResale['internal'] = false;
      this.dataResale['routes'] = this.selectedRoutes;

      console.log(this.dataResale)
      this.listService.newObject(this.dataResale, this.serviceToCallPath).subscribe(
        (response) => {
          if (response.outcome.success === true) {
            console.log('RISPOSTA PER ID RESALE', response.id)
            swal2.fire('Nuova Rivendita creata', '', 'success');
            this.newUser(response.id)
            this.router.navigate(['/resales/list']);
          } else {
            this.errorMessage = response.outcome.code + response.outcome.description
            swal2.fire('Attenzione', this.errorMessage, 'warning');
          }
        },
        (error) => {
          this.logger.log('Error', error, 200);
          this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
          swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
        }
      );
    } else {
      this.dataResale = this.formResales.value;
      this.dataResale['id'] = this.idResale;
      this.dataResale['internal'] = false;
      this.dataResale['routes'] = this.selectedRoutes;

      this.listService.edit(this.dataResale, this.serviceToCallPath).subscribe(
        (response) => {

          if (response.outcome.success === true) {
            swal2.fire('La Rivendita è stata modificata', '', 'success');
            this.router.navigate(['/resales/list']);
          } else {
            this.errorMessage = response.outcome.code + response.outcome.description
            swal2.fire('Attenzione', this.errorMessage, 'warning');
          }
        },
        (error) => {
          this.logger.log('Error', error, 200);
          this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
          swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
        }
      );
    }
  }

  viewChangeAdminUser(template: TemplateRef<any>) {
    this.openModal(template)
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  getValue() {
    const phoneAdmin = this.newUserForm.value.phoneAdmin
    const emailAdmin = this.newUserForm.value.emailAdmin
    console.log('Valore 2', phoneAdmin)
    console.log('Valore 2', emailAdmin)
  }


  loadSpecificUser() {
    this.listService.getObject(this.urlSpecificUserPath + this.idResale).subscribe((response) => {

      this.dataResale = response

      console.log('RISPOSTE', response)

      if (response.resaleAdmin !== null) {
        this.name = response.resaleAdmin.name + ' ' + response.resaleAdmin.surname
        this.username = response.resaleAdmin.username
        this.idUserToDelete = response.resaleAdmin.idUser
        console.log('RISPOSTE', this.name)
        console.log('RISPOSTE', this.username)
        console.log('USER DA ELIMINARE', this.idUserToDelete)
      }

      console.log('OBJECT ', this.dataResale)

      this.logger.log('Response:', response, 300);
      localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
    },
      (error) => {
        this.logger.log('Error', error, 200);

      }
    );
  }

  back() {
    this.router.navigate(['/resales/list']);
  }

  close() {
    this.modalRef.hide()
  }

  deleteElement() {
    const userIdToDelete = this.idUserToDelete
    console.log('USER DA ELIMINARE', this.idUserToDelete);
    this.urlDeletePath = '/user/' + userIdToDelete;
    console.log('ID ELIMINAZIONE', this.urlDeletePath);

    swal2.fire({ title: 'Eliminazione in corso...', didOpen: function () { swal2.showLoading() } });

    this.logger.log('ID DELETE:', userIdToDelete, 200);

    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {

        if (response.outcome.success === true) {
          this.logger.log('Response value', response.value, 200);
          this.UpdateNewUser()
        } else {
          this.errorMessage = response.outcome.code + response.outcome.description
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      (error) => {
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.Riprova più tardi', 'error');
      }
    );

  }

  onRoutesChange(selected: any[]): void {
    this.selectedRoutes = selected;
  }

}
