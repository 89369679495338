import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigKey } from '../../../interfaces/configuration.interface';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { UtilsService } from '../../../services/utils.service';
import swal2 from 'sweetalert2';

@Component({
    selector: 'app-keys-manage',
    templateUrl: './keys-manage.component.html',
    styleUrls: ['../../../app.component.css']
})
export class KeysManageComponent implements OnInit {

    isSoluzione1: boolean;

    // testo in HTML per tipo pagina
    kindTitle: string;
    kindManage: number;

    keysPath = '/configuration';
    dataKey: ConfigKey;

    keyForm = new FormGroup({
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        actions: new FormControl(false, Validators.required)
    });

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private listService: ListService,
        private utils: UtilsService,
        private logger: LoggingService
    ) {
        // Se creo nuova chiave
        if (this.route.snapshot.toString().indexOf('new') !== -1) {
            this.kindTitle = 'Creazione';
            this.kindManage = 1;
        } else { // se modifico chiave
            this.kindTitle = 'Modifica';
            this.kindManage = 0;
            if (localStorage.getItem('dataPassed') === '') {
                this.router.navigate(['/utility/keys']);
                return;
            }
            this.dataKey = JSON.parse(localStorage.getItem('dataPassed'));
            this.setFormValues();
            localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        }
    }

    ngOnInit(): void {
        this.listService.listRouting = '/utility/keys';
        this.isSoluzione1 = this.utils.isSol1(this.utils.getTypeUser());
    }

    back() {
        this.router.navigate(['/utility/keys']);
    }

    onSubmit() {
        swal2.fire({
            title: 'Operazione in corso...',
            didOpen: function() {
              swal2.showLoading();
            }
        });
        const config: ConfigKey = this.keyForm.value;
        if (this.kindManage) {
            this.listService.newObject(config, this.keysPath).subscribe(
                _ => {
                    swal2.fire('Successo', `Creazione chiave avvenuta con successo`, 'success');
                    this.back();
                }, (error) => {
                    this.logger.log('Error', error, 200);
                    swal2.fire(
                        'Errore',
                        `Spiacente, non è stato possibile creare la chiave`,
                        'error'
                    );
                }
            );
        } else {
            this.listService.edit(config, this.keysPath).subscribe(
                _ => {
                    swal2.fire('Successo', `Modifica chiave avvenuta con successo`, 'success');
                    this.back();
                }, (error) => {
                    this.logger.log('Error', error, 200);
                    swal2.fire(
                        'Errore',
                        `Spiacente, non è stato possibile modificare la chiave`,
                        'error'
                    );
                }
            );
        }
    }


    // ---------- PRIVATE METHODS ---------- //

    /** Method that sets default values in form on key edit */
    private setFormValues(): void {
        this.keyForm.get('name').setValue(this.dataKey.name);
        this.keyForm.get('description').setValue(this.dataKey.description);
        this.keyForm.get('actions').setValue(this.dataKey.actions);
    }

}
