import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { WalletRechargeListComponent } from './wallet-recharge-list/wallet-recharge-list.component';
import { WalletRechargeManageComponent } from './wallet-recharge-manage/wallet-recharge-manage.component';



@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
 
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ WalletRechargeListComponent, WalletRechargeManageComponent]
})
export class WalletRechargeModule {}