<div class="col-sm-8 col-sm-offset-2">
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">

      <div class="ibox float-e-margins">
        <form (ngSubmit)="onSubmit()" #resalesForm="ngForm">
          <div class="ibox-title col-md-12 col-sm-12">
            <h2>{{kindTitle}} Rivendita</h2>
          </div>

          <div *ngIf="viewEditData" class="ibox float-e-margins">
            <div class="ibox-content col-md-12">
              <ul class="nav nav-tabs">
                <li class="{{isActiveTabsPost}}">
                  <a data-toggle="tab" (click)="viewPost()" aria-expanded="true"> Insegna </a>
                </li>
                <li class="{{isActiveTabsLegal}}">
                  <a data-toggle="tab" (click)="viewLegal()" aria-expanded="false">Dati legali</a>
                </li>
                <li class="{{isActiveTabsDetails}}">
                  <a data-toggle="tab" (click)="viewDetails()" aria-expanded="false">Dettagli</a>
                </li>

                <li class="{{isActiveTabsAdministrator}}">
                  <a data-toggle="tab" (click)="viewAdministratorDetails()" aria-expanded="false">Amministratore</a>
                </li>
              </ul>
              <div class="tab-content">
                <div id="tab-6" class="{{isActiveAdministrators}}">
                  <div style="padding-left: 0px" class="panel-body">
                    <div class="col-lg-4 text-center">
                      <i class="fa fa-user big-icon"></i>
                      <button type="button" *ngIf="kindTitle == 'Modifica'" data-toggle="modal" class="btn btn-danger" (click)="viewChangeAdminUser(template)">
                        Cambia Amministratore
                      </button>
                    </div>
                    <div *ngIf="kindTitle == 'Nuova'" class="form-group col-md-6 col-sm-6">
                      <label for="emailAdmin">Email Amministratore</label>
                      <input type="email" id="emailAdmin" name="emailAdmin" class="form-control" ngModel required [ngModel]="dataResale?.emailAdmin"
                        emailAdmin #emailAdmin="ngModel">
                    </div>
                    <div *ngIf="kindTitle == 'Nuova'" class="form-group col-md-6 col-sm-6">
                      <label for="phoneAdmin">Telefono Amministratore</label>
                      <input type="text" id="phoneAdmin" name="phoneAdmin" class="form-control" ngModel required [ngModel]="dataResale?.phoneAdmin"
                        phoneAdmin #phoneAdmin="ngModel">
                    </div>
                    <div *ngIf="kindTitle == 'Modifica'" class="form-group col-lg-8 col-md-6 col-sm-6 text-center" style="margin-top: 30px">
                      <h3>Email Amministratore</h3>
                      <label for="emailAdmin"> {{username}} </label>
                    </div>
                    <div *ngIf="kindTitle == 'Modifica'" class="form-group col-lg-8 col-md-6 col-sm-6 text-center">
                      <h3>Credenziali Amministratore</h3>
                      <label for="emailAdmin">{{name}}</label>
                    </div>
                  </div>
                </div>

                <div id="tab-3" class="{{isActivePost}}">
                  <div style="padding-left: 0px" class="panel-body">

                    <div *ngIf="isSoluzione1" class="form-group col-md-12 col-sm-12">
                      <label for="idCompany"> Azienda di trasporto </label>
                      <select (change)="onChange()" type="text" id="idCompany" class="form-control" [ngModel]="dataResale?.idCompany" ngModel name="idCompany"
                        idCompany #idCompany="ngModel" required>
                        <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                      <label for="posterName">Nome Insegna</label>
                      <input type="text" id="posterName" name="posterName" class="form-control" ngModel required [ngModel]="dataResale?.posterName"
                        posterName #posterName="ngModel">
                    </div>

                    <div class="form-group col-md-6 col-sm-6">
                      <label for="posterAddress"> Indirizzo</label>
                      <input type="text" id="posterAddress" class="form-control" ngModel name="posterAddress" [ngModel]="dataResale?.posterAddress"
                        required posterAddress #posterAddress="ngModel">
                    </div>
                    <div class="form-group col-md-3 col-sm-4">
                      <label for="posterProv">Provincia</label>
                      <input type="text" id="posterProv" name="posterProv" class="form-control" ngModel required [ngModel]="dataResale?.posterProv"
                        posterProv #posterProv="ngModel">
                    </div>

                    <div class="form-group col-md-3 col-sm-4">
                      <label for="posterZipCode">Cap</label>
                      <input type="text" id="posterZipCode" name="posterZipCode" [ngModel]="dataResale?.posterZipCode" class="form-control" ngModel
                        required posterZipCode #posterZipCode="ngModel">
                    </div>

                    <div class="form-group col-md-6 col-sm-4">
                      <label for="posterCity"> Citta</label>
                      <input type="text" id="posterCity" class="form-control" [ngModel]="dataResale?.posterCity" ngModel name="posterCity" required
                        posterCity #posterCity="ngModel">
                    </div>

                    <div class="form-group col-md-3 col-sm-4">
                      <label for="status">Stato </label>
                      <select type="text" id="status" class="form-control" ngModel [ngModel]="dataResale?.status" name="status" required status
                        #status="ngModel" required>
                        <option [ngValue]="'1'">Attivo</option>
                        <option [ngValue]="'0'">Non Attivo</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div id="tab-4" class="{{isActiveLegal}}">
                  <div style="padding-left: 0px" class="panel-body">
                    <div class="form-group col-md-6 col-sm-4">
                      <label for="legalBusinessName">Ragione sociale</label>
                      <input type="text" id="legalBusinessName" name="legalBusinessName" [ngModel]="dataResale?.legalBusinessName" class="form-control"
                        ngModel required legalBusinessName #legalBusinessName="ngModel">
                    </div>

                    <div class="form-group col-md-6 col-sm-4">
                      <label for="legalAddress"> Indirizzo</label>
                      <input type="text" id="legalAddress" class="form-control" ngModel name="legalAddress" [ngModel]="dataResale?.legalAddress"
                        required legalAddress #legalAddress="ngModel">
                    </div>
                    <div class="form-group col-md-3 col-sm-4">
                      <label for="legalProv">Provincia</label>
                      <input type="text" id="legalProv" name="legalProv" class="form-control" [ngModel]="dataResale?.legalProv" ngModel required
                        legalProv #legalProv="ngModel">
                    </div>
                    <div class="form-group col-md-3 col-sm-4">
                      <label for="legalZipCode">Cap</label>
                      <input type="text" id="legalZipCode" name="legalZipCode" [ngModel]="dataResale?.legalZipCode" class="form-control" ngModel
                        required legalZipCode #legalZipCode="ngModel">
                    </div>
                    <div class="form-group  col-md-6 col-sm-6">
                      <label for="legalCity"> Città</label>
                      <input type="text" id="legalCity" class="form-control" ngModel [ngModel]="dataResale?.legalCity" name="legalCity" required
                        legalCity #legalCity="ngModel">
                    </div>
                    <div class="form-group col-md-4 col-sm-4">
                      <label for="vatNumber">Partita iva</label>
                      <input type="text" id="vatNumber" [ngModel]="dataResale?.vatNumber" name="vatNumber" class="form-control" ngModel required
                        vatNumber #vatNumber="ngModel">
                    </div>
                    <div class="form-group  col-md-2 col-sm-4">
                    </div>
                    <div style="padding-top: 0px" class="form-group col-md-3 col-sm-3">
                      <label for="dateEnd">Aggio </label>
                      <div class="input-group col-md-12 col-sm-12">

                        <input max="100" type="number" id="agio" name="agio" class="form-control" ngModel [ngModel]="dataResale?.agio" required agio
                          #agio="ngModel">
                        <span class="input-group-addon">%</span>
                      </div>
                    </div>
                    <div style="padding-top: 0px" class="form-group col-md-3 col-sm-3">
                      <label for="dateEnd">Fido </label>
                      <div class="input-group col-md-12 col-sm-12">

                        <input type="text" id="trust" name="trust" class="form-control" ngModel [ngModel]="dataResale?.trust" required trust #trust="ngModel">
                        <span class="input-group-addon">€</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="tab-5" class="{{isActiveDetails}}">
                  <div style="padding-left: 0px" class="panel-body">
                    <div class="form-group col-md-6 col-sm-4">
                      <label for="cell"> Cellulare</label>
                      <input type="text" id="cell" [ngModel]="dataResale?.cell" class="form-control" ngModel name="cell"  cell #cell="ngModel">
                    </div>
                    <div class="form-group col-md-4 col-sm-4">
                      <label for="rif"> Riferimento</label>
                      <input type="text" id="rif" class="form-control" ngModel name="rif" [ngModel]="dataResale?.rif"  rif #rif="ngModel">
                    </div>
                    <br>
                    <div class="form-group col-md-6 col-sm-4">
                      <label for="phone1"> Telefono </label>
                      <input type="text" id="phone1" [ngModel]="dataResale?.phone1" class="form-control" ngModel name="phone1" phone1 #phone1="ngModel">
                    </div>
                    <div class="form-group col-md-6 col-sm-4">
                      <label for="email1"> EMail</label>
                      <input type="email" id="email1" [ngModel]="dataResale?.email1" class="form-control" ngModel name="email1" email1 #email1="ngModel">
                    </div> 
                  </div>

                  <!-- ROUTES -->
                  <div *ngIf="routes.length"
                    style="margin-bottom: 40px" class="form-group col-md-12">
                    <app-checkbox-group
                      [sectionTitle]="' Linee abilitate alla vendita'"
                      [elements]="routes"
                      [default]="dataResale?.routes"
                      (selectChange)="onRoutesChange($event)"
                    ></app-checkbox-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="ibox-footer">
              <button style="margin-top: 10px" class="btn btn-default" type="button" (click)="back()">Annulla</button>
              <button class="btn btn-redBU" type="submit" style="float: right;  margin-top: 10px;" [disabled]="!resalesForm.valid">Salva </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<form #formNewUser="ngForm">
  <ng-template #template>
    <div tabindex="-1" role="dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span (click)="close()">×</span>
            <span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Revoca e Creazione Nuovo Amministratore</h4>
          <br>
          <h3 class = "text-center" >Questa azione eliminerà l'attuale amministratore della rivendita. 
            <br>
            <br>
            Al nuovo amministratore verrà inviata una mail con le istruzioni per accdere al portale.
            <br> 
            <br> Sei sicuro di procedere?
          </h3>
          <br>
          <div class="form-group col-md-6 col-sm-6">
            <label for="emailAdmin">Email Amministratore</label>
            <input type="text" id="emailAdmin" name="emailAdmin" class="form-control" ngModel emailAdmin #emailAdmin="ngModel">
          </div>
          <div class="form-group col-md-6 col-sm-6">
            <label for="phoneAdmin">Telefono Amministratore</label>
            <input type="text" id="phoneAdmin" name="phoneAdmin" class="form-control" ngModel phoneAdmin #phoneAdmin="ngModel">
          </div>
        </div>
        <div class="modal-footer ">
          <button class="btn btn-default text-left"  (click)="close()" type="button">
            Annulla
          </button>
          <button class="btn btn-danger text-right"  (click)="deleteElement()" type="button">
           Sì, Procedi
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</form>