<div class="row" style="padding-top: 20px"></div>

<div class="col-md-12">
  <div class="ibox float-e-margins ">

    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Categorie</strong>
      </h2>
      <div class="ibox-tools col-xs-1 pull-right" *ngIf="isSoluzione1">
        <button type="button" (click)="openModal()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="ibox-content">

      <tabset>

        <tab heading="Gruppi categorie" [active]="activeTab == 'GRUPPI'" (selectTab)="tabSelector($event,'GRUPPI')">

          <div class="row">
            <div class="col-lg-12">
              
              <div class="row">

                <div class="col-sm-6 mTB20" [hidden]="!isSoluzione1">
                  <form #searchForm="ngForm">
                    <div class="row" style="display: flex;">
                      <div class="col-sm-6">
                        <label>Seleziona Azienda di Trasporto: </label>
                        <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" idCompany #idCompany="ngModel">
                          <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                        </select>
                      </div>
                      <div class="col-sm-2" style="display: flex; align-items: flex-end;">
                        <div class="btn btn-view btn-outline" style="margin-bottom: 0;"
                          (click)="searchCompanyCategory()">Cerca</div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-sm-6 mTB20">
                  <div class="btn-group" role="group" aria-label="...">
                    <label>Visualizza categorie in stato: </label>
                  </div>
                  <div class="btn-group mL10" role="group" aria-label="..."> 
                    <button type="button" class="btn" [ngClass]="{'active btn-success': showActive == 1}" (click)="toggleShowActive(1)">Attive</button>
                    <button type="button" class="btn" [ngClass]="{'active btn-danger': showActive == 0}" (click)="toggleShowActive(0)">Disattivate</button>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
            <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
          </p>

          <div *ngIf="errorMessage === ''" class="table-responsive">
            <table class="table table-striped table-bordered table-hover dataTables-example">
              
              <thead>
                <tr>
                  <th *ngIf="isSoluzione1">Azienda</th>
                  <th>Nome</th>
                  <th>Descrizione</th>
                  <th>Tipo</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of dataList; let i = index">
                  <tr>
                    <td *ngIf="isSoluzione1">{{getCompanyName(item?.idCompany)}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.type}}</td>
                    <td width="200px">
                      <div class="category-actions" style="display: flex; justify-content: space-between;">
                        <button type="button" class="btn btn-primary btn-outline" (click)="toggleDetails(i)">
                          <i class="fa fa-angle-double-down rotate-icon" [ngClass]="{'collpaseDetails' : !item.expanded }" aria-hidden="true"></i>
                        </button>
  
                        <button *ngIf="isSoluzione1 && item.groupId !== -1" type="button" class="btn btn-success btn-outline"
                          title="Modifica gruppo di categorie" (click)="selectRow(item, TicketCategoryType.categoriesGroup)">
                          <i class="fa fa-pencil"></i>
                        </button>
  
                        <div class="btn-group pull-right" role="group" aria-label="...">
                          <button type="button" [disabled]="item.groupId == -1" class="btn" [ngClass]="{'active btn-success':  item.deleted == false}" (click)="toggleOnOff( item , i , false )">ON</button>
                          <button type="button" [disabled]="item.groupId == -1" class="btn" [ngClass]="{'active btn-danger': item.deleted == true}" (click)="toggleOnOff( item , i , true )">OFF</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let subItem of item?.categories;" class="detailRow" [hidden]="!item?.expanded">
                    <td *ngIf="isSoluzione1"></td>
                    <td>{{subItem.name}}</td>
                    <td>{{subItem.description}}</td>
                    <td>{{subItem.type}}</td>
                    <td width="200px">
                      <button *ngIf="isSoluzione1" type="button" class="btn btn-success btn-outline"
                        title="Modifica categoria" (click)="selectRow(subItem, TicketCategoryType.category)">
                        <i class="fa fa-pencil"></i>
                      </button>

                      <div class="btn-group pull-right" role="group" aria-label="...">
                        <button type="button" class="btn" [ngClass]="{'active btn-success':  subItem.deleted == false}" (click)="toggleOnOff( subItem , i , false )">ON</button>
                        <button type="button" class="btn" [ngClass]="{'active btn-danger': subItem.deleted == true}" (click)="toggleOnOff( subItem , i , true )">OFF</button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                
              </tbody>

            </table>
          </div>


        </tab>

        <tab heading="Categorie disattivate" [active]="activeTab == 'CATEGORIE_DISATTIVATE'" (selectTab)="tabSelector($event,'CATEGORIE_DISATTIVATE')">


          <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
            <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
          </p>

          <div *ngIf="errorMessage === ''" class="table-responsive">
            <table class="table table-striped table-bordered table-hover dataTables-example">
              
              <thead>
                <tr>
                  <th *ngIf="isSoluzione1">Azienda</th>
                  <th>Nome</th>
                  <th>Descrizione</th>
                  <th>Tipo</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of dataList; let i = index">
                  <tr>
                    <td *ngIf="isSoluzione1"></td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.type}}</td>
                    <td>

                      <div class="btn-group mL10 pull-right" role="group" aria-label="...">
                        <button type="button" [disabled]="item.groupId == -1" class="btn" [ngClass]="{'active btn-success':  item.deleted == false}" (click)="toggleOnOff( item , i , false )">ON</button>
                        <button type="button" [disabled]="item.groupId == -1" class="btn" [ngClass]="{'active btn-danger': item.deleted == true}" (click)="toggleOnOff( item , i , true )">OFF</button>
                      </div>

                    </td>
                  </tr>
                </ng-container>
                
              </tbody>

            </table>
          </div>


        </tab>

      </tabset>

    </div>

  </div>
</div>

<ng-template #categoryModal>
  <div class="modal-dialog">
    <div class="modal-body">
      <div class="row">
        <h1 style="padding-left: 1rem">Tipologia categoria da creare:</h1>
      </div>
      <br />
      <div class="row">
        <a class="col-md-12" (click)="newCategory(TicketCategoryType.categoriesGroup)">
          <div class="col-lg-12 text-center payment-card">
            <span class="h3" style="padding-left: 10px;">Gruppo di categorie</span>
          </div>
        </a>
        <a class="col-md-12" (click)="newCategory(TicketCategoryType.category)">
          <div class="col-lg-12 text-center payment-card">
            <span class="h3" style="padding-left: 10px;">Categoria</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-template>