import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import { AuthService} from "../../../services/auth.service";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-printers-manage',
  templateUrl: './printers-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class PrintersManageComponent implements OnInit {
    //form html
    @ViewChild('printerForm') formPrinter: NgForm;

    //testo in HTML per tipo pagina
    kindTitle;kindManage;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/printer";
    urlModelsPath = "printer/models";
    urlResalesPath = "/resale/resales";
    urlDesksPath = "/desk/desks";
    urlCompanyPath = "/company/companies";
    //oggetto contenente dati component
    dataPrinter: any;
    resalesList = new ListResales();
    desksList = new ListDesk();
    companiesList: any[] = new Array();
    isSol1; 
    typeUser;
   idCompany : String;
   companyValue;

   checkedInternal: any  = new Array()

    errorMessage;
    //DATI TEMPORANEI PER TENERE TRACCIA DI ID E DATA ACQUISTO STAMPANTE
    idTemp;
    dateSellTemp;

    colorTheme = "theme-orange";
    bsConfig: Partial<BsDatepickerConfig>;

    constructor(private utils:UtilsService, private route: ActivatedRoute, private authService: AuthService, private logger: LoggingService, public listService: ListService, private router: Router ) {
        
        //Se creo nuovo veicolo
        if(route.snapshot.toString().indexOf("new") != -1) {
            this.kindTitle = "Nuova";
            this.kindManage = 1;
        }else{ //se modifico veicolo
            this.kindTitle = "Modifica";
            this.kindManage = 0;
            if(localStorage.getItem("dataPassed")=="")  {this.router.navigate(['/printers/list']); return;}
            this.dataPrinter = JSON.parse(localStorage.getItem("dataPassed"));
            this.idTemp = this.dataPrinter["id"];
            this.dateSellTemp = this.dataPrinter["dateSell"];

            localStorage.setItem("dataPassed",""); //svuoto dato in localstorage
        }

        this.bsConfig = Object.assign(
            {},
            { containerClass: this.colorTheme },
            { dateInputFormat: "DD-MM-YYYY" },
            { rangeInputFormat: "DD-MM-YYYY" }
        );
    }

    ngOnInit() {
        this.typeUser = this.utils.getTypeUser();
        this.isSol1 = this.utils.isSol1(this.typeUser);

        if( !this.isSol1 ) {
            this.idCompany = localStorage.getItem("idCompany")
            this.companyValue =  Number(this.idCompany)
        }
   
        // console.log("result", this.idCompany)
        this.listService.resetList();
        this.getResales();
        this.getDesks();
        this.listService.listRouting= "/printers/list"
        this.listService.backToList = true;
        this.getCompanies();

        if (this.kindManage == 0) { // Se vado in edit il campo "Utilizzo" viene impostato in base a ID_DESK e ID_RESALE
            if (this.dataPrinter["idDesk"] != null) {
                this.dataPrinter["purpose"] = "desk";
            } else if(this.dataPrinter["idResale"] != null) {
                this.dataPrinter["purpose"] = "resale";
            }
            this.companySelected()
        }

        // console.log("data printer", this.dataPrinter);
    }

    //SERVIZI SELECT
    getResales() {
        this.listService.getListSelect(this.urlResalesPath).subscribe((response) => {
            this.resalesList.source = response.results;
            // this.resalesList.row = response.results.filter( (x) => { x.idCompany == this.formPrinter.controls.idCompany.value } );
            this.listFilterResale();
            this.logger.log("Lista rivenditori", this.resalesList, 300);
        },
        (error) => {
            this.logger.log("Error", error, 200);
        }
        );
    }

    getDesks() {
        this.listService.getListSelect(this.urlDesksPath).subscribe((response) => {
            this.desksList.source = response.results;
            // this.desksList.row = response.results.filter( (x) => { x.idCompany == this.formPrinter.controls.idCompany.value } );
            this.listFilterDesk();
            this.logger.log("Lista desk", this.desksList, 300);
        },
        (error) => {
            this.logger.log("Error", error, 200);
        }
        );
    }

    getCompanies() {
        this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
            this.companiesList = response.results;
            this.logger.log("Lista aziende", this.companiesList, 300);
        },
        (error) => {
            this.logger.log("Error", error, 200);
        }
        );
    }

    //MODIFICA - CREA OGGETTO
    onSubmit()  : void {

        swal2.fire({title: 'Operazione in corso...', didOpen: function () {swal2.showLoading()}});

        //COPIA DA FORM CON VALORI
        this.dataPrinter = this.formPrinter.value;

        if(this.formPrinter.value.companyInternalUse == null || this.formPrinter.value.companyInternalUse == undefined ){
            this.dataPrinter["companyInternalUse"] = false
        }

        if(this.kindManage){
            this.dataPrinter["status"]  = "1"
            this.listService.newObject(this.dataPrinter, this.serviceToCallPath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.fire("La Stampante è stata creata", "","success");
                        this.router.navigate(['/printers/list']);

                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire("Attenzione", this.errorMessage,"warning");
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                    this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                    swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
                }
            );
        }else{

            //AGGIUNGE DATI COME ID E DATESELL NON PASSATI DALLA FORM
            this.dataPrinter["id"] = this.idTemp;
            this.dataPrinter["status"]  = "1"

            console.log("COSA MANDO?", this.dataPrinter["idResale"])

            if (this.formPrinter.value.dateSell == null || this.formPrinter.value.dateSell == undefined)
                this.dataPrinter["dateSell"] =  this.dateSellTemp
            else
                this.listService.edit(this.dataPrinter, this.serviceToCallPath).subscribe(
                (response) => {
            
                    if (response.outcome.success === true) {
                        swal2.fire("La Stampante è stata modificata", "","success");
                        this.router.navigate(['/printers/list']);
    
                    } else {
                        this.errorMessage =  response.outcome.code +  response.outcome.description
                        swal2.fire("Attenzione", this.errorMessage,"warning");
                    }
                },    
                (error) => {
                    this.logger.log("Error",error,200);
                    this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
                    swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
                }
            );
        }
    }

    back(){
        this.router.navigate(['/printers/list']);
    }

    companySelected( idCompany = null ) {
        this.listFilterDesk()
        this.listFilterResale()
    }

    listFilterDesk() {
        this.desksList.row = this.desksList.source.filter( (x) => {
            if( this.kindManage == 0) {
                if( x.idCompany == this.companyValue ) {
                    return x
                } else if ( this.isSol1 ) {
                    // return x
                    if( x.idCompany == this.formPrinter.controls.idCompany.value) {
                        return x
                    }
                }
            } else if( this.kindManage == 1 ) {
                if( x.idCompany == this.formPrinter.controls.idCompany.value) {
                    return x
                }
            }
            
        });
    }

    listFilterResale() {
        this.resalesList.row = this.resalesList.source.filter( (x) => {
            console.log( 'kindManage ' , this.kindManage , 'companyValue' , this.companyValue )
            if( this.kindManage == 0) {
                if( x.idCompany == this.companyValue ) {
                    return x
                } else if ( this.isSol1 ) {
                    // return x
                    if( x.idCompany == this.formPrinter.controls.idCompany.value) {
                        return x
                    }
                }
            } else if( this.kindManage == 1 ) {
                if( x.idCompany == this.formPrinter.controls.idCompany.value) {
                    return x
                }
            }
        });
    }
}

export class ListResales {
    public row : Array<Resale>
    public source : Array<Resale>

    constructor() { 
        this.row = new Array();
        this.source = new Array();
    }
}

export class Resale {
    id: number
    idCompany: number
    legalBusinessName: string
    legalAddress: string
    legalCity: string
    legalProv: string
    legalZipCode: string
    phone1: string
    trust: number
    agio: number
    status: string
    internal: boolean
    credit: number
    pdf: any
}

export class ListDesk {
    public row : Array<Desk>
    public source : Array<Desk>

    constructor() { 
        this.row = new Array();
        this.source = new Array();
    }
}

export class Desk {
    public id: number
    public idCompany: number
    public name: string
    public description: string
    public status: string
}