<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} listino Abbonamenti</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #formTicketList="ngForm">
          <div class="ibox-content">

            <div hidden *ngIf="kindManage == 0" class="form-group">
              <label for="idTicketPricelist">IdPriceList</label>
              <input type="text" id="idTicketPricelist" name="idTicketPricelist" class="form-control" ngModel [ngModel]="ticketListData?.idTicketPricelist"
                required ng-disabled idTicketPricelist #idTicketPricelist="ngModel">
            </div>

            <div class="col-md-12">
              <div class="form-group col-md-5">
                <label for="username">Nome</label>
                <input type="text" id="name" name="name" class="form-control" ngModel [ngModel]="ticketListData?.name" required ng-disabled
                  #name="ngModel">
              </div>


              <div *ngIf="isSol1" class="form-group col-md-5">
                <label for="idCompany">Company</label>
                <select [disabled] ="kindManage == 0 && !isSol1" type="text" id="idCompany" name="idCompany" class="form-control" ngModel [ngModel]="ticketListData?.idCompany"
                  required ng-disabled idCompany #idCompany="ngModel">
                  <option *ngFor="let t of companyList" [ngValue]="t.idCompany">{{t.legalBusinessName}}</option>
                </select>
              </div>

             
            </div>



            <div class="col-md-12">
              <div class="form-group col-md-6">
                <label for="idTicketType">Tipologia</label>
                <select
                [disabled]="kindManage == 0"
                (change)="onChange($event.target.value)" type="text" id="idTicketType" class="form-control" ngModel name="idTicketType"
                  required idTicketType #idTicketType="ngModel" [ngModel]="ticketListData?.idTicketType" required>
                  <option *ngFor="let t of ticketTypesList" [ngValue]="t.idTicketType">{{t.name}}</option>
                </select>
              </div>

              <div class="form-group col-md-6">
                <label for="idTicketFare">Tariffa</label>
                <span *ngIf="stringNameLine" class="label label-warning-light pull-right">{{stringNameLine}}</span>
                <select
                [disabled]="kindManage == 0"
                 (change)="changeFare($event.target.value)" type="text" id="idTicketFare" class="form-control" ngModel name="idTicketFare"
                  required idTicketFare #idTicketFare="ngModel" [ngModel]="ticketListData?.idTicketFare" required>
                  <option *ngFor="let t of ticketFaresList" [ngValue]="t.idTicketFare">{{t.name}}</option>
                </select>
              </div>
            </div>


























            <div style="margin-top: 15px" class="form-group col-md-6">

              <div class="col-sm-12">
                <label> Abilitazioni canali di rivendita</label>
                <div class="checkbox">
                  <input type="checkbox" name="checkResale" [ngModel]="ticketListData?.checkResale" id="checkResale" checkResale #checKResale>
                  <label>Rivendita</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" name="checkMobile" [ngModel]="ticketListData?.checkMobile" id="checkMobile" checkMobile #checkMobile>
                  <label>Operatore azienda via app</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" name="checkWeb" [ngModel]="ticketListData?.checkWeb" id="checkWeb" checkWeb #checkWeb>
                  <label>Operatore azienda via portale</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" name="checkAPI" [ngModel]="ticketListData?.checkAPI" id="checkAPI" checkAPI #checkAPI>
                  <label>API</label>
                </div>
              </div>
            </div>







            <div class="col-md-6">


              <div style="margin-top: 15px" *ngIf="ArVisible" class="row"> </div>

              <div style="padding-left: 0px" class="col-md-12">
                <div class="form-group col-md-12 col-sm-12">
                  <label for="soldFrom">Vendibile dal</label>
                  <input type="text"
                    class="form-control"
                    name="dateStart"
                    id="dateStart"
                    [ngModel]="ticketListData?.dateStart"
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    (bsValueChange)="onDateChanged($event)"
                    [bsConfig]="bsConfig" />
                </div>

                <div class="form-group col-md-12 col-sm-12">
                  <label for="dateEnd">Vendibile fino al</label>
                  <input type="text"
                    class="form-control"
                    name="dateEnd"
                    id="dateEnd"
                    [ngModel]="ticketListData?.dateEnd"
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class="form-group col-md-12">
                  <label for="dateEnd"> Prezzo</label>
                <div class="input-group col-md-6">

                 
                  <input type="text" id="price" name="price" placeholder="0.00" class="form-control" ngModel [ngModel]="ticketListData?.price"
                    required price #price="ngModel">
                  <span class="input-group-addon">€</span>

                </div>
              </div>

            </div>

            <div class="col-md-12">
              <div class="form-group col-md-6">
                <label for="tripMaxDay">Obliterazioni Giornaliere</label>
                <input type="text" id="tripMaxDay" name="tripMaxDay" class="form-control" ngModel [ngModel]="ticketListData?.tripMaxDay"
                  required ng-disabled #tripMaxDay="ngModel">
              </div>
              <div class="form-group col-md-6">
                <label for="tripMaxPeriod">Obliterazioni massime consentite</label>
                <input type="text" id="tripMaxPeriod" name="tripMaxPeriod" class="form-control" ngModel [ngModel]="ticketListData?.tripMaxPeriod"
                  required ng-disabled #tripMaxPeriod="ngModel">

              </div>




            </div>


            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!formTicketList.valid">Salva </button>


          </div>
        </form>
      </div>
    </div>
  </div>
</div>