  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-8 col-sm-offset-3 col-md-offset-2">
        <div class="ibox float-e-margins">
          <form (ngSubmit)="onSubmit()" #blockForm="ngForm">
            <div class="ibox-title">
              <h2>{{kindTitle}} blocco</h2>
            </div>
            <div class="ibox-content">
              <div *ngIf="isSoluzione1" class="form-group col-xs-12 col-sm-12 col-md-12">
                <label for="idCompany">Azienda di Trasporto</label>
                <select
                  type="text"
                  id="idCompany"
                  class="form-control"
                  ngModel
                  [ngModel]="dataBlock?.idCompany"
                  name ="idCompany"
                  required
                  idCompany
                  #idCompany="ngModel"
                  required>
                  <option *ngFor="let o of companiesList" [ngValue]="o.idCompany">{{o.legalBusinessName}}</option>
                </select>
              </div> 
          
              <div class="form-group col-xs-6 col-sm-6 col-md-6">
                <label for="name">Codice Blocco</label>
                <input
                  type="text"
                  id="id"
                  name="id"
                  class="form-control "
                  ngModel
                  [ngModel]="dataBlock?.id"
                  required
                  [disabled]="kindManage == 0"
                  #id= "ngModel">
              </div>

              <div *ngIf="!isSoluzione1 && kindManage == 0" hidden class="form-group col-xs-6 col-sm-12 col-md-6">
                <label for="name">CompanyId</label>
                <input
                  type="text"
                  id="idCompany"
                  name="idCompany"
                  class="form-control "
                  ngModel
                  [ngModel]="dataBlock?.idCompany"
                  required
                  idCompany
                  #idCompany= "ngModel">
              </div>
              
              <div class="form-group col-xs-6 col-sm-6 col-md-6">
                <label for="description"> Descrizione</label>
                <input
                  type="text"
                  id="description"
                  class="form-control"
                  ngModel
                  [ngModel]="dataBlock?.description"
                  name="description"
                  required
                  description
                  #description="ngModel">
              </div>

              <div class="clearfix"></div>
            </div>
          
            <div class="ibox-footer">
              <button class="btn btn-default" type="button"  (click)="back()">Annulla</button>
              <button class="btn btn-redBU" type="submit" style="float: right"  [disabled]="!blockForm.valid" >Salva </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>    




