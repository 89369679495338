import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { WebDataRocksPivot } from './../../../webdatarocks/webdatarocks.angular4';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-statistics-export',
  templateUrl: './statistics-export.component.html',
  styleUrls: ['../../../app.component.css']
})
export class StatisticsExportComponent implements OnInit {

  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any;
  dataFilter: any[] = new Array();

  errorMessage = '';
  colorTheme = 'theme-orange';
  bsConfig: Partial<BsDatepickerConfig>;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  locale = 'it';

  @ViewChild('pivot1') child: WebDataRocksPivot;
  @ViewChild('formExportTicket', { static: true }) exportFormTicket: NgForm;

  constructor(
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall(
      '/statistics/sold',
      false,
      true,
      1,
      12
    );
    this.listService.restURL = '/stats/tickets/v1';
  }

  ngOnInit() {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      {dateInputFormat: 'DD-MM-YYYY'},
      {rangeInputFormat: 'DD-MM-YYYY'}
    );

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.maxDate.setDate(lastDay.getDate());
    this.bsRangeValue = [firstDay, this.maxDate];
    this.filterSearch()
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.exportFormTicket.value;
    console.log('FILTRO:', this.dataFilter);
    this.dataFilter['bsDaterangepicker'] = 'all';

    this.dataFilter['dateFrom'] = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
    this.dataFilter['dateTo'] =   this.utils.stringTotalFRomDate(this.bsRangeValue[1])

    this.listService.visibleFilter = true;
    this.loadElements();
  }

  // SERVIZIO LISTA ELEMENTI

  loadElements() {
    this.loadingService.presentLoader();
    this.logger.log('Response:', this.dataFilter, 300);
    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.child.webDataRocks.setReport({
          dataSource: {
            data: this.dataList
            // filename: 'https://cdn.webdatarocks.com/data/data.json'
          },
          'slice': {
            'rows': [
                {
                    'uniqueName': 'nomeCompletoTransazione'
                }
            ],
            'columns': [
                {
                    'uniqueName': 'Measures'
                },
                {
                    'uniqueName': 'ticketTsCreated.Day'
                }
            ],
            'measures': [
                {
                    'uniqueName': 'ticketPrice',
                    'aggregation': 'sum'
                }
            ]
          }
        });
        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    ).add(() => { this.loadingService.hideLoader(); });
  }

  // PIVOT ANGULAR

  /*onPivotReady(pivot: WebDataRocks.Pivot): void {
      console.log('[ready] WebDataRocksPivot', this.child);
  }

  onCustomizeCell(cell: WebDataRocks.CellBuilder, data: WebDataRocks.Cell): void {
    //console.log('[customizeCell] WebDataRocksPivot');
    if (data.isClassicTotalRow) cell.addClass('fm-total-classic-r');
    if (data.isGrandTotalRow) cell.addClass('fm-grand-total-r');
    if (data.isGrandTotalColumn) cell.addClass('fm-grand-total-c');
  }

  onReportComplete(): void {
    this.child.webDataRocks.off('reportcomplete');
    this.child.webDataRocks.setReport({
      dataSource: {
        filename: 'https://cdn.webdatarocks.com/data/data.json'
      }
    });
  } */
}
