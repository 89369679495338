import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  NgForm
} from "@angular/forms";

import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { AuthService } from "../../../services/auth.service";
import swal2 from "sweetalert2";
import { UtilsService } from "../../../services/utils.service";

@Component({
  selector: "app-blocks-manage",
  templateUrl: "./blocks-manage.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class BlocksManageComponent implements OnInit {
  //testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = "/block";
  //form html
  @ViewChild("blockForm") formBlock: NgForm;
  //oggetto contenente dati component
  dataBlock: BlockModel = new BlockModel();
  companiesList: any[] = new Array();
  pathToCallCompaniesPath = "/company/companies";

  urlSpecificResalePath = "/user/";
  isSoluzione1;
  typeUser;

  errorMessage;
  id;
  idCompany;

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    //Se creo nuovo veicolo
    if (route.snapshot.toString().indexOf("new") != -1) {
      this.kindTitle = "Nuovo";
      this.kindManage = 1;
    } else {
      //se modifico veicolo
      this.kindTitle = "Modifica";
      this.kindManage = 0;
      if (localStorage.getItem("dataPassed") == "") {
        this.router.navigate(["/blocks/list"]);
        return;
      }
      this.dataBlock = JSON.parse(localStorage.getItem("dataPassed"));
      logger.log("OGGETTO ARRIVATO", this.dataBlock, 200);
      //TO DO DA ELIMINARE

      this.id = this.dataBlock?.id;

      this.idCompany = 1;

      localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
    }
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = "/blocks/list";
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
      response => {
        this.companiesList = response.results;
        this.logger.log("Lista rivenditori", this.companiesList, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  onSubmit(): void {
    swal2.fire({
      title: "Operazione in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    if (this.kindManage) {
      this.dataBlock = this.formBlock.value;
      if(!this.isSoluzione1)
        this.dataBlock["idCompany"] = localStorage.getItem("idCompany");
      //this.dataBlock["status"]  = "1"

      this.listService
        .newObject(this.dataBlock, this.serviceToCallPath)
        .subscribe(
          response => {
            this.logger.log('Console', response, 200);
            if (response.outcome.success === true) {
              swal2.fire("Nuovo blocco creato", "", "success");
              this.router.navigate(["/blocks/list"]);
            } else {
              this.errorMessage = "Credenziali non valide.";
              swal2.fire("Attenzione", "Credenziali non valide", "warning");
            }
          },
          error => {
            this.logger.log("Error", error, 200);
            this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
            swal2.fire(
              "Errore",
              "Spiacente, si è verificato un errore tecnico.",
              "error"
            );
          }
        );
    } else {
      this.dataBlock = this.formBlock.value;
      this.dataBlock.id = this.id;
      this.dataBlock["status"]  = "1"
      this.dataBlock.idCompany = this.formBlock.value.idCompany;

      this.listService.edit(this.dataBlock, this.serviceToCallPath).subscribe(
        response => {
          if (response.outcome.success === true) {
            swal2.fire("Il blocco è stato modificato", "", "success");
            this.router.navigate(["/blocks/list"]);
          } else {
            this.errorMessage =
              response.outcome.code + response.outcome.description;
            swal2.fire("Attenzione", this.errorMessage, "warning");
          }
        },
        error => {
          this.logger.log("Error", error, 200);
          this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
          swal2.fire(
            "Errore",
            "Spiacente, si è verificato un errore tecnico.",
            "error"
          );
        }
      );
    }
  }

  back() {
    this.router.navigate(["/blocks/list"]);
  }
}

export class BlockModel {
  id;
  idCompany;
  id_block;
  description;
  status;
  constructor() {}
}
