import { Component, OnInit, ViewChild, Pipe } from "@angular/core";
import { Router } from "@angular/router";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from "../../../services/utils.service";

@Component({
  selector: "app-companies-details",
  templateUrl: "./companies-details.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class CompaniesDetailsComponent implements OnInit {
  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService
  ) {
    this.listService.listRouting = "/companies/details";
  }

  idCompany;
  model = {
    idCompany: 1
  };
  errorMessage;
  pathUrlCompanyDetails;
  companyDetails: any = new Object();

  ngOnInit() {
    //this.companyDetails =
    this.idCompany = localStorage.getItem("idCompany");
    console.log("ID ", this.idCompany);
    this.pathUrlCompanyDetails = "/dashboard/company";
    this.getDashboard();
  }

  selectRow() {
    this.model.idCompany = this.idCompany;
    console.log("OGGETTO ", this.model);
    localStorage.setItem("dataPassed", JSON.stringify(this.model));
    this.listService.backToList = true;
    this.router.navigate(["/companies/edit"]);
  }

  getDashboard() {
    this.listService.getListSelect(this.pathUrlCompanyDetails).subscribe(
      response => {
        this.companyDetails = response;
        this.logger.log("Oggetto Dashboard", this.companyDetails, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  goToVehicles() {
    this.router.navigate(["/vehicles/list"]);
  }

  goToPrinters() {
    this.router.navigate(["/printers/list"]);
  }

  goToResale() {
    this.router.navigate(["/resales/list"]);
  }
  goToObliterators() {
    this.router.navigate(["/obliterators/list"]);
  }
}
