<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <div class="col-xs-11">
            <h2>{{kindTitle}} tariffa ticket</h2>
          </div>

          <div class="ibox-tools col-xs-1 pull-right" *ngIf="!kindManage">
            <button type="file" class="btn btn-view btn-outline" title="Carica CSV tariffe" style="margin-right: 4px;"
              [disabled]="sol1OrSa && !this.ticketRatesForm?.value.idCompany"(click)="openUploadModal(uploadCSVTemplate)" >
              <i class="fa fa-upload"></i>
            </button>
          </div>
        </div>
        <form (ngSubmit)="onSubmit()" #ticketRatesForm="ngForm">
          <div class="ibox-content">

            <div class="col-md-12">
              <div *ngIf="sol1OrSa" style="padding-left: 30px" class="form-group col-md-12">
                <label for="idCompany"> Azienda di trasporto </label>
                <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" (change)="onChangeCompany($event.target)"
                  idCompany #idCompany="ngModel" [ngModel]="ticketFaresData?.idCompany" required>
                  <option *ngFor="let o of companies" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div style="padding-left: 30px" class="form-group col-md-12">
                <label for="description">Descrizione</label>
                <input type="text" id="description" name="description" class="form-control" ngModel [ngModel]="ticketFaresData?.name" required
                  #name="ngModel">
              </div>
            </div>
            
            <div style="padding-left: 30px" class="col-md-6">
              <div class="form-group col-md-12" *ngIf="kindManage == 1">
                <label for="typeTicket">Tipo</label>
                <select  type="text" id="typeTicket" class="form-control" ngModel name="typeTicket"  (change)="onChangeType($event.target.value)" typeTicket #typeTicket="ngModel"
                [ngModel]="ticketFaresData?.typeTicket">
                  <option *ngFor="let c of config" [value]="c.id">{{c.value}}</option>
                </select>
              </div>

              <div class="form-group col-md-12">
                <label for="description">Prezzo</label>
                <div class="input-group">
                  <input type="number" s id="price" min="0" name="price" class="form-control" ngModel [ngModel]="ticketFaresData?.price"
                    required price #price="ngModel">
                  <span class="input-group-addon">€</span>
                </div>
              </div>

              <div class="form-group col-md-12">
                <label for="description">Validità</label>

                <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue" ngModel bsDaterangepicker
                  #bsDaterangepicker required placement="right" [bsConfig]="bsConfig" placement="bottom" autocomplete="off">
              </div>

              <div class="col-md-12 form-group">
                <label>Categorie</label>
                <ng-select #listCategories class="custom"
                  id="categoriesList"
                  placeholder="Seleziona una o più categorie"
                  name="categoriesList"
                  [items]="categories"
                  [multiple]="true"
                  [clearable]="true"
                  [searchable]="true"
                  [disabled]="kindManage === 0"
                  bindLabel="groupedName"
                  ngModel
                ></ng-select>
              </div>

            </div>

            <div class="col-md-6">
              <h4>Canali di vendita abilitati</h4>
              <ng-container>
                <div *ngFor="let chan of dataChannel; let i = index"  clas="col-md-6">
                  <div class="checkbox">
                    <input type="checkbox" name="listChannels" 
                    id="listChannels" listChannels 
                    [checked]="isCheckChannel(chan.idChannel)"
                    (change)="setIdChannel(chan.idChannel)"
                    >
                    <label>
                      <b>{{chan.name}}</b>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="col-md-12" style="padding-left: 30px">
              <hr>
              <p class="bg-info p-sm">N.B. Aggiungere una o più regole per abilitare correttamente la tariffa</p>
              <h2> Crea le regole da applicare alla tariffa<br>
                <small>Regole salvate: {{countDecidedRules()}}</small>
              </h2>
            </div>

            <div class="col-md-3 text-right">
              <!--<button class="btn btn-danger" (click)="addNewRules()" [disabled]="!ticketRatesForm.valid"> Aggiungi Regola</button>-->
            </div>


            <div *ngFor="let c of counterRules; let i = index" class="col-md-12" style="padding-left: 30px;">


              <div style="padding-left: 10px; margin-top: 10px" class="col-md-12  text-center">
                <div class="form-group col-md-12" style="margin-bottom: 0;">
                  <label *ngIf="c.decided == false" for="description" class="text-center">Tipologia regola</label>
                  <select *ngIf="c.decided == false" type="text" id="choseKind" class="form-control" (ngModelChange)="choseKindRules($event, c)"
                    ngModel name="choseKind" required choseKind #choseKind="ngModel" [ngModel]="counterRules[i]?.choseKind"
                    required>
                    <option [ngValue]="1">LINEA</option>
                    <option [ngValue]="3">TRATTA</option>
                    <option [ngValue]="6">ZONA</option>
                    <option [ngValue]="2">TRA DUE SPECIFICHE FERMATE</option>
                    <option [ngValue]="4">CORSA</option>
                    <option [ngValue]="5">PER TUTTI</option>
                  </select>
                  <h2 *ngIf="c.decided != false" class="text-center"> {{c.kindName}}: </h2>
                  <h3 *ngIf="c.decided != false" class="text-center">{{getRouteName(c.routeId)}} {{getStopName(c.originId)}} {{getStopName(c.destinationId)}} {{getBlockName(c.blockId)}}  {{getZoneName(c.originZoneId)}} {{getZoneName(c.destinationZoneId)}}
                    {{c.tripId}} {{getForAll(c)}}
                  </h3>

                  <button style="cursor: pointer;" *ngIf="c.decided != false" class="text-right" (click)="removeRules(c)" class="btn btn-danger btn-sm">ELIMINA</button>
                  <br>
                  <br>
                  
                </div>
              </div>

              <div *ngIf="c.choseKind == '1' && c.decided == false" class="col-md-12" style="padding-left: 10px">
                <div class="col-md-5" style="margin-top: 10px; margin-bottom: 10px">
                  <label for="description" class="text-center">Linea</label>
                  <select placeholder="LINEA" type="text" id="routeId" name="routeId" (ngModelChange)="choseKindRoute($event, c)" class="form-control"
                    ngModel [ngModel]="ticketFaresData?.routeId" required #name="ngModel">
                    <option *ngFor="let l of lines" [ngValue]="l.routeId">{{l.routeLongName}}</option>
                  </select>
                </div>
                <div class="col-md-7" style="margin-top: 10px; margin-bottom: 10px" class="text-right">
                <button style="cursor: pointer;margin-top: 15px" *ngIf="counterRules.length > 1" class="text-right" (click)="removeRules(c)" class="btn btn-danger btn-sm">ELIMINA</button>
                </div>

              </div>


              <div *ngIf="c.choseKind == '2' && c.decided == false" class="col-md-12">

                <div class="col-md-12"></div>
                <div class="form-group col-md-4">
                  <label>Partenza Da:</label>
                  <select type="text" id="originId" (ngModelChange)="onChangeStop($event, c)" name="originId" class="form-control" ngModel
                    [ngModel]="ticketFaresData?.originId" required #name="ngModel">
                    <option *ngFor="let s of originStops" [ngValue]="s.stopId">{{s.stopName}}</option>
                  </select>

                </div>
                <div class="form-group col-md-4">
                  <label>Arrivo A:</label>
                  <select type="text" id="destinationId" (ngModelChange)="onChangeStopDestination($event, c)" name="destinationId" class="form-control"
                    ngModel destinationId [ngModel]="ticketFaresData?.destinationId" required #name="ngModel">
                    <option *ngFor="let s of  destinationStops" [ngValue]="s.stopId">{{s.stopName}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4 text-right">
                 
                  <button style="cursor: pointer;margin-top: 15px" *ngIf="counterRules.length > 1" class="text-right" (click)="removeRules(c)" class="btn btn-danger btn-sm">ELIMINA</button>
                </div>

              </div>

              <div *ngIf="c.choseKind == '3' && c.decided == false" class="col-md-12">

                <div style="margin-top: 5px; margin-bottom: 5px" class="form-group col-md-5">
                  <label>Tratta</label>
                  <select type="text" id="blockId" name="blockId" class="form-control" (ngModelChange)="onChangeBlockId($event, c)" blockId
                    ngModel required [ngModel]="ticketFaresData?.blockId" #name="ngModel">

                    <option *ngFor="let b of blocks" [ngValue]="b.id">{{b.description}}</option>

                  </select>
                </div>
                <div style="margin-top: 5px; margin-bottom: 5px" class="form-group col-md-7 text-right">
                    <button style="cursor: pointer;margin-top: 15px" *ngIf="counterRules.length > 1" class="text-right" (click)="removeRules(c)" class="btn btn-danger btn-sm">ELIMINA</button>
                </div>

              </div>

              <div *ngIf="c.choseKind == '6' && c.decided == false" class="col-md-12">

                <div class="col-md-12"></div>
                <div class="form-group col-md-4">
                  <label>Zona Da:</label>
                  <select type="text" id="originZoneId" (ngModelChange)="onChangeZoneOrigin($event, c)" name="originZoneId" class="form-control" ngModel originZoneId
                    [ngModel]="ticketFaresData?.originZoneId" required #name="ngModel">
                    <option *ngFor="let s of zones" [ngValue]="s.zoneId">{{s.description}}</option>
                  </select>

                </div>
                <div class="form-group col-md-4">
                  <label>Zona A:</label>
                  <select type="text" id="destinationZoneId" (ngModelChange)="onChangeZoneDestination($event, c)" name="destinationZoneId" class="form-control"
                    ngModel destinationZoneId [ngModel]="ticketFaresData?.destinationZoneId" required #name="ngModel">
                    <option *ngFor="let s of  zones" [ngValue]="s.zoneId">{{s.description}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4 text-right">
                 
                  <button style="cursor: pointer;margin-top: 15px" *ngIf="counterRules.length > 1" class="text-right" (click)="removeRules(c)" class="btn btn-danger btn-sm">ELIMINA</button>
                </div>

              </div>

              <div *ngIf="c.choseKind == '4' && c.decided == false" class="col-md-12">
                <div class="form-group col-md-4">
                  <label>Partenza Da:</label>
                  <select type="text" id="fromStopTrip" (ngModelChange)="onChangeStopTrip($event)" fromStop name="fromStopTrip" class="form-control"
                    ngModel [ngModel]="ticketFaresData?.fromStopTrip" required #name="ngModel">
                    <option *ngFor="let s of originStops" [ngValue]="s.stopId">{{s.stopName}}</option>
                  </select>

                </div>
                <div class="form-group col-md-4">
                  <label>Arrivo A:</label>
                  <select type="text" id="toStopTrip" (ngModelChange)="choseTripList($event, ticketRatesForm.value.fromStopTrip)" name="toStopTrip"
                    class="form-control" ngModel [ngModel]="ticketFaresData?.toStopTrip" required #name="ngModel">

                    <option *ngFor="let s of  destinationStops" [ngValue]="s.stopId">{{s.stopName}}</option>

                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label>Delle ore: </label>
                  <select type="text" id="tripId" tripId name="tripId" class="form-control" (ngModelChange)="onChangeTripId($event, c)" ngModel
                    [ngModel]="ticketFaresData?.tripId" required #name="ngModel">

                    <option *ngFor="let s of  tripsResponse" [ngValue]="s.tripId">{{s.arrivalTimeOrigin}}</option>

                  </select>
                </div>

                <div class="form-group col-md-12 text-center">
                  <button style="cursor: pointer;margin-top: 15px" *ngIf="counterRules.length > 1" class="text-right" (click)="removeRules(c)" class="btn btn-danger btn-sm">ELIMINA</button>
                </div>
              </div>
              <br>
              <br>
            </div>

            <div class="col-md-12 text-center" *ngIf="!isRulesAllConfirmed()">
              <button type="button" class="btn btn-danger" (click)="confirmNewRules()"> Conferma regola</button>
            </div>

            <div class="col-md-12 text-center" style="margin-top: 2rem;" *ngIf="isRulesAllConfirmed()">
              <button type="button" class="btn btn-danger" (click)="addNewRules()"> Aggiungi Regola</button>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="ibox-footer">
            <button class="btn btn-default" (click)="returnToList()" type="button">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!ticketRatesForm.valid || !isRulesAllConfirmed()">Salva </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Scroller button -->
  <app-scroll-to-bottom *ngIf="countDecidedRules() > 10"></app-scroll-to-bottom>
</div>

<ng-template #uploadCSVTemplate>
  <app-ticket-fares-uploader
    [idCompany]="getCompanyId()"
    [idFare]="ticketFaresData?.idTicketFare"
    (modalClose)="closeUploadModal()"
    (uploadEvent)="manageUploadEvent()"></app-ticket-fares-uploader>
</ng-template>