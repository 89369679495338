import { Component, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ListService} from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import { environment } from '../../../../environments/environment';
import swal2 from 'sweetalert2'


@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['../../../app.component.css']
})

export class OrderEditComponent implements OnInit {

  id: number;
  selectedStatusValue;
  defaultcodeOrder;
  defaultdescOrder;
  defaultselectedStatusValue;
  defaultselectedCodeTypeValue;
  defaultselectedSupplierValue;
  defaultselectedCommissionValue;
  defaultselectedApprovatorValue;
  urlPath;
  editUrlPath;
  supplierListPath;
  commissionListPath;
  amministratorListPath;

 
    
    suppliers: any[] = new Array();
    commissions: any[] = new Array();
    administrators: any[] = new Array();



  orderData = {

   codeOrder: "",
   descOrder: "",
   codeOrderType: "",
   idCommission: 1,
   idSupplier: 1,
   idUser: 3,
   orderStatus: ""
}

orderDataUpdate = {
   codeOrder: "",
   descOrder: "",
  // codeOrderType: "",
   idCommission: 1,
   idSupplier: 1,
   idUser: 3,
   orderStatus: "", 
   idOrder: 4,
}

  private sub: any;
  

  constructor(private route: ActivatedRoute,  public listService: ListService, private logger: LoggingService,
  private router: Router,) { 
    this.listService.restURL = "/admin/order/"+ this.id;
    this.listService.page = 1;
  }
   @ViewChild('f') signupForm: NgForm;

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number

       // In a real app: dispatch action to load the details here.
    });

     this.urlPath = "/admin/order/" + this.id;
     this.editUrlPath = "/admin/order"
     this.supplierListPath = "/suppliers/suppliers"
     this.commissionListPath ="/commissions/commissions"
     this.amministratorListPath="/admin/user/users"
   
    this.getSuppliers();
    this.getCommissions();
    this.getOrder();
    this.getAmministratorList();

    this.signupForm.statusChanges.subscribe(
      (status) => this.logger.log("Error:",status,200)
    );
  
  }


  getAmministratorList(){
    this.listService.getList(this.amministratorListPath).subscribe( (response) => {

        this.administrators = response.results;
        this.logger.log("Lista amministratori: ",this.administrators ,300);
     
      },    
      (error) => { 
        this.logger.log("Error",error,200);
   
      }
    );
  }



 getOrder(){
      this.listService.getObject(this.urlPath).subscribe( (response) => {

        // Verificare outcome

        this.orderData = response;
        
        this.defaultcodeOrder = this.orderData.codeOrder;
        this.defaultdescOrder = this.orderData.descOrder;
        this.defaultselectedStatusValue = this.orderData.orderStatus;
        this.defaultselectedCodeTypeValue = this.orderData.codeOrderType;
        this.defaultselectedSupplierValue = this.orderData.idSupplier;
        this.defaultselectedCommissionValue = this.orderData.idCommission;
        this.defaultselectedApprovatorValue = this.orderData.idUser;
       
        this.logger.log("Response:",response,300);
     
      },    
      (error) => { 
        this.logger.log("Error",error,200);
   
      }
    );
  }


  getSuppliers(){
    this.listService.getList(this.supplierListPath).subscribe( (response) => {

        // Verificare outcome

        this.suppliers = response.results;
        this.logger.log("Lista fornitori: ",this.suppliers,300);
     
      },    
      (error) => { 
        this.logger.log("Error",error,200);
   
      }
    );
  }


  getCommissions(){
    this.listService.getList(this.commissionListPath).subscribe( (response) => {

        // Verificare outcome

        this.commissions = response.results;
        this.logger.log("Lista Commesse: ",this.commissions,300);
     
      },    
      (error) => { 
        this.logger.log("Error",error,200);
   
      });
  }
  

  onSubmit() : void {
       //this.visible = true;
    swal2.fire({
      title: 'Modifica in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })
    this.orderDataUpdate.codeOrder = this.signupForm.value.codeOrder; 
    this.orderDataUpdate.​idSupplier = this.signupForm.value.idSupplier;
    this.orderDataUpdate.descOrder = this.signupForm.value.descOrder; 
    this.orderDataUpdate.orderStatus = this.signupForm.value.orderStatus; 
    // this.orderDataUpdate.codeOrderType = this.signupForm.value.codeOrderType;
    this.orderDataUpdate.idCommission = this.signupForm.value.idCommission;
    this.orderDataUpdate.idUser=this.signupForm.value.idUser;
    this.orderDataUpdate.idOrder = this.id;
      

    this.listService.edit(this.orderDataUpdate,this.editUrlPath).subscribe(
      (response) => {
 
        if (response.outcome.success === true) {
            swal2.fire("Modifica completata", "L'ordine è stato modificato con successo", "success").then((ev) => {
              if (ev.value) {
                this.router.navigate(['orders/list']);
              }
            }); 

        } else {
          this.logger.log("Response",response,200);
          swal2.fire("Errore", "L'ordine non è stato modificato a causa di un errore. Riprova più tardi.", "error");
         
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
      }
    );
  }



  deleteOrder(){

    this.logger.log("ID DELETE ", this.id,200);

    this.listService.delete(this.urlPath).subscribe(
      (response) => {
 
        if (response.outcome.success === true) {
           
            this.router.navigate(['/orders/list']);
       
        }  else if (response.outcome.code === "0014") {
      
          console.log(response)
          swal2.fire("Attenzione!", "Il codice inserito è già stato utilizzato per un ordine valido.", "warning")
          //this.errorMessage = "Il codice inserito è già stato utilizzato per una commessa valida."
       
        }else{ console.log(response)
             swal2.fire("Errore", "Errore durante la creazione dell'ordine. Riprova più tardi.", "error");
           // this.errorMessage = "Credenziali non valide."
           this.logger.log("Response",response,200);
          }
        }, 
          (error) => {
        this.logger.log("Error",error,200);
     
      }
    );

  }
}