<div class="row" style="padding-top: 20px"></div>
<div class="">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2 class="col-xs-2">
                    <strong>Linee</strong>
                </h2>

                <div *ngIf="isSoluzione1" class="ibox-tools col-md-9 pull-right">
                    <button type="button" (click)="newLine()" class="btn btn-view btn-outline">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>

            </div>
            <div class="ibox-content">
                <div class="row">


                    <form #formLines="ngForm">
                        <div *ngIf="isSoluzione1" class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                            <div class="form-group">
                                <label>Azienda di trasporto</label>
                                <div class='input-group col-xs-12'>
                                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                                        <option [ngValue] = "'all'" > Tutte </option>
                                        <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
    
                        <div class=' col-xs-6 col-sm-3 col-md-2'>
                            <div class="form-group">
                                <label>Sigla</label>
                                <div class='input-group col-xs-12'>
                                    <input type="text" id="routeId" name="routeId" class="form-control" ngModel routeId #routeId="ngModel">
                                </div>
                            </div>
                        </div>

                        <div class=' col-xs-6 col-sm-3 col-md-2'>
                            <div class="form-group">
                                <label>Nome</label>
                                <div class='input-group col-xs-12'>
                                    <input type="text" id="routeLongName" name="routeLongName" class="form-control" ngModel routeLongName #routeLongName="ngModel">
                                </div>
                            </div>
                        </div>
                     

                        <div class='col-xs-12 col-sm-4 col-md-3'>
                            <div class="form-group">
                                <label class="hide-xs"></label>
                                <div class='input-group col-md-12'>
                                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                                        <i class="fa fa-rotate-left"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
                    <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
                <div *ngIf="errorMessage === ''" class="table-responsive">
                    <table class="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                            <tr>
                                <th *ngIf="isSoluzione1" >Azienda di trasporto</th>
                                <th>Sigla</th>
                                <th>Nome esteso</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="errorMessage === ''">
                            <tr *ngFor="let data of dataList" style="cursor:pointer">
                                <td style="width: 200px;" *ngIf="isSoluzione1"> {{ getBusinessName(data.idCompany)}}</td>
                                <td>{{data.routeId}}</td>
                                <td>{{data.routeLongName}}</td>
                                <td class="text-center">
                                    <button title="Elimina" type="button" class="btn btn-danger btn-outline" (click)="manageDeleteLine(data)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
                        [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>