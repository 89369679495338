import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2, { SweetAlertResult } from 'sweetalert2'

@Component({
  selector: 'app-orderslist',
  templateUrl: './orders-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class OrdersListComponent implements OnInit {

  constructor(private logger: LoggingService, private router: Router, public listService: ListService) {
    this.listService.listRouting = "/orders/list";
    this.listService.restURL = "/order/orders";
     this.listService.backToList = false;
    this.listService.paging = true; // server pagination
    this.listService.rows = 10;
    this.listService.page = 1;
  }


  @ViewChild('f') signupForm: NgForm;
 
  private myDatePickerOptions = {
    // other options...
    dateFormat: 'dd-mm-yyyy',
  };

  // Initialized to specific date (09.10.2018).
  private model: Object = { date: { year: 2018, month: 10, day: 9 } };


  orderID = 0;
  errorMessage: string = "";
  orders: any[] = new Array();
  deletePath;

  role = "role";
  isAdministrator = true;
  commissions: any[] = new Array();
  commisionListPath;
  suppliers: any[] = new Array();
  supplierListPath;
  //defaultSupplier;
  //defaultCommission;
  visibleFilter;
  defaultValue;

  //Valori da tenere per i filtri
  idSupFilter = null;
  idCommFilter = null;
  idStatusFilter = null;




  ngOnInit() {
    this.supplierListPath = "/suppliers/suppliers"
    this.deletePath = "/admin/order/";
    this.commisionListPath = "/commissions/commissions";
 

    this.getOrders();
    this.role = localStorage.getItem("role");
    this.isAdmin();
    this.defaultValue = 0;
 
    if (this.isAdministrator){
      this.getCommissions();
      this.getSuppliers();
    }

    if(this.listService.idSupplierFilter || this.listService.idCommissionFilter){
      this.visibleFilter = true
    }
  }

  pageChange(page) {
    this.listService.page = page;
    this.getOrders();
  }

  getOrders() {
    this.listService.getGenericFilterResult(this.idSupFilter,this.idCommFilter,null,null,null,this.idStatusFilter).subscribe(
      (response) => {

        this.orders = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );

 
  }

  getSuppliers(){
        this.listService.getList(this.supplierListPath).subscribe( (response) => {
        this.suppliers = response.results;
        this.logger.log("Lista fornitori: ",this.suppliers,300);
      },    
      (error) => { 
      //  this.logger.log("Error",error,200);
      }
    );
  }
  

  selectRow(idOrder, nameOrder) {
   
    this.listService.selectedID = idOrder;
    this.listService.backToList = true;
    this.router.navigate(['/orders/edit/' + idOrder]);
  }

  reviewDocuments(idOrder,typeOrder,nameOrder,statusOrder) {
    localStorage.setItem("statusOrder", statusOrder)
    this.listService.selectedID = idOrder;
    this.listService.backToList = true;
    this.router.navigate(['orders/checklists/' + idOrder]);
  }

  
  isAdmin() {
    if (this.role === "ADMINISTRATOR") {
      this.isAdministrator = true
    }
    else {
      this.isAdministrator = false;
    }

  }

  getCommissions(){
      this.listService.getList(this.commisionListPath).subscribe( (response) => {
        this.commissions = response.results;
        this.logger.log("Lista commesse ",this.commissions,300);
        //console.log("LISTA commesse: ",response.results);
      },    
      (error) => { 
          this.logger.log("Error",error,200);
      }
    );
  }

  deleteOrder(idOrder) {
    //this.visible = true; per lo spinner
    swal2.fire({
      title: 'Eliminazione in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })

    this.listService.delete(this.deletePath + idOrder).subscribe(
      (response) => {

        if (response.outcome.success === true) {
          swal2.fire("Eliminazione completata", "L'ordine è stato eliminato con successo","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.getOrders();
            }
          });

        } else {
          swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
          this.logger.log("Response",response,200);
        }
      },
      (error) => {
        swal2.fire("Errore", error,"error");
        this.logger.log("Error",error,200);
      }
    );
  }

  newOrder() {
     this.listService.backToList = true;
    this.router.navigate(['/orders/new']);
  }

  changeOrderID(idOrder, codeOrder) {
    this.orderID = idOrder;
    var mySelf = this;
    swal2.fire({
      title: 'Eliminazione ordine',
      text: "Sei sicuro di voler eliminare l'ordine "+codeOrder+" ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteOrder(this.orderID);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })
    
  }


  reset(){
    this.idSupFilter = null;
    this.idCommFilter = null;
    this.idStatusFilter = null;
    this.visibleFilter = false;
    this.signupForm.reset();
    
    this.getOrders()
  }

  search(){

     this.listService.page= 1;
    if( this.signupForm.value.idSupplier != 0){
      this.idSupFilter = this.signupForm.value.idSupplier;
    }
    if( this.signupForm.value.idCommission != 0){
      this.idCommFilter = this.signupForm.value.idCommission;
    }
    if( this.signupForm.value.idStatus != 0){
      this.idStatusFilter = this.signupForm.value.idStatus;
    }
    
    this.visibleFilter = true
    this.listService.getGenericFilterResult(this.idSupFilter,this.idCommFilter,null,null,null,this.idStatusFilter).subscribe(
      (response) => {

        this.orders = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }
}
