<div class="row wrapper border-bottom white-bg page-heading float-e-margins mTB20">
  <div class="col-lg-9">
    <h2>Crea nuovo ordine</h2>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
      
        <div class="ibox-content">

          <form (ngSubmit)="onSubmit()" #f="ngForm">
            <div class="form-group">
              <label for="codeOrder">Codice Univoco Ordine</label>
              <input type="text" id="codeOrder" name="codeOrder" class="form-control" ngModel required codeOrder #codeOrder="ngModel">
            </div>
            <div class="form-group">
              <label for="idSupplier"> Azienda Fornitrice</label>
              <select type="text" id="idSupplier" class="form-control" ngModel name="idSupplier" required idSupplier #idSupplier="ngModel"
                required>
              <option *ngFor="let c of suppliers" [ngValue]="c.idSupplier">{{c.businessName}}</option>
                </select>
            </div>
            <div class="form-group">
              <label for="idUser"> Approvatore</label>
              <select type="text" id="idUser" class="form-control" ngModel name="idUser" required idUser #idUser="ngModel" required>
                <option *ngFor="let a of administrators" [ngValue]="a.idUser">{{a.name}} {{a.surname}} </option>
                </select>
            </div>

            <div class="form-group">
              <label for="idCommission"> Codice Commessa</label>
              <select type="text" id="idCommission" class="form-control" ngModel name="idCommission" required idCommission #idCommission="ngModel"
                required>
                <option *ngFor="let c of commissions" [ngValue]="c.idCommission">{{c.codeCommission}}</option>
                </select>
            </div>

            <div class="form-group">
              <label for="descOrder"> Descrizione</label>
              <input type="text" id="descOrder" class="form-control" ngModel name="descOrder" required descOrder #descOrder="ngModel">
            </div>


            <div class="form-group">
              <label for="orderStatus"> Stato</label>
              <select type="text" id="orderStatus" class="form-control" ngModel name="orderStatus" required orderStatus #orderStatus="ngModel"
                [ngModel]="defaultselectedStatusValue" required>
                   <option value="OPEN">Aperto</option>
                   <option value="CLOSED">Chiuso</option>
                </select>
            </div>

            <div class="form-group">
              <label for="codeOrderType"> Tipologia</label>
              <select type="text" id="codeOrderType" class="form-control" ngModel name="codeOrderType" required codeOrderType #codeOrderType="ngModel"
                [ngModel]="defaultselectedCodeTypeValue" required>
                   <option value="NORMAL">Normale</option>
                   <option value="SLIM">Slim</option>
                </select>
            </div>
            <button class="btn btn-redBU" type="submit" [disabled]="!f.valid">Crea Ordine</button>
            <button class="btn btn-default" type="button" style="float: right" (click)="back()">Annulla</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>