<div class="row" style="padding-top: 20px"></div>

<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Lotti</strong>
      </h2>

      <div class="ibox-tools col-xs-1 pull-right" *ngIf="!isSoluzione1">
        <button type="button" (click)="newTicketList()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
      </p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th *ngIf="isSoluzione1">Azienda</th>
              <th>Descrizione</th>
              <th>Data</th>
              <th>Quantità</th>
              <th>Canale</th>           
              <th *ngIf="!isMobileOperator"></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
              <td *ngIf="isSoluzione1" style="width: 200px;">{{getCompanyName(data.idCompany)}}</td>
              <td>{{data.description}}</td>
              <td style="width: 200px;">{{data.tsCreated | date:'dd-MM-yyyy HH:mm:ss'}}</td>
              <td>{{data.numTicket}}</td>
              <td>{{data.emitChannel}}</td>
              <td *ngIf="!isMobileOperator" class="col-md-3 text-center">
  
                <div class="float-right">
                  <button type="button" class="mL10 btn btn-success btn-outline" (click)="selectRow(data)" placement="top" tooltip="Visualizza lista vouchers">
                    <i class="fa  fa-list"></i>
                  </button>
                  <button *ngIf="data.emitChannel != 'PRATICKO'" type="button" class="mL10 btn btn-success btn-outline" (click)="loadVoucherByDateLot(data, data.emitChannel, 0)" placement="top" tooltip="Esporta lotto">
                    <i class="fa fa-download"></i>
                  </button>
                  <button *ngIf="data.emitChannel == 'PRATICKO'" type="button" class="mL10 btn btn-success btn-outline" (click)="loadVoucherByDateLot(data, lotPrintTemplate, 1)" placement="top" tooltip="Stampa lotto">
                    <i class="fa fa-print"></i>
                  </button>
                  <button type="button" class="mL10 btn btn-danger" [disabled]="data.deletable == false" (click)="deleteLot( data )" placement="top" tooltip="Elimina lotto">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)" [maxSize]="10">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
<div class="col-md-2"></div>

<form #formPrintLot="ngForm">
    <ng-template #lotPrintTemplate>
  
      <div class="modal-dialog">
        <div class="modal-header ">
          <h1>Scegli la stampante con la quale effettuare la stampa dei ticket</h1>
        </div>
        <div class="modal-body row">
          <div class=' col-xs-12'>
              <label for="idCompany"> Stampanti attive </label>
              <select type="text" id="printerSerialNumber" class="form-control" ngModel name="printerSerialNumber" printerSerialNumber
                  #printerSerialNumber="ngModel" required x>
                  <option *ngFor="let p of printersList" [value]='p.serialNumber'>{{p.name}}</option>
              </select>
          </div>
          
          <div class="text-center mTB20  col-xs-12">
            <button (click)="confirmPrint()" [disabled]="!formPrintLot.valid " title="Conferma Stampa" type="button" class="btn btn-danger">Stampa</button>
          </div>
        </div>
      </div>
  
    </ng-template>
  </form>