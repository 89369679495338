<div class="row" style="padding-top: 20px"></div>
    
<div class="col-md-12">
  <div  class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-md-4"><strong>Riepilogo Operatore</strong></h2>
   
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
              <div class="form-group" *ngIf="userAdmin">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                      <select type="text" class="form-control" [(ngModel)]="selectedCompany" (change)="getOperators()">
                          <option [ngValue]="'all'"> Tutte </option>
                          <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
                      </select>
                  </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="companiesList && utils.checkFilesListAvailable(userAdmin, selectedCompany) && filterUserData.length > 0">
            <form #formSalesRecapOperator="ngForm">
              <div *ngIf="!isOp && !isMob" class='form-group col-xs-6 col-sm-2'>
                  <label>
                  <i class="fas fa-user"></i> OPERATORE</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idUser" class="form-control" ngModel name="idUser" required idUser #idUser="ngModel">
                      <option *ngFor="let c of filterUserData" [ngValue]="c.idUser">{{c.surname}} {{c.name}} </option>
                    </select>
                </div>
              </div>
              <div class="form-group col-xs-6 col-sm-2">
                <label>Vendita il:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="fromDate"
                    id="fromDate"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>
              <div class="form-group col-xs-6 col-sm-2">
                <label>Dalle ore:</label>
                <div class='input-group col-xs-12'>
                  <select type="text" id="fromDateTime" class="form-control" ngModel name="fromDateTime" fromDateTime #fromDateTime="ngModel" required>
                    <option *ngFor="let hour of utils.getHours()" [ngValue]='hour'>{{hour}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group col-xs-6 col-sm-2">
                <label>Vendita al:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="toDate"
                    id="toDate"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>
              <div class="form-group col-xs-6 col-sm-2">
                  <label>Fino alle:</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="toDateTime" class="form-control" ngModel name="toDateTime" toDateTime #toDateTime="ngModel" required>
                      <option *ngFor="let hour of utils.getHours()" [ngValue]='hour'>{{hour}}</option>
                    </select>
                  </div>
                </div>

              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-4 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                      <button type="button" [disabled]="!formSalesRecapOperator.valid" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                      <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                        <i class="fa fa-rotate-left"></i>
                      </button>
                    </div>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <p *ngIf="dataList?.totalSold == 0" class="bg-info p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> Nessun riepilogo presente per l'intervallo indicato</p>
      <div *ngIf="errorMessage === '' && +dataList?.totalSold > 0" class="table-responsive">
        <h1>Totali</h1>
        <hr>
        <div class="row">
          <div class="col-lg-6">
            <div class="widget style1 lazur-bg" style="background-color: orangered; min-height: 190px;">
              <div class="row">
                <div class="col-xs-4">
                  <i class="fa fa-ticket fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h1> Ticket</h1>
                  <h3 class="font-bold"> Obliterati: {{dataList?.numberObliterated}}</h3>
                  <h3 class="font-bold"> Rifiutati: {{dataList?.numberRefused}}</h3>
                  <h3 class="font-bold"> Venduti: {{dataList?.numberSold}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="widget style1 lazur-bg" style="background-color: blue; min-height: 190px;">
              <div class="row">
                <div class="col-xs-4">
                  <i class="fa fa-eur fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h1> Incasso: {{dataList?.totalSumSold | number : '1.2-2'}} €</h1>
                  <ng-container *ngFor="let type of dataList?.paymentType" >
                    <h3 class="font-bold">
                      {{checkTypePayment(type.name)}}: {{type?.totalValue | number : '1.2-2'}} €
                      <br /><span *ngIf="type?.supplementValue > 0">({{type?.value | number : '1.2-2'}} € + {{type?.supplementValue | number : '1.2-2'}} € suppl.)</span>
                    </h3>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1>Dettagli</h1>
        <hr>
        <div class="row">
          <ng-container *ngFor="let company of dataList?.partialSummary">
            <div [ngClass]="{'col-sm-12' : (dataList?.partialSummary.length == 1) , 'col-sm-6' : (dataList?.partialSummary.length > 1) }" >
              <table class="table table-striped table-bordered table-hover dataTables-example">
                <thead>
                  <tr>
                    <th><h2><b>{{company.companyName}}</b></h2></th>
                    <th><h2><b>{{company.totalSumSold | number : '1.2-2'}} €</b></h2></th>
                  </tr>
                </thead>
                <ng-container *ngFor="let typology of company?.paymentType">
                  <tr style="cursor:pointer">
                    <td>{{checkTypePayment(typology.name)}}</td>
                    <td>{{typology.totalValue | number : '1.2-2'}} € <span *ngIf="typology.supplementValue > 0">({{typology?.value | number : '1.2-2'}} € + {{typology?.supplementValue | number : '1.2-2'}} € suppl.)</span></td>
                  </tr>
                </ng-container>
                <tbody *ngIf="errorMessage === ''">
                  <tr>
                    <td colspan="2">
                      <h3>Dettaglio per Linea</h3>
                      <br>
                      <ng-container *ngFor="let route of company?.routes">
                          <h4>{{route.routeName}} - {{route.totalSumSold| number : '1.2-2'}} € <span *ngIf="route?.totalSupplementSold > 0">({{route?.totalSold | number : '1.2-2'}} € + {{route?.totalSupplementSold | number : '1.2-2'}} € suppl.)</span></h4>
                          <h5>Dettaglio per Tariffa</h5>
                          <ng-container *ngFor="let fare of route?.fareType">
                            <h6>{{fare.name}} - {{fare.totalValue | number : '1.2-2'}} € <span *ngIf="fare?.supplementValue > 0">({{fare?.value | number : '1.2-2'}} € + {{fare?.supplementValue | number : '1.2-2'}} € suppl.)</span></h6>
                          </ng-container>
                          <h5>Dettaglio per Incasso</h5>
                          <ng-container *ngFor="let pay of route?.paymentType">
                              <h6>{{checkTypePayment(pay.name)}} - {{pay.totalValue | number : '1.2-2'}} € <span *ngIf="pay?.supplementValue > 0">({{pay?.value | number : '1.2-2'}} € + {{pay?.supplementValue | number : '1.2-2'}} € suppl.)</span></h6>
                          </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
        
        <h1>Azioni</h1>
        <hr>
        <div class="row">
          <div class="col-sm-6">
              <button type="button"  (click)="printSummary()" class="btn btn-view btn-outline"> Scarica Riepilogo PDF</button>
          </div>
          <div class="col-sm-6">
              <form #formSendSummary="ngForm">
                <select type="text" id="emails" class="form-control" ngModel name="emails" emails #emails="ngModel" required>
                  <option *ngFor="let admin of dataAdministrator"  [ngValue]='admin.email'>{{admin.email}}</option>
                </select>
                <button type="button" [disabled]="!formSendSummary.valid"  (click)="sendSummary()" class="btn btn-view btn-outline mt-2"> Invia Riepilogo via Mail</button>              
              </form>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

