<div class="row" style="padding-top: 20px;">
</div>
<div class={{stringLenghtTables}}>
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-2">
        <strong>Zone</strong>
      </h2>

      <div class="ibox-tools col-md-9 pull-right">
        <button type="button" (click)="newBlock()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>

    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <form #formZone="ngForm">

              <div *ngIf="isSoluzione1" class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}} </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- <div class=' col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Codice</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="code" name="code" class="form-control" ngModel #code="ngModel">
                  </div>
                </div>
              </div> -->
              <div class=' col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Descrizione</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="description" name="description" class="form-control" ngModel #description="ngModel">
                  </div>
                </div>
              </div>
              <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th *ngIf="isSoluzione1">Azienda di trasporto</th>
              <th>Codice</th>
              <th>Descrizione</th>
              <th *ngIf="isSoluzione1">Ultima modifica</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
              <td style="width: 200px;" *ngIf="isSoluzione1">{{getCompanyName(data.idCompany)}}</td>
              <td style="width: 200px;">{{data.zoneId}}</td>
              <td>{{data.description}}</td>
              <td *ngIf="isSoluzione1">{{data.last_modified}}</td>
              <td style="width: 100px" class="col-md-4 text-center">
                <button style="float: left" type="button" class="btn btn-success btn-outline " (click)="selectRow(data)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>