<!-- Wrapper-->
<div id="wrapper" style="background-color: white;" *ngIf="configLoaded">

    <!-- Left navigation bar -->
    <navigation></navigation>

    <!-- Main page wrapper -->
    <div id="page-wrapper" style="background-color: #eeeeee; display: flex; flex-flow: column;">

        <!-- Top navigation -->
        <topnavbar></topnavbar>
     
        <!-- Main view/routes wrapper-->
        <router-outlet></router-outlet>

        <!-- Footer -->
        <footer></footer>

    </div>
    <!-- End page wrapper-->

</div>
<!-- End wrapper-->
