import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICompany, IPass } from '../../../../interfaces/configuration.interface';
import { ListService } from '../../../../services/list.service';
import { LoggingService } from '../../../../services/logging.service';
import { UtilsService } from '../../../../services/utils.service';
import { NgForm } from '@angular/forms';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-users-passes-manage',
  templateUrl: './users-passes-manage.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class UsersPassesManageComponent implements OnInit {

  @ViewChild('passForm') passForm: NgForm;

  kindTitle: string;
  kindManage: number;
  dataPass: IPass;
  idTemp: number;
  typeUser: boolean;
  isSoluzione1: boolean;
  deleted: boolean = false;

  serviceToCallPath = '/pass';
  urlCompanyPath = '/company/companies';
  companiesList: ICompany[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listService: ListService,
    private logger: LoggingService,
    private utils: UtilsService
  ) {
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);

    if (this.route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuova';
      this.kindManage = 1;
    } else {
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      if(!localStorage.getItem('dataPassed')) () => this.router.navigate(['/users/passes']);
      this.dataPass = JSON.parse(localStorage.getItem('dataPassed'));
      this.idTemp = this.dataPass.id;
      localStorage.setItem('dataPassed', '');
    }
  }

  ngOnInit(): void {
    this.getCompanies();
  }

  onSubmit(): void {
    swal2.fire({title: 'Operazione in corso...', didOpen: () => { swal2.showLoading() }});

    this.dataPass = this.passForm.value;
    this.dataPass.deleted = this.deleted;

    if (!this.kindManage) {
      this.dataPass.id = this.idTemp;
    }

    this.manageCrudOperations();
  }

  back(): void {
    this.router.navigate(['/users/passes']);
  }

  // ---------- PRIVATE METHODS ---------- //

  private getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
      this.companiesList = response.results;
      this.passForm.controls['idCompany']?.setValue(this.listService.selectedCompany ? this.listService.selectedCompany : null);
      this.logger.log('Lista aziende', this.companiesList, 300);
    }, (error) => {
      this.logger.log('Error', error, 200);
    }
    );
  }

  private manageCrudOperations(): void {
    this.listService[this.kindManage ? 'newObject' : 'edit'](this.dataPass, this.serviceToCallPath).subscribe((response) => {
      if (response.outcome.success) {
        swal2.fire(this.kindManage ? 'Tessera creata con successo' : 'Tessera modificata con successo', '','success');
        this.router.navigate(['/users/passes']);
      } else {
        const errorMessage =  response.outcome.code +  response.outcome.description
        swal2.fire('Attenzione', errorMessage,'warning');
      }
    });
  }

}
