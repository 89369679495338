import { Component, OnInit, ViewChild, Pipe } from "@angular/core";
import { Router } from "@angular/router";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from "sweetalert2";
import { UtilsService } from "../../../services/utils.service";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: 'app-resales-details',
  templateUrl: './resales-details.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ResalesDetailsComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService
  ) {
    this.listService.listRouting = "/companies/details";
  }

  vehicleTotal;
  printersTotal;
  resaleTotal;
  obliteratorTotal;
  amount;



  urlCompany;
  companiesList: any[] = new Array();
  filterCompaniesList: any[] = new Array();
  errorMessage;

  pathDashboardResale
  resaleDashboard:any = new Array()

  ngOnInit() {

    this.pathDashboardResale = "/dashboard/resale"
    this.getDashboardResale()

 
  }

  selectRow() {


  }


  getDashboardResale(){
    this.listService.getListSelect(this.pathDashboardResale).subscribe(
      response => {
        this.resaleDashboard = response;
    
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }
  









 

  goToPrinters() {
    this.router.navigate(["/printers/list"]);
  }


}



