<div class="row wrapper border-bottom white-bg page-heading float-e-margins mTB20">
    <div class="col-lg-9">
        <h2><strong>Elenco documenti aziendali</strong></h2>
    </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
   
    <div class="row" *ngIf="viewMask; else viewDoc">

        <section *ngIf="isAppr || isAdmin">
                <div class="col-lg-12">
                    <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                            <div class="ibox-content">
                                <div class="row">
                                    <div class='col-sm-12' ></div>
                                </div>
                                <div class="table-responsive">
                                <table class="table table-striped table-bordered table-hover dataTables-example" >
                                    <thead>
                                    <tr>
                                        <th colspan="2">File caricato</th>
                                        <th class="col-sm-6">Note</th>
                                        <th class="col-sm-2">Stato</th>
                                        <th class="col-sm-3" style="text-align:left;"></th>
                                    </tr>
                                    </thead> 
                                    <tbody *ngFor="let c of documentsSupplierToApprove; let i = index;">
                                        <tr>
                                            <td colspan="5" class="font-bold text-center">{{c.descDocumentType}}</td>
                                        </tr>
                                        <tr *ngFor="let docs of c.documents; let m = index;" style="cursor:pointer">
                                            <td class="text-center" style="width: 50px;">
                                                <button  title="Caricato" *ngIf="docs.status  === 'NEW'" class="btn btn-view" ><i class="fa fa-ellipsis-h"></i></button>
                                                <button  title="Approvato"*ngIf="docs.status  === 'APPROVED'" class="btn btn-primary" ><i class="fa fa-thumbs-o-up"></i></button>
                                                <button  title="Approvato con riserva"*ngIf="docs.status  === 'CONDITIONALLY_APPROVED'" class="btn btn-warning" ><i class="fa fa-exclamation"></i></button>
                                                <button  title="Rifiutato" *ngIf="docs.status  === 'REJECTED'" class="btn btn-danger" ><i class="fa fa-thumbs-o-down"></i></button>
                                                <button  title="Scaduto"*ngIf="docs.status  === 'EXPIRED'" class="btn btn-danger" ><i class="fa fa-clock-o"></i></button>  
                                                <button title="Mancante" *ngIf="docs.status  === 'MISSING'" class="btn btn-info" ><i class="fa fa-ban"></i></button>
                                            </td>
                                            <td>{{docs.fileName}}</td>
                                            <td>{{docs.notes}}</td>
                                            <td>{{docs.status  === 'NEW' ? 'Caricato' : docs.status  === 'APPROVED' ? 'Approvato' : docs.status === 'CONDITIONALLY_APPROVED' ? 'Approvato con riserva' : docs.status === 'REJECTED' ? 'Respinto' : docs.status === 'MISSING' ? 'Mancante' : docs.status === 'EXPIRED' ? 'Scaduto' : ' - ' }}</td>
                                            <td  class=" text-center"> 
                                                <button title="Visualizza" *ngIf="docs.status  != 'MISSING'" class="btn btn-view btn-outline"  (click)="download(docs.idDocument,docs.status,docs.fileName)"><i class="fa fa-eye"></i></button>
                                                <button title="Approva" *ngIf="docs.status  != 'MISSING' && docs.status != 'APPROVED'  &&  (docs.idOffice == office || docs.idOffice == null || isAdmin)" class="btn btn-primary btn-outline" (click)="approve(docs.idDocument)"><i class="fa fa-thumbs-o-up"></i></button>
                                                <button title="Rifiuta" *ngIf="docs.status  != 'MISSING' && docs.status  != 'REJECTED'  &&  (docs.idOffice == office || docs.idOffice == null || isAdmin)" class="btn btn-danger btn-outline" (click)="approvaRiservaRespingiDoc(docs.idDocument,'reject')"><i class="fa fa-thumbs-o-down"></i></button> 
                                                <button title="Approva con riserva" *ngIf="docs.status  != 'MISSING' && docs.status != 'CONDITIONALLY_APPROVED' && (docs.idOffice == office || docs.idOffice == null || isAdmin)" class="btn btn-warning btn-outline" (click)="approvaRiservaRespingiDoc(docs.idDocument,'conditionally')"
                                                        ><i class="fa fa-exclamation"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
</section> 
    <ng-container *ngIf="!isAppr">
        <section *ngFor="let c of documentsSupplier; let i = index;">
            <div *ngIf=" i%2 == 0" class="clearfix"></div>
            <div class="col-lg-6">
                <div class="col-md-12">
                    <div class="ibox  float-e-margins ">
                        <div class="ibox-title">
                            <div class=''>
                                <div class="form-group">
                                    <label>{{c.descDocumentType}}</label> 
                                </div>
                            </div>
                            <div class="row ibox-content">
                                <div class="form-group col-md-12">

                                    <div *ngIf="c.status === 'MISSING'" class="alert alert-info">
                                        Documento mancante
                                    </div>

                                    <div *ngIf="c.status === 'NEW'" class="alert alert-warning">
                                        Documento caricato in attesa di Approvazione
                                    </div>

                                    <div *ngIf="c.status === 'CONDITIONALLY_APPROVED'" class="alert alert-warning">
                                        Approvato con Riserva
                                    </div>

                                    <div *ngIf="c.status === 'REJECTED'" class="alert alert-danger">
                                        Documento Rifiutato
                                    </div>

                                                      <div *ngIf="c.instructions !== null" class="alert alert-warning">
                                                        {{c.instructions}}
                                                    </div>


                                </div>

                                <div class="form-group col-md-12">

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                    <th colspan="3">File caricati</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let docName of c.documents">

                                                  <td style="width:50px;">
                                                            <button *ngIf="docName.status  === 'NEW'" class="btn btn-view" title="Caricato" ><i class="fa fa-paper-plane"></i></button>
                                                            <button *ngIf="docName.status  === 'APPROVED'"  title="Approvato" class="btn btn-primary" ><i class="fa fa-thumbs-o-up"></i></button>
                                                            <button *ngIf="docName.status  === 'CONDITIONALLY_APPROVED' "  title="Approvato con riserva" class="btn btn-warning" ><i class="fa fa-exclamation"></i></button>
                                                            <button *ngIf="docName.status  === 'REJECTED'"  title="Rifiutato" class="btn btn-danger" ><i class="fa fa-thumbs-o-down"></i></button>
                                                            <button *ngIf="docName.status  === 'EXPIRED'" title="Scaduto" class="btn btn-danger" ><i class="fa fa-clock-o"></i></button>  
                                                            <button *ngIf="docName.status  === 'MISSING'" title="Mancante" class="btn btn-info" ><i class="fa fa-ban"></i></button>
                                                        </td>
                                                
                                                    <td>
                                                            <p>{{docName.fileName}}</p>
                                                          <span *ngIf="docName.dtValidityEnd !== null && docName.dtValidityEnd != '' ">Valido fino: {{docName.dtValidityEnd  | date:'dd/MM/yyyy'}} </span>
                                                          <span *ngIf="docName.dtReleased !== null && docName.dtReleased !='' "><br>Data emissione: {{docName.dtReleased  | date:'dd/MM/yyyy'}} </span> 
                                                          <span *ngIf="docName.notes !== null && docName.notes !='' "><br>Note: {{docName.notes}} </span> 
                                                          <div *ngIf="docName.fileName === null" class="alert alert-info">
                                                            Nessun Documento è stato ancora caricato
                                                            </div>
                                                        </td>
                                                   
                                                        <td *ngIf= "docName.status !== 'MISSING'" style="width: 130px">
                                                 
                                                                     <div style="float: right" *ngIf="docName.fileName !== null" >
                                                               

                                                                <button type="button"   (click)="download(docName.idDocument,docName.status,docName.filename)"  class="btn btn-view btn-outline btn-s ">
                                                                    <span class="fa fa-eye"></span>
                                                                </button>

                                                                 <button  type="button" style = "margin-left: 15px" (click)="deleteDocument(docName.idDocument)" class="btn btn-danger btn-s btn-outline">
                                                                    <span class="glyphicon glyphicon-trash"></span>
                                                                </button>
                                                            </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="form-group col-md-12">
                                    <div class="row">
                                        <div *ngIf="!isAppr">
                                            <br>
                                            <div *ngIf="!isAppr" class="col-md-12" style="margin-bottom: 15px">
                                                <div *ngIf="c.codeDocumentType === codeControlUpload">
                                                    <table class="table">
                                                        <thead *ngIf="c.codeDocumentType === codeControlUpload">
                                                            <tr>
                                                                <th width="50%">Da caricare</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of uploader.queue">
                                                                <td><p>{{ item?.file?.name }}</p></td>
                                                                <td nowrap>
                                                                    <div style="float: right">
                                                                        <button type="button" class="btn btn-danger btn-outline btn-s" (click)="item.remove()">
                                                                                    <span class="glyphicon glyphicon-trash"></span>
                                                                                </button>
                                                                        <button type="button" style="margin-left: 15px" class="btn btn-view btn-outline btn-s">
                                                                                    <span class="fa fa-eye"></span>
                                                                                </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    
                                                    <form #dateForm="ngForm">                                 
                                                        <div *ngIf="c.codeDocumentType === '01' && c.codeDocumentType !== '06'" class="form-group col-md-12">
                                                            <label>Data emissione</label>
                                                            <input type="text"
                                                                class="form-control"
                                                                name="emissionDocument"
                                                                id="emissionDocument"
                                                                [(ngModel)]="model"
                                                                required
                                                                bsDatepicker
                                                                #bsDatepicker
                                                                placement="bottom"
                                                                autocomplete="off"
                                                                [bsConfig]="bsConfig" />
                                                        </div>                                                 
                                                        <div *ngIf="c.codeDocumentType !== '06'" class="form-group col-md-6">
                                                            <label>Inizio validità</label>
                                                            <input type="text"
                                                                class="form-control"
                                                                name="beginValidity"
                                                                id="beginValidity"
                                                                ngModel
                                                                required
                                                                bsDatepicker
                                                                #bsDatepicker
                                                                placement="bottom"
                                                                autocomplete="off"
                                                                [bsConfig]="bsConfig" />
                                                        </div>
                                                        <div *ngIf="c.codeDocumentType !== '06'" class="form-group col-md-6">
                                                            <label>Fine validità</label>
                                                            <input type="text"
                                                                class="form-control"
                                                                name="endValidity"
                                                                id="endValidity"
                                                                ngModel
                                                                required
                                                                bsDatepicker
                                                                #bsDatepicker
                                                                placement="bottom"
                                                                autocomplete="off"
                                                                [bsConfig]="bsConfig" />
                                                        </div>
                                                        <div *ngIf="c.codeDocumentType === '06'" class="form-group col-md-12">
                                                            <label for="exampleTextarea">Descrizione documento</label>
                                                            <textarea class="form-control " id="descTextArea" name="descTextArea" ngModel  rows="3"></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button style="float: left" *ngIf="!isAppr" (click)="fileInput.click()" class="btn btn-default"> <i class="fa fa-plus" multiple ></i>     Aggiungi </button>
                                    <span style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                                                    <input (change)="codaUpload(c.codeDocumentType)" type="file" #fileInput ng2FileSelect [uploader]="uploader" />
                                                </span>
                                    <button  style="margin-left: 15px" *ngIf="c.templateLink != null && c.templateLink !='' " class="btn btn-default" (click)="downloadTemplate(c.templateLink)" > <span class="glyphicon glyphicon-download"></span>    File d'esempio</button>
                                                
                                    <button style="margin-left: 15px" *ngIf="uploadButtonVisible && c.codeDocumentType === codeControlUpload " class="btn btn-primary"
                                        (click)="multipleUpload(c.codeDocumentType)"> <span class="glyphicon glyphicon-upload"></span>    Carica</button>


                                       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            </ng-container>
   
    </div>
    <app-documents-viewer #viewerDiv class="row" [ngClass]="{hidden: viewMask}"></app-documents-viewer>  

    <div #myModal class="modal fade show" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Errore caricamento</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Non è stato possibile caricare il Documento perchè {{errorMessage}} {{errorDurcMessage}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>


    <app-dialog #dialogView></app-dialog>