import { Component, OnInit, ViewChild } from '@angular/core';
import { ListService, UploadType } from '../../../services/list.service';
import { FileUploader } from 'ng2-file-upload';
import { DocumentsViewerComponent } from '../../documents/documents-viewer/documents-viewer.component';
import { LoggingService } from '../../../services/logging.service';
import { UtilsService } from '../../../services/utils.service';

@ViewChild(DocumentsViewerComponent)

@Component({
  selector: 'app-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ImporterComponent implements OnInit {
  UploadType = UploadType;

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  urlCompany = '/company/companies';
  companiesList: any[] = [];
  selectedCompany: string;
  reloadList = false;

  userAdmin: boolean;

  constructor(
    private listService: ListService,
    private logger: LoggingService,
    public utils: UtilsService
  ) {
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.userAdmin = localStorage.getItem('typeUser') === 'SUPERADMIN';
  }

  async ngOnInit() {
    if (this.userAdmin) {
      this.companiesList = await this.utils.getCompanyList();
    }
  }

  /** Mehod that reloads the list of file entries after an upload process is completed */
  reloadImporterList(): void {
    this.reloadList = true;
    setTimeout(() => {
      this.reloadList = false;
    }, 200);
  }

}

