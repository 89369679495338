<div class="row" style="padding-top: 20px"></div>

<div class="">

  <div *ngIf="!qrCodeWindow" class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-md-6">
          <strong>Analisi Ticket</strong>
        </h2>
      </div>
      <div class="ibox-content">

        <form #formStatsTicket="ngForm">
          
          <!-- COMPANY (SA) -->
          <div class="row">
            <div *ngIf="isSol1" class="form-group col-lg-3 col-xs-6">
              <label>Azienda di trasporto</label>
              <div class='input-group col-xs-12'>
                <select type="text" id="idCompany" class="form-control" name="idCompany" [(ngModel)]="dataFilter.idCompany" ngModel idCompany
                  #idCompany="ngModel" (change)="onCompanySelect()" required>
                  <option [ngValue]="'all'"> Tutti </option>
                  <option *ngFor="let c of dataCompany" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
                </select>
              </div>
            </div>
          </div>

          <ng-container class="filters" *ngIf="showFilters">
            <!-- ID TICKET - VENDITA DA/A - TARIFFA - CANALE -->
            <div class="row">
              <div class="form-group col-lg-3 col-xs-6">
                <label>Id Ticket</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <input type="text" id="idTicket" class="form-control" ngModel name="idTicket" required idTicket #idTicket="ngModel">
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-12 col-lg-3'>
                <label>Vendita da - a:</label>
                <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue" ngModel bsDaterangepicker
                  #bsDaterangepicker placement="right" [bsConfig]="bsConfig" placement="bottom" autocomplete="off">
              </div>

              <div class="form-group col-lg-3 col-xs-6">
                <label>Tariffa</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="idTicketFare" class="form-control" [(ngModel)]="dataFilter.idTicketFare" ngModel name="idTicketFare" required idTicketFare #idTicketFare="ngModel"
                    required>
                    <option [ngValue]="'all'"> Tutte </option>
                    <option *ngFor="let s of dataFare" [ngValue]="s.idTicketFare" [ngStyle]="{'color': utils.checkDateValidity(s.dateTo) ? 'auto' : '#ed5565'}">
                      ({{utils.formatDateToLocal(s.dateTo)}}) {{s.name}}
                    </option>
                  </select>
                </div>
              </div>

              <div *ngIf="!isResale" class="form-group col-lg-3 col-xs-6">
                <label>Canale</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="idTicketChannel" class="form-control" [(ngModel)]="dataFilter.idTicketChannel" ngModel name="idTicketChannel" required idTicketChannel #idTicketChannel="ngModel"
                    required>
                    <option [ngValue]="'all'"> Tutti </option>
                    <option *ngFor="let c of dataChannel" [ngValue]="c.idChannel">{{c.name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- STATO TICKET - MOTIVO INVALIDAMENTO TICKET - STATO VENDITA - OPERATORE -->
            <div class="row">
              <div class="form-group col-lg-3 col-xs-4">
                <div>
                  <label style="margin-right: 2px;">Stato ticket</label>
                  <i class="fas fa-info-circle" style="cursor: pointer;" data-html="true" [tooltip]="popTemplate" placement="bottom"></i>
                  <ng-template #popTemplate><div [innerHtml]="ticketStatusText"></div></ng-template>
                </div>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="status" class="form-control" [(ngModel)]="dataFilter.status" ngModel name="status" required status #status="ngModel"
                    (change)="onTicketStatusChange()">
                    <option [ngValue]="'all'"> Tutti </option>
                    <option value="OPERATIVE">Operativo</option>
                    <option value="ACTIVE">Attivo</option>
                    <option value="INVALID">Invalido</option>
                    <option value="OBLITERATED">Obliterato</option>
                    <option value="REF_TKT">Riferimento rimborso</option>
                  </select>
                </div>
              </div>

              <div *ngIf="!isResale && formStatsTicket.value.status === 'INVALID'" class="form-group col-lg-3 col-xs-4 ticket-status">
                <label class="overflowed-text">Motivo invalidamento ticket</label>
                <div class='input-group col-lg-12 col-xs-12'>
                  <ng-select #filterStatusReason
                    appendTo="body" 
                    id="filterStatusReason"
                    name="filterStatusReason"
                    [items]="invalidationStatus"
                    [multiple]="true"
                    [clearable]="false"
                    bindLabel="groupedName"
                    ngModel
                    required
                  ></ng-select>
                </div>
              </div>

              <div class="form-group col-lg-3 col-xs-4">
                <label>Stato vendita</label>
                <div class='input-group col-lg-12 col-xs-12'>
                  <select type="text" id="filterTransStatus" class="form-control" [(ngModel)]="dataFilter.filterTransStatus" ngModel name="filterTransStatus" required filterTransStatus #filterTransStatus="ngModel">
                    <option value="CONFIRMED">Confermato</option>
                    <option value="UNCONFIRMED">Non confermato</option>
                    <option value="PENDING">Pending</option>
                  </select>
                </div>
              </div>

              <div class="form-group col-lg-3 col-xs-4">
                <label>Operatore</label>
                <div class="input-group col-xs-12 col-lg-12">
                  <select type="text" id="idUser" class="form-control" [(ngModel)]="dataFilter.idUser" ngModel name="idUser" required idUser #idUser="ngModel">
                    <option [ngValue]="'all'">Tutti</option>
                    <option *ngFor="let c of filterUserData" [ngValue]="c.idUser" [ngStyle]="{'color': !c.enabled ? '#ed5565' : 'auto'}">
                      {{c.surname}} {{c.name}} <span *ngIf="!c.enabled">(N/A)</span>
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- RIVENDITA - DESK - VEICOLO -->
            <div class="row">
              <div *ngIf="!isResale" class="form-group col-lg-3 col-xs-4">
                <label>Rivendita</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="idResale" class="form-control" [(ngModel)]="dataFilter.idResale" ngModel name="idResale" required idResale #idResale="ngModel">
                    <option [ngValue]="'all'"> Tutte </option>
                    <option *ngFor="let r of dataResale" [ngValue]="r.id">{{r.legalBusinessName}} </option>
                  </select>
                </div>
              </div>

              <div *ngIf="!isResale" class="form-group col-lg-3 col-xs-4">
                <label>Desk</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="idDesk" class="form-control" [(ngModel)]="dataFilter.idDesk" ngModel name="idDesk" required idDesk #idDesk="ngModel">
                    <option [ngValue]="'all'"> Tutti </option>
                    <option *ngFor="let d of dataDesk" [ngValue]="d.id">{{d.name}} </option>
                  </select>
                </div>
              </div>

              <div *ngIf="!isResale" class="form-group col-lg-3 col-xs-4">
                <label>Veicolo</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="idVehicle" class="form-control" [(ngModel)]="dataFilter.idVehicle" ngModel name="idVehicle" required idVehicle #idVehicle="ngModel">
                    <option [ngValue]="'all'"> Tutti </option>
                    <option *ngFor="let d of dataVehicles" [ngValue]="d.id">{{d.plate}} ({{d.name}}) </option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12"></div>
            </div>

            <!-- FIELDSET -->
            <div class="row">
              <div class="the-fieldset">

                <div class="col-sm-6 form-group">
                  <p><label>Cerca per:</label></p>

                  <div class="form-check form-check-inline">
                    <input type="radio" id="linea" class="form-check-input" name="searchType" value="linea"
                      [checked]="chosedSearchType === 'linea'" (change)="changeSearchType($event.target.value)">
                    <label class="form-check-label" for="linea">Linea</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input type="radio" id="corsa" name="searchType" value="corsa"
                      [checked]="chosedSearchType === 'corsa'" (change)="changeSearchType($event.target.value)">
                    <label class="form-check-input" for="corsa">Corsa</label>
                  </div>
                  
                </div>

                <div class="col-xs-12"></div>

                <div class="col-xs-6 form-group" *ngIf="!isResale && chosedSearchType == 'linea'">
                  <ng-select #routeList class="custom" [multiple]="true" id="routeIdRoute" placeholder="Seleziona una o più linee" [clearable]="true" [searchable]="true" name="routeIdRoute" [items]="lineList" bindLabel="routeId" ngModel>
                  </ng-select>
                </div>

                <div *ngIf="!isResale && chosedSearchType == 'corsa'" class="form-group col-lg-2 col-xs-4">
                  <label>Linea</label>
                  <div class='input-group col-lg-12  col-xs-12'>
                    <select (change)="onChange($event.target.value)" type="text" id="routeId" class="form-control" ngModel name="routeId" routeId
                      #routeId="ngModel" required [(ngModel)] = "modTicketRoute">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let o of lineList, let x = index" [value]='x'>{{o.routeId}}, {{o.routeLongName}}</option>
                  </select>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-xs-4" *ngIf="chosedSearchType == 'corsa'">
                  <label for="direction">Direzione </label>
                  <select type="text" id="direction" (change)="getDirection($event.target.value)" class="form-control" ngModel name="direction"
                      required direction #direction="ngModel" [(ngModel)] = "modTicketDir">
                      <option *ngFor="let d of modDirectionList, let f = index" [value]="f">{{d.arrival ? d.arrival : d.tripHeadsign}} </option>
                  </select>
                </div>

                <div class="form-group col-lg-2 col-xs-4" *ngIf="chosedSearchType == 'corsa'">
                  <div class="form-group">
                      <label>Data di viaggio</label>
                      <div class='input-group col-xs-12'>
                      <input type="text"
                        class="form-control"
                        name="tripDate"
                        id="tripDate"
                        ngModel
                        required
                        bsDatepicker
                        #bsDatepicker
                        placement="bottom"
                        autocomplete="off"
                        (bsValueChange)="onDateChanged($event)"
                        [bsConfig]="bsConfig" />
                      </div>
                  </div>
                </div>

                <div *ngIf="!isResale && chosedSearchType == 'corsa'" class="form-group col-lg-2 col-xs-4">
                  <label>Corsa</label>
                  <div class='input-group col-lg-12  col-xs-12'>
                    <select type="text" id="tripId" (change)="getTrip($event.target.value)" class="form-control"
                      ngModel name="tripId" required tripId #tripId="ngModel" [(ngModel)] = "modTicketTrip">
                      <option [ngValue]="null"> Tutte </option>
                      <option  *ngFor="let data of modTripList,let i = index" [value]="i">{{data.departureTime}} - {{data.arrivalTime}} ({{data.tripId}})</option>
                  </select>
                  </div>
                </div>
              
              </div>

              <div class="col-xs-12"></div>
            </div>

            <!-- FILTERS SEARCH BUTTONS -->
            <div class="row">
              <div class='col-xs-12 col-sm-6 col-sm-6 col-md-3 col-lg-3'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                    <button *ngIf="!isResale" type="button" (click)="getCsv()" style="margin-left: 10px" class="btn btn-info btn-outline">
                      Esporta</button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          
        </form>

        <div class="clearfix"></div>
        <p  class="bg-info p-sm">N.B. Per cercare ticket legati ad una corsa selezionare Linea, Direzione e data di viaggio. La ricerca inizia solo dopo aver scelto una corsa e cliccato 'Cerca'</p>
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
        </p>

        <div *ngIf="errorMessage === '' && showFilters" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th></th>
                <th>ID Ticket</th>
                <th>Tariffa/Promozione (Categoria)</th>
                <th>Tipologia</th>
                <th>Data vendita</th>
                <!-- <th>Ristampato</th> -->
                <th *ngIf="!isResale">Canale di vendita</th>
                <th>Linea</th>
                <th class="text-right">Importo</th>
                <th *ngIf="!isResale">Azioni</th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList" style="cursor:pointer">
                <td style="width: 60px">
                  <button (click)="viewDetailsTicket(data, ticketTemplate)" title="{{utils.checkTitleTickets(data.status)}}"
                    style="width: 40px" type="button" class="{{utils.checkColorTickets(data.status)}}">
                    <i class="{{utils.checkIconTickets(data.status)}}"></i>
                  </button>
                </td>
                <td><span *ngIf="data.status != 'REF_TKT'">{{data.idTicket}}</span><span *ngIf="data.status == 'REF_TKT'">Rif. Ticket {{data.idParentTicket || '-' }}</span></td>
                <td>
                  <ng-container *ngIf="data.ticketFareName"><b>T: </b>{{ data.ticketFareName || '-' }}</ng-container>
                  <ng-container *ngIf="data.promotion"><br /><b>P: </b>{{ data.promotion || '-' }}</ng-container>
                  <br>({{ data.ticketCategoryName || '-' }})
                </td>
                <td><span *ngIf="data.status != 'REF_TKT'">{{checkNameKind(data.ticketTypeName)}}</span><span *ngIf="data.status == 'REF_TKT'">Rimborso</span></td>
  
                <td>{{data.dateSell | date:'dd-MM-yyyy HH:mm:ss' }}</td>
  
                <!--  <td>{{isReprinted(data.reprinted)}}</td> -->
                <td *ngIf="!isResale">{{data.channel}} <br><small>({{data.paymentType}})</small></td>
                <td>{{data.idRoute || '-'}}</td>
  
                <td class="text-right">
                  {{data.price | number : '1.2-2'}} €<br/>
                  <span *ngIf="data.supplementPrice">+ (suppl. {{data.supplementPrice | number : '1.2-2'}} €)</span>
                </td>

                <td *ngIf="!isResale" style="width: 160px;">
                  <strong class="text-danger" *ngIf="data.status =='INVALID' || data.transStatus =='UNCONFIRMED'">{{utils.checkReasonTitleTickets(data.transStatus,data.statusReason)}}</strong>
                  <button *ngIf="data.status != 'INVALID' && data.status != 'REF_TKT'" (click)="getidTicket(data.idTicket, data.channel, template)" data-toggle="modal"
                    title="Invalida Biglietto" type="button" class="btn btn-danger btn-outline pull-right" style="margin-right: 5px;">
                    <i class="fa fa-ban"></i>
                  </button>
                  <button *ngIf="data.status == 'ACTIVE' && data.ticketTypeName == 'T'" (click)="getTicketPdf(data)" data-toggle="modal"
                    title="Stampa PDF" type="button" class="btn btn-success btn-outline pull-right" style="margin-right: 5px;">
                    <i class="fa fa-file"></i>
                  </button>
                  <button *ngIf="data.status == 'ACTIVE' && data.ticketTypeName == 'T'" (click)="modifyTicket(data, ticketModifyTemplate)" data-toggle="modal"
                    title="Modifica Biglietto" type="button" class="btn btn-success btn-outline pull-right" style="margin-right: 5px;">
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="listService.totalRows" style="margin-top: 30px" class="row">
            <div style="float: right; text-align: end;" class="col-md-3">
              <h2>Totale biglietti venduti: <span>{{ listService?.totalRows }}</span></h2>
            </div>
          </div>
          <ngb-pagination *ngIf="paginationVisible" class="pull-right" [collectionSize]="listService.totalRows" [pageSize]="listService.rows"
            [maxSize]="10" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<form #formInvalidTicket="ngForm">
  <ng-template #template>
    <div class="loader absolute-top" *ngIf="isLoading"></div>
    <div class="modal-dialog">
      <div class="modal-body">
        <h1>Causa invalidazione biglietto</h1>
        <div class=''>
          <label>Tipo di invalidazione</label>
          <select type="text" id="statusReason" class="form-control" ngModel name="statusReason" required statusReason #statusReason="ngModel" (change)="selectOption($event.target.value)">
            <option *ngFor="let opt of dataOptionTicket; index as i" [ngValue]="i">{{opt.label}}</option>
          </select>
        </div>
        <div *ngIf="viewSubOption" class='mTB20'>
          <label>Seleziona la tipologia</label>
          <select type="text" id="subStatusReason" class="form-control" ngModel name="subStatusReason" required subStatusReason #subStatusReason="ngModel" (change)="selectSubOption($event.target.value)">
            <option *ngFor="let subOpt of dataSubOptionTicket; index as i" [ngValue]="subOpt.value">{{subOpt.label}}</option>
          </select>
        </div>
        <div class="text-right mTB20">
          <button (click)="manageTicketInvalidation()" [disabled]="optionSelected == null || isLoading" title="Invalida Biglietto" type="button" class="btn btn-danger">Invalida</button>
        </div>
        <div class=""  *ngIf="errorMessageRefund && errorMessageRefund != ''" >
          <p class="bg-danger p-sm">
            {{errorMessageRefund}}
          </p>
        </div>
      </div>
    </div>

  </ng-template>
</form>

<form #formModifyTicket="ngForm">
  <ng-template #ticketModifyTemplate>

    <div class="modal-dialog">
      <div class="modal-body">
        <h1>Modifica Biglietto {{ticketToModified.id}}</h1>

        <div class=' col-xs-12'>
            <label for="idCompany"> Linea </label>
            <select (change)="onChange($event.target.value, true)" type="text" id="routeId" class="form-control" ngModel name="routeId" routeId
                #routeId="ngModel" required [(ngModel)] = "modalModTicketRoute">
                <option *ngFor="let o of lineList, let x = index" [value]='x'>{{o.routeId}}, {{o.routeLongName}}</option>
            </select>
        </div>

        <div class=' col-xs-12'>
            <label for="direction">Direzione </label>
            <select (change)="getDirection($event.target.value, true)" type="text" id="direction" class="form-control" ngModel name="direction"
                required direction #direction="ngModel" [(ngModel)] = "modalModTicketDir">
                <option *ngFor="let d of modalModDirectionList, let f = index" [value]="f">{{d.arrival ? d.arrival : d.tripHeadsign }}</option>
            </select>
        </div>

        <div class=' col-md-6'>
            <label for="origin" class='text-danger'>Origine </label>
            <select type="text" id="origin" (change)="getDirectionsTo($event.target.value, true)" class="form-control" ngModel name="origin"
                required origin #origin="ngModel" >
                <option *ngFor="let d of modOriginList, let f = index" [value]="f">{{d.stopName}} </option>
            </select>
        </div>

        <div class=' col-md-6'>
            <label for="destination" class='text-danger'>Destinazione </label>
            <select type="text" id="destination" class="form-control" ngModel name="destination"
                required destination #destination="ngModel" >
                <option *ngFor="let d of modDestinationList, let f = index" [value]="f">{{d.stopName}} </option>
            </select>
        </div>

        <div  class='col-xs-12 col-sm-6'>
            <div class="form-group">
                <label>Data</label>
                <div class='input-group col-xs-12'>
                <input type="text"
                  class="form-control"
                  name="dateNewTicket"
                  id="dateNewTicket"
                  ngModel
                  required
                  bsDatepicker
                  #bsDatepicker
                  placement="bottom"
                  autocomplete="off"
                  [bsConfig]="bsConfig"
                  (bsValueChange)="onDateChanged($event, true)" />
                </div>
            </div>
        </div>

        <div class=' col-xs-12 col-sm-6'>
            <label for="trip">Corse </label>
            <select type="text" id="trip" (change)="getTrip($event.target.value, true)" class="form-control" ngModel name="trip" required trip #trip="ngModel" [(ngModel)] = "modalModTicketTrip">
                <option  *ngFor="let data of modalModTripList, let i = index" [value]="i">{{data.departureTime}} - {{data.arrivalTime}} ({{data.tripId}})</option>
            </select>
        </div>
        <div class=' col-xs-12 col-sm-6'>
          <label for="stopTime" class='text-danger'>Orario fermata </label>
          <select type="text" id="stopTime" (change)="getStopTime($event.target.value)" class="form-control" ngModel name="stopTime" required stopTime #stopTime="ngModel" [(ngModel)] = "modTicketStop">
              <option  *ngFor="let data of modTripListStops,let i = index" [value]="i">{{data.departureTime}} ({{data.stopName}})</option>
          </select>
      </div>
        <div class="clearfix"></div>
        <div class=' col-xs-12'>
          * Seleziona la direzione per scegliere la corsa corretta. I campi in <b class='text-danger'>rosso</b> verranno utilizzati per la dicitura del pdf generato
          <hr>
        </div>
        <div class="text-right mTB20">
          <button (click)="confirmModifyTicket()" [disabled]="!formModifyTicket.valid || modTripListStops == null" title="Invalida Biglietto" type="button" class="btn btn-danger">Modifica</button>
        </div>
      </div>
    </div>

  </ng-template>
</form>


<ng-template #ticketTemplate>

  <div tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">
          <span *ngIf="status == 'REF_TKT'">Dettaglio Rimborso:</span>
          <span *ngIf="status != 'REF_TKT'">Dettaglio Biglietto:</span>
        </h4>
      </div>
      <br>

      <div *ngIf="status != 'REF_TKT'">
        <h2 style="margin-left: 25px">{{kindMenu}} <span *ngIf="cardId != null"> num. {{cardId}}</span></h2>
        <div class="row" style="margin: 0px 15px">
          <div class="col-xs-6">
            <h3><i class="fas fa-calendar-alt" ></i> {{purchasedForDate | date:'dd-MM-yyyy HH:mm'}}</h3>
            <h3 class="modal-stop"><i class="fas fa-map-marker-alt" style="color:green;"></i> {{origin}}</h3>
          </div>

          <div class="col-xs-6">
            <h3 style="min-height: 1.5rem;"><span *ngIf="idRoute"><i class="fas fa-route"></i> {{idRoute}} - {{idTrip}}</span></h3>
            <h3 class="modal-stop"><i class="fas fa-map-marker-alt" style="color:red;"></i> {{destination}}</h3>
          </div>
        </div>
      </div>

      <h2 style="margin-left: 25px">Venduto da: {{channel}}<span *ngIf="status != 'REF_TKT' && vehiclePlate!= null"><small> (su veicolo {{vehiclePlate}})</small></span></h2>

      <div class="row" style="margin: 0px 15px">

        <div class="col-xs-6">
          <h3>
            <span *ngIf="status == 'REF_TKT'">Data di rimborso:</span>
            <span *ngIf="status != 'REF_TKT'">Data di vendita:</span>
            {{dateSell | date:'dd-MM-yyyy HH:mm'}}
          </h3>
          <h3>Pagamento: {{paymentType}}</h3>
          <h3>N. Transazione<span *ngIf="status == 'REF_TKT'"> di rimborso</span>:  {{idTransaction}}</h3>
          <h3 *ngIf="status != 'REF_TKT' && vehiclePlate!= null">Veicolo:  {{vehiclePlate}} <small>({{vehicleId}})</small></h3>
        </div>

        <div class="col-xs-6">
          <h3><i class="fas fa-user"></i> {{name}} <span *ngIf="deskName != ''">-</span> {{deskName}}</h3>
          <h3><i class="fas fa-envelope"></i> {{sellerOperator}}</h3>
          <h3><i class="fas fa-user-tag"></i> {{ticketCategoryName}}</h3>
        </div>

      </div>

      <div class="row" style="margin: 0px 15px">
        <div class="col-xs-8" style="padding: 0;">
          <ng-container *ngIf="supplementPrice">
            <div class="col-xs-6">
              <h3><span>Prezzo: </span>{{price}} <i class="fas fa-euro-sign"></i></h3>
            </div>
            <div class="col-xs-6 text-center">
              <h3><span>Supplemento: </span>{{supplementPrice}} <i class="fas fa-euro-sign"></i></h3>
            </div>
          </ng-container>
        </div>
        <div class="col-xs-4 text-right">
          <h3><span>Totale: </span>{{totalPrice}} <i class="fas fa-euro-sign"></i></h3>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>

</ng-template>