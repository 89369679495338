import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2, { SweetAlertResult } from 'sweetalert2'

@Component({
  selector: 'app-subscription-fare',
  templateUrl: './subscription-fare.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SubscriptionFareComponent implements OnInit {

  @ViewChild('formSubscriptionFares') ticketSubscriptionForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  linesList: any[] = new Array();
  bsConfig: Partial<BsDatepickerConfig>;
  colorTheme = "theme-orange";
  datepickerModel: Date[];

  //PATH CHIAMATE
  urlLinesPath = "/route/routes";
  urlDeletePath;
  errorMessage: string = "";
  visibleFilter;
  defaultValue;
  UpOrDown;
  pathToCallCompaniesPath = "/company/companies"
  companiesList :any[] = new Array();
  typeUser;
  isSoluzione1;
  roleUser;
  isMobileOperator
  //VARIABILI PER SELECT
  dataChannel: any[] = new Array();
  urlChannelPath = "/ticket/channel/channels";
  categories: any[] = new Array();
  pathListCategories = '/category/categories?type=S' // filtro per ticket

  constructor(private logger: LoggingService, public utils:UtilsService, private router: Router, public listService: ListService) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/subscription/fares",false,true,1,10);
    this.listService.restURL = "/ticket/fare/fares";
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  ngOnInit() {

    //CHIAMATE PER SELECT
    //   this.getLines();
    this.getCompanies();
    //CHIAMATA LISTA ELEMENTI
    this.loadElements();
    this.getCategories()
    this.getChannel();

    this.UpOrDown = true;
    this.roleUser = this.utils.getRoleUser();
    this.isMobileOperator = this.utils.isOperatorMobile(this.roleUser)

  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  changeStatus(){
    if(this.UpOrDown == true){
      this.UpOrDown = false;

      console.log("VIEE", this.UpOrDown)
    }else{
      this.UpOrDown = true;
    }
  
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.ticketSubscriptionForm.value;
    this.dataFilter['bsDatepicker'] = 'all';
    if(this.datepickerModel != null)
    {
      var dateOn = this.utils.stringTotalFRomDate(this.datepickerModel);
      this.dataFilter["dateOn"] = dateOn; 
    }
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  getCompanyName(id){
    for (let company of this.companiesList){
      if (company.idCompany ==  id){
        return company.legalBusinessName;
      }
    }

  }

  getChannel() {
    this.listService.getListSelect(this.urlChannelPath).subscribe((response) => {
      this.dataChannel = response.results;
      this.logger.log("Lista canali", this.dataChannel, 300);
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }


  getCategories() {
    this.listService.getListSelect(this.pathListCategories)
    .subscribe((response) => {
      this.categories = response.results;
    
      this.logger.log("Lista Categorie", this.categories, 300);
      },
      (error) => {
          this.logger.log("Error", error, 200);
      }
      );
  }

  filterReset(){

    
    this.ticketSubscriptionForm.value.idCompany = ""
    this.ticketSubscriptionForm.value.code = ""
    this.ticketSubscriptionForm.value.name = ""
    this.dataFilter['dateOn'] = 'all'
    this.listService.visibleFilter = false;
    this.loadElements();
     this.ticketSubscriptionForm.reset();


    //this.loadElements();
  }

  //SERVIZI SELECT
  getLines() {
    this.listService.getListSelect(this.urlLinesPath).subscribe((response) => {
      this.linesList = response.results;
      this.logger.log("Lista linee", this.linesList, 300);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
    this.companiesList = response.results;
    this.logger.log("Lista aziende", this.companiesList, 300);
    //console.log("LISTA commesse: ",response.results);
    },
    (error) => {
        this.logger.log("Error", error, 200);
    }
    );
}

  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.dataFilter['type'] = "S";
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = this.utils.formatDates(response.results);
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/subscription/fares/edit']);
  }

  newTicketFares() {
    this.listService.backToList = true;
    this.router.navigate(['/subscription/fares/new']);
  }

  deleteTicketFares(idFares) {
    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione Tariffa',
      text: "Sei sicuro di voler eliminare la tariffa "+idFares+" ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idFares);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idElement) {
    this.urlDeletePath =  "/ticket/fare/"+ idElement;

    swal2.fire({title: 'Eliminazione in corso...', didOpen: function () {swal2.showLoading()}});
    
    this.logger.log("ID DELETE:",idElement,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
    
        if (response.outcome.success === true) {
              
          this.logger.log("Response value",response.value,200);
          swal2.fire("La tariffa è stata eliminata con successo", "","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.logger.log("Response",response,200);
          swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

}

