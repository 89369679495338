import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



import { NavigationModule } from "../navigation/navigation.module";
import { TopnavbarModule } from "../topnavbar/topnavbar.module";
import { FooterModule } from "../footer/footer.module";


import { BlankComponent } from "./blank/blank.component";
import { BasicComponent } from "./basic/basic.component";

@NgModule({
    declarations: [BlankComponent, BasicComponent],
    imports     : [BrowserModule, RouterModule, NavigationModule, TopnavbarModule, FooterModule, FormsModule],
    exports     : [BlankComponent, BasicComponent, FormsModule,]
})

export class LayoutsModule {}
