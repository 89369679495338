import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
import { FormControl, FormGroup } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { CompanyConfig } from './../../../interfaces/configuration.interface';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-params-list',
  templateUrl: './params-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ParamsListComponent implements OnInit, OnDestroy {

  isSoluzione1: boolean;
  dataFilter: any[] = new Array();
  visiblePaging = false;

  // companies info
  urlCompanyPath = '/company/companies';
  companiesList: any[] = [];

  configurationPath = '/configuration/detail'
  configurations: CompanyConfig[] = [];

  filters = new FormGroup({
    company: new FormControl()
  });

  idCompany = localStorage.getItem('idCompany');

  constructor(
    public listService: ListService,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggingService
  ) {
    this.isSoluzione1 = this.utils.isSol1(this.utils.getTypeUser());
    this.listService.restURL = this.configurationPath;
  }

  ngOnInit(): void {
    this.listService.visibleFilter = false;
    this.getCompanies();
    if (!this.isSoluzione1) {
      this.dataFilter['idCompany'] = this.idCompany;
    }
    this.loadElements(true);
  }

  ngOnDestroy(): void {
    this.listService.visibleFilter = false;
  }

  newConfig(): void {
    this.listService.backToList = true;
    this.router.navigate(['/utility/params/new']);
  }

  selectRow(key: any): void {
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(key));
    this.router.navigate(['/utility/params/edit']);
  }

  filterSearch(): void {
    this.dataFilter['idCompany'] = this.filters.get('company').value;
    this.loadElements();
  }

  filterReset(): void {
    this.filters.reset();
    this.configurations = [];
    this.listService.visibleFilter = false;
    this.dataFilter = [];
    this.loadElements(true);
  }

  pageChange(page: any) {
    this.listService.page = page;
    this.loadElements();
  }

  /** Method that populates company column getting the value from the list */
  getConfigurationCompany(idCompany: number): string {
    return this.companiesList.find(c => c.idCompany === idCompany)
      ? this.companiesList.find(c => c.idCompany === idCompany).legalBusinessName
      : '';
  }

  // ---------- PRIVATE METHODS ---------- //

  private getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
      this.companiesList = response.results;
      this.logger.log('Lista aziende', this.companiesList, 300);
    }, (error) => {
      this.logger.log('Error', error, 200);
    });
  }

  private loadElements(avoidUndo = false) {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.configurations = response.results;
        this.listService.totalRows = response.total;

        if (this.listService.totalRows > 10) {
          this.visiblePaging = true;
        } else {
          this.visiblePaging = false;
        }

        if (!avoidUndo) {
          this.listService.visibleFilter = true;
        }
        this.logger.log('Response: ', response, 300);
      }, (error) => {
        this.logger.log('Error', error, 200);
        this.listService.visibleFilter = true;
        swal2.fire(
          'Errore',
          `Spiacente, si è verificato un errore tecnico`,
          'error'
        );
      }
    );
  }

}

