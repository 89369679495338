<div class="row" style="padding-top: 20px"></div>

<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Elenco Voucher</strong>
        <span *ngIf="voucherListForm.value.searchFor === 'searchLot'">
          - {{lotDescriptionName}}
        </span>
      </h2>
      
      <div class="ibox-tools col-xs-1 pull-right" *ngIf="!isSoluzione1">
        <button type="button" (click)="newTicketList()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <form #formVoucherList="ngForm">

              <div class="col-sm-4 col-md-4 col-lg-3">
                <div class="form-group">
                  <label>Cerca per</label>
                  <select type="text" id="searchFor" name="searchFor" #searchFor searchFor [ngModel]="searchType" (change)="onChangeSearchType($event , searchFor.value)" class="form-control">
                    <option [value]="'searchDate'">Data</option>
                    <option [value]="'searchLot'">Lotto</option>
                  </select>
                </div>
              </div>

              <div *ngIf="voucherListForm.value.searchFor == 'searchLot'" class='col-xs-12 col-sm-6 col-md-4 col-lg-2'>
                <div class="form-group">
                  <label>Scegli il lotto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="lotCode" name="lotCode" #lotCode lotCode [ngModel]="searchTsCreated" class="form-control"  (change)="onChangeLot($event , lotCode.value)">
                      <option [value]="'all'"> Tutte </option>
                      <option *ngFor="let l of lotList" [value]="l.lotCode" data-des="l.description">{{l.description}} ({{l.tsCreated | date:'dd-MM-yyyy HH:mm:ss'}})</option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="voucherListForm.value.searchFor == 'searchDate'" class="form-group  col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <label>Creati dopo:</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateFrom"
                    id="dateFrom"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div *ngIf="voucherListForm.value.searchFor == 'searchDate'" class="form-group  col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <label>Creati prima del:</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateTo"
                    id="dateTo"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div *ngIf="voucherListForm.value.searchFor != 'searchLot'" class="form-group  col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <label>Canali:</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="emitChannel" [ngModel]="searchChannel" class="form-control" name="emitChannel">
                    <option [value]="'all'">Tutti</option>
                    <option *ngFor="let c of channelList" [value]="c.name">{{ c.name }}</option>
                  </select>
                </div>
              </div>
              
              <div class="clearfix"></div>

              <div *ngIf="voucherListForm.value.searchFor != 'searchLot'" class="form-group  col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <label>Tipologia Codice:</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="codeType" [ngModel]="searchCodetype" class="form-control" name="codeType">
                    <option [value]="'all'">Tutti</option>
                    <option *ngFor="let ct of codetypeList" [value]="ct">{{ ct }}</option>
                  </select>
                </div>
              </div>

              <div *ngIf="voucherListForm.value.searchFor != 'searchLot'" class="form-group  col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <label>Stato</label>
                <div class='input-group col-lg-12  col-xs-12'>
                  <select type="text" id="status" [ngModel]="searchStatus" class="form-control" name="status">
                    <option [value]="'all'"> Tutti </option>
                    <option *ngFor="let vs of voucherStatusList" [value]="vs.key">{{ utils.checkMultiselectStatusVoucher(vs) }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <label>Codice Voucher</label>
                <input type="text" id="codeVoucher" class="form-control" ngModel name="codeVoucher" codeVoucher #codeVoucher="ngModel" [(ngModel)]="dataFilter['codeVoucher']">
              </div>

              <div class="col-xs-12"></div>

              <div class='col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                    <button type="button" (click)="getCsv()" style="margin-left: 10px" class="btn btn-info btn-outline">Esporta</button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th *ngIf="isSoluzione1">Azienda</th>
              <th>Codice</th>
              <th>Lotto</th>
              <th>Status</th>
              <th>Periodo validità</th>
              <th>Canale</th>
              <th *ngIf="!isMobileOperator"></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer"
              [ngStyle]="{'opacity': data.status === 'CANCELED' ? 0.5 : 1 }">
              <td *ngIf="isSoluzione1" style="width: 200px;">{{getCompanyName(data.idCompany)}}</td>
              <td>{{data.code}}</td>
              <td>{{data.description}}<br><small>({{data.tsCreated | date:'dd-MM-yyyy HH:mm:ss'}})</small></td>
              <td>{{utils.checkStatusVoucher(data)}}</td>
              <td>{{data.validFrom | date:'dd-MM-yyyy'}} / {{data.validTo | date:'dd-MM-yyyy'}}</td>
              <td>{{data.emitChannel}}<br><small>({{data.codeType}})</small></td>
              <td *ngIf="!isMobileOperator" style="width: 160px; opacity: 1 !important;" class="col-md-4 text-center">
                <div style="float:left">
                  <button type="button" class="btn btn-success btn-outline" placement="top" tooltip="Dettagli Voucher"
                    [ngStyle]="{'pointer-events': data.status === 'CANCELED' ? 'none' : 'all'}"
                    (click)="viewDetailVoucher(data, ticketTemplate)">
                    <i class="fa fa-eye"></i>
                  </button>
                </div>
  
                <ng-container *ngIf="data.ticketIds.length && emitChannel != 'PRATICKO'">
                  <div style="float: left; margin-left: 10px">
                    <button class="btn btn-primary btn-outline" tooltip="Dettaglio ticket" placement="top"
                      [ngStyle]="{'pointer-events': data.status === 'CANCELED' ? 'none' : 'all'}"
                      (click)="goToDetailTicket( data.code )">
                      <i class="fa fa-ticket"></i>
                    </button>
                  </div>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)" [maxSize]="10">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #ticketTemplate>

  <div tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Dettaglio Voucher</h4>
      </div>
      <br>

      <div>
        
        <div class="row" style="margin-left: 15px">
          <div class="col-md-6">
            <h2 style="margin-top:10px;">Voucher <br>
              <span *ngIf="details.code != null"><span style="font-weight: bold;">{{details.code}}</span></span>
            </h2>
          </div>
          <div *ngIf="details.codeType == 'QRCODE'" class="col-md-6 text-right">
            <qr-code [value]="details.dataCode" [size]="150"></qr-code>
          </div>
          <div *ngIf="details.codeType == 'CODE128'" class="col-md-6 float-right">
            <ngx-barcode6 [bc-value]="details.code" [bc-display-value]="false" [bc-height]="50" [bc-width]="1"></ngx-barcode6>
          </div>
          <div class="col-xs-12">
            <h3 style="margin-top: 10px;"><i class="fas fa-calendar-alt" ></i> Validità</h3>
            <p>Dal {{details.validFrom | date:'dd-MM-yyyy HH:mm'}} Al {{details.validTo | date:'dd-MM-yyyy HH:mm'}}</p>
          </div>

          <div class="col-xs-6">
            <h3><i class="fas fa-tag"></i> Stato</h3>
            <p>{{utils.checkStatusVoucher(details)}}</p>
            <h3 *ngIf="details.idRoute != '' && details.idRoute != null"><i class="fas fa-map-marker-alt" style="color:green"></i> Linea</h3>
            <p>{{details.idRoute}}</p>
            <h3><i class="fas fa-code"></i> Tipologia</h3>
            <p>{{details.codeType}}</p>
          </div>

          <div class="col-xs-6">
            <h3><i class="fas fa-building"></i> Canale emissione</h3>
            <p> {{details.emitChannel}}</p>
            <h3 *ngIf="details.idTrip != '' && details.idTrip != null"><i class="fas fa-map-marker-alt" style="color:red"></i> Corsa </h3>
            <p>{{details.idTrip}}</p>
          </div>

        </div>
      </div>

      <!-- <h2 style="margin-left: 25px">Venduto da: {{sellerName}}</h2> -->

      <div class="modal-footer"></div>
    </div>
  </div>

</ng-template>