import { Injectable } from "@angular/core";
import { LoggingService } from "./logging.service";
import { ListService } from "../services/list.service";
import { UtilsService } from "./utils.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SoldService {
  logActualLevel: string = "ALL";
  logLevels = new Array({ ALL: 100, DEBUG: 200, INFO: 300, PROD: 400 });

  constructor ( private utils: UtilsService, private listService:ListService, private logger: LoggingService, private http: HttpClient) {}



  getLineDescriptionName(idLine, lineList){
    var string;
    for (let l of lineList) {
      if (idLine == l.routeId) {
        return (string = l.routeLongName);
      }
    }

    return string
  }



  getPartialPriceWithExtra(idPriceList, priceListData)
{

  

}
  getTypologyName(idTypology, typologyDataList ) {
    var string;
    for (let t of typologyDataList) {
      if (idTypology == t.idTicketType) {
        return (string = t.name);
      }
    }

    return string
  }


  getfareName(idFare, fareDataList ) {
    var string;
    for (let t of fareDataList) {
      if (idFare == t.idTicketFare) {
        return (string = t.name);
      }
    }

    return string
  }




getDataPriceListFilter(priceListData, typologyTicketData ){

var  arrayToReturn: any[] = new Array;

for(let f of priceListData){
   var ticketType =  f.idTicketType


   for(let t of typologyTicketData)

   if(t.idTicketType == ticketType){

if(t.extra == true){
  arrayToReturn.push(f)
}


   }
 
}

return arrayToReturn


}


  getInfoPriceList( idPriceList, priceListData, stringDataLine, originalTicket){
    var ticketModel = new TicketModel();
    for(let p of priceListData){
      if (p.idTicketPricelist == idPriceList){

 ticketModel.ar = p.ar;
 ticketModel.idPriceList = p.idTicketPricelist
 ticketModel.amount = originalTicket.amount

 //CONTROLLO PREZZO 

 if(originalTicket.ar == true){
  ticketModel.totalPrice = (p.price * originalTicket.amount) * 2
  ticketModel.amount = originalTicket.amount * 2 
 }else{
  ticketModel.totalPrice = p.price * originalTicket.amount
  ticketModel.amount = originalTicket.amount 
 }


 //CONTROLLO DELLA TOLLERANZA DA FARE 
 ticketModel.tolerance = 999999
 ticketModel.price = p.price
 ticketModel.idCompany = p.idCompany
 ticketModel.line = "Vincolata a nessuna linea"
 ticketModel.listNameString = p.name
 ticketModel.rate = p.idTicketFare

 //CONTROLLO TIPOLOGIA DEL LISTINO DA FARE 
 ticketModel.typeTicketString = p.name
 ticketModel.hour = this.utils.getTime();
 ticketModel.date = stringDataLine

 

        
      }
    }

    return ticketModel

  }



  getTicketChannel(typeUser){
     

    var value;
    switch(typeUser){
      case "AZIENDA DI TRASPORTO":
      return value = 5;


      case "RIVENDITA":
      return value = 3;



    }
    

    return value;

  }



  getServicePriceList(pricelist, id){
    var priceListData; 

    for(let c of pricelist){
      if(c.idTicketPricelist == id){

     priceListData = c

      }
    }

    return priceListData

  }


  getCommonPrice(ticketCart){
    var totalPrice = 0.00;


    for(let t of ticketCart){

      totalPrice =   totalPrice +   t.totalPrice 

    }
return totalPrice;

  }

}
export class TicketModel {
  ar;
  rate;
  amount;
  totalPrice;
  idPriceList;
  tolerance;
  price;
  idCompany;
  typeTicketString;
  listNameString;
  hour;
  idTrip;
  date;
  dateReturn;
  hourReturn;
  directionIdR;
  directionR;
  idTripR;
  rateId;
  direction;
  directionId;
  line;
  lineID;
  idCard;
  tripMaxPeriod;
  tripMaxDay;
  subscription;
  constructor() {}
}