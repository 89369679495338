export class Chart {
  
  public title: string;
  public report: string;
  public stacked: string;
  public type: string;

  public labels: Array<any>;
  public data: Array<any>;

  public reportTypes = {
    "month": "Deliveries per month" ,
    "nation": "Deliveries per nation",
    "service": "Deliveries per service",
    "status": "Deliveries per status",
    "month-status": "Deliveries per month and status",
    "nation-status": "Deliveries per nation and status",
    "service-status": "Deliveries per service and status"
  }

  private optionsNotStacked = {};
  private optionsStacked = {
      scales: {
          yAxes: [{
              stacked: true
          }]
      }
  };
  
  constructor( title:string, report:string, stacked:string, type: string) {
    this.title = title;
    this.report = report;
    this.stacked = stacked; 
    this.type = type; 

    if(report == 'month' || report == "nation"){
      this.data = [{data: [58, 59, 8, 81, 56, 55], label: 'Deliveries'}];  
    } else if (report == 'service' || report == "status") {
      this.data = [{data: [58, 18, 81, 55], label: 'Deliveries'}];  
    } else if(report == 'month-status' || report == "nation-status"){
      this.data = [
        {data: [65, 59, 80, 81, 56, 55], label: 'Ontime'},
        {data: [28, 15, 28, 19, 38, 27], label: 'Delay'},
        {data: [18, 48, 77, 9, 90, 27], label: 'On going'},
        {data: [1, 4, 7, 9, 0, 2], label: 'Other'}
      ];
    } else if(report == 'service-status'){
      this.data = [
        {data: [65, 89, 81,  55], label: 'Ontime'},
        {data: [28,  19, 6, 27], label: 'Delay'},
        {data: [1, 8,  9, 10], label: 'On going'},
        {data: [1, 9, 0, 2], label: 'Other'}
      ];      
    }

    if(report == "month" || report == "month-status"){
      this.labels = ['January', 'February', 'March', 'April', 'May', 'June'];
    } else if (report == "nation" || report == "nation-status"){
      this.labels = ['England', 'France', 'Italy', 'USA', 'Germany', 'Canada'];
    } else  if (report == "service" || report == "service-status"){
      this.labels = ['DHL Standard', 'UPS Saver', 'Fedex Express', 'DHL Standard'];
    } else  if (report == "status" ){
      this.labels = ['Ontime', 'Delay', 'On going', 'Other'];
    }
  };

  public getOptions() {
    if(this.stacked == "Yes" && this.type != "pie"){
        return this.optionsStacked;
    } else {
        return this.optionsNotStacked;
    } 
  }
}