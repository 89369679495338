<div class="row" style="padding-top: 20px">
</div>

<div class="col-md-12">
  <div  class="ibox float-e-margins ">
    <div class="ibox-title">
        <h2 class="col-md-4"><strong>Registro Attività</strong></h2>
       
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <form #formActivityList="ngForm">
              <div class="form-group col-xs-6 col-md-2">
                <label>Data da:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateFrom"
                    id="dateFrom"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class="form-group col-xs-6 col-md-2">
                <label>Data a:</label>
                <div class='input-group col-xs-12'>
                  <input type="text"
                    class="form-control"
                    name="dateTo"
                    id="dateTo"
                    ngModel
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
                </div>
              </div>

              <div class='form-group col-sm-6 col-lg-2 col-xs-6'>
              
                  <label for="agent">Agente:</label>
                  <input
                    type="text"
                    id="agent"
                    name="agent"
                    class="form-control"
                    ngModel
                    
                    agent
                    #agent= "ngModel">
            
              </div>
              <div class='form-group col-sm-6 col-lg-2 col-xs-6'>
               
                  <label for="codeAgent">Codice Agent:</label>
                  <input
                    type="text"
                    id="codeAgent"
                    name="codeAgent"
                    class="form-control"
                    ngModel
                    codeAgent
                    #codeAgent= "ngModel">
               
              </div>


              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                        <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                        <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                    </div>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>

      <div class="ibox-content inspinia-timeline">
        <div *ngFor="let c of activities" class="timeline-item" >
            <div class="row">
                <div class="col-xs-4 col-md-2 date">
                    
                    <i  class="fa fa-print"></i>
                    {{ c.datetime | date:'dd/MM/yyyy'}}
                    <br>
                    <small class="text-redBU">{{ c.datetime | date:'HH:mm:ss'}}</small>
                </div>
                <div class="col-xs-8 col-md-10 content">
                    <p class="m-b-xs"><strong>Agente: {{c.agent}}  Codice Agente: {{c.codeAgent}}</strong>   <p style="float: right" >{{c.logType}}</p>
                    <p>{{c.message}}</p>
                    <p>{{c.codeMessage}}</p>
                </div>
            </div>
        </div>
      </div>
    </div>

    <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
      [pageSize]="listService.rows"
      [maxSize]="10" [(page)]="listService.page" (pageChange)="pageChange($event)">
    </ngb-pagination>
  </div>
</div>
                

             

          
          
       

      

      
  
    

