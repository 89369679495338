<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Rivendite</strong>
      </h2>
      <div class="ibox-tools col-xs-1 pull-right">
        <button type="button" (click)="newResale()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <form #formResale="ngForm">

                <div  *ngIf="isSoluzione1" class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                    <div class="form-group">
                      <label>Azienda di trasporto</label>
                      <div class='input-group col-xs-12'>
                        <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany  #idCompany ="ngModel">
                          <option [ngValue] = "'all'" > Tutte </option>
                          <option *ngFor="let t of companiesList" [ngValue]="t.idCompany">{{t.legalBusinessName}} </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  
              <div class=' col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Ragione sociale</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="legalBusinessName" name="legalBusinessName" class="form-control" ngModel legalBusinessName #legalBusinessName="ngModel">
                  </div>
                </div>
              </div>

              <div class=' col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label>Città</label>
                  <div class='input-group col-xs-12'>
                    <input type="text" id="legalCity" name="legalCity" class="form-control" ngModel legalCity #legalCity="ngModel">
                  </div>
                </div>
              </div>

              

              <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th *ngIf="isSoluzione1">Azienda di trasporto</th>
              <th>Ragione sociale</th>
              <th>Recapito Telefonico</th>
              
              <th>Indirizzo </th>

              <th class="text-right" >Aggio</th>
              <th class="text-right">Fido</th>
              <th class="text-right" >Saldo</th>
         
              
              <th class="text-center">PDF</th>

              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataResale" style="cursor:pointer">
              <td  style="width: 200px;" *ngIf="isSoluzione1" > {{ getBusinessName(data.idCompany)}} </td>
              <td>{{data.legalBusinessName}}</td>
              <td>{{data.phone1}} </td>
              <td  style="width: 300px;">{{data.legalAddress}}, {{data.legalCity}}, {{data.legalZipCode}}, {{data.legalProv}} </td>
              <td style="min-width:70px;" class="text-right">{{(data.agio) || "0"}} %</td>
              <td   style="min-width:70px;"class="text-right">{{(data.trust | number : '1.2-2') || "0.00"}} €</td>
              <td  style="min-width:70px;" class="text-right">{{(data.trust + data.credit | number : '1.2-2') || "0.00"}} € </td>
              <td  style="width: 70px" class="col-md-4 text-center">
                  <button (click)="openpopUp(data.id, data.pdf)" type="button" class="{{getClassEnablePdf(data.pdf)}}" >
                      <i class="{{getClassIcon(data.pdf)}}"></i>
                    </button>
              </td>
              <td    [ngStyle]="{'width': isSoluzione1 ? '200px' : '160px'}"  class="col-md-4 text-center">
                <button *ngIf="isSoluzione1" title="Elimina" style="float: right; margin-left: 8px" type="button" class="btn btn-danger btn-outline" (click)=" changeUserID(data.id , data.legalBusinessName)">
                  <i class="fa fa-trash"></i>
                </button>
                <button  title="Dettaglio Movimenti"  style="float: right;margin-left: 8px" type="button" class="btn btn-success btn-outline " (click)="transactionResales(data.id)">
                  <i class="fa fa-search"></i>
                </button>
                <button  title="Modifica"  style="float: right;margin-left: 8px " type="button" class="btn btn-success btn-outline " (click)="selectRow(data)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button *ngIf="!data.internal"  title="Ricarica Credito"  style="float: right;margin-left: 8px" type="button" class="btn btn-success btn-outline " (click)="recharge(data)">
                  <i class="fa fa-credit-card"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>