<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h2>{{kindTitle}} chiave</h2>
                </div>
                <form (ngSubmit)="onSubmit()" [formGroup]="keyForm">
                    <div class="ibox-content">
                        <div class="form-group form-group col-xs-12 col-sm-6">
                            <label for="value">Nome</label>
                            <input
                                type="text"
                                id="name"
                                class="form-control"
                                formControlName="name">
                        </div>
                        <div class="form-group form-group col-xs-12 col-sm-6">
                            <label for="value">Azioni</label>
                            <div style="display: flex; align-items: center;">
                                <input
                                    type="checkbox"
                                    id="actions"
                                    class="form-control"
                                    formControlName="actions"
                                    style="width: 1.5rem; margin: 0; margin-right: 6px;">
                                <div>Azioni disponibili per la chiave?</div>
                            </div>
                        </div>
                        <div class="form-group form-group col-xs-12 col-sm-12" style="height: 10rem;">
                            <label for="value">Descrizione</label>
                            <textarea
                                id="description"
                                class="form-control"
                                rows="5"
                                formControlName="description"
                                style="resize: none; height: 100% !important;"
                            ></textarea>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
                        <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!keyForm.valid">Salva</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>