import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';

import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import { AuthService} from "../../../services/auth.service";
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { BrowserModule, DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { DialogViewerComponent } from '../dialog/dialog-viewer.component';
import swal2 from 'sweetalert2'



const URL = 'https://185.56.9.94/prs-rest/rest/document/upload';
const AUTHTOKEN = localStorage.getItem("token");
const HEADER = 'X-AUTH-TOKEN';
declare var jQuery:any;

@Component({
  selector: 'app-documents-viewer',
  templateUrl: './documents-viewer.component.html',
  styleUrls: ['../../../app.component.css']
})

export class DocumentsViewerComponent implements OnInit {
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
}