<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
    <div class="ibox float-e-margins ">
        <div class="ibox-title">
            <h2 class="col-xs-11">
                <strong>Dettagli Rivendita</strong>
            </h2>


        </div>
        <div class="ibox-content">
            <div class="row">
               <!-- <div class="col-lg-3 col-md-3 col-sm-3">
                    <div style="cursor: pointer" class="widget style1 red-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-bus fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Incassi </span>
                                <h2 class="font-bold"></h2>
                            </div>
                        </div>
                    </div> 
                </div> -->
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div  style="cursor: pointer"  (click)="goToPrinters()" class="widget style1 lazur-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-print fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span>Stampanti </span>
                                <h2 class="font-bold">{{resaleDashboard.numPrinter}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div  style="cursor: pointer"  class="widget style1 yellow-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-money fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Importo rifatturazione </span>
                                <h2 class="font-bold">{{resaleDashboard.rebillingMonthAmount | number:'.2'}}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div  style="cursor: pointer"  class="widget style1 navy-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-line-chart fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Incasso </span>
                                <h2 class="font-bold">{{resaleDashboard.soldMonthAmount | number:'.2'}}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div  style="cursor: pointer"  class="widget style1">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-check-circle fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Vendite Effettuate </span>
                                <h2 class="font-bold">{{resaleDashboard.numTransaction}}</h2>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <div style="margin-top: 30px" class="row">

        </div>







    </div>
</div>
</div>