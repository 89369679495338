<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Tariffe Abbonamenti</strong>
      </h2>

      <div class="ibox-tools col-xs-1 pull-right">
        <button type="button" (click)="newTicketFares()" class="btn btn-view btn-outline">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <form #formSubscriptionFares="ngForm">

              <div *ngIf="isSoluzione1" class="form-group col-sm-12 col-md-3 col-lg-2">
                <label for="idCompany"> Azienda di trasporto </label>
                <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" idCompany #idCompany="ngModel" >
                  <option [ngValue] = "'all'" > Tutte </option>
                  <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                </select>
              </div>
              <div class=' col-sm-6 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label>Descrizione</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="name" name="name" class="form-control" ngModel #name="ngModel">
                    </div>
                  </div>
                </div>

                <div class=' col-sm-6 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label>Categoria</label>
                    <select type="text" id="idCategory" class="form-control" ngModel name="idCategory" idCategory #idCategory="ngModel" >
                      <option [ngValue] = "'all'" > Tutte </option>
                      <option *ngFor="let c of categories" [ngValue]='c.categoryId'>{{c.name}}</option>
                    </select>
                  </div>
                </div>

                <div class=' col-sm-6 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label>Canale di vendita</label>
                    <select type="text" id="idTicketChannel" class="form-control" ngModel name="idTicketChannel" idTicketChannel #idTicketChannel="ngModel" >
                      <option [ngValue] = "'all'" > Tutte </option>
                      <option *ngFor="let o of dataChannel" [ngValue]='o.idChannel'>{{o.name}}</option>
                    </select>
                  </div>
                </div>

                <div class=' col-sm-6 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label>Valida al: </label>
                    <input class="form-control" id="bsDatepicker" name="bsDatepicker"  [(ngModel)]="datepickerModel"
                        ngModel bsDatepicker #bsDatepicker placement="right" [bsConfig]="bsConfig" placement="bottom" autocomplete="off">
                  </div>
                </div>

            
              <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>  <th *ngIf="isSoluzione1" >Azienda di Trasporto</th>
              <th>Descrizione</th>
              <th>Tipologia</th>
              <th>Prezzo</th>
              <th>Validità Tariffa</th>
              <th>Canale di Vendita</th>
              <th>Max oblit./GG</th>
              <th>Max oblit./Periodo</th>
              <th *ngIf="!isMobileOperator"></th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
              <td *ngIf="isSoluzione1" style="width: 200px;" >{{getCompanyName(data.idCompany)}}</td>
              <td>{{data.name}}</td>
              <td>{{data.category}}</td>
              <td>{{data.price | number : '1.2-2'}} €</td>
              <td>{{data.dateFrom | date:'dd-MM-yyyy'}} / {{data.dateTo | date:'dd-MM-yyyy'}} </td>
              <td>{{utils.getTicketChannel(dataChannel, data.ticketChannel)}}</td>
              <td>{{data.tripMaxDay}}</td>
              <td>{{data.tripMaxPeriod}} </td>
  
              <td *ngIf="!isMobileOperator" style="width: 100px" class="col-md-4 text-center">
                <button style="float: left" type="button" class="btn btn-success btn-outline " (click)="selectRow(data)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button style="float: right" type="button" *ngIf="data.numPricelist == 0" class="btn btn-danger btn-outline" (click)="deleteTicketFares(data.idTicketFare)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>