import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SalesChannelsListComponent } from './sales-channels-list/sales-channels-list.component';
import { SalesChannelsManageComponent } from './sales-channels-manage/sales-channels-manage.component'

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SalesChannelsListComponent, SalesChannelsManageComponent]
})
export class SalesChannelsModule { }