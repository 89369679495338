import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoggingService } from '../../../services/logging.service';
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['../../../app.component.css']
})
export class LogoutComponent implements OnInit {

  sub;
  id;

  constructor(
     private logger: LoggingService,
     private router: Router,
     private route: ActivatedRoute,
     private authService: AuthService 
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      
    });

    this.authService.logout().subscribe(
      (response) => {
        this.logger.log("Response",response,300);
      },    
      (error) => {
        this.logger.log("Error",error,200);
      }
    );
  }

  goToLogin(){
    this.router.navigate(['/login']);
  }

  sessionExpired(value: string): boolean{
    
    if(this.id === value) return false;
    return true;
  }
  
}
