<div class="row" style="padding-top: 20px"></div>
    
<div class="">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2    class="col-md-4"><strong>Corse</strong></h2>
                <div class="ibox-tools col-md-8 pull-right">
                </div>
            </div>
            <div class="ibox-content">
                <div class="row">
                    <form #formTrips="ngForm">

                            <div *ngIf="isSoluzione1" class='col-xs-12 col-sm-6 col-md-3 col-lg-2'>
                                    <div class="form-group">
                                      <label>Azienda di trasporto</label>
                                      <div class='input-group col-xs-12'>
                                        <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany  #idCompany ="ngModel">
                                            <option [ngValue] = "'all'" > Tutte </option>
                                          <option *ngFor="let t of companiesList" [ngValue]="t.idCompany">{{t.legalBusinessName}} </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div> 

                                  <div class='col-xs-6 col-sm-2 col-md-2'>
                                    <div class="form-group">
                                    <label>Sigla</label>
                                        <div class='input-group  col-xs-12'>
                                        <select
                                        type="text"
                                        id="routeId"
                                        name="routeId"
                                        class="form-control"
                                        ngModel
                                        routeId
                                        #routeId= "ngModel">
                                        <option [ngValue] = "'all'" > Tutte </option>
                                        <option *ngFor="let r of routeList" [ngValue] = "r.routeId" > {{r.routeId }}</option>
                                       
                                        </select>
                                        </div>
                                    </div>
                                </div>

                                  <div class='col-xs-6 col-sm-3 col-md-3'>
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <div class='input-group  col-xs-12'>
                                        <input
                                        type="text"
                                        id="routeLongName"
                                        name="routeLongName"
                                        class="form-control"
                                        ngModel
                                        routeLongName
                                        #routeLongName= "ngModel">
                                        </div>
                                    </div>
                                </div>


                        <div class='col-xs-6 col-sm-3 col-md-3'>
                            <div class="form-group">
                                <label>Direzione</label>
                                <div class='input-group  col-xs-12'>
                                <select
                                type="text"
                                id="directionId"
                                name="directionId"
                                class="form-control"
                                ngModel
                                directionId
                                #directionId= "ngModel">
                                <option [ngValue] = "'all'" > Tutte </option>
                                <option [ngValue] = "'0'" > Andata </option>
                                <option [ngValue] = "'1'" > Ritorno </option>
                                </select>
                                </div>
                            </div>
                        </div>



    
                   
                        <div class='col-xs-12 col-sm-3 col-md-3'>
                            <div class="form-group">
                                <label></label>
                                <div class='input-group col-md-12'>
                                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
    
                <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
                <div *ngIf="errorMessage === ''" class="table-responsive">
                    <table class="table table-striped table-bordered table-hover dataTables-example" >
                    <thead>
                        <tr>
                        <th *ngIf="isSoluzione1">Azienda di trasporto</th>
                        <th>Sigla</th>
                        <th>Id Corsa</th>
                        <th>Partenza</th>
                        <th>Descrizione</th>
                        <th>Direzione</th>
                   
                        </tr>
                    </thead>
                    <tbody *ngIf="errorMessage === ''">
                        <tr *ngFor="let data of dataList"  style="cursor:pointer">
                            <td *ngIf="isSoluzione1"> {{getCompanyName(data.idCompany)}} </td>
                            <td>{{data.routeId}}</td>
                            <td>{{data.tripId}}</td>
                            <td>{{data.departureTime}}</td>
                            <td>{{data.departure}} / {{data.arrival}}</td>
                            <td>{{getDirection(data.directionId)}}</td>
                        <!--    <td style="width: 130px" class="col-md-4 text-center"> 
                              <button  type="button" class="btn btn-success btn-outline " (click)="selectRow(data.tripID)"><i class="fa fa-pencil"></i></button>
                              <button type="button" class="btn btn-danger btn-outline  btn_delete_order" (click)=" deleteTrip(data.tripID)">
                                  <i class="fa fa-trash"></i>
                              </button>
                          </td> -->
                        </tr>
                    </tbody>          
                    </table>
                    <ngb-pagination 
                        *ngIf="listService.totalRows > listService.rows"
                        class="pull-right"
                        [maxSize]="10" 
                        [collectionSize]="listService.totalRows" 
                        [pageSize]="listService.rows" 
                        [(page)]="listService.page"
                        (pageChange)="pageChange($event)" >
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
    