<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-8 col-sm-offset-3 col-md-offset-2">
      <div class="ibox float-e-margins">
        <form *ngIf="channelForm" [formGroup]="channelForm" (ngSubmit)="onSubmit()">
          <div class="ibox-title">
            <h2>{{kindTitle}} {{kindDetail}}</h2>
          </div>
          <div class="ibox-content">
            <div class="form-group col-sm-12 col-md-6">
              <label for="company">Azienda di Trasporto</label>
              <select type="text"
                id="company"
                class="form-control"
                formControlName="idCompany"
                (change)="onCompanyChange()">
                <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
              </select>
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="name">Nome</label>
              <input type="text"
                id="name"
                class="form-control"
                formControlName="name" />
            </div>
            <div class="form-group col-sm-12" [ngClass]="!!kindManage ? 'col-md-12' : 'col-md-10'">
              <label for="description">Descrizione</label>
              <input type="text"
                id="description"
                class="form-control"
                formControlName="description" />
            </div>
            <div *ngIf="!(!!kindManage)" class="form-group col-sm-2 col-md-2">
              <div class="form-group">
                <label class="text-success">Status</label>
                <div class="checkbox">
                  <input type="checkbox"
                    name="deleted"
                    id="deleted" 
                    formControlName="deleted" />
                  <label>Disattivata</label>
                </div>
              </div>
            </div>
            <!-- CATEGORY TEMPLATE ADDITIONAL INFO -->
            <div *ngIf="categoryType === TicketCategoryType.category">
              <div class="form-group col-sm-12 col-md-3">
                <label for="mode">Modalità</label>
                <input type="text"
                  id="mode"
                  class="form-control"
                  formControlName="mode" />
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label for="type">Tipo</label>
                <select type="text"
                  id="type"
                  class="form-control"
                  formControlName="type"
                  (change)="onSubTypeChange()">
                    <option *ngFor="let t of ticketTypes" [value]="t.id">{{t.value}}</option>
                </select>
              </div>
              <div class="form-group col-sm-12 col-md-3" *ngIf="channelForm.controls.type.value === TicketType.subId">
                <label for="subscription">Abbonamento</label>
                <select type="text"
                  id="subscription"
                  class="form-control"
                  formControlName="subscription">
                    <option *ngFor="let s of subscriptionTypes" [value]="s.id">{{s.value}}</option>
                </select>
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label for="groupId">Gruppo categoria</label>
                <select type="text"
                  id="groupId"
                  class="form-control"
                  formControlName="groupId">
                    <option *ngFor="let ct of categoriesList" [value]="ct.groupId">{{ct.name}}</option>
                </select>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!channelForm.valid">Salva</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>