<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} tessera</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #passForm="ngForm">
          <div class="ibox-content">
            <div *ngIf="isSoluzione1" class="form-group col-md-12">
              <label for="idCompany">Azienda di trasporto</label>
              <select class="form-control" type="text"
                id="idCompany"
                name="idCompany"
                ngModel
                idCompany
                #idCompany="ngModel"
                [ngModel]="dataPass?.idCompany"
                required>
                <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{ c.legalBusinessName }}</option>
              </select>
            </div>
            <div class="form-group col-xs-12 col-sm-6">
              <label for="name">Nome</label>
              <input class="form-control" type="text"
              id="name"
              name="name"
              ngModel
              #name="ngModel"
              [ngModel]="dataPass?.name"
              required>
            </div>
            <div class="form-group col-xs-12 col-sm-6">
              <label for="name">Codice</label>
              <input class="form-control" type="text"
              id="code"
              name="code"
              ngModel
              #code="ngModel"
              [ngModel]="dataPass?.code"
              required>
            </div>
            <div class="form-group col-md-10">
              <label for="name">Descrizione</label>
              <input class="form-control" type="text"
              id="description"
              name="description"
              ngModel
              #description="ngModel"
              [ngModel]="dataPass?.description">
            </div>
            <div class="form-group col-md-2">
              <label for="deleted"></label>
              <div class="checkbox">
                <input class="form-control" type="checkbox"
                id="deleted"
                name="deleted"
                [(ngModel)]="deleted">
                <label>Cancellato</label>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!passForm.valid">Salva</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>