<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h2>{{kindTitle}} Acl</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #aclForm="ngForm">
          <div class="ibox-content"> 

              <div *ngIf="isSol1" class="form-group form-group col-xs-12 col-sm-6">
                  <label for="idCompany">Azienda di trasporto</label>
                  <select
                  type="text"
                  id="idCompany"
                  class="form-control"
                  [ngModel]="dataAcl?.idCompany"
                  ngModel
                  name ="idCompany"
                  required
                  idCompany
                  #idCompany="ngModel"
                    idResale>
                      <option *ngFor="let r of companiesList" [ngValue]="r.idCompany"> {{r.legalBusinessName}}</option>
                  </select>
                </div>
                
                
              <div class="form-group col-xs-12 col-sm-6">
                <label for="macAddress">Contesto</label>
                <input
                  type="text"
                  id="context"
                  class="form-control"
                  ngModel
                  [ngModel]="dataAcl?.context"
                  name="context"
                  required
                  context
                  #context="ngModel">
              </div>

              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="extra"> Extra</label>
                <input
                  type="text"
                  id="extra"
                  class="form-control"
                  ngModel
                    [ngModel]="dataAcl?.extra"
                  name="extra"
                  required
                  extra
                  #extra="ngModel">
              </div>

            
              
              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="field"> Campo</label>
                <input
                  type="text"
                  id="field"
                  class="form-control"
                  ngModel
                  [ngModel]="dataAcl?.field"
                  name="field"
                  required
            
                  #field="ngModel">
              </div>

              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="operation"> Entità</label>
                <input
                  type="text"
                  id="entity"
                  class="form-control"
                  ngModel
                  [ngModel]="dataAcl?.entity"
                  name="entity"
                  required
                  #entity="ngModel">
              </div>

              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="type"> Tipo</label>
                <input
                  type="text"
                  id="type"
                  class="form-control"
                  ngModel
                  [ngModel]="dataAcl?.type"
                  name="type"
                  required
            
                  #type="ngModel">
              </div>

              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="operation"> Operazione</label>
                <input
                  type="text"
                  id="operation"
                  class="form-control"
                  ngModel
                  [ngModel]="dataAcl?.operation"
                  name="operation"
                  required
                  #operation="ngModel">
              </div>

            <div class="form-group form-group col-xs-12 col-sm-6">
              <label for="idUser">Utente</label>
              <select
              type="text"
              id="idUser"
              class="form-control"
              [ngModel]="dataAcl?.idUser"
              ngModel
              name ="idUser"
              idUser
              #idUser="ngModel"
              idUser>
                <option  [ngValue]=null> --- </option>
                  <option *ngFor="let r of usersList" [ngValue]="r.id"> {{r.name}} {{r.surname}}</option>
              </select>
            </div>

            <div class="form-group form-group col-xs-12 col-sm-6">
              <label for="idEcommerce">eCommerce</label>
              <select
              type="text"
              id="idEcommerce"
              class="form-control"
              [ngModel]="dataAcl?.idEcommerce"
              ngModel
              name ="idEcommerce"
              idEcommerce
              #idDesk="ngModel"
              idEcommerce>
                <option  [ngValue]=null> --- </option>
                  <option *ngFor="let d of ecommercesList" [ngValue]="d.idEcommerce"> {{d.name}}</option>
              </select>
            </div> 

            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button"  (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right"  [disabled]="!aclForm.valid" >Salva </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>    