import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../services/logging.service";
import { ListService } from "../../services/list.service";

import swal2 from 'sweetalert2'

@Component({
  selector: 'app-stop-times',
  templateUrl: './stop-times.component.html',
  styleUrls: ['../../app.component.css']
})
export class StopTimesComponent implements OnInit {
//FORM
@ViewChild('formTimes') timesForm: NgForm;
//OGGETTO LISTA ELEMENTI
dataList: any[] = new Array();
// dataList = [{  name: "BUS01",  brand: "Mercedes",  plate: "FF 000 EB",  status: 1}];
dataFilter: any[] = new Array();
//PATH CHIAMATE
urlDeletePath = "";
errorMessage: string = "";
visibleFilter;
defaultValue;

UpOrDown;

constructor(private logger: LoggingService, private router: Router, public listService: ListService){
  this.listService.resetList();
  this.listService.configurationServiceCall("/vehicles/list",false,true,1,20);
  this.listService.restURL = "/stop/stopstime";
}

ngOnInit() {
  this.loadElements();
  this.UpOrDown = true;
}

pageChange(page){
  this.listService.page = page; 
  this.loadElements();
}

changeStatus(){
  if(this.UpOrDown == true){
    this.UpOrDown = false;

    console.log("VIEE", this.UpOrDown)
  }else{
    this.UpOrDown = true;
  }

}


//FILTRI


filterSearch(){
  this.dataFilter = this.timesForm.value;
  this.loadElements();
  this.listService.visibleFilter = true;
}

filterReset(){
  this.timesForm.value.tripId = ""
  this.timesForm.value.arrivalTime = ""
  this.listService.visibleFilter = false;
  this.loadElements();
  this.timesForm.reset();
  //this.loadElements();
}

//SERVIZIO LISTA ELEMENTI
loadElements(){
  this.listService.getListFilter(this.dataFilter).subscribe(
    (response) => {
      this.dataList = response.results;
      this.listService.totalRows = response.total;

      this.logger.log("Response:",response,300);
      this.errorMessage = "";
    },    
    (error) => { 
      this.logger.log("Error",error,200);
      this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
    }
  );
}



}
