<nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
            <li class="nav-header" [ngClass]="{sol1: typeUser == 'SOLUZIONE1' || typeUser == 'SUPERADMIN' , company: typeUser == 'AZIENDA DI TRASPORTO', resale: typeUser == 'RIVENDITA'}">
                <div class="profile-element">
                    <div style="display: flex; justify-content: center;">
                        <img src="{{utils.configurations?.company?.logoUrl ? utils.configurations?.company?.logoUrl : './assets/img/logo_soluzione1.png'}}" width="100px">
                    </div>
                    <span class="clear"> <span class="block m-t-xs"> <strong class="font-bold">{{name}} {{surname}}</strong></span> 
                        <span class="text-muted text-xs block">{{typeUser}}  </span>
                        <span class="text-muted text-xs block">{{roleUser}}</span>
                    </span>
                </div>
                <div class="logo-element">
                    <img src="./assets/img/logo_ptk_small.png" width="40px">
                </div>
            </li>

            <li *ngIf="isCompanyAdminstrator"[ngClass]="{active: activeRoute('companies')}">
                <a (click)="listService.resetList()" routerLink="/companies/details"><i class="fa fa-building"></i> <span class="nav-label">Dashboard</span> </a>
            </li>

            <li *ngIf="isResaleAdministrator"[ngClass]="{active: activeRoute('resales')}">
                <a (click)="listService.resetList()" routerLink="/resales/details"><i class="fa fa-shopping-basket"></i> <span class="nav-label">Dashboard</span> </a>
            </li>

            <!-- Moduli di Base -->
            <li *ngIf="lineManager && !isDesk" [ngClass]="{active: activeRoute('lines') || activeRoute('blocks') || activeRoute('research') || activeRoute('tickets') || activeRoute('subscription') || activeRoute('categories') || activeRoute('zones') || activeRoute('/utility/importer') || activeRoute('salesChannels')}">
                <a href="#"><i class="fa fa-bookmark"></i> <span class="nav-label">Moduli di base</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">

                    <!-- GTFS -->
                    <li [ngClass]="{active: activeRoute('lines') || activeRoute('blocks') || activeRoute('zones') || activeRoute('timetable') || activeRoute('research') || activeRoute('/utility/importer') }"> 
                        <a href="#"><span class="nav-label">GTFS</span><span class="fa arrow"></span></a> 
                        <ul class="nav nav-third-level">
                            <li [ngClass]="{active: activeRoute('/lines/list')}"> <a (click)="listService.resetList()" routerLink="/lines/list"><i class="fa fa-code-fork"></i> <span class="nav-label">Linee</span> </a></li>
                            <li [ngClass]="{active: activeRoute('blocks')}"> <a (click)="listService.resetList()" routerLink="/blocks/list"><i class="fa fa-th"></i> <span class="nav-label">Blocchi</span> </a></li>
                            <li [ngClass]="{active: activeRoute('zones')}"> <a (click)="listService.resetList()" routerLink="/zones/list"><i class="fa fa-th"></i> <span class="nav-label">Zone</span> </a></li>
                            <li [ngClass]="{active: activeRoute('research')}" *ngIf="isSoluzione1 || isCompanyAdminstrator"> <a (click)="listService.resetList()" routerLink="/research/trips-lines"><i class="fa fa-calendar"></i> <span class="nav-label">Corse </span> </a></li>
                            <li [ngClass]="{active: activeRoute('/utility/importer')}" *ngIf="isSoluzione1 || isCompanyAdminstrator"> <a (click)="listService.resetList()" routerLink="/utility/importer"><i class="fa fa-upload"></i> <span class="nav-label">Importer </span> </a></li>       
                        </ul>
                    </li>

                    <!-- Tickets -->
                    <li [ngClass]="{active: activeRoute('tickets')}">
                        <a href="#"><span class="nav-label">Tickets</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-third-level">
                            <li [ngClass]="{active: activeRoute('tickets/fares')}">  <a (click)="listService.resetList()" routerLink="/tickets/fares"><i class="fa fa-calculator"></i> <span class="nav-label">Tariffe</span> </a></li>
                        </ul>
                    </li>

                    <!-- Abbonamenti -->
                    <li [ngClass]="{active: activeRoute('subscription')}">
                        <a href="#"><span class="nav-label">Abbonamenti</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-third-level">
                            <li [ngClass]="{active: activeRoute('subscription')}">  <a (click)="listService.resetList()" routerLink="/subscription/fares"><i class="fa fa-calculator"></i> <span class="nav-label">Tariffe</span> </a></li>
                        </ul>
                    </li>

                    <!-- Categorie -->
                    <li [ngClass]="{active: activeRoute('categories')}">  <a (click)="listService.resetList()" routerLink="/categories"><i class="fa fa-sitemap" ></i> <span class="nav-label">Categorie</span> </a></li>

                    <!-- Canali di vendita -->
                    <li [ngClass]="{active: activeRoute('salesChannels')}">  <a (click)="listService.resetList()" routerLink="/salesChannels"><i class="fas fa-shopping-bag"></i> <span class="nav-label">Canali di vendita</span> </a></li>

                </ul>
            </li>

            <!-- Vouchers e promozioni -->
            <li [ngClass]="{active: activeRoute('voucher')}" *ngIf="isSoluzione1 || isCompanyAdminstrator">
                <a href="#"><i class="fa fa-tags"></i> <span class="nav-label">Vouchers e promozioni</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">
                    <li [ngClass]="{active: activeRoute('/voucher/promotion')}">  <a (click)="listService.resetList()" routerLink="/voucher/promotion"><i class="fa fa-calculator"></i> <span class="nav-label">Promozioni</span> </a></li>
                    <li [ngClass]="{active: activeRoute('/voucher/list/all')}">  <a (click)="listService.resetList()" routerLink="/voucher/list/all"><i class="fa fa-list-alt"></i> <span class="nav-label">Voucher</span> </a></li>
                    <li [ngClass]="{active: activeRoute('/voucher/lot')}">  <a (click)="listService.resetList()" routerLink="/voucher/lot"><i class="fa fa-book"></i> <span class="nav-label">Lotti</span> </a></li>
                </ul>
            </li>

            <!-- Gestione Utenti -->
            <li  [ngClass]="{active: activeRoute('users') || activeRoute('sales/recap')}">
                <a href="#"><i class="fa fa-users"></i> <span class="nav-label">Gestione Utenti</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">

                    <li [ngClass]="{active: activeRoute('users')}">
                        <a href="#"><span class="nav-label">Anagrafica utenti</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-third-level">
                            <li *ngIf="!isOperator"[ngClass]="{active: activeRoute('/users/list')}"><a (click)="listService.resetList()" routerLink="/users/list"><i class="fa fa-user"></i> <span class="nav-label">Aziendali</span> </a></li>      
                            <li *ngIf="typeUser != 'RIVENDITA' || !isOperator" [ngClass]="{active: activeRoute('/users/ecommerce')}"><a (click)="listService.resetList()" routerLink="/users/ecommerce"><i class="fas fa-shopping-cart"></i> <span class="nav-label">Clienti</span> </a></li>
                            <li [ngClass]="{active: activeRoute('/users/validations')}"><a (click)="listService.resetList()" routerLink="/users/validations"><i class="fa fa-bullhorn"></i> <span class="nav-label">Validazioni</span> </a></li>      
                        </ul>
                    </li>

                    <li *ngIf="!isOperator" [ngClass]="{active: activeRoute('/users/passes')}">
                        <a (click)="listService.resetList()" routerLink="/users/passes"><i class="fa fa-id-card"></i> <span class="nav-label">Tessere</span></a> 
                    </li>

                    <li *ngIf="isOperator || isMobileOperator || isSoluzione1 || isCompanyAdminstrator || isResaleAdministrator" [ngClass]="{active: activeRouteSame('/sales/recap/operator')}">
                        <a (click)="listService.resetList()" routerLink="/sales/recap/operator"><i class="fa fa-ticket"></i> <span class="nav-label"> Riepilogo Operatore</span></a> 
                    </li>


                    <li *ngIf="isResaleAdministrator || isCompanyAdminstrator" [ngClass]="{active: activeRoute('operators')}">  <a (click)="listService.resetList()" routerLink="/statistics/operators"><i class="fa fa-money"></i> <span class="nav-label">Versamento Cassa</span> </a></li>

                </ul>
            </li>

            <!-- Rivendite -->
            <li *ngIf="(typeUser == 'AZIENDA DI TRASPORTO' || typeUser == 'SUPER ADMIN'  || typeUser == 'RIVENDITA' || typeUser == 'SOLUZIONE1')  && !isMobileOperator">
                <a href="#"><i class="fa fa-shopping-basket"></i> <span class="nav-label">Rivendite</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">

                    <li *ngIf="resaleManager  && !isMobileOperator && !isOperator" [ngClass]="{active: activeRoute('resales')}">
                        <a (click)="listService.resetList()" routerLink="/resales/list"><i class="fa fa-shopping-basket"></i> <span class="nav-label">Anagrafica</span> </a>
                    </li>

                    <li *ngIf="resaleManager  && !isMobileOperator && !isOperator" [ngClass]="{active: activeRoute('desk')}">
                        <a (click)="listService.resetList()" routerLink="/desk/list"><i class="fas fa-store"></i> <span class="nav-label">Desk</span> </a>
                    </li>

                    <li *ngIf="(typeUser == 'AZIENDA DI TRASPORTO' || typeUser == 'SUPER ADMIN'  || typeUser == 'RIVENDITA' || typeUser == 'SOLUZIONE1')  && !isMobileOperator" [ngClass]="{active: activeRoute('printers')}">
                        <a (click)="listService.resetList()" routerLink="/printers/list"><i class="fa fa-print"></i> <span class="nav-label">Stampanti</span> </a>
                    </li> 

                </ul>
            </li>

            <!-- Obliterazione -->
            <li *ngIf="isCompanyAdminstrator || typeUser == 'SUPER ADMIN'">
                <a href="#"><i class="fa fa-check-square-o"></i> <span class="nav-label">Obliterazione</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">

                    <li *ngIf="(typeUser == 'AZIENDA DI TRASPORTO' || typeUser == 'SUPER ADMIN'  || typeUser == 'SOLUZIONE1')  && !isMobileOperator && !isOperator" [ngClass]="{active: activeRoute('obliterators')}">
                        <a (click)="listService.resetList()" routerLink="/obliterators/list"><i class="fa fa-check-square-o"></i> <span class="nav-label">Obliteratrici</span> </a>
                    </li>

                    <li *ngIf="vehicleManager && !isMobileOperator && !isOperator" [ngClass]="{active: activeRoute('vehicles')}">
                        <a (click)="listService.resetList()" routerLink="/vehicles/list"><i class="fa fa-bus"></i> <span class="nav-label">Veicoli</span> </a>
                    </li>

                </ul>
            </li>

            <!-- Analisi dati -->
            <li *ngIf="(!isResales && !isOperator) || isSeller || isResaleAdministrator">
                <a href="#"><i class="fa fa-line-chart"></i> <span class="nav-label">Analisi dati</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">

                    <li *ngIf="!isResales && !isOperator" [ngClass]="{active: activeRoute('/statistics/earnings')}">  
                        <a (click)="listService.resetList()" routerLink="/statistics/earnings"><i class="fa fa-money"></i> <span class="nav-label">Analisi Incassi</span> </a>
                    </li>                    
                    <li *ngIf="!isResales && !isOperator" [ngClass]="{active: activeRoute('/statistics/ticket')}">  
                        <a (click)="listService.resetList()" routerLink="/statistics/ticket"><i class="fa fa-ticket"></i> <span class="nav-label">Analisi Ticket</span> </a>
                    </li>
                    <li *ngIf="isResales || isResaleAdministrator || isSeller" [ngClass]="{active: activeRoute('/sales/ticket')}">
                        <a (click)="listService.resetList()" routerLink="/statistics/ticket"><i class="fa fa-ticket"></i> <span class="nav-label"> Consultazione vendite</span></a> 
                    </li>
                    <li *ngIf="!isResales && !isOperator">  
                        <a (click)="listService.resetList()" routerLink="/statistics/vanalysis"><i class="fa fa-gift"></i> <span class="nav-label">Analisi Vouchers</span> </a>
                    </li>
                    <li *ngIf="!isResales && !isOperator">  
                        <a (click)="listService.resetList()" routerLink="/statistics/obliterations"><i class="fa fa-check"></i> <span class="nav-label">Analisi Obliterazioni</span> </a>
                    </li>
                    <li *ngIf="!isResales && !isOperator">  
                        <a (click)="listService.resetList()" routerLink="/statistics/passengers"><i class="fa fa-users"></i> <span class="nav-label">Analisi Passeggeri</span> </a>
                    </li>
                    <li *ngIf="(isSoluzione1 || isAdministrator) && (!isResales && !isOperator)">  
                        <a (click)="listService.resetList()" routerLink="/statistics/export"><i class="fa fa-download"></i> <span class="nav-label">Estrazione dati</span> </a>
                    </li>
                    <li  *ngIf="isResales || isResaleAdministrator || isSeller" [ngClass]="{active: activeRoute('wallet')}">
                        <a (click)="listService.resetList()" routerLink="/wallet/list"><i class="fa fa-money"></i> <span class="nav-label">Movimenti</span> </a>
                    </li> 
                </ul>
            </li>

            <!-- Azienda di trasporto -->
            <li *ngIf="isSoluzione1" [ngClass]="{active: activeRoute('companies')}">
                <a (click)="listService.resetList()" routerLink="/companies/list"><i class="fa fa-building"></i> <span class="nav-label">Aziende di Trasporto</span> </a>
            </li>

            <!-- Vendita -->
            <li *ngIf="newSellManager || isOperator" [ngClass]="{active: activeRouteSame('/sale/ticket') || activeRouteSame('/subscription/sell') || activeRouteSame('/recover/pdf')}">
                <a href="#"><i class="fa fa-eur"></i> <span class="nav-label">Vendita</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">

                    <li *ngIf="newSellManager || isOperator" >
                        <a (click)="listService.resetList()" routerLink="/sale/ticket"><i class="fa fa-eur"></i> <span class="nav-label">Ticket</span></a> 
                    </li>

                    <li *ngIf="(newSellManager || isOperator) && showSubscriptions" >
                        <a (click)="listService.resetList()" routerLink="/subscription/sell"><i class="fa fa-eur"></i> <span class="nav-label">Abbonamenti</span></a> 
                    </li>

                    <li *ngIf="isPdfEnabled" [ngClass]="{active: activeRoute('recover')}">
                        <a (click)="listService.resetList()" routerLink="/recover/pdf"><i class="fa fa-file"></i> <span class="nav-label">Recupero ultimi PDF</span> </a>
                    </li>

                </ul>
            </li>

            <!-- Attività -->
            <li *ngIf="isSoluzione1" [ngClass]="{active: activeRoute('/activities/activity')}">
                <a (click)="listService.resetList()" routerLink="/activities/activity"><i class="fa fa-book"></i> <span class="nav-label">Registro Attività</span></a> 
            </li>
            
            <!-- Utility -->
            <li [ngClass]="{active: activeRouteSame('utility') || activeRouteSame('utility')}">
                <a href="#"><i class="fa fa-gear"></i> <span class="nav-label">Utility</span><span class="fa arrow"></span></a>
                <ul class="nav nav-second-level">
                    <li *ngIf="isSoluzione1">
                        <a (click)="listService.resetList()" routerLink="/utility/acl/list"><i class="fa fa-database"></i> <span class="nav-label">ACL</span></a> 
                    </li>
                    <li *ngIf="isSoluzione1">
                        <a (click)="listService.resetList()" routerLink="/utility/files/list"><i class="fa fa-file"></i> <span class="nav-label">Gestione file</span></a> 
                    </li>
                    <li *ngIf="isSoluzione1">
                        <a (click)="listService.resetList()" routerLink="/utility/channel/list"><i class="fas fa-flag"></i> <span class="nav-label">Canali voucher</span></a>
                    </li>
                    <li [ngClass]="{active: activeRoute('/utility/params') || activeRoute('/utility/keys')}"> 
                        <a href="#"><span class="nav-label">Azienda di trasporto</span><span class="fa arrow"></span></a> 
                        <ul class="nav nav-third-level">
                            <li [ngClass]="{active: activeRoute('/utility/params')}">
                                <a (click)="listService.resetList()" routerLink="/utility/params"><i class="fas fa-cogs"></i> <span class="nav-label">Parametri</span></a>
                            </li>
                            <li *ngIf="isSoluzione1" [ngClass]="{active: activeRoute('/utility/keys')}">
                                <a (click)="listService.resetList()" routerLink="/utility/keys"><i class="fa fa-key"></i> <span class="nav-label">Anagrafica chiavi</span></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>