<div class="row" style="padding-top: 20px"></div>

<div class="">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2 class="col-md-4"><strong>Fermate</strong></h2>
                <div class="ibox-tools col-md-8 pull-right">
                    <!--<button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-default "> <i class="fa fa-rotate-left"></i></button>
                    <button type="button" (click)="filterSearch()" class="btn btn-view"> <i class="fa fa-filter"></i></button>-->
                </div>
            </div>
            <div class="ibox-content">
                <div class="row">
                    <form #formStops="ngForm">
                            <div *ngIf="isSoluzione1" class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                                    <div class="form-group">
                                        <label>Azienda di trasporto</label>
                                        <div class='input-group col-xs-12'>
                                            <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel">
                                                <option [ngValue] = "'all'" > Tutte </option>
                                                <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}} </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                             
                        <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                            <div class="form-group">
                                <label>Linee</label>
                                <div class='input-group col-xs-12'>
                                    <select type="text" id="routeId" class="form-control" ngModel name="routeId" required routeId #routeId="ngModel">
                                        <option [ngValue] = "'all'" > Tutte </option>
                                        <option *ngFor="let c of lineList" [ngValue]="c.routeId">{{c.routeId}} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                            <div class="form-group">
                                <label>Nome fermata</label>
                                <div class='input-group col-xs-12'>
                                <input
                                type="text"
                                id="stopName"
                                name="stopName"
                                class="form-control"
                                ngModel
                                stopName
                                #stopName= "ngModel">
                                </div>
                            </div>
                        </div>
                      
                       
                        <div class='col-xs-6 col-sm-3 col-md-3'>
                            <div class="form-group">
                                <label></label>
                                <div class='input-group col-md-12'>
                                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter"> <i class="fa fa-rotate-left"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
    
                <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
                <div *ngIf="errorMessage === ''" class="table-responsive">
                    <table class="table table-striped table-bordered table-hover dataTables-example" >
                    <thead>
                        <tr>
                       
                            <th *ngIf="isSoluzione1">Azienda di trasporto</th>
                            <th>Linea</th>
                        <th>Nome fermata</th>
                       
                        <th>Latitudine</th>
                        <th>Longitudine</th>
                    
                        </tr>
                    </thead>
                    <tbody *ngIf="errorMessage === ''">
                        <tr *ngFor="let data of dataList"  style="cursor:pointer">
                            <td *ngIf="isSoluzione1" style="width: 200px;">{{ getCompanyName(data.idCompany)}}</td>
                            <td>{{data.routeShortName}}</td>
                            <td>{{data.stopName}}</td>
                            <td>{{data.stopLat}}</td>
                            <td>{{data.stopLon}}</td>
                            <!--
                            <td style="width: 130px" class="col-md-4 text-center"> 
                                <button  type="button" class="btn btn-success btn-outline " (click)="selectRow(data.stopId)"><i class="fa fa-pencil"></i></button>
                                <button type="button" class="btn btn-danger btn-outline  btn_delete_order" (click)=" deleteStop(data.stopId)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        -->
                        </tr>
                    </tbody>          
                    </table>
                    <ngb-pagination 
                    *ngIf="listService.totalRows > listService.rows"
                    class="pull-right"
                    [maxSize]="10" 
                    [collectionSize]="listService.totalRows" 
                    [pageSize]="listService.rows" 
                    [(page)]="listService.page"
                    (pageChange)="pageChange($event)" >
                </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
        