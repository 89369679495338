import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import {OrderNewComponent} from './order-new/order-new.component';
import {BrowserModule} from "@angular/platform-browser";


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [OrdersListComponent, OrderEditComponent, OrderNewComponent]
})
export class OrdersModule { }
