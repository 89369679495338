import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';

import { Observable } from 'rxjs';
import { ActivatedRoute,Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-order-new',
  templateUrl: './order-new.component.html',
  styleUrls: ['./order-new.component.css']
})
export class OrderNewComponent  implements OnInit {

 
defaultselectedStatusValue;
defaultselectedCodeTypeValue;
errorMessage;
suppliers: any[] = new Array();
commissions: any[] = new Array();
administrators: any[] = new Array();

urlPath;
supplierListPath;
commisionListPath;
administratorListPath;



  constructor(private route: ActivatedRoute,
    private router: Router,
    private logger: LoggingService, 
    public listService: ListService ) { }


   @ViewChild('f') signupForm: NgForm;
  
  userData = {
        codeOrder: "",
        codeOrderType:"",
        descOrder: "",
        orderStatus: "",
        ​idCommission:​ ​0,
        ​idSupplier:​ ​0,
        idUser:​ ​0     
  };

  ngOnInit() {

    this.supplierListPath = "/suppliers/suppliers"
    this.commisionListPath = "/commissions/commissions"
    this.administratorListPath = "/admin/user/users"
 
    this.getAdministratorList();
    this.getSuppliers();
    this.getCommissions();
    this.defaultselectedStatusValue = "OPEN";
    this.defaultselectedCodeTypeValue = "NORMAL"
    this.urlPath = "/admin/order";

    
    this.signupForm.statusChanges.subscribe(
      (status) => this.logger.log("Status",status,200)
    );
    
  }

  back(){
      this.router.navigate(['/orders/list']);
  }
  getAdministratorList(){
    this.listService.getList(this.administratorListPath).subscribe( (response) => {

        this.administrators = response.results;
        this.logger.log("Lista amministratori" , this.administrators,200);
     
      },    
      (error) => { 
        this.logger.log("Error",error,200);
      }
    );
  }


getSuppliers(){
        this.listService.getList(this.supplierListPath).subscribe( (response) => {
        this.suppliers = response.results;
        this.logger.log("Lista fornitori: ",this.suppliers,300);
      },    
      (error) => { 
        this.logger.log("Error",error,200);
      }
    );
  }

  getCommissions(){
        this.listService.getList(this.commisionListPath).subscribe( (response) => {
        this.commissions = response.results;
        this.logger.log("Lista commesse: ",this.commissions,300);
      },    
      (error) => { 
        this.logger.log("Error",error,200);
      }
    );
  }

 onSubmit()  : void {
      
    //this.visible = true
    
    swal2.fire({
      title: 'Modifica in corso...',
      didOpen: function () {
        swal2.showLoading()
      }
    })
    this.userData.codeOrder = this.signupForm.value.codeOrder; 
    this.userData.descOrder = this.signupForm.value.descOrder; 
    this.userData.orderStatus = this.signupForm.value.orderStatus; 
    this.userData.idCommission = this.signupForm.value.idCommission;
    this.userData.idSupplier = this.signupForm.value.idSupplier;
    this.userData.idUser = this.signupForm.value.idUser;
    this.userData.codeOrderType = this.signupForm.value.codeOrderType

    this.listService.newObject(this.userData, this.urlPath).subscribe(
      (response) => {
 
        if (response.outcome.success === true) {
            swal2.fire("Creazione ordine", "Il nuovo ordine è stato creato con successo", "success").then((ev) => {
              if (ev.value) {
                this.router.navigate(['orders/list']);
              }
            }); 
            
       
        } 
            // login failed
            
        
          else if (response.outcome.code === "0014") {
      
          console.log(response)
          swal2.fire("Attenzione!", "Il codice inserito è già stato utilizzato per un ordine valido.", "warning")
          //this.errorMessage = "Il codice inserito è già stato utilizzato per una commessa valida."
       
        }
         else {
             this.logger.log("Response",response,200);
             swal2.fire("Errore", "Errore durante la creazione dell'ordine. Riprova più tardi.", "error");
            this.errorMessage = "Credenziali non valide."
          }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
    this.signupForm.reset();
  }
}