import * as i0 from '@angular/core';
import { Component, Input, ViewChild, NgModule } from '@angular/core';
import jsbarcode from 'jsbarcode';
const _c0 = ["bcElement"];
class NgxBarcode6Component {
  get options() {
    return {
      format: this.format,
      lineColor: this.lineColor,
      width: this.width,
      height: this.height,
      displayValue: this.displayValue,
      fontOptions: this.fontOptions,
      font: this.font,
      textAlign: this.textAlign,
      textPosition: this.textPosition,
      textMargin: this.textMargin,
      fontSize: this.fontSize,
      background: this.background,
      margin: this.margin,
      marginTop: this.marginTop,
      marginBottom: this.marginBottom,
      marginLeft: this.marginLeft,
      marginRight: this.marginRight,
      valid: this.valid
    };
  }
  constructor(renderer) {
    this.renderer = renderer;
    this.elementType = 'svg';
    this.cssClass = 'barcode'; // this should be done more elegantly
    this.format = 'CODE128';
    this.lineColor = '#000000';
    this.width = 2;
    this.height = 100;
    this.displayValue = false;
    this.fontOptions = '';
    this.font = 'monospace';
    this.textAlign = 'center';
    this.textPosition = 'bottom';
    this.textMargin = 2;
    this.fontSize = 20;
    this.background = '#ffffff';
    this.margin = 10;
    this.marginTop = 10;
    this.marginBottom = 10;
    this.marginLeft = 10;
    this.marginRight = 10;
    this.value = '';
    this.valid = () => true;
  }
  ngAfterViewInit() {
    this.createBarcode();
  }
  ngOnChanges() {
    if (this.bcElement) {
      this.createBarcode();
    }
  }
  createBarcode() {
    if (!this.value) {
      return;
    }
    let element;
    switch (this.elementType) {
      case 'img':
        element = this.renderer.createElement('img');
        break;
      case 'canvas':
        element = this.renderer.createElement('canvas');
        break;
      case 'svg':
      default:
        element = this.renderer.createElement('svg', 'svg');
    }
    jsbarcode(element, this.value, this.options);
    for (const node of this.bcElement.nativeElement.childNodes) {
      this.renderer.removeChild(this.bcElement.nativeElement, node);
    }
    this.renderer.appendChild(this.bcElement.nativeElement, element);
  }
  static {
    this.ɵfac = function NgxBarcode6Component_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxBarcode6Component)(i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxBarcode6Component,
      selectors: [["ngx-barcode6"]],
      viewQuery: function NgxBarcode6Component_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bcElement = _t.first);
        }
      },
      inputs: {
        elementType: [0, "bc-element-type", "elementType"],
        cssClass: [0, "bc-class", "cssClass"],
        format: [0, "bc-format", "format"],
        lineColor: [0, "bc-line-color", "lineColor"],
        width: [0, "bc-width", "width"],
        height: [0, "bc-height", "height"],
        displayValue: [0, "bc-display-value", "displayValue"],
        fontOptions: [0, "bc-font-options", "fontOptions"],
        font: [0, "bc-font", "font"],
        textAlign: [0, "bc-text-align", "textAlign"],
        textPosition: [0, "bc-text-position", "textPosition"],
        textMargin: [0, "bc-text-margin", "textMargin"],
        fontSize: [0, "bc-font-size", "fontSize"],
        background: [0, "bc-background", "background"],
        margin: [0, "bc-margin", "margin"],
        marginTop: [0, "bc-margin-top", "marginTop"],
        marginBottom: [0, "bc-margin-bottom", "marginBottom"],
        marginLeft: [0, "bc-margin-left", "marginLeft"],
        marginRight: [0, "bc-margin-right", "marginRight"],
        value: [0, "bc-value", "value"],
        valid: [0, "bc-valid", "valid"]
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 2,
      vars: 2,
      consts: [["bcElement", ""]],
      template: function NgxBarcode6Component_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.cssClass);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxBarcode6Component, [{
    type: Component,
    args: [{
      selector: 'ngx-barcode6',
      template: `<div #bcElement [class]="cssClass"></div>`
    }]
  }], () => [{
    type: i0.Renderer2
  }], {
    elementType: [{
      type: Input,
      args: ['bc-element-type']
    }],
    cssClass: [{
      type: Input,
      args: ['bc-class']
    }],
    format: [{
      type: Input,
      args: ['bc-format']
    }],
    lineColor: [{
      type: Input,
      args: ['bc-line-color']
    }],
    width: [{
      type: Input,
      args: ['bc-width']
    }],
    height: [{
      type: Input,
      args: ['bc-height']
    }],
    displayValue: [{
      type: Input,
      args: ['bc-display-value']
    }],
    fontOptions: [{
      type: Input,
      args: ['bc-font-options']
    }],
    font: [{
      type: Input,
      args: ['bc-font']
    }],
    textAlign: [{
      type: Input,
      args: ['bc-text-align']
    }],
    textPosition: [{
      type: Input,
      args: ['bc-text-position']
    }],
    textMargin: [{
      type: Input,
      args: ['bc-text-margin']
    }],
    fontSize: [{
      type: Input,
      args: ['bc-font-size']
    }],
    background: [{
      type: Input,
      args: ['bc-background']
    }],
    margin: [{
      type: Input,
      args: ['bc-margin']
    }],
    marginTop: [{
      type: Input,
      args: ['bc-margin-top']
    }],
    marginBottom: [{
      type: Input,
      args: ['bc-margin-bottom']
    }],
    marginLeft: [{
      type: Input,
      args: ['bc-margin-left']
    }],
    marginRight: [{
      type: Input,
      args: ['bc-margin-right']
    }],
    value: [{
      type: Input,
      args: ['bc-value']
    }],
    valid: [{
      type: Input,
      args: ['bc-valid']
    }],
    bcElement: [{
      type: ViewChild,
      args: ['bcElement']
    }]
  });
})();
class NgxBarcode6Module {
  static {
    this.ɵfac = function NgxBarcode6Module_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxBarcode6Module)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxBarcode6Module
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxBarcode6Module, [{
    type: NgModule,
    args: [{
      declarations: [NgxBarcode6Component],
      imports: [],
      exports: [NgxBarcode6Component]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-barcode6
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxBarcode6Component, NgxBarcode6Module };
