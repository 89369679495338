<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">


  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Vendita abbonamenti</strong>
        <!-- <button (click)="downloadPdf()">ddd</button> -->
      </h2>
    </div>




    <div class="ibox-content">
      <div class="row">


        <form #formSalesNewSubscription="ngForm">

          <div class="col-lg-12">

            <div style="margin-bottom: 20px" class="col-md-12">

              <div class="col-md-6">
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="form-group">
                            <label>TARIFFA</label>
                            <select (change)="onChangeFare($event.target.value)" type="text" id="fare" class="form-control" ngModel name="fare" required
                              fare #fare="ngModel" required>
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let f of fareList" [ngValue]="f.idTicketFare">{{f.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-xs-12">
                          <div *ngIf="visiblePriceList" class="form-group">
                            <label>LISTINO-ABBONAMENTO</label>
                            <select (change)="onChange($event.target.value)" type="text" id="list" class="form-control" ngModel name="list" required
                              list #list="ngModel" required>
                              <option [ngValue]=null> --- </option>
                              <option *ngFor="let f of filterPriceList" [ngValue]="f.idTicketPricelist">{{f.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div *ngIf="visiblePickerDate" class='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                          <div  class="form-group">
                            <label>INIZIO VALIDITA' ABBONAMENTO</label>
                            <div class='input-group col-xs-12'>
                              <input type="text"
                                class="form-control"
                                name="date"
                                id="date"
                                ngModel
                                required
                                bsDatepicker
                                #bsDatepicker
                                placement="bottom"
                                autocomplete="off"
                                (bsValueChange)="onDateChanged($event)"
                                [bsConfig]="bsConfig" />
                            </div>
                          </div>
                        </div>

                        <div class="col-xs-6">
                          <div *ngIf="visibleMonthList" class="form-group">
                            <label>MESE INIZIO VALIDITA'</label>
                            <select  (change)="onChangeMonth($event.target.value)" type="text" id="month" autocomplete="off" class="form-control" ngModel name="month" required
                            month #month="ngModel" required>
                              <option [ngValue]="'01-01-'"> GENNAIO </option>
                              <option [ngValue]="'01-02-'"> FEBBRAIO  </option>
                              <option [ngValue]="'01-03-'"> MARZO</option>
                              <option [ngValue]="'01-04-'"> APRILE </option>
                              <option [ngValue]="'01-05-'"> MAGGIO </option>
                              <option [ngValue]="'01-06-'"> GIUGNO </option>
                              <option [ngValue]="'01-07-'"> LUGLIO </option>
                              <option [ngValue]="'01-08-'"> AGOSTO </option>
                              <option [ngValue]="'01-09-'"> SETTEMBRE </option>
                              <option [ngValue]="'01-10-'"> OTTOBRE </option>
                              <option [ngValue]="'01-11-'"> NOVEMBRE </option>
                              <option [ngValue]="'01-12-'"> DICEMBRE </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-xs-6">
                          <div *ngIf="visibleMonthList" class="form-group">
                            <label>ANNO INIZIO VALIDITA'</label>
                            <select  (change)="onChangeYear($event.target.value)" type="text" id="year" class="form-control" autocomplete="off" ngModel name="year" required
                            year #year="ngModel" required>
                              <option [ngValue]="'2018'"> 2018 </option>
                              <option [ngValue]="'2018'"> 2019 </option>
                              <option [ngValue]="'2018'"> 2020 </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-xs-12">
                          <div *ngIf="visiblePriceList" class="form-group">
                            <label>ID CARD</label>
                            <input (change)="changeIdNumber($event.target.value)" type="text" id="idCard" class="form-control" ngModel name="idCard" required idCard #idCard="ngModel" required>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div *ngIf="chooseList" class="col-md-6">

                <h2 class="font-bold m-b-xs">
                  {{listNameString}}
                </h2>

                <h4 style="margin-top: 25px">DETTAGLI ABBONAMENTO:</h4>

                <div class="col-lg-6">
                    <h3>Tariffa: </h3>  <strong class="text-navy">{{fareNameString}}</strong>
                   
                    <h3>ID Card: </h3>  <strong class="text-navy"> {{idCardString}}</strong>

                    <h3>Inizio Validità: </h3>  <strong class="text-navy">{{stringDataLine}}{{stringDataLineYear}}</strong>
                </div>

                <div class="col-lg-6">
                   
                    <h3>Tipologia: </h3>  <strong class="text-navy">Abbonamento</strong>
                    <h3>Linea: </h3>  <strong class="text-navy">{{lineString}}</strong>
                </div>

                <div class="clearfix"></div>
              
                <div style="margin-top: 20px" class="row">
                  <div  class="col-md-4">
                      <div class='col-md-12'>
                      <label>Stampante</label>
                          <select (change)="getSerialNumber($event.target.value)"  [ngModel]="defaultIdPrinter" type="text" id="idPrinter" class="form-control" ngModel name="idPrinter"
                             required idPrinter #idPrinter="ngModel">
                            <option *ngIf="isPdfEnabled" [ngValue]="0"> Stampa come pdf </option>
                            <option *ngFor="let f of printerList" [ngValue]="f.id">{{f.name}}</option>
                          </select>
                        </div>
                  </div>
                  <div  class="col-md-3">
                    <div class="m-t-md">
                      <h2 class="product-main-price" style="color: #1ab394">{{ticketTotalPrice}} € </h2>
                    </div>
                  </div>
                  <div style="margin-top: 15px;" class="col-md-3">
                    <button style="float: right" (click)="completeSold()" class="btn btn-primary" [disabled]="!formSalesNewSubscription.valid" type="button">
                    Stampa
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


</div>