import { Chart } from './chart.model';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ChartsService {
  chartsChanged = new EventEmitter<Chart[]>();

  public tmpTitle: string = "";
  public tmpReport: string = "";
  public tmpStacked: string = "";
  public tmpType: string = "";

  private charts: Chart[] = [
    new Chart('Deliveries per service and status','service-status','No','bar'),
    new Chart('Deliveries per nation and status','nation-status','Yes','line'),
    new Chart('Deliveries per month and status','month-status','Yes','horizontalBar'),
    new Chart('Deliveries per status','status','No','pie')
  ];

  getCharts() {
    return this.charts.slice();
  }

  addChart() {
    if(this.tmpTitle === "" || this.tmpReport === "" || this.tmpStacked === "" || this.tmpType === ""){
      alert("Insert all values!");
      return;
    } else {
      let lchart = new Chart(this.tmpTitle,this.tmpReport,this.tmpStacked,this.tmpType);
      this.charts.splice(0, 0, lchart);
      this.tmpTitle = this.tmpReport = this.tmpType = this.tmpStacked = "";
      this.chartsChanged.emit(this.charts.slice());
    }
  }

  changeChartType(idx :number, newType :string){
    this.charts[idx].type = newType;
    this.chartsChanged.emit(this.charts.slice());
  }

  deleteChart(idx :number){
    this.charts.splice(idx,1);
    this.chartsChanged.emit(this.charts.slice());
  }

}
