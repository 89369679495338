<style>
    .my-drop-zone { border: dotted 3px lightgray; }
    .nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
    .another-file-over-class { border: dotted 3px green; }
 
    html, body { height: 100%; }
</style>
 
<div class="row" style="padding-top: 20px"> </div>

<div class="">
    <!-- Obliterator APK -->
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>APK Praticko Obliterator</strong>
                </h2>
            </div>
            <app-file-upload [uploadType]="UploadType.APK"
                [uploadUrl]="'/file/upload/apk'"
                [title]="'Upload .apk obliteratrice'"
                [description]="'Effettua l\'upload di un file .apk utile alla configurazione di una obliteratrice Praticko'"
                [fileTypes]="['.apk']"
                (onUpload)="reloadFilesList()">
            </app-file-upload>
            <app-uploads-archive [filesType]="'apk'" [reload]="reloadList"></app-uploads-archive>
        </div>
    </div>

    <!-- Controller APK -->
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <!-- TITLE -->
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>APK Praticko Controller</strong>
                </h2>
            </div>
            <!-- APP INFO -->
            <div class="ibox-content" style="padding-bottom: 0; border-bottom: 0 !important;"
                *ngIf="appInfoLoaded">
                <div class="table-responsive">
                    <form #formControllerAppInfo="ngForm">
                        <div style="display: flex; align-items: center;">
                            <div class='col-xs-6 col-sm-3 col-md-2 version-input'>
                                <div class="form-group">
                                    <label>Versione</label>
                                    <div class='input-group col-xs-12'>
                                        <input type="text"
                                            id="appVersion"
                                            name="appVersion"
                                            [(ngModel)]="appInfo.version"
                                            class="form-control"
                                            ngModel appVersion #appVersion="ngModel">
                                    </div>
                                </div>
                            </div>
                            <div class='col-xs-6 col-sm-3 col-md-1'>
                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" name="forced"
                                            [(ngModel)]="appInfo.forced"
                                            id="forced" forced />
                                        <label>
                                            <b>Forzata</b>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class='col-xs-6 col-sm-3 col-md-1'>
                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" name="enabled" 
                                            [(ngModel)]="appInfo.enabled"
                                            id="enabled" enabled />
                                        <label>
                                            <b>Scaricabile</b>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- APP FILE UPLOAD -->
            <app-file-upload [uploadType]="UploadType.CONTROLLER_APK" 
                [uploadUrl]="'/file/upload/controllerApk'"
                [title]="'Upload .apk app controllore'"
                [description]="'Effettua l\'upload di un file .apk utilizzato da un controllore in fase di controllo biglietti'"
                [fileTypes]="['.apk']"
                [noBorder]="true"
                [appInfo]="appInfo">
            </app-file-upload>
        </div>
    </div>

    <!-- Template PDF -->
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>Template PDF</strong>
                </h2>
            </div>
            <app-file-upload [uploadType]="UploadType.TEMPLATE" 
                [uploadUrl]="''"
                [title]="'Upload file template .pdf'"
                [description]="'Il file dovrà essere in formato .pdf e rispettare le linee guida per la creazione di un template Praticko'"
                [fileTypes]="['.pdf']">
            </app-file-upload>
            <app-uploads-archive [filesType]="'template'" [downloadable]="false"></app-uploads-archive>
        </div>
    </div>
</div>
