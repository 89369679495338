import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2, { SweetAlertResult } from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-subscription-typology',
  templateUrl: './subscription-typology.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SubscriptionTypologyComponent implements OnInit {

  @ViewChild('formSubscriptionTypology') ticketSubscriptionForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  //dataList: any[] = new Array();
 
  dataFilter: any[] = new Array();
  dataList: any[] = new Array();

  //PATH CHIAMATE
  urlDeletePath;
  errorMessage: string = "";
  visibleFilter;
  defaultValue;
  UpOrDown;
  pathToCallCompaniesPath = "/company/companies"
  companiesList :any[] = new Array();
  typeUser;
  isSoluzione1;
  roleUser;
  isMobileOperator;
  
  constructor(private logger: LoggingService, public utils:UtilsService,  private router: Router, public listService: ListService) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/tickets/typology",false,true,1,10);
    this.listService.restURL = "/ticket/type/types";
  }

  ngOnInit() {

    //CHIAMATA LISTA ELEMENTI
    this.loadElements();
    this.UpOrDown = true;
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.roleUser = this.utils.getRoleUser();
    this.isMobileOperator = this.utils.isOperatorMobile(this.roleUser)
    

  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
    this.companiesList = response.results;
    this.logger.log("Lista aziende", this.companiesList, 300);
    //console.log("LISTA commesse: ",response.results);
    },
    (error) => {
        this.logger.log("Error", error, 200);
    }
    );
}

  //FILTRI
  filterSearch(){

    this.dataFilter = []; 
    this.dataFilter = this.ticketSubscriptionForm.value;
    this.dataFilter['subscription']= "any"
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){


    this.ticketSubscriptionForm.value.idCompany = ""
    this.ticketSubscriptionForm.value.ticketTypeName = ""
    this.listService.visibleFilter = false;
    this.loadElements();
    this.ticketSubscriptionForm.reset();



    this.UpOrDown = true;
  }



getBusinessName(idcompany){
  var string;
  for (let company of this.companiesList){
    if(company.idCompany == idcompany){
      return string  = company.legalBusinessName
    }
  }
}

getValidity(bool){
  var boolean;
  if(bool){
   return boolean = "Andata/Ritorno"
  }else{
     return boolean = "Sola Andata"
  }
}


  changeStatus(){
    if(this.UpOrDown == true){
      this.UpOrDown = false;

      console.log("VIEE", this.UpOrDown)
    }else{
      this.UpOrDown = true;
    }
  
  }
//SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.dataFilter['subscription'] = "any"
   this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
       this.dataList = response.results;
        this.listService.totalRows = response.total;


        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/subscription/typology/edit']);
  }

  newTicketTipology(){
    this.listService.backToList = true;
    this.router.navigate(['/subscription/typology/new']);
  }


  deleteTypology(idType) {
    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione tipologia',
      text: "Sei sicuro di voler eliminare la tipologia?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idType);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idElement) {
    this.urlDeletePath =  "/ticket/type/"+ idElement;

    swal2.fire({title: 'Eliminazione in corso...', didOpen: function () {swal2.showLoading()}});
    
    this.logger.log("ID DELETE:",idElement,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
     
        if (response.outcome.success === true) {
               
          this.logger.log("Response value",response.value,200);
          swal2.fire("La tipologia  è stata eliminata con successo", "","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire("Attenzione", this.errorMessage,"warning");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

}


