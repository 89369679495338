import { Component, OnInit } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
import { ConfigKey } from '../../../interfaces/configuration.interface';
import { LoggingService } from '../../../services/logging.service';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-keys-list',
  templateUrl: './keys-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class KeysListComponent implements OnInit {

  isSoluzione1: boolean;
  visiblePaging = false;

  // companies info
  keysPath = '/configuration';
  keysList: ConfigKey[] = [];

  constructor(
    private listService: ListService,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggingService
  ) {
    this.isSoluzione1 = this.utils.isSol1(this.utils.getTypeUser());
  }

  ngOnInit(): void {
    this.loadElements();
  }

  newKey(): void {
    this.listService.backToList = true;
    this.router.navigate(['/utility/keys/new']);
  }

  selectRow(key: any): void {
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(key));
    this.router.navigate(['/utility/keys/edit']);
  }

  getStatusDescription(availableActions: boolean) {
    return availableActions ? 'Disponibili' : 'Non disponibili';
  }

  getButtonCircle(availableActions: boolean) {
    return availableActions ? 'btn btn-primary btn-circle' : 'btn btn-danger btn-circle';
  }

  pageChange(page: any) {
    this.listService.page = page;
    this.loadElements();
  }

  // ---------- PRIVATE METHODS ---------- //

  private loadElements() {
    this.listService.restURL = this.keysPath;
    this.listService.getListFilter().subscribe(
      (response) => {
        this.keysList = response.results;
        this.listService.totalRows = response.total;

        if (this.listService.totalRows > 10) {
          this.visiblePaging = true;
        } else {
          this.visiblePaging = false;
        }

        this.listService.visibleFilter = true;
        this.logger.log('Response: ', response, 300);
      }, (error) => {
        this.logger.log('Error', error, 200);
        this.listService.visibleFilter = true;
        swal2.fire(
          'Errore',
          `Spiacente, si è verificato un errore tecnico`,
          'error'
        );
      }
    );
  }

}

