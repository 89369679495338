
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoggingService } from './logging.service';
import { ListService } from './list.service';


@Injectable()
export class AuthService {

  constructor (private http: HttpClient, private logger: LoggingService, private listService: ListService) {}
   
  login(loginData: Object): Observable<any> { 

    return this.http.post(environment.restBaseUrl + '/login', loginData).pipe(
      map(
        (response: Response) => {
          let res: any = response;

          if(res.outcome.success === true){
            
            this.logger.log("Service:","SUCCESS",200);
            localStorage.setItem("name", res.name);
            localStorage.setItem("surname", res.surname);
            localStorage.setItem("roleUser", res.roleUser);
            localStorage.setItem("typeUser", res.typeUser);
            localStorage.setItem("token", res.token);

            if( res.idCompany != null){
              localStorage.setItem("idCompany", res.idCompany);
            }
           
           
           
            return res;    
          } else {
            this.logger.log("Service:","FAILURE",200);
            this.logger.log("Service:",res.outcome,200);
            localStorage.clear();
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      ),);
  }

  logout(): Observable<any>  {

    localStorage.clear();
    this.listService.clearConfigurations();

    return this.http.post(environment.restBaseUrl + '/logout', null).pipe(
      map(
        (response: Response) => {
          let res: any = response;

          if(res.outcome.success === true){
            
            this.logger.log("Logout"," SUCCESS",200);
            return res;    
          } else {
            this.logger.log("Logout"," FAILURE",200);
            
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      ),);
    }

  forgotPwd(email: string): Observable<any>  {

    localStorage.clear();

    let mailObj: any = {
      email: email
    }

    return this.http.put(environment.restBaseUrl + '/account/forgot', mailObj).pipe(
      map(
        (response: Response) => {
          let res: any = response;

          if(res.outcome.success === true){
            this.logger.log("Forgot pwd"," SUCCESS",200);
            return res;    
          } else {
            this.logger.log("Forgot pwd"," FAILURE",200);
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      ),);
    }


    changePwd(passwords: Object): Observable<any>  {
      
            return this.http.put(environment.restBaseUrl + '/account/changepwd', passwords).pipe(
              map(
                (response: Response) => {
                  let res: any = response;
            
                  if(res.outcome.success === true){
                    this.logger.log("Change PWD"," SUCCESS",200);
                    return res;    
                  } else {
                    this.logger.log("Change PWD"," FAILURE",200);
                    return res;
                  }
                }
              ),
              catchError(
                (error: Response) => {
                  return observableThrowError(error);
                }
              ),);
    }


    activate(data: Object): Observable<any>  {
      
          localStorage.clear();
      
          // TODO: inserire la chiamata al servizio corretto!
          return this.http.put(environment.restBaseUrl + '/account/activate', data).pipe(
            map(
              (response: Response) => {
                let res: any = response;
      
                if(res.outcome.success === true){
                  this.logger.log("Activate"," SUCCESS",200);
                  return res;    
                } else {
                  this.logger.log("Activate"," FAILURE",200);
                  return res;
                }
              }
            ),
            catchError(
              (error: Response) => {
                return observableThrowError(error);
              }
            ),);
    }

    

          
  invite(idUser: number): Observable<any>  {

    let invite: any = {
    
    }

    // TODO: inserire la chiamata al servizio corretto!
    return this.http.put(environment.restBaseUrl + '/account/invite/' + idUser,invite).pipe(
      map(
        (response: Response) => {
          let res: any = response;

          if(res.outcome.success === true){
            this.logger.log("Forgot pwd"," SUCCESS",200);
            return res;    
          } else {
            this.logger.log("Forgot pwd"," FAILURE",200);
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      ),);
    }


          
  paymentTypes(): Observable<any>  {
    return this.http.get(environment.restBaseUrl + '/stats/cards').pipe(
      map(
        (response: Response) => {
          let res: any = response;

          if(res.outcome.success === true){
            this.logger.log("cards=> " + res," SUCCESS",200);
            localStorage.setItem("paymentTypes", JSON.stringify(res.results));
            return res;    
          } else {
            this.logger.log("cards "," FAILURE",200);
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      ),);
  } 
}
