<style>
    .my-drop-zone { border: dotted 3px lightgray; }
    .nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
    .another-file-over-class { border: dotted 3px green; }
 
    html, body { height: 100%; }
</style>
 
<div class="row" style="padding-top: 20px"> </div>

<div class="">
    <!-- GTFS -->
    <div class="col-lg-12" style="display: flex;">
        <div class="ibox float-e-margins" style="background: white; width: 100%;">
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>Gestione GTFS</strong>
                </h2>
                <div class="col-xs-1" style="display: flex; justify-content: end; padding-right: 8px;">
                    <button style="float: left" type="button" class="btn btn-view btn-outline"
                        title="Ricarica lista GTFS" (click)="reloadImporterList()">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
            
            <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group" *ngIf="userAdmin">
                    <label>Azienda di trasporto</label>
                    <div class='input-group col-xs-12'>
                        <select type="text" class="form-control" [(ngModel)]="selectedCompany">
                            <option [ngValue]="'all'"> Tutte </option>
                            <option *ngFor="let c of companiesList" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div *ngIf="companiesList && utils.checkFilesListAvailable(userAdmin, selectedCompany)">
                <app-file-upload [uploadType]="UploadType.GTFS" 
                    [uploadUrl]="'/file/upload/gtfs'"
                    [title]="'Importa file .zip dei GTFS'"
                    [description]="'Il file dovrà essere in formato .zip e contenere il file GTFS relativo alle fermate di una determinata linea da configurare'"
                    [fileTypes]="['.zip']"
                    [companyId]="selectedCompany"
                    (onUpload)="reloadImporterList()">
                </app-file-upload>
                <app-uploads-archive [filesType]="'gtfs'" [companyId]="selectedCompany" [reload]="reloadList"></app-uploads-archive>
            </div>
        </div>
    </div>

    <!-- Timetable -->
    <div class="col-lg-12" *ngIf="companiesList && utils.checkFilesListAvailable(userAdmin, selectedCompany)">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h2 class="col-xs-11">
                    <strong>Upload Orari</strong>
                </h2>
            </div>
            <app-file-upload [uploadType]="UploadType.STOPS"
                [uploadUrl]="'/stop/upload/stopsarchive'"
                [title]="'Importa file .zip degli orari'"
                [description]="'Il file dovrà essere in formato .zip e contenere direttamente tutti i file .pdf degli orari da visualizzare, utilizzando come nome il campo STOP_ID delle fermate presenti nel file GTFS fornito in Praticko'"
                [fileTypes]="['.zip']"
                [companyId]="selectedCompany"
                (onUpload)="reloadImporterList()">
            </app-file-upload>
            <app-uploads-archive [filesType]="'timetables'" [companyId]="selectedCompany" [reload]="reloadList"></app-uploads-archive>
        </div>
    </div>
</div>
