<div class="loginColumns animated fadeInDown">
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
      <div class="ibox-content">

        <div class="text-center">
        <img src="./assets/img/Bu.JPG" width="50%">
      </div>
       


        <form (ngSubmit)="onSubmit()" #f="ngForm">
          <p *ngIf="errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
          <div class="form-group">
            <label for="username">Nome utente</label>
            <input type="text"
              id="username"
              class="form-control"
              ngModel
              name="username"
              required
              username
              #username="ngModel">
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password"
              id="password"
              class="form-control"
              ngModel
              name="password"
              required
              password
              #password="ngModel">
          </div>
          <button type="submit" [disabled]="!f.valid" class="btn btn-redBU block full-width m-b">Accedi</button>
          <a (click)="forgotPwd()" >
            <small>Password dimenticata?</small>
          </a>
        </form> 
      </div>
    </div>
  </div>
</div>
