import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";

import swal2, { SweetAlertResult } from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-stops-list',
  templateUrl: './stops-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class StopsListComponent implements OnInit {

  //FORM
  @ViewChild('formStops') tripsForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();
  lineList: any[] = new Array();
  //PATH CHIAMATE
  urlDeletePath = "";
  pathToCallCompaniesPath = "/company/companies";
  pathToCallLines = "/route/routes"
  errorMessage: string = "";
  typeUser;
  isSoluzione1;

  constructor(private logger: LoggingService,public utils:UtilsService, private router: Router, public listService: ListService){

    this.listService.configurationServiceCall("/stops/list",false,true,1,15);
    this.listService.restURL = "/stop/stops";
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  ngOnInit() {
    this.listService.visibleFilter = false;
    
   
    this.getCompanies();

    //CARICA ELEMENTI
    
    this.getLines();

    this.loadElements();
  }

  pageChange(page){
    this.listService.page = page; 
    this.loadElements();
  }

  selectRow(dataElement){
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/stops/edit']);
  }

  newStop(){
    this.listService.backToList = true;
    this.router.navigate(['/stops/new']);
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.tripsForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){
    this.tripsForm.value.routeId = ""
    this.tripsForm.value.stopName = ""
    this.tripsForm.value.idCompany = ""
    this.listService.visibleFilter = false;
    this.loadElements();
     this.tripsForm.reset();
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
        this.companiesList = response.results;
        this.logger.log("Lista aziende di trasporto", this.companiesList, 300);
        //console.log("LISTA commesse: ",response.results);
    },
    (error) => {
        this.logger.log("Error", error, 200);
    }
    );
  }

  getLines() {
    this.listService.getListSelect(this.pathToCallLines).subscribe((response) => {
        this.lineList = response.results;
        this.logger.log("Lista aziende di trasporto", this.companiesList, 300);
        //console.log("LISTA commesse: ",response.results);
    },
    (error) => {
        this.logger.log("Error", error, 200);
    }
    );
  }


  getCompanyName(idCompany){
    var string;
  for(let c of this.companiesList){
    if (c.idCompany == idCompany){
      return string = c.legalBusinessName;
    }
  }

  }

  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
       
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  deleteStop(idStop) {
    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione fermata',
      text: "Sei sicuro di voler eliminare la fermata "+idStop+" ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idStop);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idElement) {
    this.urlDeletePath =  "/stop/"+ idElement;

    swal2.fire({title: 'Eliminazione in corso...', didOpen: function () {swal2.showLoading()}});
    
    this.logger.log("ID DELETE:",idElement,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
     
        if (response.outcome.success === true) {
               
          this.logger.log("Response value",response.value,200);
          swal2.fire("Eliminazione completata", "La fermata è stata eliminata con successo","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire("Attenzione", this.errorMessage,"warning");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

}
