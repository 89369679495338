<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} Taglio Ricarica</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #formRecharge="ngForm">
          <div class="ibox-content">
            <div *ngIf=isSoluzione1 class="form-group col-xs-12 col-sm-6 col-md-6">
              <label for="idCompany"> Azienda di trasporto </label>
              <select
              type="text"
              id="idCompany"
              class="form-control"
              ngModel
              [ngModel]="ticketTypologyData?.idCompany"
              name ="idCompany"
              idCompany
              #idCompany="ngModel"
                required>
                  <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
              </select>
            </div>

            <div class="form-group col-xs-12 col-sm-6 col-md-6">
                <label for="description">Descrizione taglio</label>
                <input
                  type="text"
                  id="name"
                  name="description"
                  class="form-control"
                  ngModel
                  [ngModel]="ticketTypologyData?.description"
                  required
                  #description= "ngModel">
              </div>




            <div class="form-group col-xs-6 col-sm-4 col-md-4">
              <label for="amount">Taglio</label>
                <div class="input-group col-md-12">
           
                
                    <span class="input-group-addon">€</span>
                    <input type="number" id="amount" name="amount" placeholder="Taglio" class="form-control" ngModel [ngModel]="ticketTypologyData?.amount"
                      required  amount #amount="ngModel">
                   
                  </div>
            </div>


            <div class="form-group col-xs-6 col-sm-4 col-md-4">
              <label for="amount">Prezzo</label>
                <div class="input-group col-md-12">
           
                
                    <span class="input-group-addon">€</span>
                    <input type="number" id="price" name="price" placeholder="Prezzo" class="form-control" ngModel [ngModel]="ticketTypologyData?.price"
                      required  price #price="ngModel">
                   
                  </div>
            </div>


         



       

        
        

            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            

            <button class="btn btn-default" type="button"  (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right"  [disabled]="!formRecharge.valid" > Salva </button>

          </div>
        </form>

      </div>
    </div>
  </div>
</div>    