<div class="loginColumns animated fadeInDown">
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
      <div class="ibox-content">
          <div class="text-center">
              <img  src="./assets/img/Bu.JPG" width="50%">
            </div>
        <form (ngSubmit)="onSubmit()" #f="ngForm">
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm"><i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
        <div *ngIf="!emailSent || emailSent == false"  class="well">
          Inserite il vostro indirizzo email.<br> 
          La password sarà resettata e vi verrà inviato un nuovo link per l'accesso.
        </div>
        <p *ngIf="emailSent == true" class="bg-success p-sm"><i class="fa fa-lg fa-smile" aria-hidden="true"></i> Invio avvenuto con successo!</p>
        <div *ngIf="emailSent == true" class="well">  
          Verificate la vostra casella di posta, a breve riceverete un nuovo link di accesso.
        </div>          
        <div class="form-group">
          <label for="username">Email</label>
          <input type="text"
            id="email"
            class="form-control"
            ngModel
            name="email"
            required
            email
            #email="ngModel">
        </div>
        <button type="submit" [disabled]="!f.valid" class="btn btn-redBU block full-width m-b">Invia</button>
        <div *ngIf="emailSent == true">
          <a  (click)="goToLogin()" >
            <small>Vai alla login</small>
          </a>
        </div>
        </form> 
      </div>
    </div>
  </div>
</div>
