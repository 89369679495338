import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { IConfigValue } from "../../../interfaces/configuration.interface";
import { SaleMode } from "../../../enums/configuration.enum";
import swal2 from "sweetalert2";

@Component({
  selector: "app-sales-subscription",
  templateUrl: "./sales-subscription.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class SalesSubscriptionComponent implements OnInit {
  
  @ViewChild("formFindTrip") findTripForm: NgForm;

  isRoundTrip;
  minDate: Date;
  maxDate: Date;
  colorTheme = "theme-dark-blue";
  bsConfig: Partial<BsDatepickerConfig>;
  daterangepickerModel: Date[];
  datepickerModel: Date;
  inputTextPerson = "";
  categories: any[] = new Array();
  passengers: any[] = new Array();
  cart: any[] = new Array();
  searchTripList: boolean;
  originStops: any[] = new Array();
  destinationStops: any[] = new Array();
  printerList: any[] = new Array();
  pathListStops = "/stop/stops/v2";

  originId;
  destinationId;
  pathListTrips = "/trip/trips/v2";
  pathListTripsResume = "/trip/trips/resume";
  pathListCategories = "/category/categories?type=S"; // filtro per ticket
  tripsAndata;
  tripsRitorno = null;
  tripsAndataResume;
  tripsRitornoResume = null;
  idTripA = -1;
  idTripR = -1;
  requestResumeTmp;
  requestResume;
  total;
  isPdfEnabled;
  pathChekPdfAvaiable = "/ticket/pdfenable";
  pathUrlPrinterList = "/printer/printers";
  defaultValuePcUUID;
  defaultIdPrinter;
  serialNumber;
  idCompany;
  pathListInitTicket = "/ticket/v2";
  dataResponseTickets: any[] = new Array();
  errorMessage;
  channel;
  urlCredit = "/transaction/resalescredit/";
  avaiableCredit;
  availableTrust;
  destinationA;
  destinationR;
  originA;
  originR;
  subscriptionValue = "0";
  routeId;
  price;
  monthlyDate;
  cardIdValue;
  listMonths: any[] = new Array();

  userInfoMode: number; // 1 - email, 2 - user pass
  kindValidation: number;
  idPrinter: number;
  pathCustomerValidations = '/customer/validation/validations?paging=false';

  saleModes: IConfigValue[];

  saleMode = SaleMode;

  constructor(
    public listService: ListService,
    public utils: UtilsService,
    private logger: LoggingService,
    public cookieService: CookieService,
    public toastr: ToastrService,
    private router: Router
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getFullYear() + 1);
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );

    listService.listRouting = "/subscription/sell";
    listService.backToList = true;
  }

  ngOnInit() {
    this.channel = this.utils.getSoldChannel();
    this.saleModes = this.utils.manageSaleModes();
    this.isRoundTrip = false;
    this.searchTripList = false;

    //carico categorie se la modalità della company è standard
    if (this.saleModes[0].id === SaleMode.standardId) {
      this.getCategories();
    }
    
    this.getStopsFrom();
    this.getPrinterList();
    this.checkEnable();
    this.idPrinter = +localStorage.getItem("defaultPrinterId");
    this.idCompany = localStorage.getItem("idCompany");

    if (this.channel == "3") {
      this.getCredit();
    }

    this.createSelectMonth();
  }

  createSelectMonth(){
    //setto oggi, i prossimi 12 mesi e li salvo
    for(var i = 0; i< 12; i++){
      var oggi = new Date();
      // oggi.setMonth(oggi.getMonth()+i);
      oggi = this.addMonths( oggi , i )
      this.listMonths.push(oggi);
    }
  }

  /**
   * url: https://stackoverflow.com/a/13633692
   * @param year 
   */
  isBisestile( year ) {
    return ((( year % 4 === 0 ) && ( year % 100 !== 0 ) || ( year % 400 === 0 )))
  }

  /**
   * url: https://stackoverflow.com/a/13633692
   * @param year 
   * @param month 
   */
  getDaysInMonth( year , month ) {
    return [31, (this.isBisestile(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  /**
   * url: https://stackoverflow.com/a/13633692
   * @param date 
   * @param value 
   */
  addMonths( date , value ) {
    var n = date.getDate();
    date.setDate(1)
    date.setMonth( date.getMonth() + value )
    date.setDate(Math.min(n , this.getDaysInMonth(date.getFullYear() , date.getMonth() ) ))
    return date
  }

  getValueSelectMonth(month: Date){
    let day = "-" + this.utils.manageSubDayMonth();
    //controllo che se è il mese corrente, metto il giorno di oggi e non il 01, altrimenti non prende l'abbonamento
    /*var oggi = new Date();
    if( ("0" + (oggi.getMonth()+1)).slice(-2) == ("0" + (month.getMonth()+1)).slice(-2)){
      day = "-" + ("0" + (oggi.getDate())).slice(-2);
    }*/
    console.log("DAY"+day);
    return month.getFullYear() + "-" + ("0" + (month.getMonth()+1)).slice(-2) + day;
  }
  
  getNameMonth(monthDate){
    var monthName = monthDate.toLocaleString('it-it', { month: 'long' });
    return monthName.toUpperCase() + " " + monthDate.getFullYear();
  }

  getCredit() {
    this.listService.getListSelect(this.urlCredit).subscribe(
      response => {
        var credit = response.results;
        this.logger.log("Lista credito", credit, 300);

        for (let c of credit) {
          this.avaiableCredit = c.creditAmount;
          this.availableTrust = c.trust;
        }

        //console.log("LISTA commesse: ",response.results);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getPrinterList() {
    this.listService.getListSelect(this.pathUrlPrinterList).subscribe(
      response => {
        this.printerList = response.results;
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  checkDefaultPrinter(): void {
    /**
     * When an idPrinter was set then the printer is selected automatically from the list.
     * 
     * If pdf print was selected it is automatically managed (as index 0), otherwise nothing is preset
     */
    if (this.idPrinter !== null) {
      const printer = this.printerList.find(printer => printer.id === this.idPrinter);
      this.defaultIdPrinter = printer ? this.idPrinter : (this.idPrinter === 0 ? 0 : null);
      this.serialNumber = printer?.serialNumber;
    }
  }

  getSerialNumber(id) {
    this.serialNumber = null;
    var stringTest = id.split(":")[1];
    var stringtrip = stringTest.substring(1);
    var idPrinter = stringtrip;
    for (let p of this.printerList) {
      if (p.id == idPrinter) {
        this.serialNumber = p.serialNumber;
      }
    }

    console.log("SERIAL NUMBER", this.serialNumber);
  }

  checkEnable() {
    this.listService.getListSelect(this.pathChekPdfAvaiable).subscribe(
      response => {
        this.isPdfEnabled = response.outcome.success;
        console.log("RISPOSTA PDF", this.isPdfEnabled);
      },
      error => {
        this.logger.log("Error", error, 200);
      },
      () => {
        this.checkDefaultPrinter();
      }
    );
  }

  isRoundTripMethod(event, string) {
    if (string == "ONE") {
      this.isRoundTrip = false;
    } else {
      this.isRoundTrip = true;
    }
    this.idTripA = -1;
    this.idTripR = -1;
    this.searchTripList = false;

    console.log("ANDATA e Ritorno? " + this.isRoundTrip);
  }

  preventClose(event: MouseEvent) {
    event.preventDefault();
    let div: HTMLElement = document.getElementById("name") as HTMLElement;
    div.click();
  }

  saveValueInput(pos, val) {
    console.log("pos:" + pos + " & val:" + val.target.value);
    let valToSave = val.target.value;
    if (valToSave > this.utils.manageMaxTicket()) valToSave = this.utils.manageMaxTicket();
    if (valToSave < 0) valToSave = 0;
    this.passengers[pos]["total"] = valToSave;
    this.changeTextInputValue();
  }

  changeTextInputValue() {
    this.inputTextPerson = "";
    let count = 0;
    Object.keys(this.passengers).forEach(key => {
      if (count !== 0)
        this.inputTextPerson =
          this.inputTextPerson +
          "," +
          this.passengers[key]["total"] +
          " " +
          this.categories[key]["name"];
      else
        this.inputTextPerson =
          this.inputTextPerson +
          this.passengers[key]["total"] +
          " " +
          this.categories[key]["name"];
      count++;
    });
  }

  minusValueInput(pos) {
    if (this.passengers[pos]["total"] > 0) {
      this.passengers[pos]["total"] = this.passengers[pos]["total"] - 1;
    }
    this.changeTextInputValue();
  }
  plusValueInput(pos) {
    if (this.passengers[pos]["total"] < 10) {
      this.passengers[pos]["total"] = this.passengers[pos]["total"] + 1;
    }
    this.changeTextInputValue();
  }

  createPassengers() {
    Object.keys(this.categories).forEach(key => {
      this.passengers.push({
        categoryId: this.categories[key]["categoryId"],
        total: 0
      });
    });
    console.log(this.passengers);
  }

  searchTrip() {
    this.searchTripList = true;
  }

  // Tutte le fermate da
  getStopsFrom(): void {
    this.listService.getListSelect(this.pathListStops).subscribe(
      response => {
        this.originStops = response.results;
        this.logger.log("Lista fermate partenza", this.originStops, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  onChangeMontlyDate(date) {
    this.monthlyDate = "";
    this.monthlyDate = date;
    console.log("DATA MENSILE", date);
  }

  onChangeStop() {
    this.getStopsTo(this.findTripForm.value.origin);
  }

  onChangeSubscriptionType() {
    
    this.passengers = [];

    var category = {
      categoryId: this.findTripForm.value.categorySub,
      total: this.utils.manageMinSubPassengers()
    };

    this.passengers.push(category);
    this.findCategoryValue(this.findTripForm.value.categorySub);
  }

  findCategoryValue(id) {
    for (let c of this.categories) {
      if (c.categoryId == id) {
        this.subscriptionValue = c.subscription;
      }
    }
  }

  getStopsTo(idStop): void {
    this.listService.getListSelect(this.pathListStops + "/" + idStop).subscribe(
      response => {
        this.destinationStops = response.results;
        this.logger.log("Lista fermate fine", this.destinationStops, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getCategories(validationId?: number) {
    this.kindValidation = validationId ? validationId : this.findTripForm?.controls.customerInfoGroup?.['controls']['customerValidation']?.value;
    this.listService.getListSelect(this.pathListCategories + "&mode=" + (this.kindValidation ? 'RESIDENTE' : 'DEFAULT')).subscribe(
      response => {
        this.categories = response.results;
        this.createPassengers();
        this.logger.log("Lista fermate fine", this.destinationStops, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  choseTripList() {
    const dataRequest = {};
    dataRequest["origin"] = this.findTripForm.value.origin;
    dataRequest["destination"] = this.findTripForm.value.destination;
    console.log(this.datepickerModel);
    if(this.subscriptionValue != "30" && this.subscriptionValue != "90"){
      dataRequest["dateA"] = new DatePipe("en-EN").transform(
        this.datepickerModel,
        "yyyy-MM-dd"
      );
    }else{
      dataRequest["dateA"] = this.monthlyDate;
    }
 

    dataRequest["dateR"] = "";

    dataRequest["passengers"] = this.passengers;

    
    console.log(dataRequest);
    this.requestResumeTmp = dataRequest;
    //sessionStorage.setItem("dataRequestTrips", JSON.stringify(dataRequest));
    this.getTrips(dataRequest);
    //this.router.navigate(['/trip/list']);
  }

  getTrips(dataRequest): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });

    //filter by Date per avere la data anche se già scaduta

    this.listService.newObject(dataRequest, this.pathListTrips + "?filterByDate=false").subscribe(
      response => {
        swal2.close();
        const tripsResponse = response;
       
        this.logger.log("Lista corse trovate ", tripsResponse, 300);
        this.tripsAndata = this.orderTrips(tripsResponse.andata);
        if(this.tripsAndata.length > 0){
          this.originA = this.tripsAndata[0]["origin"];
          this.destinationA = this.tripsAndata[0]["destination"];
          this.routeId = this.tripsAndata[0]["routeId"];
          this.idTripA = this.tripsAndata[0]["tripId"]
          this.price = this.tripsAndata[0]["fare"]["amount"];
          this.tripsRitorno = this.orderTrips(tripsResponse.ritorno);
          this.searchTripList = true;
        }else{
          this.searchTripList = false;
        }
        if (tripsResponse.ritorno.length !== 0) {
          this.tripsRitorno = tripsResponse.ritorno;
          this.originR = this.tripsRitorno[0]["origin"];
          this.destinationR = this.tripsRitorno[0]["destination"];
        }
        // this.completeTripsData(tripsResponse.ritorno);
        else {
          this.tripsRitorno = null;
        }
      },
      error => {
        swal2.close();
        this.logger.log("Error", error, 200);
      }
    );
  }

  orderTrips(arrayTrips) {
    let result = [];
    for (let x = 0; x < arrayTrips.length; x++) {
      if (x === 0) result.push(arrayTrips[x]);
      else {
        let insertValue = false;
        for (let y = 0; y < result.length; y++) {
          if (
            result[y].arrivalTimeOrigin > arrayTrips[x].arrivalTimeOrigin &&
            !insertValue
          ) {
            result.splice(y, 0, arrayTrips[x]);
            insertValue = true;
          }
        }
        if (!insertValue) result.push(arrayTrips[x]);
      }
    }
    return result;
  }

  chooseTripA(chooseId) {
    this.idTripA = chooseId;
    // console.log(this.idTripA);
    console.log(this.idTripA);
  }

  chooseTripR(chooseId) {
    this.idTripR = chooseId;
    console.log(this.idTripR);
    // console.log(this.idTripR);
  }

  changeChooseA() {
    this.idTripA = -1;
  }

  changeChooseR() {
    this.idTripR = -1;
  }

  getResume(dataRequest): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });
    this.listService.newObject(dataRequest, this.pathListTripsResume).subscribe(
      response => {
        swal2.close();
        const tripsResponse = response;
        this.logger.log("Lista viaggi trovate ", tripsResponse, 300);
        this.tripsAndataResume = tripsResponse.andata[0];
        this.tripsAndataResume.dateA = dataRequest["dateA"];
        this.tripsAndataResume.subscription = this.subscriptionValue
        this.tripsAndataResume.cardId = this.cardIdValue

        console.log("VALORE CARDID",  this.cardIdValue);
        // this.completeTripsData(tripsResponse.andata);
          this.cart.push(this.tripsAndataResume);
          this.tripsRitornoResume = null;
      

        console.log("INSERISCO NEL CARRELLO", this.cart);
        this.totalCart();
      },
      error => {
        swal2.close();
        this.logger.log("Error", error, 200);
      }
    );
  }

  bestPrice(wayA, wayR) {
    if (wayA <= wayR) return wayA;
    else return wayR;
  }

  cardIDChange(value){
    this.cardIdValue  = value;
  }

  totalTickets() {
    this.total = 0.0;
    if (this.tripsAndataResume) {
      if (
        this.tripsAndataResume.promotion.amount !== 0 &&
        this.tripsAndataResume.fare.amount >
          this.tripsAndataResume.promotion.amount
      ) {
        this.total += this.tripsAndataResume.promotion.amount;
      } else {
        this.total += this.tripsAndataResume.fare.amount;
      }

      if (this.tripsRitornoResume) {
        if (
          this.tripsRitornoResume.promotion.format !== 0 &&
          this.tripsRitornoResume.fare.amount >
            this.tripsRitornoResume.promotion.amount
        ) {
          this.total += this.tripsRitornoResume.promotion.amount;
        } else {
          this.total += this.tripsRitornoResume.fare.amount;
        }
      }
    }
    return this.total;
  }

  totalCart() {
    this.total = 0.0;
    for (let c of this.cart) {
      if (c.promotion.amount !== 0 && c.fare.amount > c.promotion.amount) {
        this.total += c.promotion.amount;
      } else {
        this.total += c.fare.amount;
      }
    }
  }

  removeFromCart(data) {
    this.cart = this.cart.filter(obj => obj !== data);
    this.totalCart();
  }

  putIntoCart() {
    this.searchTripList = false;

    const dataRequest = {};

    dataRequest["origin"] = this.requestResumeTmp["origin"];
    dataRequest["destination"] = this.requestResumeTmp["destination"];
    if (this.isRoundTrip === false) {
      console.log(this.datepickerModel);
      dataRequest["dateA"] = this.requestResumeTmp["dateA"];
      dataRequest["dateR"] = this.requestResumeTmp["dateR"];
      dataRequest["tripIdA"] = this.idTripA;
    } else {
      dataRequest["dateA"] = this.requestResumeTmp["dateA"];
      dataRequest["dateR"] = this.requestResumeTmp["dateR"];
      dataRequest["tripIdA"] = this.idTripA;
      dataRequest["tripIdR"] = this.idTripR;
    }
    dataRequest["passengers"] = this.passengers;

    this.getResume(dataRequest);
  }

  initializeSoldTicket() {
    var tickets = new TicketCreate();
    
    if (
      localStorage.getItem("deskId") != null &&
      localStorage.getItem("deskId") != undefined &&
      localStorage.getItem("deskId") != ""
    ) {
      tickets.idDesk = localStorage.getItem("deskId");
    }

    if (this.idPrinter !== this.findTripForm.value.idPrinter) {
      localStorage.setItem('defaultPrinterId', this.findTripForm.value.idPrinter);
    }

    if (this.kindValidation) {
      tickets.idCustomerValidation = this.kindValidation;
    }

    for (let t of this.cart) {

      if (this.serialNumber == undefined || this.serialNumber == null) {
        console.log(
          "SERIAL NUMBER UNDE DA VERIFICA PER PDF O STAMP",
          this.serialNumber
        );

        //CONTROLLO POI ELIMINO
        localStorage.setItem("PDF", "printPDF");
      } else {
        console.log("VERIFICA SERIAL NUMBER", this.serialNumber);
        localStorage.setItem("PDF", "noPDF");
      }
      console.log("LISTA PER CARD ID",  t.fare.passengerList);
      console.log("Oggetto PER CARD ID",  this.cart);
     
        for (let f of t.fare.passengerList) {
          for (var _i = 0; _i < f.total; _i++) {
            var ticket = new Ticket();

            var stringDate = "";
            {
              if (t.dateA != null) {
                stringDate = t.dateA + " " + "00:00:00";
              } else {
                stringDate = t.dateR + " " + "00:00:00";
              }
            }
              ticket.idCompany = this.idCompany;
              (ticket.usableFrom = stringDate),
              (ticket.idTrip = null),
              (ticket.idFare = f.fareId);
              (ticket.tolerance = 360),
              (ticket.price = f.amount),
              (ticket.idTicketChannel = this.channel),
              (ticket.printerSerialNumber = this.serialNumber),
              (ticket.origin = t.origin),
              (ticket.destination = t.destination);
              (ticket.category = f.categoryDescription);
              (ticket.idCategory = f.categoryId);
              (ticket.idCard = t.cardId);
              (ticket.subscription = t.subscription);
              (ticket.idRoute = t.routeId);
              

            tickets.tickets.push(ticket);
          }
        }
      
    }

    console.log("COSA MANDO AL SERVER", tickets);
    this.sendTicketToServer(tickets);
  }

  sendTicketToServer(tickets) {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });
    this.listService.newObject(tickets, this.pathListInitTicket).subscribe(
      response => {
        swal2.close();
        const respo = response;
        console.log("RISPOSTA STAMPANTE TICKET", respo.results);

        if (respo.outcome.success === true) {
          var count = 0;

          console.log("RISULTATO DA INVIARE AL SOMMARIO", response.results);
          for (let t of response.results) {
            if (t.outcome.success == true) {
              this.toastr.success('Biglietto correttamente stampato', 'Vendita completata');
              this.dataResponseTickets.push(t);
            }
            if (t.outcome.code == "0019") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato perchè la stampante non è pronta per la stampa"
              );
              this.dataResponseTickets.push(t);
            }
            if (t.outcome.code == "0018") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato perchè la stampante non è raggiungibile"
              );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0020") {
              count = count + 1;
              this.toastr.error("", "Il biglietto non è stato stampato");
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0021") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato, perche non è stata individuata la stampante"
              );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0022") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato, perche la stampante non è raggiungibile"
              );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0023") {
              count = count + 1;
              this.toastr.error("", "Il biglietto non è stato stampato");
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0024" || t.outcome.code == "0025") {
              count = count + 1;
              this.toastr.error("", "Errore nella stampa del biglietto");
              this.dataResponseTickets.push(t);
            }

            console.log("ERRORI STAMPA", count);
          }

          if (
            count == 0 &&
            (this.serialNumber != null || this.serialNumber != undefined)
          ) {
            swal2.fire("La stampa è avvenuta con successo", "", "success");
          } else if (count > 0) {
            swal2.fire(
              "Attenzione",
              "Alcuni biglietti potrebbero non essere stati stampati",
              "warning"
            );
          }

          //PASSARE AL SOMMARIO IN QUESTO PUNTO PASSANDOGLI L'ARRAY RICEVUTO DAL SERVER
          localStorage.setItem(
            "dataResponseTicket",
            JSON.stringify(this.dataResponseTickets)
          );
          console.log("ARRIVO", this.dataResponseTickets);
          this.router.navigate(["/summarySale"]);
        } else {
          if (response.outcome.code == "0013") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          } else if (response.outcome.code == "0015") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          } else {
            this.errorMessage = "Generic Error";
          }

          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }
    );
  }

  clearCategories(): void {
    this.categories = [];
  }

}

export class Ticket {
  id;
  idCompany;
  idLinkedTicket;
  idTicketPricelist;
  idTicketChannel;
  usableFrom;
  usableTo;
  idPromotion;
  idFare;
  idCategory;
  tolerance;
  price;
  printerSerialNumber;
  origin;
  destination;
  category;
  subscription;
  idCard;
  idTrip;
  idRoute;

  constructor() {}
}

export class TicketCreate {
  tickets: any[] = Array();
  idDesk: string;
  idCustomerValidation: number;

  constructor() {}
}
